<ion-toolbar>
    @if (hasLeftButton) {
        <ion-buttons slot="start">
            <ion-button
                class="app-header-button l"
                appTrackClick
                [appTrackClickEnabled]="shouldTrackEvents"
                [identifier]="identifierLeftButton"
                [disabled]="isDisabled()"
                [attr.data-test]="identifierLeftButton"
                (click)="leftButtonAction()"
            >
                <ion-icon name="arrow-back-outline" [color]="this.buttonBackground === 'black' ? 'snowy' : 'dark'" />
            </ion-button>
        </ion-buttons>
    }

    @if (progressRatio) {
        <div class="app-header-progress-bar center">
            <ion-progress-bar color="sophie" [value]="progressRatio" />
            <ion-title class="app-header-progress-bar__title">{{ title }}</ion-title>
        </div>
    } @else {
        <ion-title class="center">
            <h2 data-test="app-header-title">
                {{ title }}
            </h2>
        </ion-title>
    }

    @if (hasRightButton) {
        <ion-buttons class="app-header-end" slot="end">
            <ion-button
                class="app-header-button l"
                appTrackClick
                [appTrackClickEnabled]="shouldTrackEvents"
                [identifier]="identifierRightButton"
                [disabled]="isDisabled()"
                [attr.data-test]="identifierRightButton"
                (click)="rightButtonAction()"
            >
                @if (rightButton.type === RightButtonHeaderType.ION_ICON) {
                    <ion-icon
                        [color]="this.buttonBackground === 'black' ? 'snowy' : 'dark'"
                        [name]="rightButton.content"
                    />
                }
                @if (rightButton.type === RightButtonHeaderType.FA_ICON) {
                    @if (rightButton.pinned) {
                        <div>
                            <i class="fa-solid fa-circle-check"></i>
                        </div>
                    } @else {
                        <div>
                            <i class="fa-thin fa-circle-plus"></i>
                        </div>
                    }
                }
            </ion-button>
            @if (rightButton.type === RightButtonHeaderType.IMAGE) {
                <div class="app-header-end__button app-header-end__button--image">
                    <img [src]="rightButton.content" alt="rightbutton-image" />
                </div>
            }
        </ion-buttons>
    }
</ion-toolbar>
