import { createSelector } from '@ngrx/store';
import { selectIsUsUser } from '@frontend/data-access/user/account';
import { selectOnboardingProgress } from '../../data-access/onboarding.selectors';
import { specialNeedOptions, usSpecialNeedOptions } from './question-special-needs.constants';

export const selectQuestionSpecialNeedsPageVm = createSelector(
    selectOnboardingProgress,
    selectIsUsUser,
    (progress, isUsUser) => ({
        progress,
        specialNeeds: isUsUser ? usSpecialNeedOptions : specialNeedOptions,
    }),
);
