import { AsyncPipe } from '@angular/common';
import { Component, effect } from '@angular/core';
import { accountFeature } from '@frontend/data-access/user/account';
import { Color } from '@shared/utils/typescript';
import { AnalyticsTrackClickDirective } from '@frontend/ui';
import { IonButton, IonFooter, IonInput, IonItem, IonSpinner } from '@ionic/angular/standalone';
import { OnboardingBaseComponent } from '../../onboarding-base/onboarding-base.component';
import { selectQuestionOwnerNamePageVm } from './question-owner-name.page.selectors';
import { OnboardingQuestionTemplateComponent } from '../../onboarding-question-template/onboarding-question-template.component';
import { onboardingQuestionSubmittedOwnerName } from '../../data-access/onboarding.actions';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';

@Component({
    selector: 'app-onboarding-question-owner-name',
    templateUrl: 'question-owner-name.page.html',
    styleUrls: ['../../onboarding.scss'],
    standalone: true,
    imports: [
        AnalyticsTrackClickDirective,
        AsyncPipe,
        IonButton,
        IonFooter,
        IonInput,
        IonItem,
        OnboardingQuestionTemplateComponent,
        ReactiveFormsModule,
        IonSpinner,
    ],
})
export class OnboardingQuestionOwnerNamePage extends OnboardingBaseComponent {
    protected readonly OWNER_NAME_MAX_LENGTH = 32 as const;

    public vm = this.store.selectSignal(selectQuestionOwnerNamePageVm);

    private ownerName = this.store.selectSignal(accountFeature.selectOwnerName);

    public form = new FormGroup({
        ownerName: new FormControl('', {
            validators: [Validators.required, Validators.maxLength(this.OWNER_NAME_MAX_LENGTH)],
            nonNullable: true,
        }),
    });

    protected readonly Color = Color;

    constructor() {
        super();

        effect(() => {
            if (this.ownerName()) {
                this.form.setValue({
                    ownerName: this.ownerName()!,
                });
            }
        });
    }

    public onSubmit(): void {
        if (this.form.invalid || this.vm().isLoading) {
            return;
        }

        this.store.dispatch(
            onboardingQuestionSubmittedOwnerName({
                ownerName: this.form.controls.ownerName.value,
            }),
        );
    }
}
