import { Component } from '@angular/core';
import { AnalyticsTrackClickDirective } from '@frontend/ui';
import { IonicModule } from '@ionic/angular';
import { Color } from '@shared/utils/typescript';

@Component({
    standalone: true,
    selector: 'app-encourage-trial-modal',
    templateUrl: './encourage-trial-modal.component.html',
    styleUrls: ['./encourage-trial-modal.component.scss'],
    imports: [AnalyticsTrackClickDirective, IonicModule],
})
export class EncourageTrialModalComponent {
    protected readonly Color = Color;
}
