import { TopicName } from '@shared/content-api-interface';
import { Step, StepStatus } from '../step/step.model';

export function getTopicProgress(topicTitle: TopicName, steps: Step[]): number {
    const topicSteps = steps.filter((step) => step.content.topic === topicTitle);

    const completedStepsForTopic = topicSteps.filter((step) => step.progress.state === StepStatus.COMPLETED);

    return completedStepsForTopic.length > 0
        ? Math.trunc((completedStepsForTopic.length / topicSteps.length) * 100)
        : 0;
}
