import { inject, Injectable } from '@angular/core';
import { adjustRequestTrackingConsent } from '@frontend/data-access/adjust';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, map, of } from 'rxjs';
import { AccountService } from '../../services/account.service';
import { mapUserDtoToProfile } from '../../utils/account.map';
import { getAccount, getAccountFailure, getAccountSuccess } from './get-account.actions';

@Injectable()
export class GetAccountEffects {
    private readonly actions$ = inject(Actions);
    private readonly accountService = inject(AccountService);

    getAccount$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getAccount),
            concatMap(() =>
                this.accountService.getAccount().pipe(
                    map((userDto) => {
                        return getAccountSuccess({
                            profile: mapUserDtoToProfile(userDto),
                            profileId: userDto.id,
                        });
                    }),
                    catchError(({ error }: { error: Error }) => of(getAccountFailure({ error }))),
                ),
            ),
        );
    });

    requestTrackingConsentOnGetAccountSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getAccountSuccess),
            map(() => adjustRequestTrackingConsent()),
        );
    });
}
