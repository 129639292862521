import { accountFeature, selectProfileBreedInfo } from '@frontend/data-access/user/account';
import { configCatFeature } from '@frontend/data-access/user/config-cat';
import { createSelector } from '@ngrx/store';
import { AnalyticsProfile } from '@frontend/data-access/analytics';
import { hasArrivedAtTimeOfOnboarding, mapConfigCatFlagsToKeyValuePairs } from './analytics.utils';

export const selectAnalyticsProfile = createSelector(
    accountFeature.selectProfile,
    selectProfileBreedInfo,
    configCatFeature.selectConfigCatFlags,
    (profile, breedInfo, featureFlags): AnalyticsProfile => {
        return {
            name: profile?.name,
            dateOfBirth: profile?.dateOfBirth,
            breed: breedInfo?.name,
            breedType: breedInfo?.breedGroup.name,
            gender: profile?.gender,
            ownerName: profile?.ownerName,
            mail: profile?.mail,
            dateOfOnboarding: profile?.dateOfOnboarding,
            dateOfArrival: profile?.dateOfArrival,
            hasArrived: profile?.hasArrived,
            partners: profile?.partners,
            $email: profile?.mail ?? '',
            featureFlags: mapConfigCatFlagsToKeyValuePairs(featureFlags),
            countryCode: profile?.countryCode,
            isRescuePup: profile?.isRescuePup ?? false,
            purinaMarketingAccepted: profile?.purinaMarketingAccepted,
            hasArrivedAtTimeOfOnboarding: hasArrivedAtTimeOfOnboarding(
                profile?.hasArrived,
                profile?.dateOfArrival,
                profile?.dateOfOnboarding,
            ),
            userToken: '',
        };
    },
);

export const selectAnalyticsFeatureFlagProfile = createSelector(
    configCatFeature.selectConfigCatFlags,
    (featureFlags) => ({
        featureFlags: mapConfigCatFlagsToKeyValuePairs(featureFlags),
    }),
);
