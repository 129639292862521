@if (vm$ | async; as vm) {
    @if (course) {
        <ion-card
            class="course-slider-tile tile"
            color="harley"
            [ngStyle]="setBackground()"
            [attr.data-test]="'course-slider-tile-' + course.id"
        >
            <div class="course-slider-tile-left-section">
                <div>
                    <!-- This div is needed to position the top and middle sections into the same flexbox -->
                    <div class="course-slider-tile-top-section">
                        <h2 class="ignore-screen-based-font-scaling">
                            {{ course.title }}
                        </h2>
                    </div>
                    <div class="course-slider-tile-middle-section">
                        <p class="course-slider-tile-intro s">{{ course.introduction }}</p>
                    </div>
                </div>
                <div class="course-slider-tile-bottom-section">
                    <ion-button
                        (click)="openCourseInformationModal(course)"
                        [ngStyle]="{
                            '--color': course.color + '-contrast',
                            '--border-color': course.color + '-contrast'
                        }"
                        class="s course-slider-tile-learn-more-btn ignore-screen-based-font-scaling"
                        data-test="course-slider-learn-more-btn"
                        identifier="course-slider-learn-more-btn"
                        appTrackClick
                        size="small"
                        fill="outline"
                        >Learn more
                    </ion-button>
                </div>
            </div>
            <div class="course-slider-tile-right-section">
                @if (course.status === CourseStatus.INACTIVE) {
                    <div
                        (click)="vm.unlocked ? startCourse(course.id) : openPaymentModal()"
                        data-test="course-slider-tile-start-btn"
                        identifier="course-slider-tile-start-btn"
                        appTrackClick
                    >
                        <i class="fa-thin fa-circle-plus fa-2xl"></i>
                    </div>
                } @else {
                    <circle-progress
                        [attr.data-test]="'course-circle-progress-' + course.id"
                        [options]="getCircleProgressOptions(course)"
                    ></circle-progress>
                }
            </div>
        </ion-card>
    }
}
