import { AsyncPipe } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { requestPasswordReset } from '@frontend/data-access/user/authentication';
import { AnalyticsTrackClickDirective, HeaderComponent, InvalidInputErrorMessageComponent } from '@frontend/ui';
import {
    IonButton,
    IonButtons,
    IonContent,
    IonFooter,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonProgressBar,
    IonTitle,
    IonToolbar,
} from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { addIcons } from 'ionicons';
import { arrowBackOutline, close } from 'ionicons/icons';
import { selectResetPasswordEnterEmailPageVm } from './reset-password-enter-email.page.selectors';
import { routeToPrevious } from '@frontend/data-access/router';

@Component({
    selector: 'app-reset-password-enter-email',
    templateUrl: './reset-password-enter-email.page.html',
    styleUrl: './reset-password-enter-email.page.scss',
    standalone: true,
    imports: [
        IonContent,
        FormsModule,
        ReactiveFormsModule,
        InvalidInputErrorMessageComponent,
        AnalyticsTrackClickDirective,
        AsyncPipe,
        IonFooter,
        IonButton,
        IonItem,
        IonInput,
        IonToolbar,
        IonHeader,
        IonTitle,
        IonButtons,
        IonIcon,
        IonProgressBar,
        HeaderComponent,
    ],
})
export class ResetPasswordEnterEmailPage {
    private readonly store = inject(Store);
    public requestPasswordResetForm = new FormGroup({
        email: new FormControl('', [Validators.required, Validators.email]),
    });

    public vm$ = this.store.select(selectResetPasswordEnterEmailPageVm);

    constructor() {
        addIcons({ close, arrowBackOutline });
    }

    public send(): void {
        const email = this.requestPasswordResetForm.value.email as string;
        this.store.dispatch(requestPasswordReset({ email }));
    }

    public routeToPrevious(): void {
        this.store.dispatch(routeToPrevious({}));
    }

    public showEmailError(): boolean {
        return (
            this.requestPasswordResetForm.controls.email.dirty && this.requestPasswordResetForm.controls.email.invalid
        );
    }
}
