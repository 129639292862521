import {
    getAccount,
    getAccountFailure,
    getAccountSuccess,
    updateAccount,
    updateAccountFailure,
    updateAccountSuccess,
} from '@frontend/data-access/user/account';
import { createFeature, createReducer, on } from '@ngrx/store';
import { onboardingAddSelectedTopic, onboardingRemoveSelectedTopic } from './onboarding.actions';

export interface OnboardingState {
    selectedTopicIds: (string | number)[];
    isLoading: boolean;
}

const initialState: OnboardingState = {
    selectedTopicIds: [],
    isLoading: false,
};

export const onboardingFeature = createFeature({
    name: 'onboarding',
    reducer: createReducer(
        initialState,
        on(onboardingAddSelectedTopic, (state, { topicEntityId }): OnboardingState => {
            return {
                ...state,
                selectedTopicIds: [...state.selectedTopicIds, topicEntityId],
            };
        }),
        on(onboardingRemoveSelectedTopic, (state, { topicEntityId }): OnboardingState => {
            return {
                ...state,
                selectedTopicIds: state.selectedTopicIds.filter((id) => id !== topicEntityId),
            };
        }),
        on(getAccount, updateAccount, (state): OnboardingState => {
            return {
                ...state,
                isLoading: true,
            };
        }),
        on(
            getAccountSuccess,
            getAccountFailure,
            updateAccountSuccess,
            updateAccountFailure,
            (state): OnboardingState => {
                return {
                    ...state,
                    isLoading: false,
                };
            },
        ),
    ),
});
