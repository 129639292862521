import { OptionSelectorSquareOption } from '@frontend/ui';
import { Color } from '@shared/utils/typescript';

export interface AiChatSuggestedQuestionsCardConfig {
    title: string;
    titleUs?: string;
    identifier: string;
    imageUrl: string;
    background: Color;
    options: OptionSelectorSquareOptionConfig[];
}

// TODO: NBSon - can we look at if the label and value are the same, to take from the same field?
interface OptionSelectorSquareOptionConfig {
    id: string;
    label: string;
    labelUs?: string;
    value: string;
    /**
     * TODO: NBSon - this is temporary, see https://www.notion.so/Suggested-topic-tiles-Move-Content-into-Contentful-63cbca24753541ea9bf0140f2a36757b?pvs=4
     *
     * Right now this is ONLY used for the AI chat messages, but, to make this more generic, we should remove this property:
     * - Move the hard-coded prompts to Contentful
     * - Use API to determine the value of the prompt (GB or US)
     * - Remove this field
     */
    valueUs?: string;
}

export interface AiChatSuggestedQuestionsCard {
    title: string;
    identifier: string;
    imageUrl: string;
    background: Color;
    options: OptionSelectorSquareOption[];
}

const charlyAssetPath = 'assets/images/charly/';
const baileyAssetPath = 'assets/images/bailey/';
const buddyAssetPath = 'assets/images/buddy/';
const oliverAssetPath = 'assets/images/oliver/';

// Non Home Recommended Content User Prompts
export const pupNotArrivedOptions: AiChatSuggestedQuestionsCardConfig[] = [
    {
        title: 'Feeding & Chewing',
        identifier: 'feeding',
        imageUrl: `${buddyAssetPath}buddy-licks-food-from-treat-mat.svg`,
        background: Color.Harley,
        options: [
            {
                id: 'how-often-to-feed',
                label: 'How often to feed my pup?',
                value: 'How often should I feed my pup?',
            },
            {
                id: 'do-puppies-drink-milk',
                label: 'Do puppies drink milk?',
                value: 'Do puppies drink milk?',
            },
            {
                id: 'what-can-my-pup-chew-safely',
                label: 'What can my pup chew safely?',
                value: 'What can my pup chew safely?',
            },
        ],
    },
    {
        title: 'Sleep',
        identifier: 'sleep',
        imageUrl: `${oliverAssetPath}oliver-sleeps-in-crate.svg`,
        background: Color.Charly,
        options: [
            {
                id: 'where-should-pup-sleep',
                label: 'Where should my pup sleep?',
                value: 'Where should my pup sleep?',
            },
            {
                id: 'do-i-need-a-crate',
                label: 'Should I buy a crate?',
                value: 'Should I buy a crate for my puppy?',
            },
            {
                id: 'how-much-sleep-should-pup-have',
                label: 'Puppy sleep needs',
                value: 'How much sleep should my puppy have?',
            },
        ],
    },
    {
        title: 'Pup Essentials',
        identifier: 'pup-essentials',
        imageUrl: `${baileyAssetPath}bailey-looks-up-at-owner-with-checklist.svg`,
        background: Color.Sophie,
        options: [
            {
                id: 'do-i-need-a-crate',
                label: 'Do I need a crate?',
                value: 'Do I need a crate?',
            },
            {
                id: 'what-do-i-need-for-my-pup',
                label: 'What do I need for my pup?',
                value: 'What do I need to buy for my puppy?',
            },
            {
                id: 'does-my-pup-need-clothes',
                label: 'Does my pup need clothes?',
                value: 'Does my pup need clothes?',
            },
        ],
    },
];

export const zeroToTwelveWeeksOptions: AiChatSuggestedQuestionsCardConfig[] = [
    {
        title: 'Toilet Training',
        identifier: 'toilet-training',
        imageUrl: `${charlyAssetPath}charly-looks-at-poo.svg`,
        background: Color.Sophie,
        options: [
            {
                id: 'toilet-training-duration',
                label: 'Toilet training duration',
                value: 'How long does toilet training take?',
                valueUs: 'How long does potty training take?',
            },
            {
                id: 'puppy-peeing-in-crate',
                label: 'Puppy peeing in crate',
                value: 'How do I stop my puppy peeing in their crate?',
            },
            {
                id: 'toilet-training-no-garden',
                label: 'Toilet training - no garden',
                value: 'How do I toilet train without a garden?',
            },
        ],
    },
    {
        title: 'Biting',
        identifier: 'biting',
        imageUrl: `${charlyAssetPath}charly-bites-toy-bone.svg`,
        background: Color.Luna,
        options: [
            {
                id: 'how-do-i-stop-puppy-biting',
                label: 'Stop puppy biting',
                value: 'How do I stop my puppy biting me?',
            },
            {
                id: 'when-do-they-lose-teeth',
                label: 'When do they lose teeth?',
                value: 'When does a puppy lose their teeth?',
            },
            {
                id: 'puppy-biting-clothes-and-feet',
                label: 'Puppy biting clothes & feet',
                value: 'My pup is grabbing my clothes and ankles, what should I do?',
            },
        ],
    },
    {
        title: 'Sleep',
        identifier: 'sleep',
        imageUrl: `${oliverAssetPath}oliver-sleeps-in-crate.svg`,
        background: Color.Charly,
        options: [
            {
                id: 'sleeping-through-the-night',
                label: 'Sleeping through the night',
                value: 'When will my puppy sleep through the night?',
            },
            {
                id: 'puppy-crying-at-night',
                label: 'Puppy crying at night',
                value: 'How to stop a puppy crying at night?',
            },
            {
                id: 'puppy-sleep-needs',
                label: 'Puppy sleep needs',
                value: 'How much sleep should my puppy have?',
            },
        ],
    },
];

export const twelveToTwentySixWeekOptions: AiChatSuggestedQuestionsCardConfig[] = [
    {
        title: 'Toilet Training',
        identifier: 'toilet-training',
        imageUrl: `${charlyAssetPath}charly-looks-at-poo.svg`,
        background: Color.Sophie,
        options: [
            {
                id: 'toilet-training-duration',
                label: 'Toilet training duration',
                value: 'How long does toilet training take?',
                valueUs: 'How long does potty training take?',
            },
            {
                id: 'puppy-peeing-in-crate',
                label: 'Puppy peeing in crate',
                value: 'How do I stop my puppy peeing in their crate?',
            },
            {
                id: 'toilet-training-no-garden',
                label: 'Toilet training - no garden',
                value: 'How do I toilet train without a garden?',
            },
        ],
    },
    {
        title: 'Biting',
        identifier: 'biting',
        imageUrl: `${charlyAssetPath}charly-bites-toy-bone.svg`,
        background: Color.Luna,
        options: [
            {
                id: 'how-do-i-stop-puppy-biting',
                label: 'Stop puppy biting',
                value: 'How do I stop my puppy biting me?',
            },
            {
                id: 'when-do-they-lose-teeth',
                label: 'When do they lose teeth?',
                value: 'When does a puppy lose their teeth?',
            },
            {
                id: 'puppy-biting-clothes-and-feet',
                label: 'Puppy biting clothes & feet',
                value: 'My pup is grabbing my clothes and ankles, what should I do?',
            },
        ],
    },
    {
        title: 'Walks & Exercise',
        identifier: 'walks-and-exercise',
        imageUrl: `${baileyAssetPath}bailey-walks-nicely-on-leash.svg`,
        background: Color.Oliver,
        options: [
            {
                id: 'when-can-pup-go-for-walk',
                label: 'When can pup go for a walk?',
                value: 'When can my pup go out for walks?',
            },
            {
                id: 'how-often-exercise',
                label: 'How often to exercise?',
                value: 'How much exercise does my pup need?',
            },
            {
                id: 'puppy-stopping-on-walks',
                label: 'Puppy stopping on walks',
                value: 'How do I stop my puppy stopping on walks?',
            },
        ],
    },
    {
        title: 'Being alone',
        identifier: 'being-alone',
        imageUrl: `${oliverAssetPath}oliver-looks-sadly-out-window.svg`,
        background: Color.Charly,
        options: [
            {
                id: 'teach-pup-to-be-alone',
                label: 'Teach my pup to be alone',
                value: 'How do I teach my pup to be on their own?',
            },
            {
                id: 'stop-pup-crying-on-own',
                label: 'Pup crying on their own',
                value: 'How do I stop my pup from crying on their own?',
            },
            {
                id: 'how-long-leave-pup',
                label: 'How long can I leave them?',
                value: 'How long can I leave my pup for?',
            },
        ],
    },
];

export const twentySixPlusWeeks: AiChatSuggestedQuestionsCardConfig[] = [
    {
        title: 'Being alone',
        identifier: 'being-alone',
        imageUrl: `${oliverAssetPath}oliver-looks-sadly-out-window.svg`,
        background: Color.Charly,
        options: [
            {
                id: 'teach-pup-to-be-alone',
                label: 'Teach my pup to be alone',
                value: 'How do I teach my pup to be on their own?',
            },
            {
                id: 'stop-pup-crying-on-own',
                label: 'Pup crying on their own',
                value: 'How do I stop my pup from crying on their own?',
            },
            {
                id: 'how-long-leave-pup',
                label: 'How long can I leave them?',
                value: 'How long can I leave my pup for?',
            },
        ],
    },
    {
        title: 'Neutering',
        identifier: 'neutering',
        imageUrl: `${charlyAssetPath}charly-sniffs-the-ground.svg`,
        background: Color.Luna,
        options: [
            {
                id: 'should-i-neuter',
                label: 'Should I neuter my pup?',
                value: 'Should I neuter my pup?',
            },
            {
                id: 'when-to-neuter',
                label: 'When to neuter pup?',
                value: 'When should I neuter my pup?',
            },
            {
                id: 'when-will-pup-be-in-season',
                label: 'When will pup be in season',
                value: 'When will my pup have a season?',
            },
        ],
    },
    {
        title: 'Walks & Exercise',
        identifier: 'walks-and-exercise',
        imageUrl: `${baileyAssetPath}bailey-walks-nicely-on-leash.svg`,
        background: Color.Oliver,
        options: [
            {
                id: 'when-can-pup-go-for-walk',
                label: 'When can pup go for a walk?',
                value: 'When can my pup go out for walks?',
            },
            {
                id: 'how-long-walk',
                label: 'How long can my pup walk?',
                value: 'How long can my pup walk?',
            },
            {
                id: 'puppy-stopping-on-walks',
                label: 'Puppy stopping on walks',
                value: 'How do I stop my puppy stopping on walks?',
            },
        ],
    },
];

export const twentySixPlusWeeksAndRescue: AiChatSuggestedQuestionsCardConfig[] = [
    {
        title: 'Socialisation',
        titleUs: 'Socialization',
        identifier: 'socialisation',
        imageUrl: `${charlyAssetPath}charly-copies-older-dog.svg`,
        background: Color.Harley,
        options: [
            {
                id: 'how-do-i-socialise-my-rescue',
                label: 'How do I socialize my rescue?',
                value: 'How do I socialise my rescue?',
                valueUs: 'How do I socialize my rescue?',
            },
            {
                id: 'how-to-introduce-visitors',
                label: 'How to introduce visitors?',
                value: 'How to introduce visitors to my rescue?',
            },
            {
                id: 'meeting-other-dogs',
                label: 'Meeting other dogs?',
                value: 'What is the best way to introduce my rescue to other dogs?',
            },
        ],
    },
    {
        title: 'Rescue Essentials',
        identifier: 'rescue essentials',
        imageUrl: `${oliverAssetPath}oliver-sleeps-in-crate.svg`,
        background: Color.Charly,
        options: [
            {
                id: 'when-will-my-rescue-settle',
                label: 'When will my rescue settle?',
                value: 'When will my rescue settle?',
            },
            {
                id: 'what-is-decompression',
                label: 'What is decompression?',
                value: 'What is decompression when bringing a rescue home?',
            },
            {
                id: 'what-training-can-i-start',
                label: 'What training can I start?',
                value: 'What training can I start with my rescue?',
            },
        ],
    },
    {
        title: 'Focus & Attention',
        identifier: 'focus-and-attention',
        imageUrl: `${baileyAssetPath}bailey-sits-and-looks-up-winking.svg`,
        background: Color.Sophie,
        options: [
            {
                id: 'how-to-teach-watch-me',
                label: 'How to teach "Watch Me"? ',
                value: 'How to teach "Watch Me" to my rescue?',
            },
            {
                id: 'getting-attention-on-walks',
                label: 'Getting attention on walks?',
                value: "How to get my rescue dog's attention when on walks?",
            },
            {
                id: 'how-to-train-recall',
                label: 'How to train recall?',
                value: 'How do I train recall to my rescue?',
            },
        ],
    },
];

export const fiftyTwoPlusWeeks: AiChatSuggestedQuestionsCardConfig[] = [
    {
        title: 'Being alone',
        identifier: 'being-alone',
        imageUrl: `${oliverAssetPath}oliver-sleeps-in-crate.svg`,
        background: Color.Charly,
        options: [
            {
                id: 'teach-dog-to-be-alone',
                label: 'Teach my dog to be alone',
                value: 'How do I teach my dog to be on their own?',
            },
            {
                id: 'stop-dog-crying-on-own',
                label: 'Dog crying on their own',
                value: 'How do I stop my dog from crying on their own?',
            },
            {
                id: 'how-long-leave-dog',
                label: 'How long can I leave my dog?',
                value: 'How long can I leave my dog for?',
            },
        ],
    },
    {
        title: 'Lead walking',
        titleUs: 'Leash walking',
        identifier: 'lead-walking',
        imageUrl: `${baileyAssetPath}bailey-walks-nicely-on-leash.svg`,
        background: Color.Sophie,
        options: [
            {
                id: 'stop-dog-lead-pulling',
                label: 'Stop dog lead pulling',
                labelUs: 'Stop dog leash pulling',
                value: 'How do I stop my dog pulling on lead?',
                valueUs: 'How do I stop my dog pulling on leash?',
            },
            {
                id: 'stop-dog-pulling-to-other-dogs',
                label: 'Dog pulling to other dogs',
                value: 'How do I stop dog pulling towards other dogs?',
            },
            {
                id: 'stop-dog-stopping-on-walks',
                label: 'Dog stopping on walks',
                value: 'How do I stop my dog stopping on walks?',
            },
        ],
    },
    {
        title: 'Barking',
        identifier: 'barking',
        imageUrl: `${charlyAssetPath}charly-sitting-looking-up.svg`,
        background: Color.Luna,
        options: [
            {
                id: 'barking-at-dogs',
                label: 'Barking at other dogs',
                value: 'How do I stop my dog barking at other dogs?',
            },
            {
                id: 'barking-in-garden',
                label: 'Barking in the garden',
                value: 'How do I stop my dog barking in the garden?',
            },
            {
                id: 'get-dog-to-be-quiet',
                label: 'Get dog to be quiet',
                value: 'How can I train my dog to be quiet?',
            },
        ],
    },
    {
        title: 'Leave it',
        identifier: 'leave-it',
        imageUrl: `${buddyAssetPath}buddy-plays-tug-of-war-with-phone.svg`,
        background: Color.Lola,
        options: [
            {
                id: 'stop-eating-poop',
                label: 'Dog eating poop',
                value: 'How do I stop my dog eating poop?',
            },
            {
                id: 'stop-eating-rubbish',
                label: 'Dog eating rubbish',
                labelUs: 'Dog eating trash',
                value: 'How do I stop my dog eating rubbish?',
                valueUs: 'How do I stop my dog eating trash?',
            },
            {
                id: 'stop-dog-chasing-cat',
                label: 'Dog chasing cat',
                value: 'How do I stop my dog chasing cats?',
            },
        ],
    },
];

// Home Recommended Content User Prompts
export const pupNotArrivedOptionsHomeRecommended: AiChatSuggestedQuestionsCardConfig[] = [
    {
        title: 'Feeding & Chewing',
        identifier: 'feeding',
        imageUrl: `${buddyAssetPath}buddy-licks-food-from-treat-mat.svg`,
        background: Color.Harley,
        options: [
            {
                id: 'what-should-i-feed-my-pup',
                label: 'What should I feed my pup?',
                value: 'What should I feed my pup?',
            },
            {
                id: 'how-often-to-feed',
                label: 'How often to feed my pup?',
                value: 'How often should I feed my pup?',
            },
            {
                id: 'do-puppies-drink-milk',
                label: 'Do puppies drink milk?',
                value: 'Do puppies drink milk?',
            },
        ],
    },
    {
        title: 'Sleep',
        identifier: 'sleep',
        imageUrl: `${oliverAssetPath}oliver-sleeps-in-crate.svg`,
        background: Color.Charly,
        options: [
            {
                id: 'where-should-pup-sleep',
                label: 'Where should my pup sleep?',
                value: 'Where should my pup sleep?',
            },
            {
                id: 'do-i-need-a-crate',
                label: 'Should I buy a crate?',
                value: 'Should I buy a crate for my puppy?',
            },
            {
                id: 'how-much-sleep-should-pup-have',
                label: 'Puppy sleep needs',
                value: 'How much sleep should my puppy have?',
            },
        ],
    },
    {
        title: 'Pup Essentials',
        identifier: 'pup-essentials',
        imageUrl: `${baileyAssetPath}bailey-looks-up-at-owner-with-checklist.svg`,
        background: Color.Sophie,
        options: [
            {
                id: 'do-i-need-a-crate',
                label: 'Do I need a crate?',
                value: 'Do I need a crate?',
            },
            {
                id: 'what-do-i-need-for-my-pup',
                label: 'What do I need for my pup?',
                value: 'What do I need to buy for my puppy?',
            },
            {
                id: 'does-my-pup-need-clothes',
                label: 'Does my pup need clothes?',
                value: 'Does my pup need clothes?',
            },
        ],
    },
];

// zeroToTwelveWeeksOptionsHomeRecommended same as zeroToTwelveWeeksOptions

// twelveToTwentySixWeekOptionsHomeRecommended same as twelveToTwentySixWeekOptions

// twentySixPlusWeeksOptionsHomeRecommended is now pupNotArrivedOptionsHomeRecommended

// twentySixPlusWeeksAndRescueHomeRecommended same as twentySixPlusWeeksAndRescue

// fiftyTwoPlusWeeksHomeRecommended same as fiftyTwoPlusWeeks
