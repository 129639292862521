<ion-header class="page-header ion-no-border">
    <ion-toolbar>
        <app-header />
    </ion-toolbar>
</ion-header>

<ion-content [fullscreen]="true">
    <div class="payment-modal-trial-reminder-content">
        <h1 data-test="payment-modal-trial-reminder-title">We’ll remind you 2 days before your trial ends</h1>
        <p data-test="payment-modal-trial-reminder-text">You will get a push notification on {{ trialEndDate }}</p>
        <img src="/assets/images/buddy/buddy-plays-on-back-with-bell.svg" />
    </div>
</ion-content>

<ion-footer class="payment-modal-trial-reminder-footer page-padding-sides">
    <ion-button
        appTrackClick
        identifier="payment-modal-trial-reminder-see-plans-btn"
        color="max"
        expand="full"
        shape="round"
        data-test="payment-modal-trial-reminder-see-plans-btn"
        (click)="seePlansClicked()"
    >
        See plans
    </ion-button>
</ion-footer>
