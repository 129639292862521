<ion-header class="page-header ion-no-border">
    <ion-toolbar>
        <app-header
            title="Reset Password"
            identifierLeftButton="reset-password-waiting-back-btn"
            [hasLeftButton]="true"
            (leftButtonPressed)="routeToPrevious()"
        />
    </ion-toolbar>
</ion-header>

<ion-content
    [fullscreen]="true"
    class="reset-password-waiting-page page-ion-content ion-text-center doodle"
    [style.--bgcolor]="'var(--ion-color-lola-rgb)'"
>
    <div class="page-content ion-justify-content-evenly">
        <div class="reset-password-waiting-page__check-your-mail-img">
            <img src="/assets/images/charly/charly-eats-mail.png" alt="check your email" />
        </div>
        <h1 data-test="reset-password-waiting-title">Check your email</h1>
        @if (email) {
        <p class="ion-padding-start ion-padding-end" data-test="reset-password-waiting-explanation">
            We sent an email to {{ email }}. Be sure to open the email on your phone
        </p>
        }
        <div>
            <img src="assets/images/illustrations/authentication/sign-in-notification.svg" alt="sign-in-notification" />
            <ion-button
                color="max"
                appTrackClick
                data-test="check-mail-sign-in-btn"
                identifier="check-mail-sign-in-btn"
                expand="block"
                (click)="routeToLogin()"
                >Sign in
            </ion-button>
        </div>
    </div>
</ion-content>

<ion-footer class="ion-text-center page-footer" [style.--bgcolor]="'var(--ion-color-lola-rgb)'">
    <button
        data-test="no-email-received"
        class="zz-button reset-password-waiting-page__no-email-received-button"
        appTrackClick
        identifier="no-email-received"
        (click)="routeToPrevious()"
    >
        I didn't receive an email
    </button>
</ion-footer>
