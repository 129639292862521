import { ConfigCatFlag } from '@frontend/data-access/user/config-cat';
import { compareAsc } from 'date-fns';

export function mapConfigCatFlagsToKeyValuePairs(configCatFlags: ConfigCatFlag[]): Record<string, boolean> {
    const flags: Record<string, boolean> = {};

    configCatFlags.forEach((flag) => {
        flags[flag.name] = flag.flagValue;
    });
    return flags;
}

export function hasArrivedAtTimeOfOnboarding(
    hasArrived: boolean | undefined,
    dateOfArrival: Date | undefined,
    dateOfOnboarding: Date | undefined,
): boolean | undefined {
    if (dateOfOnboarding === undefined) {
        return hasArrived;
    }

    if (hasArrived === false || dateOfArrival === undefined) {
        return false;
    }

    const sortDate = compareAsc(dateOfArrival, dateOfOnboarding);

    return sortDate !== 1;
}
