import { Component, computed, input } from '@angular/core';
import { CourseCard } from './course-card.model';
import { CourseCardStepsComponent } from './course-card-steps/course-card-steps.component';
import { CourseStatus } from '../../../store/course/course.model';
import { IonCard, IonCardHeader } from '@ionic/angular/standalone';
import { CircleProgressOptions, NgCircleProgressModule } from 'ng-circle-progress';
import { NgStyle } from '@angular/common';
import { TextReplacementPipe } from '@frontend/data-access/text-replacement';
import { getTemplateThemeColor } from '@shared/utils/typescript';

@Component({
    selector: 'app-course-card-today-component',
    templateUrl: 'course-card.component.html',
    styleUrls: ['course-card.component.scss'],
    standalone: true,
    imports: [NgStyle, IonCardHeader, IonCard, NgCircleProgressModule, CourseCardStepsComponent, TextReplacementPipe],
})
export class CourseCardComponent {
    public course = input.required<CourseCard>();

    public CourseStatus = CourseStatus;

    public circleProgressOptions = computed(() => {
        const title = this.course().completedStepsToday === 3 ? '✓' : `${this.course().completedStepsToday} / 3`;
        const percent = (this.course().completedStepsToday / 3) * 100;
        const innerStrokeColor = `var(--ion-color-${this.course().color})`;

        return {
            showTitle: true,
            title,
            titleFontSize: '14',
            showSubtitle: false,
            clockwise: true,
            startFromZero: false,
            radius: 25,
            showUnits: false,
            percent,
            showBackground: true,
            backgroundColor: 'white',
            backgroundPadding: 0,
            space: 0,
            showZeroOuterStroke: false,
            outerStrokeWidth: 5,
            outerStrokeColor: 'var(--ion-color-max)',
            innerStrokeWidth: 3,
            innerStrokeColor,
            animation: false,
            animateTitle: false,
            renderOnClick: false,
        } as CircleProgressOptions;
    });

    public backgroundColor = computed(() => {
        const courseColor = this.course().color;

        return {
            'background-color':
                this.course().status !== CourseStatus.INACTIVE
                    ? getTemplateThemeColor(courseColor)
                    : getTemplateThemeColor(courseColor, false, 0.5),
        };
    });

    public textColor = computed(() => {
        return {
            color: getTemplateThemeColor(this.course().color, true),
        };
    });
}
