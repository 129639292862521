import { inject, Injectable } from '@angular/core';
import { adjustEventFailure } from '@frontend/data-access/adjust';
import { logoutAnalyticsFailure, trackEvent } from '@frontend/data-access/analytics';
import {
    getLocalStorageFailure,
    removeAllLocalStorageFailure,
    removeLocalStorageFailure,
    setLocalStorageFailure,
} from '@frontend/data-access/capacitor';
import {
    createChannelFailure,
    destroyChatFailure,
    getChatTokenFailure,
    getLatestMessageDateFailure,
    initChatUserFailure,
    watchChannelFailure,
} from '@frontend/data-access/chat';
import {
    getBreedGroupsFailure,
    getCoursesFailure,
    getDailyBitesFailure,
    getDevelopmentalWindowsFailure,
    getFaqsFailure,
    getPageTodayFailure,
    getStepsFailure,
} from '@frontend/data-access/contentful';
import { getGeoLocationFailure } from '@frontend/data-access/geo-location';
import {
    logoutPushNotificationsFailure,
    trackIterablePushOpenEventFailure,
    upsertIterableUserFailure,
} from '@frontend/data-access/push-notification';
import {
    getEntitlementsFailure,
    getOfferingsFailure,
    grantFreeAccessFailure,
    initializeSubscriptionServiceFailure,
    logInToPurchasesFailure,
    purchaseProductFailure,
} from '@frontend/data-access/subscription';
import { deleteAccountFailure, getAccountFailure, updateAccountFailure } from '@frontend/data-access/user/account';
import {
    initializeAuthenticationServiceFailure,
    loginWithAppleFailure,
    loginWithEmailAndPasswordFailure,
    loginWithGoogleFailure,
    logoutFailure,
    requestPasswordResetFailure,
} from '@frontend/data-access/user/authentication';
import {
    addTagFailure,
    getActiveChannelTagsFailure,
    getAllChatTagsFailure,
    removeTagFailure,
} from '@frontend/data-access/user/chat-tags';
import { getConfigCatFeatureFlagsFailure } from '@frontend/data-access/user/config-cat';
import { addPinnedTopicFailure, removePinnedTopicFailure } from '@frontend/data-access/user/pinned-topics';
import {
    createTodayCourseProgressFailure,
    getRescheduledStepsFailure,
    getTodayCourseProgressFailure,
    updateCourseProgressFailure,
    updateTodayCourseProgressFailure,
} from '@frontend/data-access/user/progress';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as Sentry from '@sentry/capacitor';
import { map } from 'rxjs/operators';
import { sendMessageFailure } from '../chat/ai-chat/store/ai-chat.actions';
import { userRatedMessageFailure } from '../chat/chat.actions';
import {
    getFoodPortionFailure,
    loadExpectedWeightFailure,
    loadFoodProductsFailure,
} from '../food-calculator/data-access/store/food-calculator.actions';

@Injectable()
export class ErrorEffects {
    private readonly actions$ = inject(Actions);
    trackAppError$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(
                addTagFailure,
                adjustEventFailure,
                // todo add createAccountFailure but error needs to match typing
                getActiveChannelTagsFailure,
                getAllChatTagsFailure,
                getConfigCatFeatureFlagsFailure,
                getEntitlementsFailure,
                getFoodPortionFailure,
                getOfferingsFailure,
                getRescheduledStepsFailure,
                loadExpectedWeightFailure,
                loadFoodProductsFailure,
                logInToPurchasesFailure,
                loginWithEmailAndPasswordFailure,
                logoutFailure,
                addPinnedTopicFailure,
                purchaseProductFailure,
                removeAllLocalStorageFailure,
                removeLocalStorageFailure,
                getLocalStorageFailure,
                setLocalStorageFailure,
                removeTagFailure,
                requestPasswordResetFailure,
                removePinnedTopicFailure,
                updateCourseProgressFailure,
                getTodayCourseProgressFailure,
                createTodayCourseProgressFailure,
                updateTodayCourseProgressFailure,
                getConfigCatFeatureFlagsFailure,
                getGeoLocationFailure,
                logoutPushNotificationsFailure,
                logoutAnalyticsFailure,
                getChatTokenFailure,
                initChatUserFailure,
                createChannelFailure,
                watchChannelFailure,
                userRatedMessageFailure,
                deleteAccountFailure,
                updateAccountFailure,
                getAccountFailure,
                destroyChatFailure,
                initializeSubscriptionServiceFailure,
                initializeAuthenticationServiceFailure,
                getBreedGroupsFailure,
                getCoursesFailure,
                getDailyBitesFailure,
                getDevelopmentalWindowsFailure,
                getFaqsFailure,
                getPageTodayFailure,
                getStepsFailure,
                upsertIterableUserFailure,
                getLatestMessageDateFailure,
                trackIterablePushOpenEventFailure,
                sendMessageFailure,
                loginWithAppleFailure,
                loginWithGoogleFailure,
                grantFreeAccessFailure,
            ),
            map((action: { error?: Error | string; type: string }) => {
                Sentry.captureException(action.error);
                return trackEvent({
                    eventName: 'App error',
                    eventProperties: {
                        error: action.error ?? 'not provided',
                        action: action.type,
                    },
                });
            }),
        );
    });
}
