import { DatePipe } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { AnalyticsTrackClickDirective, InvalidInputErrorMessageComponent } from '@frontend/ui';
import { IonCard, IonIcon, IonInput, IonItem, IonLabel } from '@ionic/angular/standalone';
import { addIcons } from 'ionicons';
import { informationCircleOutline } from 'ionicons/icons';
import { debounceTime, distinctUntilChanged, startWith } from 'rxjs/operators';
import { TextReplacementPipe } from '@frontend/data-access/text-replacement';
import { FoodPortionQuery } from '../../data-access/food-calculator.model';
import { FoodProductSelectorComponent } from './food-product-selector/food-product-selector.component';
import { WeightSelectorComponent } from './weight-selector/weight-selector.component';

@Component({
    selector: 'app-food-calculator-form',
    templateUrl: './food-calculator-form.component.html',
    styleUrls: ['./food-calculator-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        FormsModule,
        ReactiveFormsModule,
        WeightSelectorComponent,
        FoodProductSelectorComponent,
        AnalyticsTrackClickDirective,
        InvalidInputErrorMessageComponent,
        DatePipe,
        TextReplacementPipe,
        IonCard,
        IonItem,
        IonLabel,
        IonIcon,
        IonInput,
    ],
})
export class FoodCalculatorFormComponent implements OnChanges, OnInit {
    @Input() foodPortionQuery?: Partial<FoodPortionQuery>;
    @Input() foodPortionLastCalculated?: string;

    @Output() showWeightInfoModal = new EventEmitter<void>();
    @Output() formValueChange = new EventEmitter<FoodPortionQuery>();

    minAgeInWeeks = 7;
    minExpectedWeight = 1;
    debounceDelay = 400;

    form = new FormGroup({
        ageInWeeks: new FormControl('', {
            validators: [Validators.required, Validators.min(this.minAgeInWeeks)],
            updateOn: 'blur',
        }),
        currentWeight: new FormControl('', Validators.required),
        expectedWeight: new FormControl('', {
            validators: [Validators.required, Validators.min(1)],
            updateOn: 'blur',
        }),
        foodProductId: new FormControl('', Validators.required),
    });

    constructor() {
        addIcons({ informationCircleOutline });
    }

    ngOnChanges({ foodPortionQuery }: SimpleChanges) {
        if (foodPortionQuery?.firstChange) {
            this.form.setValue(
                {
                    ageInWeeks: '',
                    currentWeight: '',
                    expectedWeight: '',
                    foodProductId: '',
                    ...foodPortionQuery.currentValue,
                },
                { emitEvent: false },
            );
        } else if (foodPortionQuery?.currentValue?.expectedWeight) {
            this.form
                .get('expectedWeight')
                ?.setValue(foodPortionQuery.currentValue.expectedWeight, { emitEvent: false });
        }
    }

    ngOnInit() {
        this.form.valueChanges
            .pipe(startWith(this.form.value), debounceTime(this.debounceDelay), distinctUntilChanged())
            .subscribe((form) => {
                this.formValueChange.emit({
                    ...form,
                    foodProductId: form.foodProductId ?? '',
                    currentWeight: Number.parseFloat(form.currentWeight ?? ''),
                    expectedWeight: Number.parseInt(form.expectedWeight ?? '', 10),
                    ageInWeeks: Number.parseInt(form.ageInWeeks ?? '', 10),
                });
            });
    }
}
