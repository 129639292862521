import { Component, inject } from '@angular/core';
import { DailyBite } from '@frontend/data-access/contentful';
import { DailyBiteCardComponent } from '../../../daily-bites/daily-bite-card/daily-bite-card.component';
import { DailyBiteModalComponent } from '../../../daily-bites/daily-bite-modal/daily-bite-modal.component';
import { HeaderComponent } from '@frontend/ui';
import { IonContent, IonHeader, IonToolbar, Platform, ViewWillEnter, ViewWillLeave } from '@ionic/angular/standalone';
import { Location } from '@angular/common';
import { ModalService } from '@frontend/utility/modal';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { selectLibraryDailyBiteOverviewVm } from './library-daily-bite-overview.selectors';

@Component({
    selector: 'app-library-daily-bite-overview',
    templateUrl: './library-daily-bite-overview.component.html',
    styleUrls: ['./library-daily-bite-overview.component.scss'],
    standalone: true,
    imports: [IonContent, IonHeader, IonToolbar, DailyBiteCardComponent, HeaderComponent],
})
export class LibraryDailyBiteOverviewComponent implements ViewWillEnter, ViewWillLeave {
    private readonly store = inject(Store);
    private readonly location = inject(Location);
    private readonly platform = inject(Platform);
    private readonly modalService = inject(ModalService);

    public vm = this.store.selectSignal(selectLibraryDailyBiteOverviewVm);

    public backButtonSub: Subscription = new Subscription();

    public ionViewWillEnter(): void {
        this.backButtonSub = this.platform.backButton.subscribeWithPriority(20, () => this.routeToPrevious());
    }

    public ionViewWillLeave(): void {
        this.backButtonSub.unsubscribe();
    }

    public routeToPrevious(): void {
        this.location.back();
    }

    public openDailyBiteModal(bite: DailyBite): void {
        void this.modalService.showModal({
            component: DailyBiteModalComponent,
            componentProps: { dailyBite: bite },
        });
    }
}
