@if (vm$ | async; as vm) {
<ion-header class="page-header ion-no-border">
    <ion-toolbar>
        <app-header
            title="Reset Password"
            identifierLeftButton="reset-password-enter-email-back-btn"
            [hasLeftButton]="true"
            (leftButtonPressed)="routeToPrevious()"
        />
    </ion-toolbar>
</ion-header>

<ion-content [fullscreen]="true" class="page-ion-content ion-text-center reset-password-enter-email">
    <main class="page-content ion-justify-content-center">
        <section>
            <h1>Enter your email</h1>
            <p class="ion-padding-start ion-padding-end">We’ll send you a link to reset your password</p>
        </section>
        <form [formGroup]="requestPasswordResetForm">
            <ion-item>
                <ion-input
                    class="zz-input"
                    data-test="email-input"
                    type="email"
                    placeholder="Email"
                    formControlName="email"
                    [tabindex]="1"
                />
            </ion-item>
            @if (showEmailError()) {
            <app-invalid-input-error-message errorMessage="Please fill in a valid email" />
            }
        </form>
    </main>
</ion-content>

@if (!vm.isIonInputFocusedOnAndroid) {
<ion-footer class="ion-text-center page-footer">
    <ion-button
        appTrackClick
        identifier="send-email-button"
        data-test="send-email-button"
        color="max"
        expand="block"
        [disabled]="!requestPasswordResetForm.valid"
        (click)="send()"
    >
        Send Email
    </ion-button>
</ion-footer>
} }
