@if (vm$ | async; as vm) {
    @if (vm.step) {
        <ion-content class="page-ion-content">
            <div
                class="lesson-intro-page-header"
                [ngStyle]="{ 'background-color': 'var(--ion-color-' + vm.step.topic.color + ')' }"
            >
                <ion-header class="page-header ion-no-border">
                    <ion-toolbar>
                        <app-header
                            buttonBackground="white"
                            identifierLeftButton="step-prev-btn"
                            identifierRightButton="step-close-btn"
                            [hasLeftButton]="true"
                            [hasRightButton]="true"
                            (leftButtonPressed)="previousPage()"
                            (rightButtonPressed)="closeLesson()"
                        />
                    </ion-toolbar>
                </ion-header>
                <div class="lesson-intro-page-header__image-container">
                    <img
                        class="lesson-intro-page-header__image"
                        [src]="vm.step.topic.imageUrl"
                        [alt]="vm.step.topic.title + ' image'"
                    />
                </div>
            </div>

            <section class="lesson-intro-page-content lesson-column">
                <div class="lesson-intro-page-content__situation">
                    <span>{{ vm.step.content.duration }} min | </span>
                    <ion-icon [name]="vm.locationIcon" class="lesson-intro-page-content__location-icon" />
                    <span>{{ vm.step.content.location }}</span>
                </div>

                <h1 data-test="step-intro-page-title">{{ vm.step.content.title | textReplacement }}</h1>

                @if (vm.step.content.lessonAuthor) {
                    <app-author-chip
                        [author]="vm.step.content.lessonAuthor.authorName"
                        [authorImageUrl]="vm.step.content.lessonAuthor.authorImageUrl"
                    />
                }

                <p data-test="step-intro-page-introduction">
                    {{ vm.step.content.introduction | textReplacement }}
                </p>

                @for (dynamicContent of vm.step.content.introductionDynamicContent; track dynamicContent.title) {
                    <app-dynamic-content-block [title]="dynamicContent.title" [content]="dynamicContent.content" />
                }

                @if (vm.showWhatsNeeded && vm.step.content.whatsNeeded) {
                    <ion-card class="lesson-intro-page-whats-needed">
                        <h2 class="no-margin-top">You will need</h2>
                        <div class="lesson-intro-page-whats-needed__container ion-no-padding">
                            @if (vm.step.content.whatsNeeded.treats.length > 0) {
                                <div class="lesson-intro-page-whats-needed__container-item">
                                    <p class="no-margin">
                                        <i class="fa-regular fa-bone lesson-intro-page-whats-needed__icon"></i>
                                    </p>
                                    <div>
                                        @for (treat of vm.step.content.whatsNeeded.treats; track treat) {
                                            <p class="no-margin">{{ treat }}</p>
                                        }
                                    </div>
                                </div>
                            }
                            @if (vm.step.content.whatsNeeded.toys.length > 0) {
                                <div class="lesson-intro-page-whats-needed__container-item">
                                    <p class="no-margin">
                                        <i class="fa-regular fa-duck lesson-intro-page-whats-needed__icon"></i>
                                    </p>
                                    <div>
                                        @for (toy of vm.step.content.whatsNeeded.toys; track toy) {
                                            <p class="no-margin">{{ toy }}</p>
                                        }
                                    </div>
                                </div>
                            }
                            @if (vm.step.content.whatsNeeded.equipmentAccessories.length > 0) {
                                <div class="lesson-intro-page-whats-needed__row">
                                    <p class="no-margin">
                                        <i class="fa-regular fa-suitcase lesson-intro-page-whats-needed__icon"></i>
                                    </p>
                                    <div>
                                        @for (
                                            equipment of vm.step.content.whatsNeeded.equipmentAccessories;
                                            track equipment
                                        ) {
                                            <p class="no-margin">{{ equipment }}</p>
                                        }
                                    </div>
                                </div>
                            }
                            @if (vm.step.content.whatsNeeded.other.length > 0) {
                                <div class="lesson-intro-page-whats-needed__container-item">
                                    <p class="no-margin">
                                        <i class="fa-regular fa-paw lesson-intro-page-whats-needed__icon"></i>
                                    </p>
                                    <div>
                                        @for (thing of vm.step.content.whatsNeeded.other; track thing) {
                                            <p class="no-margin">{{ thing }}</p>
                                        }
                                    </div>
                                </div>
                            }
                        </div>
                    </ion-card>
                }

                @if (vm.shouldShowSelfSetReminderTile) {
                    <h2>Want to train later?</h2>
                    <app-generic-card-slim
                        data-test="lesson-intro-training-reminder-card"
                        [background]="Color.Luna"
                        [imageUrl]="'assets/images/charly/charly-gets-notification.svg'"
                        [title]="'Set training reminder'"
                        [unlocked]="true"
                        (click)="setTrainingReminder(vm.dogName)"
                    />
                }
            </section>

            <ion-footer class="gradient-footer lesson-intro-page-footer">
                @if (vm.unlocked) {
                    @if (
                        !(
                            vm.step.progress.state === StepState.COMPLETED ||
                            vm.step.progress.state === StepState.SKIPPED
                        )
                    ) {
                        <ion-button
                            class="lesson-intro-page-footer__skip"
                            appTrackClick
                            identifier="step-intro-skip-lesson"
                            color="max"
                            expand="block"
                            fill="outline"
                            data-test="lesson-intro-skip-lesson-btn"
                            (click)="showSkipNotificationModal(vm.step)"
                        >
                            Skip
                        </ion-button>
                    }
                    <ion-button
                        class="lesson-intro-page-footer__start"
                        appTrackClick
                        identifier="step-page-view-steps"
                        color="max"
                        expand="block"
                        data-test="lesson-intro-start-lesson-btn"
                        (click)="nextPage()"
                    >
                        Start
                    </ion-button>
                } @else {
                    <ion-button
                        class="lesson-intro-page-footer__start"
                        appTrackClick
                        identifier="step-intro-start-free-trial-btn"
                        color="max"
                        expand="block"
                        data-test="lesson-intro-start-free-trial-btn"
                        (click)="openPaymentModal()"
                    >
                        <i
                            class="fa-regular fa-lock-keyhole ion-margin-end icon-large lesson-intro-page-footer__padlock-icon"
                        ></i>
                        {{ !vm.hasHistoricalPurchase ? 'Start my free trial' : 'See plans' }}
                    </ion-button>
                }
            </ion-footer>
        </ion-content>
    }
}
