import { AsyncPipe } from '@angular/common';
import { Component } from '@angular/core';
import { Color } from '@shared/utils/typescript';
import { AnalyticsTrackClickDirective, MultiSelectComponent, MultiSelectOption } from '@frontend/ui';
import { IonButton, IonCard, IonFooter } from '@ionic/angular/standalone';
import { OnboardingBaseComponent } from '../../onboarding-base/onboarding-base.component';
import { selectQuestionTopicPageVm } from './question-topic.page.selectors';
import { routeTo } from '@frontend/data-access/router';
import { OnboardingQuestionTemplateComponent } from '../../onboarding-question-template/onboarding-question-template.component';
import { OnboardingRoutes } from '../../onboarding-routing.model';
import {
    onboardingAddSelectedTopic,
    onboardingQuestionSubmittedTopic,
    onboardingRemoveSelectedTopic,
} from '../../data-access/onboarding.actions';

@Component({
    selector: 'app-question-topic',
    templateUrl: './question-topic.page.html',
    styleUrls: ['../../onboarding.scss', 'question-topic.page.scss'],
    standalone: true,
    imports: [
        AnalyticsTrackClickDirective,
        AsyncPipe,
        IonButton,
        IonCard,
        IonFooter,
        MultiSelectComponent,
        OnboardingQuestionTemplateComponent,
    ],
})
export class OnboardingQuestionTopicPage extends OnboardingBaseComponent {
    public vm = this.store.selectSignal(selectQuestionTopicPageVm);

    protected readonly Color = Color;

    public openExtraTopics(): void {
        this.store.dispatch(routeTo({ commands: ['onboarding', OnboardingRoutes.TOPIC_EXTRA], extras: {} }));
    }

    public continue(): void {
        this.store.dispatch(onboardingQuestionSubmittedTopic());
    }

    public updateTopics(option: MultiSelectOption): void {
        if (option.selected) {
            this.store.dispatch(
                onboardingAddSelectedTopic({
                    topicEntityId: option.value as string,
                }),
            );
        } else {
            this.store.dispatch(
                onboardingRemoveSelectedTopic({
                    topicEntityId: option.value as string,
                }),
            );
        }
    }
}
