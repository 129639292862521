import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, of, tap, throwError } from 'rxjs';
import { User } from '../models/user.model';
import { watchForLoginStateChange } from '../store/authentication.actions';
import { AuthenticationService } from './authentication.service';

const mockAuthLoggedInKey = 'mock-auth-service-logged-in';

@Injectable()
export class MockAuthenticationService extends AuthenticationService {
    private readonly store = inject(Store);

    public token$: Observable<string | null> = of(null);

    private loggedIn$ = new BehaviorSubject<boolean>(false);
    private suffix = 'from-mock-auth-service';
    private user?: User;

    constructor() {
        super();

        if (localStorage.getItem(mockAuthLoggedInKey)) {
            console.log('[Mock Authentication Service] Logged in user from local storage');

            this.loggedIn$.next(true);

            this.user = { email: 'logged.in.from.localstorage@test.navara.nl', id: 'logged-in-from-local-storage' };
        }
    }

    public initialize(): void {
        console.log('[Mock Authentication Service] Initialize');
        this.store.dispatch(watchForLoginStateChange());
    }

    public signUpWithEmailAndPassword(email: string, password: string): Observable<{ user: User }> {
        console.log(`[Mock Authentication Service] Sign Up With Email And Password ${email}`);
        return this.signUpOrLogin(email, password);
    }

    public loginWithEmailAndPassword(email: string, password: string): Observable<{ user: User }> {
        console.log(`[Mock Authentication Service] Login With Email And Password ${email}`);
        return this.signUpOrLogin(email, password);
    }

    public loginWithGoogle(): any {
        console.log('[Mock Authentication Service] Login With Google');
    }

    public loginWithApple(): any {
        console.log('[Mock Authentication Service] Login With Apple');
    }

    public signUpOrLogin(email: string, password: string): Observable<{ user: User }> {
        console.log(`[Mock Authentication Service] Sign Up Or Login ${email}`);

        if (password === 'wrong-password') {
            return throwError(() => new Error('Authentication error triggered in MockAuthenticationService'));
        }

        this.user = { email, id: `fake-id-${this.suffix}` };
        this.token$ = of(`fake-token-${this.suffix}`);

        this.loggedIn$.next(true);

        return of({ user: this.user });
    }

    public logout(): Observable<void> {
        console.log('[Mock Authentication Service] Logout');

        localStorage.removeItem(mockAuthLoggedInKey);

        this.user = undefined;

        this.loggedIn$.next(false);

        this.token$ = of(null);

        return of<void>(undefined);
    }

    public isLoggedIn(): Observable<boolean> {
        return this.loggedIn$.pipe(
            tap((value) => {
                console.log(`[Mock Authentication Service] Login State changed to: ${value}`);
            }),
        );
    }

    public requestPasswordReset(email: string): Observable<void> {
        console.log(`[Mock Authentication Service] Request Password Reset ${email}`);

        return of<void>(undefined);
    }

    public getAccount(): Observable<{ user: User }> {
        console.log('[Mock Authentication Service] GetAccount');

        if (this.user) {
            return of({ user: this.user });
        } else {
            return throwError(() => new Error('User is not defined'));
        }
    }
}
