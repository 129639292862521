import { inject, Injectable } from '@angular/core';
import { platformReady } from '@frontend/data-access/platform';
import { timeFeature } from '@frontend/data-access/time';
import { ModalService } from '@frontend/utility/modal';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { combineLatest, EMPTY, of } from 'rxjs';
import { catchError, concatMap, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { trackEvent } from '@frontend/data-access/analytics';
import { FoodCalculatorDisclaimerModalComponent } from '../../feature-food-calculator/food-calculator-disclaimer-modal/food-calculator-disclaimer-modal.component';
import { FoodCalculatorService } from '../food-calculator.service';
import {
    checkExpectedWeight,
    foodCalculatorPageLoaded,
    foodPortionQueryUpdated,
    getFoodPortion,
    getFoodPortionFailure,
    getFoodPortionSuccess,
    loadExpectedWeight,
    loadExpectedWeightFailure,
    loadExpectedWeightSuccess,
    loadFoodProducts,
    loadFoodProductsFailure,
    loadFoodProductsSuccess,
    showFoodCalculatorDisclaimerModal,
} from './food-calculator.actions';
import { selectExpectedWeight, selectExpectedWeightQuery } from './food-calculator.selectors';
import { getLocalStorage, getLocalStorageSuccess, setLocalStorage } from '@frontend/data-access/capacitor';
import { FOOD_CALCULATOR_ACTION_SOURCE, FOOD_CALCULATOR_LOCAL_STORAGE_KEY } from './food-calculator.constants';
import { ofSourceType } from '@shared/utils/typescript';
import { foodCalculatorFeature } from './food-calculator.reducer';

@Injectable()
export class FoodCalculatorEffects {
    private store = inject(Store);
    private actions$ = inject(Actions);
    private foodCalculatorService = inject(FoodCalculatorService);
    private modalService = inject(ModalService);

    triggerInitialFoodCalculatorActions$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(foodCalculatorPageLoaded),
            switchMap(() => [loadFoodProducts(), checkExpectedWeight()]),
        );
    });

    showFoodCalculatorDisclaimerModal$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(showFoodCalculatorDisclaimerModal),
                tap(() => {
                    void this.modalService.showModal({
                        component: FoodCalculatorDisclaimerModalComponent,
                        cssClass: ['modal', 'modal-small'],
                    });
                }),
                filter(() => false),
            );
        },
        { dispatch: false },
    );

    loadFoodProducts$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(loadFoodProducts),
            concatMap(() =>
                this.foodCalculatorService.getProducts().pipe(
                    map((foodProducts) => loadFoodProductsSuccess({ foodProducts })),
                    catchError((error: Error) => of(loadFoodProductsFailure({ error }))),
                ),
            ),
        );
    });

    checkExpectedWeight$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(checkExpectedWeight),
            concatLatestFrom(() => this.store.select(selectExpectedWeight)),
            filter(([_, expectedWeight]) => !expectedWeight),
            map(() => loadExpectedWeight()),
        );
    });

    // TODO: NBSon - refactor this to use filter?
    loadExpectedWeight$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(loadExpectedWeight),
            concatLatestFrom(() => this.store.select(selectExpectedWeightQuery)),
            concatMap(([_, query]) => {
                if (query) {
                    return this.foodCalculatorService.getExpectedWeight(query).pipe(
                        map((expectedWeight) => loadExpectedWeightSuccess({ expectedWeight })),
                        catchError((error: Error) => of(loadExpectedWeightFailure({ error }))),
                    );
                } else {
                    return EMPTY;
                }
            }),
        );
    });

    getFoodPortionOnQueryChange$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(foodPortionQueryUpdated),
            filter(({ foodPortionQuery }) => {
                const { foodProductId, currentWeight, expectedWeight, ageInWeeks } = foodPortionQuery;

                return !!foodProductId && !!currentWeight && !!expectedWeight && !!ageInWeeks;
            }),
            map(({ foodPortionQuery }) => {
                return getFoodPortion({ foodPortionQuery });
            }),
        );
    });

    getFoodPortion$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getFoodPortion),
            switchMap(({ foodPortionQuery }) => {
                return this.foodCalculatorService.getPortion(foodPortionQuery).pipe(
                    concatLatestFrom(() => {
                        return this.store.select(timeFeature.selectToday);
                    }),
                    map(([amountPerDay, currentDate]) => getFoodPortionSuccess({ amountPerDay, currentDate })),
                    catchError(({ error }) => [getFoodPortionFailure({ error, foodPortionQuery })]),
                );
            }),
        );
    });

    trackGetFoodPortionEvent$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getFoodPortion),
            concatLatestFrom(() => this.store.select(foodCalculatorFeature.selectFoodProducts)),
            map(([{ foodPortionQuery }, foodProducts]) => {
                const foodProduct = foodProducts.find((product) => product.id === foodPortionQuery.foodProductId);
                return trackEvent({
                    eventName: 'calculateFoodPortion',
                    eventProperties: {
                        ...foodPortionQuery,
                        foodProduct: foodProduct?.name ?? 'unknown',
                    },
                });
            }),
        );
    });

    trackFailedFoodPortionCall$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getFoodPortionFailure),
            map(({ foodPortionQuery }) =>
                trackEvent({ eventName: 'Failed food portion call', eventProperties: { foodPortionQuery } }),
            ),
        );
    });

    // Think about how this could be generic
    // Start listening action
    // Local storage key
    // Action source to listen to
    // Selector to pull state from

    readFoodCalculatorStateFromLocalStorage$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(platformReady),
            map(() => getLocalStorage({ key: FOOD_CALCULATOR_LOCAL_STORAGE_KEY })),
        );
    });

    syncFoodCalculatorStateWithLocalStorage$ = createEffect(() => {
        return combineLatest([
            this.actions$.pipe(
                // State needs to be read first, before we want to start saving.
                ofType(getLocalStorageSuccess),
                filter(({ key }) => key === FOOD_CALCULATOR_LOCAL_STORAGE_KEY),
                take(1),
            ),
            this.actions$.pipe(
                // Save on every action
                ofSourceType(FOOD_CALCULATOR_ACTION_SOURCE),
            ),
        ]).pipe(
            concatLatestFrom(() => this.store.select(foodCalculatorFeature.selectFoodCalculatorState)),
            map(([, state]) => setLocalStorage({ key: FOOD_CALCULATOR_LOCAL_STORAGE_KEY, data: state })),
        );
    });
}
