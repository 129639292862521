import { AsyncPipe, Location } from '@angular/common';
import { Component, Input, inject } from '@angular/core';
import { AnalyticsTrackClickDirective, HeaderComponent } from '@frontend/ui';
import { IonButton, IonContent, IonFooter, IonHeader, IonToolbar } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { routeTo } from '@frontend/data-access/router';

@Component({
    selector: 'app-reset-password-waiting',
    templateUrl: './reset-password-waiting.page.html',
    styleUrls: ['./reset-password-waiting.page.scss'],
    standalone: true,
    imports: [
        IonContent,
        AnalyticsTrackClickDirective,
        IonButton,
        IonFooter,
        AsyncPipe,
        HeaderComponent,
        IonHeader,
        IonToolbar,
    ],
})
export class ResetPasswordWaitingPage {
    private readonly location = inject(Location);
    private readonly store = inject(Store);
    @Input({ required: true }) email!: string;

    routeToPrevious(): void {
        void this.location.back();
    }

    routeToLogin(): void {
        void this.store.dispatch(routeTo({ commands: ['auth', 'login'] }));
    }
}
