import { Adjust } from '@awesome-cordova-plugins/adjust/ngx';
import { TitleCasePipe } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { APP_INITIALIZER, enableProdMode, ErrorHandler, importProvidersFrom } from '@angular/core';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
    PreloadAllModules,
    provideRouter,
    Router,
    RouteReuseStrategy,
    withComponentInputBinding,
    withPreloading,
} from '@angular/router';
import { BASE_ENVIRONMENT_CONFIG, ProjectEnvironment, WINDOW } from '@frontend/configuration';
import { ZigzagFrontendDataAccessSentryModule } from '@frontend/data-access/sentry';
import { CHAT_CONFIG } from '@frontend/data-access/chat';
import { AUTHENTICATION_CONFIG } from '@frontend/data-access/user/authentication';
import { CONFIGCAT_CONFIG } from '@frontend/data-access/user/config-cat';
import { effects, metaReducers, zigzagMainFeatureStates, ZigzagMainModule } from '@frontend/zigzag-main';
import { Preferences } from '@capacitor/preferences';
import { IonicRouteStrategy, provideIonicAngular } from '@ionic/angular/standalone';
import { provideEffects } from '@ngrx/effects';
import { provideStore } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { TranslateModule } from '@ngx-translate/core';
import * as SentrySibling from '@sentry/angular-ivy';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { NgrxFormsModule } from 'ngrx-forms';
import { StreamChatModule, StreamTextareaModule } from 'stream-chat-angular';
import { register } from 'swiper/element/bundle';
import { MIXPANEL_FRONTEND_CONFIG } from '@frontend/data-access/analytics';
import { ZigzagFrontendDataAccessApplicationInsightsModule } from '@frontend/data-access/application-insights';
import { AppComponent } from './app/app.component';
import appRoutes from './app/app.routes';
import { httpInterceptorProviders } from './app/http-interceptors';
import { adjustConfigProvider } from './environments/configuration-providers/adjust-config.provider';
import { applicationInsightsConfigProvider } from './environments/configuration-providers/application-insights-config.provider';
import { environment } from './environments/environment/environment';
import { ENVIRONMENT } from './environments/injection-tokens';
import { LOCAL_STORAGE_PLUGIN } from './injection-tokens/local-storage.injection-token';
import { SUBSCRIPTION_CONFIG } from '@frontend/data-access/subscription';
import { ZigzagFeatureAuthenticationModule } from '@frontend/feature/authentication';
import { ZigzagFeatureOnboardingModule } from '@frontend/feature/onboarding';

// swiper
register();

if (environment.baseEnvironmentConfig.production) {
    enableProdMode();
}

bootstrapApplication(AppComponent, {
    providers: [
        importProvidersFrom(
            provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
            NgrxFormsModule,
            StreamChatModule,
            StreamTextareaModule,
            TranslateModule.forRoot(),
            NgCircleProgressModule.forRoot(),
            ZigzagMainModule,
            ZigzagFeatureAuthenticationModule,
            ZigzagFeatureOnboardingModule,
            ...(environment.baseEnvironmentConfig.environment === ProjectEnvironment.PRODUCTION
                ? [ZigzagFrontendDataAccessSentryModule]
                : []),
            ...(environment.applicationInsights.active ? [ZigzagFrontendDataAccessApplicationInsightsModule] : []),
        ),
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        provideRouter(appRoutes, withPreloading(PreloadAllModules), withComponentInputBinding()),
        provideIonicAngular({
            mode: 'ios',
            useSetInputAPI: true,
        }),
        provideStore(
            {},
            {
                metaReducers,
                runtimeChecks: {
                    strictStateImmutability: true,
                    strictActionImmutability: true,
                },
            },
        ),
        ...zigzagMainFeatureStates,
        provideEffects(effects),
        ...(environment.baseEnvironmentConfig.environment === ProjectEnvironment.DEVELOPMENT
            ? [
                  provideStoreDevtools({
                      connectInZone: true,
                  }),
              ]
            : []),
        Adjust,
        TitleCasePipe,
        httpInterceptorProviders,
        adjustConfigProvider,
        applicationInsightsConfigProvider,
        { provide: ENVIRONMENT, useValue: environment },
        { provide: MIXPANEL_FRONTEND_CONFIG, useValue: environment.mixpanelConfig },
        { provide: LOCAL_STORAGE_PLUGIN, useValue: Preferences },
        { provide: WINDOW, useValue: window },
        { provide: AUTHENTICATION_CONFIG, useValue: environment.firebaseConfig },
        {
            provide: BASE_ENVIRONMENT_CONFIG,
            useValue: environment.baseEnvironmentConfig,
        },
        {
            provide: SUBSCRIPTION_CONFIG,
            useValue: {
                revenueCatKey: environment.revenueCatKey,
                useSubscriptionMock: environment.useSubscriptionMock,
            },
        },
        {
            provide: CHAT_CONFIG,
            useValue: environment.streamChatConfig,
        },
        {
            provide: CONFIGCAT_CONFIG,
            useValue: environment.configCatConfig,
        },
        ...(environment.baseEnvironmentConfig.environment === ProjectEnvironment.PRODUCTION
            ? [
                  {
                      provide: ErrorHandler,
                      // Attach the Sentry ErrorHandler
                      useValue: SentrySibling.createErrorHandler(),
                  },
                  {
                      provide: SentrySibling.TraceService,
                      deps: [Router],
                  },
                  {
                      provide: APP_INITIALIZER,
                      // eslint-disable-next-line @typescript-eslint/no-empty-function
                      useFactory: () => () => {},
                      deps: [SentrySibling.TraceService],
                      multi: true,
                  },
              ]
            : []),
        provideAnimations(),
        provideHttpClient(withInterceptorsFromDi()),
    ],
}).catch((err) => console.error(err));
