<ion-header class="page-header ion-no-border">
    <ion-toolbar>
        <app-header
            buttonBackground="white"
            identifierRightButton="close-review-modal-btn"
            [hasRightButton]="true"
            (rightButtonPressed)="closeModal()"
        />
    </ion-toolbar>
</ion-header>

<ion-content>
    <div class="chat-review-modal-content-container page-padding-sides page-padding-bottom">
        <div class="modal-top">
            <div class="chat-review-modal-image-container ion-align-self-center">
                <img [src]="'/assets/images/bailey/bailey-holds-one-ear-up-listening.svg'" alt="chat-review image" />
            </div>
            @switch (modalPage) {
                @case (1) {
                    <h2 data-test="chat-review-title" class="ion-text-center">
                        Was this chat helpful to resolve your issue?
                    </h2>
                    <p class="chat-review-modal-sub-title ion-text-center">We would love to get your feedback</p>
                    <div class="chat-review-feedback-buttons">
                        <div
                            class="button-negative"
                            data-test="chat-negative-review-btn"
                            (click)="page1ReviewButton('negative')"
                        >
                            <img [src]="'/assets/images/illustrations/chat/button-neg.svg'" alt="Button neg image" />
                        </div>
                        <div
                            class="button-positive"
                            data-test="chat-positive-review-btn"
                            (click)="page1ReviewButton('positive')"
                        >
                            <img [src]="'/assets/images/illustrations/chat/button-pos.svg'" alt="Button pos image" />
                        </div>
                    </div>
                }
                @case (2) {
                    <h2 data-test="chat-negative-review-title" class="ion-text-center">
                        Oh, no, sorry about that! Is there anything we could’ve done better?
                    </h2>
                    <div class="comment-input-container">
                        <ion-item class="comment-input">
                            <ion-input
                                #commentInput
                                class="comment-ion-input"
                                autocapitalize="sentences"
                                placeholder="Please add your comment"
                                data-test="comment-input"
                                type="text"
                                appTrackClick
                                identifier="comment-input"
                                [tabindex]="1"
                            ></ion-input>
                        </ion-item>
                    </div>
                }
                @case (3) {
                    <h2 data-test="chat-review-thank-you-title" class="ion-text-center chat-thank-you-title">
                        Thank you for your feedback!
                    </h2>
                }
            }
        </div>
        <div class="modal-bottom">
            @switch (modalPage) {
                @case (2) {
                    <div class="button-container">
                        <ion-button
                            color="max"
                            expand="block"
                            appTrackClick
                            identifier="chat-review-submit-btn"
                            data-test="chat-review-submit-btn"
                            [disabled]="!commentReviewInput?.value"
                            (click)="page2ReviewButton(false)"
                            >Submit
                        </ion-button>
                        <button
                            appTrackClick
                            identifier="chat-review-skip-btn"
                            data-test="chat-review-skip-btn"
                            class="zz-button as-div ion-text-center"
                            (click)="page2ReviewButton(true)"
                        >
                            Skip
                        </button>
                    </div>
                }
                @case (3) {
                    <div class="button-container">
                        <ion-button
                            color="max"
                            expand="block"
                            appTrackClick
                            identifier="chat-review-close-btn"
                            data-test="chat-review-close-btn"
                            (click)="closeModal()"
                            >Close
                        </ion-button>
                    </div>
                }
            }
        </div>
    </div>
</ion-content>
