<ion-card class="course-card" data-test="course-card">
    <ion-card-header class="course-card__header" [ngStyle]="backgroundColor()">
        <h1 class="course-card__title" [ngStyle]="textColor()" data-test="course-card-title">
            {{ course().title }}
        </h1>
        @if (course().status !== CourseStatus.INACTIVE) {
            <circle-progress
                class="course-card__progress"
                data-test="course-circle-progress"
                [options]="circleProgressOptions()"
            />
        }
    </ion-card-header>
    <app-course-card-today-steps-component
        class="course-card__next-lessons"
        data-test="course-card-steps-component"
        [course]="course()"
    />
    @if (course().courseCompliment) {
        <section
            class="course-card__motivational-message ignore-global-color"
            data-test="course-card-motivational-message"
        >
            <p class="s">
                {{ course().courseCompliment | textReplacement }}
            </p>
        </section>
    }
</ion-card>
