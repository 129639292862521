@if (vm$ | async; as vm) {
    <div>
        <div
            [ngStyle]="{ transform: 'translate(' + step.coordinates.x + 'px,' + step.coordinates.y + 'px)' }"
            class="course-path-step-container"
            data-test="course-path-step"
        >
            <div class="course-path-step-inner-container">
                @if (step.topic && step.progress) {
                    <div
                        [ngStyle]="
                            step.isFirstInactiveStep && vm.isCourseActive
                                ? setBorderAnimationColor(vm.selectedCourseColor)
                                : null
                        "
                        [ngClass]="['course-path-step', step.topic.color, vm.unlocked ? step.progress.state : 'locked']"
                        (click)="goToStep(step, vm.unlocked)"
                        data-test="course-path-step-icon"
                    >
                        <div>
                            @if (!step.progress.isPractice) {
                                <div class="rating-container">
                                    @if (step.progress.rating && step.progress.state !== StepState.INACTIVE) {
                                        <div class="circle-container">
                                            @for (number of [1, 2, 3, 4, 5]; track number) {
                                                <ion-icon
                                                    [color]="
                                                        number <= step.progress.rating ? 'oliver' : 'disabled-text'
                                                    "
                                                    name="star"
                                                ></ion-icon>
                                            }
                                        </div>
                                    }
                                </div>
                            } @else {
                                <img
                                    [src]="'/assets/images/illustrations/step/step-practice-text.svg'"
                                    alt="This is a practice lesson"
                                    class="step-practice-image image-no-max-width"
                                />
                            }
                            <div class="step-icon">
                                <img
                                    [ngSrc]="step.thumbnailUrl"
                                    fill="true"
                                    loading="eager"
                                    alt="step-image"
                                    class="{{ mapStepTypeToImageClass(step.content.contentType) }}"
                                />
                                @if (vm.unlocked) {
                                    @if (!step.progress.isPractice && iconType[step.progress.state]) {
                                        <div>
                                            <ion-icon
                                                [class]="step.progress.state"
                                                [name]="iconType[step.progress.state]"
                                                class="state-icon"
                                            ></ion-icon>
                                        </div>
                                    }
                                } @else {
                                    <div>
                                        <ion-icon class="state-icon locked" name="lock-closed"></ion-icon>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                }
                <p class="path-step-title ignore-screen-based-font-scaling xs">{{ step.content.title }}</p>
            </div>
        </div>
    </div>
}
