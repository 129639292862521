import { Injectable, inject } from '@angular/core';
import { ModalController } from '@ionic/angular/standalone';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { from } from 'rxjs';
import { Store } from '@ngrx/store';
import { concatMap, filter, switchMap } from 'rxjs/operators';
import { InfoDialogComponent } from '../../components/info-dialog/info-dialog.component';
import { selectContentUnlocked } from '../payment/store/payment.selectors';
import { openInfoDialog } from './info-dialog.actions';

@Injectable()
export class InfoDialogEffects {
    private readonly actions$ = inject(Actions);
    private readonly modalController = inject(ModalController);
    private readonly store = inject(Store);
    openInfoDialog$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(openInfoDialog),
                concatLatestFrom(() => this.store.select(selectContentUnlocked)),
                concatMap(([{ dialogProps, supportProps }, contentUnlocked]) =>
                    from(
                        this.modalController.create({
                            cssClass: ['modal', 'modal-info-notification'],
                            component: InfoDialogComponent,
                            componentProps: { dialogProps, supportProps, contentUnlocked },
                        }),
                    ).pipe(
                        switchMap((modal) => {
                            void modal.present(); // todo should this be await?
                            return from(modal.onDidDismiss());
                        }),
                    ),
                ),
                filter(() => false),
            );
        },
        { dispatch: false },
    );
}
