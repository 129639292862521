import { NgClass, NgOptimizedImage } from '@angular/common';
import { Component, Input } from '@angular/core';
import { Color } from '@shared/utils/typescript';
import { StepType } from '@shared/content-api-interface';
import { IonIcon } from '@ionic/angular/standalone';
import { addIcons } from 'ionicons';
import { checkmarkOutline, glassesOutline, lockClosed, playForward, star } from 'ionicons/icons';
import { StepStatus } from '../../store/step/step.model';
import { LessonLocation } from './models/lesson-location.model';
import { StepCardSlimModel } from './models/step-card-slim.model';

@Component({
    standalone: true,
    selector: 'app-step-card-slim',
    templateUrl: './step-card-slim.component.html',
    styleUrls: ['./step-card-slim.component.scss'],
    imports: [IonIcon, NgClass, NgOptimizedImage],
})
export class StepCardSlimComponent {
    @Input({ required: true }) public content!: StepCardSlimModel;
    public StepStatus = StepStatus;
    public Color = Color;
    public StepType = StepType;
    public LessonLocation = LessonLocation;

    public stepStateIcon: Record<string, string> = {
        [StepStatus.READ]: 'glasses-outline',
        [StepStatus.SKIPPED]: 'play-forward',
        [StepStatus.COMPLETED]: 'checkmark-outline',
    };

    constructor() {
        addIcons({ glassesOutline, playForward, checkmarkOutline, lockClosed, star });
    }
}
