import { NgClass } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { IonButton, IonInput, IonItem } from '@ionic/angular/standalone';
import {
    ChatMessageRatingFeedbackOption,
    ChatMessageRatingFeedbackSubmittedEvent,
} from './chat-message-rating-feedback.model';

@Component({
    standalone: true,
    imports: [ReactiveFormsModule, IonItem, IonButton, IonInput, NgClass],
    selector: 'app-chat-message-rating-feedback',
    templateUrl: './chat-message-rating-feedback.component.html',
    styleUrls: ['./chat-message-rating-feedback.component.scss'],
})
export class ChatMessageRatingFeedbackComponent {
    @Input()
    otherFeedbackOptionLabel = 'Something else';
    @Input()
    feedbackOptions: ChatMessageRatingFeedbackOption[] = [
        {
            key: 'not-clear',
            value: 'Not clear',
        },
        {
            key: 'too-generic',
            value: 'Too generic',
        },
        {
            key: 'incorrect',
            value: 'Incorrect',
        },
    ];

    @Output()
    optionSelected: EventEmitter<ChatMessageRatingFeedbackOption> = new EventEmitter<ChatMessageRatingFeedbackOption>();
    @Output()
    feedbackSubmitted: EventEmitter<ChatMessageRatingFeedbackSubmittedEvent> =
        new EventEmitter<ChatMessageRatingFeedbackSubmittedEvent>();

    commentFormControl = new FormControl();

    selectedFeedbackOption: ChatMessageRatingFeedbackOption | undefined;
    shouldShowCommentInput = false;

    private otherFeedbackOption: ChatMessageRatingFeedbackOption = {
        key: 'other',
        value: this.otherFeedbackOptionLabel,
    };

    onClickFeedbackOption(option: ChatMessageRatingFeedbackOption) {
        this.selectedFeedbackOption = option;
        this.shouldShowCommentInput = false;

        this.optionSelected.emit(option);
    }

    onSelectOtherFeedbackOption() {
        this.selectedFeedbackOption = undefined;
        this.shouldShowCommentInput = true;

        this.optionSelected.emit(this.otherFeedbackOption);
    }

    onClickDone() {
        if (this.shouldShowCommentInput) {
            this.feedbackSubmitted.emit({
                option: this.otherFeedbackOption,
                comment: this.commentFormControl?.value,
            });

            return;
        }

        if (this.selectedFeedbackOption) {
            this.feedbackSubmitted.emit({
                option: this.selectedFeedbackOption,
            });

            return;
        }

        this.feedbackSubmitted.emit();
    }
}
