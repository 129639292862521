@if (vm$ | async; as vm) { @if (vm.topic) {
<ion-header class="topic-page-header page-header ion-no-border">
    <ion-toolbar class="topic-page-header__toolbar">
        <app-header
            title="Library"
            toolbarBackground="var(--ion-color-app-background)"
            identifierLeftButton="topic-back-button"
            [identifierRightButton]="getRightButtonIdentifier(vm.topic)"
            [hasLeftButton]="true"
            [hasRightButton]="true"
            [rightButton]="getRightButtonOptions(vm.topic)"
            (leftButtonPressed)="routeToLibrary()"
            (rightButtonPressed)="handlePinButtonClick(vm.topic.pinned, vm.topic.entityId)"
        />
    </ion-toolbar>
</ion-header>

<ion-content color="app-background">
    <main class="topic-page">
        <section class="topic-page__title-container">
            <h1 class="topic-page__title">{{ vm.topic.title }}</h1>
            <circle-progress
                data-test="topic-progress"
                [options]="getCircleProgressOptions(vm.topic, vm.progress)"
            ></circle-progress>
        </section>

        @if (vm.topic.intro) {
        <p class="topic-page__topic-intro">{{ vm.topic.intro | textReplacement }}</p>
        }
        <ion-card>
            @for (step of vm.steps; track step.id) {
            <app-step-card-slim
                appTrackClick
                identifier="to-step-from-library"
                [content]="step"
                [attr.data-test]="'step-card-' + step.id"
                (click)="goToStep(step, vm.topic.entityId)"
            >
            </app-step-card-slim>
            }
        </ion-card>
        <section>
            <h2 class="no-margin">Have a question about {{ vm.topic.title.toLowerCase() }}?</h2>
            @if (vm.aiChatEnabled) {
            <app-generic-card-slim
                appTrackClick
                [identifier]="'topic-page-ziggy-ai-tile'"
                [attr.data-test]="'topic-page-ziggy-ai-tile'"
                [background]="Color.Sophie"
                [imageUrl]="'assets/images/ziggy/ziggy-close-up.svg'"
                [title]="'Ask Ziggy'"
                [unlocked]="vm.aiChatEnabled"
                (click)="openZiggyChat()"
            ></app-generic-card-slim>
            } @else {
            <app-generic-card-slim
                appTrackClick
                [identifier]="vm.contentUnlocked ? 'topic-page-expert-support-tile' : 'topic-page-expert-support-tile-locked'"
                [attr.data-test]="vm.contentUnlocked ? 'topic-page-expert-support-tile' : 'topic-page-expert-support-tile-locked'"
                [background]="Color.Harley"
                [imageUrl]="'/assets/images/trainers/coach-petrina-red-circle.png'"
                [title]="'Chat with a training coach'"
                [unlocked]="vm.contentUnlocked"
                (click)="vm.contentUnlocked ? routeToChat(): openPaymentModal('topic-page-expert-support-tile-locked')"
            ></app-generic-card-slim>
            }
        </section>
    </main>
</ion-content>
} }
