import { NgClass } from '@angular/common';
import { Component, inject, Input } from '@angular/core';
import { dismissInfo } from '@frontend/data-access/dismissible-info';
import { AnalyticsTrackClickDirective } from '@frontend/ui';
import { Color } from '@shared/utils/typescript';
import { ModalService } from '@frontend/utility/modal';
import { IonContent, IonIcon } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { addIcons } from 'ionicons';
import { closeOutline } from 'ionicons/icons';
import { PupHappinessFeedbackButton } from './model/pup-happiness-modal.model';
import { PUP_HAPPINESS_MODAL_ID } from './pup-happiness-modal.constant';
import { trackEvent } from '@frontend/data-access/analytics';

@Component({
    standalone: true,
    selector: 'app-pup-happiness-modal',
    templateUrl: './pup-happiness-modal.component.html',
    styleUrls: ['./pup-happiness-modal.component.scss'],
    imports: [IonContent, IonIcon, AnalyticsTrackClickDirective, NgClass],
})
export class PupHappinessModalComponent {
    @Input({
        required: true,
    })
    public dogName!: string;

    private store = inject(Store);
    private modalService = inject(ModalService);

    public buttons: PupHappinessFeedbackButton[] = [
        {
            id: 'sad',
            icon: 'fa-face-frown',
            text: 'Sad',
        },
        {
            id: 'ok',
            icon: 'fa-face-meh',
            text: 'Ok',
        },
        {
            id: 'happy',
            icon: 'fa-face-smile',
            text: 'Happy',
        },
    ];

    protected readonly Color = Color;

    constructor() {
        addIcons({ closeOutline });
    }

    public closeModal(buttonId?: string): void {
        if (buttonId) {
            this.store.dispatch(
                trackEvent({
                    eventName: `[Pup Happiness] ${buttonId} Button Clicked`,
                }),
            );
        }

        void this.modalService.dismiss({
            dismissed: true,
        });
    }

    public dontShowAgain(): void {
        this.store.dispatch(
            dismissInfo({
                id: PUP_HAPPINESS_MODAL_ID,
                dontShowAgain: true,
            }),
        );

        this.closeModal('dont show again');
    }
}
