<ion-text class="ion-text-center"
    ><h2>Why do you want to<br />skip this lesson?</h2></ion-text
>
<div class="buttons">
    @for (skipReason of skipStepOptions.reasons; track skipReason.id) {
        <ion-button
            [id]="skipReason.id"
            appTrackClick
            [identifier]="'step-skip-reason-' + skipReason.id"
            (click)="skipLesson(skipReason)"
            class="skip-reason-button"
            >{{ skipReason.text | textReplacement }}</ion-button
        >
    }
    <ion-button
        (click)="cancelLessonStep()"
        class="lesson-button"
        color="max"
        appTrackClick
        identifier="step-skip-cancel-button"
        >Cancel
    </ion-button>
</div>
