import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { AnalyticsTrackClickDirective } from '../directives/tracking/analytics-track-click.directive';

@Component({
    standalone: true,
    selector: 'app-audio-player',
    templateUrl: './audio-player.component.html',
    styleUrls: ['./audio-player.component.scss'],
    imports: [AnalyticsTrackClickDirective],
})
export class AudioPlayerComponent implements AfterViewInit {
    @Input({ required: true })
    audio!: {
        url: string;
        title: string;
    };

    @ViewChild('audioPlayer')
    audioPlayer!: ElementRef;

    private audioElement!: HTMLAudioElement;

    isAudioPlaying = false;
    audioProgress = 0;
    audioRemainingTime = '0:00';
    audioDuration = 0;

    ngAfterViewInit() {
        this.audioElement = this.audioPlayer.nativeElement as HTMLAudioElement;

        this.audioElement.addEventListener('loadedmetadata', () => {
            this.audioDuration = this.audioElement.duration;
            this.updateRemainingTime(this.audioElement.currentTime);
        });
    }

    async toggleAudio() {
        if (this.isAudioPlaying) {
            this.audioElement.pause();
        } else {
            await this.audioElement.play();
            this.updateProgress();
        }

        this.isAudioPlaying = !this.isAudioPlaying;
    }

    // stops the audio scrubbing to where the button is when clicked
    // but still allows the user to stop / start audio playback
    onPlayButtonClick(event: MouseEvent) {
        event.stopPropagation();
    }

    updateProgress() {
        this.audioDuration = this.audioElement.duration;
        this.updateRemainingTime(this.audioElement.currentTime);

        this.audioProgress = (this.audioElement.currentTime / this.audioElement.duration) * 100;

        if (this.audioElement.currentTime < this.audioElement.duration) {
            requestAnimationFrame(() => this.updateProgress());
        } else {
            this.isAudioPlaying = false;
            this.audioProgress = 0;
            this.audioRemainingTime = '0:00';
        }
    }

    updateRemainingTime(currentTime: number) {
        const remainingSeconds = Math.max(this.audioDuration - currentTime, 0);
        const minutes = Math.floor(remainingSeconds / 60);
        const seconds = Math.floor(remainingSeconds % 60);

        this.audioRemainingTime = `${minutes.toString()}:${seconds.toString().padStart(2, '0')}`;
    }

    seekAudio(event: MouseEvent) {
        const targetOfClick = event.currentTarget as HTMLElement;
        const progressRect = targetOfClick.getBoundingClientRect();
        const clickPosition = event.clientX - progressRect.left;
        const percentageClicked = (clickPosition / progressRect.width) * 100;

        if (this.audioElement.duration) {
            this.audioElement.currentTime = (percentageClicked / 100) * this.audioElement.duration;
        }
    }
}
