import { Injectable, inject } from '@angular/core';
import { dismissInfo } from '@frontend/data-access/dismissible-info';
import {
    createRescheduledSteps,
    createStepProgress,
    createStepProgressSuccess,
    getRescheduledSteps,
    getStepProgress,
    selectCompletedStepProgress,
    selectCompletedStepsCount,
    selectRescheduledStepsPractice,
    selectRescheduledStepsPracticeIdsWithCount,
    selectSkippedStepProgress,
    setRescheduledStepsAsInactive,
} from '@frontend/data-access/user/progress';
import { ModalService } from '@frontend/utility/modal';
import { StepType, TopicName } from '@shared/content-api-interface';
import { CourseProgressType, LessonProgressType, RescheduledStepType } from '@shared/user-domain';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { concatMap } from 'rxjs';
import { delay, filter, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { AdjustEventType, trackAdjustEvent } from '@frontend/data-access/adjust';
import { trackEvent } from '@frontend/data-access/analytics';
import { selectSelectedCourseWithStepsAndSplits } from '../../../courses/course-path/store/course-path.selectors';
import { launchRatingQualifierModal } from '../../../rating/app-rating-qualifier/store/rating-qualifier-modal.actions';
import { LincolnSurveyModalComponent } from '../../../today/lincoln-survey-modal/lincoln-survey-modal.component';
import { selectCoursesWith100PercentProgressButNotCompleted } from '../../course/course-with-progress.selectors';
import { selectSteps } from '../../step/step.selectors';
import { triggerUpdateCourseProgress } from '../course-progress/course-progress.actions';
import {
    setStepCompleted,
    setStepRead,
    setStepSkipped,
    triggerCreateStepProgress,
    updateJumpToProgressForSelectedCourse,
} from './step-progress.actions';
import { CreateSkippedStepProgress } from './step-progress.model';
import { getInactiveOrReadStepIdsBeforeIndex, mapCreateStepProgressToStepProgressPayload } from './step-progress.utils';
import { getAccountSuccess } from '@frontend/data-access/user/account';
import { onboardingComplete } from '@frontend/feature/onboarding';

@Injectable()
export class StepProgressEffects {
    private readonly actions$ = inject(Actions);
    private readonly store = inject(Store);
    private readonly modalService = inject(ModalService);
    triggerGetStepProgressGetAccountSuccess$ = createEffect(() => {
        return this.actions$.pipe(ofType(getAccountSuccess)).pipe(map(() => getStepProgress()));
    });

    triggerGetStepProgressOnboardingComplete$ = createEffect(() => {
        return this.actions$.pipe(ofType(onboardingComplete)).pipe(map(() => getStepProgress()));
    });

    triggerGetRescheduledStepsGetAccountSuccess$ = createEffect(() => {
        return this.actions$.pipe(ofType(getAccountSuccess)).pipe(map(() => getRescheduledSteps()));
    });

    triggerGetRescheduledStepsOnboardingComplete$ = createEffect(() => {
        return this.actions$.pipe(ofType(onboardingComplete)).pipe(map(() => getRescheduledSteps()));
    });

    triggerCreateStepProgress$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(triggerCreateStepProgress),
            concatLatestFrom(() => this.store.select(selectSteps)),
            map(([{ progress }, steps]) => {
                const mappedProgress = mapCreateStepProgressToStepProgressPayload(progress, steps);

                return createStepProgress({ progress: mappedProgress });
            }),
        );
    });

    setStepRead$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(setStepRead),
            concatLatestFrom(() => [
                this.store.select(selectCompletedStepProgress),
                this.store.select(selectSkippedStepProgress),
            ]),
            filter(([{ properties }, completedStepProgress, skippedStepProgress]) => {
                const isAlreadyCompleted = !!completedStepProgress.find(
                    (progress) => progress.stepId === properties.stepId,
                );
                const isAlreadySkipped = !!skippedStepProgress.find(
                    (progress) => progress.stepId === properties.stepId,
                );

                return !isAlreadyCompleted && !isAlreadySkipped;
            }),
            map(([{ properties }]) => {
                return triggerCreateStepProgress({
                    progress: [
                        {
                            stepId: properties.stepId,
                            progressType: LessonProgressType.READ,
                        },
                    ],
                });
            }),
        );
    });

    setStepSkipped$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(setStepSkipped),
            concatLatestFrom(() => this.store.select(selectCompletedStepProgress)),
            filter(([{ properties }, completedStepProgress]) => {
                return !completedStepProgress.find((progress) => progress.stepId === properties.stepId);
            }),
            map(([{ properties }]) => {
                return triggerCreateStepProgress({
                    progress: [
                        {
                            stepId: properties.stepId,
                            progressType: LessonProgressType.SKIPPED,
                            skipReason: properties.skipReason,
                        },
                    ],
                });
            }),
        );
    });

    setStepCompleted$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(setStepCompleted),
            map(({ properties }) => {
                return triggerCreateStepProgress({
                    progress: [
                        {
                            stepId: properties.stepId,
                            progressType: LessonProgressType.COMPLETED,
                            rating: properties.rating,
                            isPractice: properties.isPractice,
                        },
                    ],
                });
            }),
        );
    });

    trackFiveStepsCompleted$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(createStepProgressSuccess),
            map(({ progress }) => {
                return progress.filter((progressItem) => progressItem.progress === LessonProgressType.COMPLETED);
            }),
            filter((completedProgress) => completedProgress.length > 0),
            concatLatestFrom(() => this.store.select(selectCompletedStepsCount)),
            filter(([_, completedStepCount]) => completedStepCount === 4),
            switchMap(() => [
                trackEvent({ eventName: 'Five lessons completed' }),
                trackAdjustEvent({ event: AdjustEventType.FIVE_LESSONS_COMPLETED }),
            ]),
        );
    });

    updateProgressForJumpToNextPartInCourse$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(updateJumpToProgressForSelectedCourse),
            concatLatestFrom(() => this.store.select(selectSelectedCourseWithStepsAndSplits)),
            map(([{ split }, course]) => {
                return course ? getInactiveOrReadStepIdsBeforeIndex(course.steps, split.splitIndex) : [];
            }),
            filter((stepIds) => stepIds.length > 0),
            map((stepIds) => {
                const progress: CreateSkippedStepProgress[] = stepIds.map((stepId) => ({
                    stepId,
                    progressType: LessonProgressType.SKIPPED,
                    skipReason: 'jump-to-next-part',
                }));

                return triggerCreateStepProgress({
                    progress,
                });
            }),
        );
    });

    checkIfStepCompletionShouldLaunchRatingPrompt$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(createStepProgressSuccess),
            delay(1500),
            map(({ progress }) => {
                return progress.filter((progressItem) => progressItem.progress === LessonProgressType.COMPLETED);
            }),
            filter((completedProgress) => completedProgress.length > 0),
            concatLatestFrom(() => this.store.select(selectSteps)),
            filter(([progress, steps]) => {
                const stepsToCheck = steps.filter((step) =>
                    progress.some((progressItem) => progressItem.stepId === step.id),
                );

                return stepsToCheck.some(
                    (step) => step.topic.title === TopicName.SIT && step.content.contentType === StepType.LESSON,
                );
            }),
            map(() => {
                return launchRatingQualifierModal({ trigger: 'sit-lesson' });
            }),
        );
    });

    setExistingPracticeAsInactiveIfRatingIsHigh$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(createStepProgressSuccess),
            map(({ progress }) => {
                return progress.filter(
                    (progressItem) =>
                        progressItem.progress === LessonProgressType.COMPLETED &&
                        !!progressItem.rating &&
                        progressItem.rating > 3,
                );
            }),
            filter((completedProgress) => completedProgress.length > 0),
            concatLatestFrom(() => this.store.select(selectRescheduledStepsPractice)),
            map(([completedProgress, rescheduledSteps]) => {
                const progressStepIds = completedProgress.map((progressItem) => progressItem.stepId);
                const rescheduledStepIds = rescheduledSteps.map((rescheduleStep) => rescheduleStep.stepId);

                return rescheduledStepIds.find((stepId) => progressStepIds.includes(stepId));
            }),
            filter((stepId) => stepId !== undefined),
            map((stepId) => {
                return setRescheduledStepsAsInactive({ stepId: stepId! });
            }),
        );
    });

    checkForCourseToComplete$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(createStepProgressSuccess),
            concatLatestFrom(() => this.store.select(selectCoursesWith100PercentProgressButNotCompleted)),
            filter(([_, courses]) => courses.length > 0),
            switchMap(([_, courses]) => {
                // probably only one course is ever completed in this way. In the future we should re-work this potentially
                return courses.map((course) => {
                    return triggerUpdateCourseProgress({
                        courseProgress: { id: course.id, progressType: CourseProgressType.COMPLETED },
                    });
                });
            }),
        );
    });

    createRescheduledStepForPracticeWhenRatingIsLow$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(createStepProgressSuccess),
            concatLatestFrom(() => this.store.select(selectRescheduledStepsPracticeIdsWithCount)),
            map(([{ progress }, practiceItems]) => {
                // only return items that need to create a rescheduled step
                return progress.filter((progressItem) => {
                    const isRating3OrLower = !!progressItem.rating && progressItem.rating <= 3;

                    if (!isRating3OrLower) {
                        return false;
                    }

                    if (progressItem.isPractice) {
                        return true;
                    }

                    const hasExistingPractice = practiceItems.find(
                        (item) => item.stepId === progressItem.stepId,
                    )?.count;
                    return !hasExistingPractice;
                });
            }),
            filter((progress) => progress.length > 0),
            concatMap((progress) => {
                // for all items create a rescheduled step
                return progress.map((progressItem) => {
                    return createRescheduledSteps({
                        rescheduledSteps: [
                            {
                                stepId: progressItem.stepId,
                                contentId: progressItem.contentId,
                                type: RescheduledStepType.PRACTICED,
                                isActive: true,
                            },
                        ],
                    });
                });
            }),
        );
    });

    showLincolnSurveyModal$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(createStepProgressSuccess),
            concatLatestFrom(() => this.store.select(selectCompletedStepsCount)),
            filter(([_, completedStepCount]) => completedStepCount === 42),
            tap((): void => {
                void this.modalService.showModal({
                    component: LincolnSurveyModalComponent,
                    cssClass: ['modal-center', 'lincoln-modal-background'],
                });
            }),
            mergeMap(() => {
                return [
                    dismissInfo({ id: 'lincoln-survey-modal' }),
                    trackEvent({ eventName: '[Separation Anxiety] Show Lincoln Survey Modal' }),
                ];
            }),
        );
    });
}
