import { NgTemplateOutlet } from '@angular/common';
import { Component, inject, Input, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { AnalyticsTrackClickDirective, HeaderComponent } from '@frontend/ui';
import { ModalService } from '@frontend/utility/modal';
import { IonButton, IonContent, IonFooter, IonHeader, IonToolbar } from '@ionic/angular/standalone';
import { DismissibleModalConfig } from './dismissible-modal.config';

@Component({
    standalone: true,
    selector: 'app-dismissible-modal',
    templateUrl: './dismissible-modal.component.html',
    styleUrls: ['./dismissible-modal.component.scss'],
    imports: [
        NgTemplateOutlet,
        AnalyticsTrackClickDirective,
        IonContent,
        IonFooter,
        IonToolbar,
        IonButton,
        HeaderComponent,
        IonHeader,
    ],
})
export class DismissibleModalComponent {
    public readonly modalService = inject(ModalService);
    private readonly router = inject(Router);
    @Input() vm: DismissibleModalConfig = {
        modalId: 'unknown',
        useModalTemplate: true,
        closeable: true,
    };

    @Input() modalContent: TemplateRef<unknown> | undefined;

    public callToAction(): void {
        if (this.vm?.mainButtonPath) {
            void this.router.navigate([this.vm.mainButtonPath]);
        } else if (this.vm?.mainButtonAction) {
            this.vm.mainButtonAction();
        }

        this.closeModal();
    }

    public closeModal(): void {
        void this.modalService.dismiss({
            dismissed: true,
        });
    }
}
