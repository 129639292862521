import { CourseProgressType } from '@shared/user-domain';
import { IsNumber, IsString } from 'class-validator';

export class InternalPostCourseProgressCommand {
    @IsString()
    courseId!: string;

    progressType!: CourseProgressType;

    @IsNumber()
    timestamp!: number;
}
