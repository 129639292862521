import { NgStyle } from '@angular/common';
import { Component, Input, model } from '@angular/core';
import { IonCard, IonChip } from '@ionic/angular/standalone';
import { Color } from '@shared/utils/typescript';
import { OptionSelectorSquareOption } from '../models/option-selector-square.model';

@Component({
    standalone: true,
    selector: 'app-option-selector-square',
    templateUrl: './option-selector-square.component.html',
    styleUrls: ['./option-selector-square.component.scss'],
    imports: [NgStyle, IonCard, IonChip],
})
export class OptionSelectorSquareComponent {
    @Input({ required: true }) public title!: string;
    @Input({ required: true }) public imageUrl!: string;
    @Input({ required: true }) public background!: Color;
    @Input({ required: true }) public options!: OptionSelectorSquareOption[];

    public selected = model<OptionSelectorSquareOption | undefined>();

    public optionClicked(option: OptionSelectorSquareOption) {
        if (this.selected()?.id === option.id) {
            this.clearSelection();
        } else {
            this.selected.set(option);
        }
    }

    public clearSelection(): void {
        this.selected.set(undefined);
    }
}
