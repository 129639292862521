import { NgClass, NgStyle } from '@angular/common';
import { Component, input } from '@angular/core';
import { Color } from '@shared/utils/typescript';
import { IonCard, IonIcon } from '@ionic/angular/standalone';
import { addIcons } from 'ionicons';
import { lockClosed } from 'ionicons/icons';

@Component({
    standalone: true,
    selector: 'app-generic-card-slim',
    templateUrl: './generic-card-slim.component.html',
    styleUrl: './generic-card-slim.component.scss',
    imports: [NgClass, NgStyle, IonCard, IonIcon],
})
export class GenericCardSlimComponent {
    public title = input.required<string>();
    public imageUrl = input.required<string>();
    public background = input.required<Color>();
    public unlocked = input.required<boolean>();

    public disabled = input<boolean>(false);

    constructor() {
        addIcons({ lockClosed });
    }
}
