@if (vm()) {
    <ion-content color="app-background">
        <div class="library-daily-bite-overview">
            <ion-header class="page-header ion-no-border">
                <ion-toolbar>
                    <app-header
                        title="Daily Bites"
                        identifierLeftButton="dailyBites-back-button"
                        [hasLeftButton]="true"
                        (leftButtonPressed)="routeToPrevious()"
                    />
                </ion-toolbar>
            </ion-header>

            @for (bite of vm().seenBites; track bite.id) {
                <app-daily-bite-card [content]="bite" (click)="openDailyBiteModal(bite)" />
            }
        </div>
    </ion-content>
}
