import { createSelector } from '@ngrx/store';
import { onboardingFeature } from '../../data-access/onboarding.reducer';
import { selectOnboardingProgress } from '../../data-access/onboarding.selectors';

export const selectQuestionNotificationsPageVm = createSelector(
    selectOnboardingProgress,
    onboardingFeature.selectIsLoading,
    (progress, isLoading: boolean) => {
        return {
            progress,
            isLoading,
        };
    },
);
