import { Injectable, inject } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { concatMap, from, mergeMap, of } from 'rxjs';
import { catchError, filter, map } from 'rxjs/operators';
import { LocalStorageService } from '../local-storage.service';
import {
    getLocalStorage,
    getLocalStorageFailure,
    getLocalStorageSuccess,
    removeAllLocalStorage,
    removeAllLocalStorageFailure,
    removeAllLocalStorageSuccess,
    removeLocalStorage,
    removeLocalStorageFailure,
    removeLocalStorageSuccess,
    safeSetLocalStorage,
    safeSetLocalStorageFailure,
    safeSetLocalStorageSuccess,
    setLocalStorage,
    setLocalStorageFailure,
    setLocalStorageSuccess,
    syncLegacyLocalStorageData,
    syncLegacyLocalStorageDataFailure,
    syncLegacyLocalStorageDataSuccess,
} from './local-storage.actions';
import { Store } from '@ngrx/store';
import { localStorageKeysRestoredFeature } from './local-storage.reducer';

@Injectable()
export class LocalStorageEffects {
    private readonly actions$ = inject(Actions);
    private readonly store = inject(Store);
    private readonly localStorageService = inject(LocalStorageService);
    copyOverLegacyData$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(syncLegacyLocalStorageData),
            concatMap(() => {
                return from(this.localStorageService.syncLegacyData()).pipe(
                    map(() => syncLegacyLocalStorageDataSuccess()),
                    catchError((error) => of(syncLegacyLocalStorageDataFailure({ error }))),
                );
            }),
        );
    });

    setLocalStorageAtKeyWithProtection$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(safeSetLocalStorage),
            concatLatestFrom(() => this.store.select(localStorageKeysRestoredFeature.selectRestoredKeys)),
            filter(([{ key }, restoredKeys]) => restoredKeys.includes(key)),
            concatMap(([{ key, data }]) =>
                from(this.localStorageService.setLocalStorage(key, data)).pipe(
                    map(() => {
                        return safeSetLocalStorageSuccess({ key });
                    }),
                    catchError((error) => of(safeSetLocalStorageFailure({ key, error }))),
                ),
            ),
        );
    });

    setLocalStorageAtKey$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(setLocalStorage),
            concatMap(({ key, data }) =>
                from(this.localStorageService.setLocalStorage(key, data)).pipe(
                    map(() => {
                        return setLocalStorageSuccess({ key });
                    }),
                    catchError((error) => of(setLocalStorageFailure({ key, error }))),
                ),
            ),
        );
    });

    getLocalStorageAtKey$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getLocalStorage),
            mergeMap(({ key }) =>
                from(this.localStorageService.getLocalStorage(key)).pipe(
                    map((data) => {
                        return getLocalStorageSuccess({ key, data });
                    }),
                    catchError((error) => of(getLocalStorageFailure({ key, error }))),
                ),
            ),
        );
    });

    removeLocalStorageAtKey$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(removeLocalStorage),
            concatMap(({ key }) =>
                from(this.localStorageService.deleteLocalStorage(key)).pipe(
                    map(() => {
                        return removeLocalStorageSuccess({ key });
                    }),
                    catchError((error) => of(removeLocalStorageFailure({ key, error }))),
                ),
            ),
        );
    });

    removeAllLocalStorage$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(removeAllLocalStorage),
            concatMap(() =>
                from(this.localStorageService.clearLocalStorage()).pipe(
                    map(() => removeAllLocalStorageSuccess()),
                    catchError((error) => of(removeAllLocalStorageFailure({ error }))),
                ),
            ),
        );
    });
}
