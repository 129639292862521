import { subscriptionFeature, SubscriptionPackage } from '@frontend/data-access/subscription';
import { accountFeature } from '@frontend/data-access/user/account';
import { selectIsFollowUpDiscountOfferAb } from '@frontend/data-access/user/config-cat';
import { createSelector } from '@ngrx/store';
import {
    selectDefaultOfferingAvailablePackagesSortedByPrice,
    selectIncreasedUkPriceForDiscountPaywallAbTestJuly2024OfferingAvailablePackagesSortedByPrice,
} from '../store/payment/store/payment.selectors';
import { PaymentProductCard } from './payment-modal-product/payment-modal-product.model';
import {
    mapOfferingToDiscountPaymentProductCardArray,
    mapOfferingToPaymentProductCardArray,
} from './payment-modal.utils';

export const selectPaymentModalVm = createSelector(
    selectDefaultOfferingAvailablePackagesSortedByPrice,
    selectIncreasedUkPriceForDiscountPaywallAbTestJuly2024OfferingAvailablePackagesSortedByPrice,
    accountFeature.selectOwnerName,
    subscriptionFeature.selectHasHistoricalPurchase,
    selectIsFollowUpDiscountOfferAb,
    (
        packages: SubscriptionPackage[],
        increasedUkPricePackages: SubscriptionPackage[],
        ownerName,
        hasHistoricalPurchase,
        isFollowUpDiscountOfferAb,
    ): {
        products: PaymentProductCard[];
        discountProducts: PaymentProductCard[];
        ownerName: string;
        hasHistoricalPurchase: boolean;
    } => {
        const products = isFollowUpDiscountOfferAb
            ? mapOfferingToPaymentProductCardArray(increasedUkPricePackages, !!hasHistoricalPurchase) // UK and US
            : mapOfferingToPaymentProductCardArray(packages, !!hasHistoricalPurchase); // ROW

        return {
            products,
            discountProducts: mapOfferingToDiscountPaymentProductCardArray(packages),
            ownerName,
            hasHistoricalPurchase: !!hasHistoricalPurchase,
        };
    },
);
