import { createSelector } from '@ngrx/store';
import { selectContentUnlocked } from '../../store/payment/store/payment.selectors';
import {
    selectHeightOfFirstInactiveStep,
    selectSelectedCourseWithStepsAndSplits,
} from '../course-path/store/course-path.selectors';
import { selectCoursesWithStepsAndSplits } from '../course-steps-and-splits.selectors';
import { selectIsHomeRecommendedContentEnabled } from '@frontend/data-access/user/config-cat';

export const selectCoursesPageVm = createSelector(
    selectCoursesWithStepsAndSplits,
    selectSelectedCourseWithStepsAndSplits,
    selectHeightOfFirstInactiveStep,
    selectContentUnlocked,
    selectIsHomeRecommendedContentEnabled,
    (courses, selectedCourse, heightOfFirstInactiveStep, contentUnlocked, isRecommendedContentEnabled) => {
        const nextCourse = courses[courses.findIndex((course) => course.id === selectedCourse?.id) + 1] ?? undefined;
        return {
            courses,
            selectedCourse,
            nextCourse,
            heightOfFirstInactiveStep,
            contentUnlocked,
            isRecommendedContentEnabled,
        };
    },
);
