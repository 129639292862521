import { AsyncPipe } from '@angular/common';
import { Component, inject } from '@angular/core';
import { Color } from '@shared/utils/typescript';
import { Topic } from '@frontend/data-access/contentful';
import { pinTopic, unpinTopic } from '@frontend/data-access/user/account';
import {
    AnalyticsTrackClickDirective,
    GenericCardSlimComponent,
    HeaderComponent,
    RightButtonHeaderType,
} from '@frontend/ui';
import { IonButton, IonCard, IonContent, IonHeader, IonToolbar, NavController } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { CircleProgressOptions } from 'ng-circle-progress/lib/ng-circle-progress.component';
import { TextReplacementPipe } from '@frontend/data-access/text-replacement';
import { StepCardSlimModel } from '../../../step/step-card-slim/models/step-card-slim.model';
import { StepCardSlimComponent } from '../../../step/step-card-slim/step-card-slim.component';
import { showPaymentModal } from '../../../store/payment/store/payment.actions';
import { routeToStep } from '../../../store/router/router.actions';
import { selectTopicVm } from './topic.page.selectors';
import { routeToAiChat, routeToCoachChat } from '../../../chat/chat.actions';

@Component({
    selector: 'app-topic',
    templateUrl: 'topic.page.html',
    styleUrls: ['../../library.scss', './topic.scss'],
    standalone: true,
    imports: [
        IonContent,
        NgCircleProgressModule,
        StepCardSlimComponent,
        AnalyticsTrackClickDirective,
        GenericCardSlimComponent,
        AsyncPipe,
        TextReplacementPipe,
        IonCard,
        IonButton,
        HeaderComponent,
        IonHeader,
        IonToolbar,
    ],
})
export class TopicPage {
    private readonly store = inject(Store);
    private readonly navController = inject(NavController);
    vm$ = this.store.select(selectTopicVm);

    protected readonly Color = Color;

    routeToLibrary() {
        void this.navController.navigateBack(['main', 'library']);
    }

    getCircleProgressOptions(topic: Topic, progress: number): CircleProgressOptions {
        const title = progress < 100 ? progress.toString() : '✓';
        const showUnits = progress < 100;
        const titleColor = progress < 100 ? 'var(--ion-color-paragraph-color)' : 'white';
        const outerStrokeColor = `var(--ion-color-${topic.color})`;
        const titleFontSize = progress < 100 ? '12' : '25';
        const backgroundColor = progress < 100 ? 'white' : `var(--ion-color-${topic.color})`;
        const innerStrokeWidth = progress < 100 ? 4 : 0;

        return {
            title,
            titleColor,
            titleFontSize,
            showSubtitle: false,

            units: '%',
            showUnits,
            percent: progress,
            clockwise: true,
            startFromZero: false,

            radius: 20,
            space: -4,

            showBackground: true,
            backgroundColor,
            backgroundPadding: 0,

            innerStrokeWidth,
            innerStrokeColor: '#F5EFEB',

            outerStrokeWidth: 4,
            outerStrokeColor,
            showZeroOuterStroke: false,

            animation: false,
            animateTitle: false,
            renderOnClick: false,
        } as CircleProgressOptions;
    }

    goToStep(cardItem: StepCardSlimModel, topicId: string) {
        this.store.dispatch(
            routeToStep({
                baseUrl: ['main', 'library', 'topic', topicId],
                stepId: cardItem.id,
                from: 'library',
            }),
        );
    }

    handlePinButtonClick(pinned: boolean, topicEntityId: string) {
        const action = pinned ? unpinTopic({ topicEntityId }) : pinTopic({ topicEntityId });
        this.store.dispatch(action);
    }

    getRightButtonIdentifier(topic: Topic): string {
        return topic.pinned
            ? `unpin-topic-${topic.entityId}-from-topic-page`
            : `pin-topic-${topic.entityId}-from-topic-page`;
    }

    getRightButtonOptions(topic: Topic) {
        return {
            type: RightButtonHeaderType.FA_ICON,
            content: '',
            pinned: topic.pinned,
        };
    }

    routeToChat() {
        this.store.dispatch(routeToCoachChat());
    }

    openZiggyChat() {
        this.store.dispatch(routeToAiChat({}));
    }

    openPaymentModal(trigger: string): void {
        this.store.dispatch(showPaymentModal({ trigger }));
    }
}
