import { Component, EventEmitter, Output } from '@angular/core';
import { AnalyticsTrackClickDirective } from '@frontend/ui';
import { IonButton, IonButtons, IonHeader, IonIcon, IonTitle, IonToolbar } from '@ionic/angular/standalone';
import { addIcons } from 'ionicons';
import { arrowBackOutline } from 'ionicons/icons';

@Component({
    selector: 'app-ai-chat-header',
    templateUrl: 'ai-chat-header.component.html',
    styleUrls: ['ai-chat-header.component.scss'],
    standalone: true,
    imports: [AnalyticsTrackClickDirective, IonHeader, IonButtons, IonButton, IonIcon, IonToolbar, IonTitle],
})
export class AiChatHeaderComponent {
    @Output()
    backClicked: EventEmitter<void> = new EventEmitter<void>();

    constructor() {
        addIcons({ arrowBackOutline });
    }

    onBackClicked() {
        this.backClicked.emit();
    }
}
