import { AsyncPipe, DatePipe, NgClass, NgStyle } from '@angular/common';
import { Component, inject } from '@angular/core';
import { Color } from '@shared/utils/typescript';
import { Profile, updateAccount } from '@frontend/data-access/user/account';
import { logout } from '@frontend/data-access/user/authentication';
import { AnalyticsTrackClickDirective, HeaderComponent, InvalidInputErrorMessageComponent } from '@frontend/ui';
import { ModalService } from '@frontend/utility/modal';
import {
    IonButton,
    IonCard,
    IonContent,
    IonFooter,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonSelect,
    IonSelectOption,
    IonToolbar,
} from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { AndroidSettings, IOSSettings } from 'capacitor-native-settings';
import { addIcons } from 'ionicons';
import { shareSocialOutline } from 'ionicons/icons';
import { FormGroupState, NgrxFormsModule } from 'ngrx-forms';
import { trackEvent } from '@frontend/data-access/analytics';
import { genderOptionValues } from '../../../constants/profile-form.constants';
import { EditProfileFormValues } from '../../../store/forms/edit-profile/edit-profile.form.model';
import { openInfoDialog } from '../../../store/info-dialog/info-dialog.actions';
import { openToast } from '../../../store/toast/toast.actions';
import { openSettingsAndroid, openSettingsIos, socialShare } from '@frontend/data-access/capacitor';
import { DeleteAccountComponent } from '../../delete-account/delete-account.component';
import { ProfileImageComponent, ProfileImageType } from '@frontend/feature/profile-picture';
import { emailIsReadonlyDialogProps } from './profile-edit-dialog-props';
import { resetProfileEditForm } from './store/profile-edit.page.actions';
import { selectProfileEditVm } from './store/profile-edit.selectors';
import { openPrivacy, openTerms, openUrlWindow, routeToPrevious } from '@frontend/data-access/router';
import { UPDATE_ACCOUNT_FROM_ZIGZAG_MAIN } from '../../../store/profile/account/account.constants';
import { Gender } from '@shared/user-domain';

@Component({
    selector: 'app-profile-edit',
    templateUrl: 'profile-edit.page.html',
    styleUrl: './profile-edit.page.scss',
    standalone: true,
    imports: [
        AsyncPipe,
        DatePipe,
        NgClass,
        NgStyle,
        IonButton,
        IonCard,
        IonContent,
        IonFooter,
        IonHeader,
        IonIcon,
        IonInput,
        IonItem,
        IonLabel,
        IonList,
        IonSelect,
        IonSelectOption,
        IonToolbar,
        NgrxFormsModule,
        AnalyticsTrackClickDirective,
        HeaderComponent,
        InvalidInputErrorMessageComponent,
        ProfileImageComponent,
    ],
})
export class ProfileEditPage {
    private readonly store = inject(Store);
    private readonly modalService = inject(ModalService);

    public vm$ = this.store.select(selectProfileEditVm);

    protected readonly ProfileImageType = ProfileImageType;
    protected readonly genderOptions = genderOptionValues;

    constructor() {
        addIcons({ shareSocialOutline });
    }

    public back(profile: Profile | undefined): void {
        if (profile) {
            this.store.dispatch(resetProfileEditForm({ profile }));
        }

        this.store.dispatch(routeToPrevious({}));
    }

    public showIsReadonlyModal(): void {
        // TODO: NBSon - don't do this, dispatch many is no no no good, no good yess, no good
        void this.store.dispatch(trackEvent({ eventName: 'readonly email clicked, modal shown' }));
        void this.store.dispatch(openInfoDialog(emailIsReadonlyDialogProps));
    }

    public share(): void {
        void this.store.dispatch(
            socialShare({
                message:
                    'Puppyhood made easy! The #1 app 100% dedicated to puppyhood training. Join me on https://zigzag.dog',
            }),
        );
    }

    public saveProfile(form: FormGroupState<EditProfileFormValues>): void {
        if (form.isValid) {
            const breedIdChanged = form.controls.breedId?.isDirty;
            const genderChanged = form.controls.gender?.isDirty;
            const mailChanged = form.controls.mail?.isDirty;
            const ownerNameChanged = form.controls.ownerName?.isDirty;
            const dateOfBirthChanged = form.controls.dateOfBirth?.isDirty;
            const nameChanged = form.controls.name?.isDirty;
            const isApproximateDateOfBirth = dateOfBirthChanged ? false : undefined;

            if (
                breedIdChanged ||
                genderChanged ||
                mailChanged ||
                ownerNameChanged ||
                nameChanged ||
                dateOfBirthChanged
            ) {
                this.store.dispatch(
                    updateAccount({
                        command: {
                            ...(isApproximateDateOfBirth !== undefined && {
                                isApproximateDateOfBirth: isApproximateDateOfBirth,
                            }),
                            ...(breedIdChanged && { breedId: form.controls.breedId.value }),
                            ...(genderChanged && { gender: form.controls.gender.value as Gender }),
                            ...(mailChanged && { mail: form.controls.mail.value }),
                            ...(ownerNameChanged && { ownerName: form.controls.ownerName.value }),
                            ...(nameChanged && { name: form.controls.name.value }),
                            ...(dateOfBirthChanged && { dateOfBirth: form.controls.dateOfBirth.value }),
                        },
                        correlationId: UPDATE_ACCOUNT_FROM_ZIGZAG_MAIN,
                    }),
                );
            }
            this.store.dispatch(routeToPrevious({}));
        } else {
            void this.store.dispatch(
                openToast({
                    message: 'Please fill in all fields correctly',
                    color: Color.Jack,
                }),
            );
        }
    }

    // TODO: NBSon - can we make this an effect?
    public openApplicationDetails(platform: { ios: boolean; android: boolean; capacitor: boolean }) {
        if (platform.android) {
            this.store.dispatch(openSettingsAndroid({ option: AndroidSettings.ApplicationDetails }));
        }

        if (platform.capacitor && platform.ios) {
            this.store.dispatch(openSettingsIos({ option: IOSSettings.App }));
        }
    }

    public openSubscriptions(link: string): void {
        this.store.dispatch(openUrlWindow({ url: link, openInNewWindow: true }));
    }

    public showTerms(countryCode: string): void {
        this.store.dispatch(openTerms({ countryCode }));
    }

    public showPrivacy(countryCode: string): void {
        this.store.dispatch(openPrivacy({ countryCode }));
    }

    public logout(): void {
        this.store.dispatch(logout());
    }

    public showDeleteAccount(): void {
        void this.modalService.showModal({
            component: DeleteAccountComponent,
            cssClass: ['modal', 'modal-info-notification'],
        });
    }
}
