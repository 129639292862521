import { Component, inject } from '@angular/core';
import { AnalyticsTrackClickDirective, HeaderComponent } from '@frontend/ui';
import { ModalService } from '@frontend/utility/modal';
import { IonButton, IonFooter, IonHeader, IonToolbar } from '@ionic/angular/standalone';
import { TextReplacementPipe } from '@frontend/data-access/text-replacement';

@Component({
    selector: 'app-arrival-modal',
    templateUrl: './arrival-modal.component.html',
    styleUrls: ['./arrival-modal.component.scss'],
    standalone: true,
    imports: [
        AnalyticsTrackClickDirective,
        TextReplacementPipe,
        IonFooter,
        IonToolbar,
        IonButton,
        HeaderComponent,
        IonHeader,
    ],
})
export class ArrivalModalComponent {
    private modalService = inject(ModalService);

    public closeModal(arrived: boolean): void {
        void this.modalService.dismiss({
            arrived,
        });
    }
}
