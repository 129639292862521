import { MultiSelectOption } from '@frontend/ui';

const baseSpecialNeedOptions: MultiSelectOption[] = [
    {
        title: 'Kids',
        value: 'kids',
        image: 'assets/images/buddy/buddy-gets-head-pats.png',
        backgroundColor: 'bella',
        selected: false,
    },
    {
        title: 'Other dogs',
        value: 'otherDogs',
        image: 'assets/images/oliver/oliver-sniffs-older-dogs-butt.png',
        backgroundColor: 'bailey',
        selected: false,
    },
    {
        title: 'A cat',
        value: 'aCat',
        image: 'assets/images/charly/charly-chases-a-cat.png',
        backgroundColor: 'oliver',
        selected: false,
    },
];

export const specialNeedOptions: MultiSelectOption[] = [
    ...baseSpecialNeedOptions,
    {
        title: 'No garden',
        value: 'noGarden',
        image: 'assets/images/oliver/oliver-destroys-chair-large.png',
        backgroundColor: 'lola',
        selected: false,
    },
];

export const usSpecialNeedOptions: MultiSelectOption[] = [
    ...baseSpecialNeedOptions,
    {
        title: 'No yard',
        value: 'noGarden',
        image: 'assets/images/oliver/oliver-destroys-chair-large.png',
        backgroundColor: 'lola',
        selected: false,
    },
];
