import { AsyncPipe } from '@angular/common';
import { Component } from '@angular/core';
import { Color } from '@shared/utils/typescript';
import { AnalyticsTrackClickDirective, InvalidInputErrorMessageComponent } from '@frontend/ui';
import { IonButton, IonSpinner } from '@ionic/angular/standalone';
import { trackEvent } from '@frontend/data-access/analytics';
import { OnboardingBaseComponent } from '../../onboarding-base/onboarding-base.component';
import { selectQuestionPurinaOptInPageVm } from './question-purina-opt-in.page.selectors';
import { openUrlWindow } from '@frontend/data-access/router';
import { OnboardingQuestionTemplateComponent } from '../../onboarding-question-template/onboarding-question-template.component';
import { onboardingQuestionSubmittedPurinaOptIn } from '../../data-access/onboarding.actions';

@Component({
    selector: 'app-onboarding-question-purina-opt-in',
    templateUrl: 'question-purina-opt-in.page.html',
    styleUrls: ['../../onboarding.scss', 'question-purina-opt-in.page.scss'],
    standalone: true,
    imports: [
        AnalyticsTrackClickDirective,
        AsyncPipe,
        InvalidInputErrorMessageComponent,
        IonButton,
        OnboardingQuestionTemplateComponent,
        IonSpinner,
    ],
})
export class OnboardingQuestionPurinaOptInPage extends OnboardingBaseComponent {
    public vm = this.store.selectSignal(selectQuestionPurinaOptInPageVm);

    protected readonly Color = Color;

    constructor() {
        super();

        this.store.dispatch(trackEvent({ eventName: '[Onboarding] Purina Opt-in Page Opened' }));
    }

    public async openUrlWindow(url: string): Promise<void> {
        this.store.dispatch(openUrlWindow({ url, openInNewWindow: false }));
    }

    public answeredPurinaOptIn(optedIn: boolean): void {
        if (this.vm().isLoading) {
            return;
        }

        this.store.dispatch(onboardingQuestionSubmittedPurinaOptIn({ purinaMarketingAccepted: optedIn }));
    }
}
