import { InternalIterableDataFields } from '../../models/third-party/iterable/iterable-fields.model';
import { InternalIterableDogFieldsMock } from './internal-iterable-dog-fields.mock';

export class InternalIterableDataFieldsMock {
    private defaultValue: InternalIterableDataFields = {
        ownerName: 'owner-name',
        locale: 'locale',
        partners: ['partner'],
        stepsComplete: 1,
        userId: 'user-id',
        appVersion: '123',
        deviceOs: 'android',
        ip: 'ip',
        dogs: [new InternalIterableDogFieldsMock().value],
        purinaMarketingAccepted: false,
        enabledFeatureFlags: ['flag1', 'flag2'],
    };

    constructor(overrides?: Partial<InternalIterableDataFields>) {
        Object.assign(this.defaultValue, overrides);
    }

    public get value(): InternalIterableDataFields {
        return this.defaultValue;
    }
}
