<ion-content [fullscreen]="true" class="landing-page page-ion-content ion-text-center">
    <main class="landing-page__content page-content-padding-top">
        <header class="landing-page__header-section">
            <img alt="zigzag logo" src="assets/images/zigzag/zigzag-logo.png" class="logo" />
        </header>
        <section class="landing-page__title-section">
            <h1>Welcome! We'd give you a cuddle if we could!</h1>
        </section>
        <section class="landing-page__img-section">
            <img alt="happy-dog-image" src="assets/images/charly/charly-sits-on-squiggle.png" />
        </section>
    </main>
</ion-content>

<ion-footer class="landing-page-footer ion-text-center page-footer">
    @if (vm().isSsoEnabled) { @if (vm().isIos) {
    <ion-button
        class="landing-page-button"
        appTrackClick
        identifier="auth-apple-btn"
        expand="block"
        color="snowy"
        data-test="auth-apple-btn"
        (click)="signInWithApple()"
    >
        <h3 class="landing-page-button__content">
            <i class="landing-page-button__icon fa-brands fa-apple"></i>
            <span class="landing-page-button__text">Continue with Apple</span>
        </h3>
    </ion-button>
    }

    <ion-button
        class="landing-page-button"
        appTrackClick
        identifier="auth-google-btn"
        expand="block"
        color="snowy"
        data-test="auth-google-btn"
        (click)="signInWithGoogle()"
    >
        <h3 class="landing-page-button__content">
            <img src="assets/images/third-party/google-logo-color.svg" alt="Continue with Google" />
            <span class="landing-page-button__text">Continue with Google</span>
        </h3>
    </ion-button>

    <p class="landing-page-footer__or">or</p>
    }

    <div>
        @if (vm().isSsoEnabled) {
        <ion-button
            class="landing-page-button"
            appTrackClick
            identifier="auth-sign-up-btn"
            expand="block"
            color="snowy"
            data-test="auth-sign-up-btn"
            (click)="getStarted()"
        >
            <ion-text class="landing-page-button__content" color="max">
                <i class="landing-page-button__icon fa-solid fa-envelope"></i>
                <span class="landing-page-button__text">Sign Up with Email</span>
            </ion-text>
        </ion-button>
        } @else {
        <ion-button
            (click)="getStarted()"
            data-test="auth-sign-up-btn"
            color="max"
            expand="block"
            appTrackClick
            identifier="auth-sign-up-btn"
            >Sign up
        </ion-button>
        }
        <section class="landing-page-footer__sign-in-wrapper">
            <p>Already a member?</p>
            <button
                class="zz-button landing-page-footer__sign-in-button"
                appTrackClick
                identifier="auth-sign-in-btn"
                data-test="auth-login-btn"
                (click)="signIn()"
            >
                Sign in
            </button>
        </section>
    </div>
</ion-footer>
