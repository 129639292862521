<ion-card class="{{ background }} option-selector-square">
    <img class="option-selector-square__img" [src]="imageUrl" />
    <h3
        class="option-selector-square__title ignore-screen-based-font-scaling"
        [ngStyle]="{ color: 'var(--ion-color-' + background + '-contrast)' }"
    >
        {{ title }}
    </h3>
    @for (option of options; track option.id) {
        <ion-chip
            class="option-selector-square__chip {{
                option.id === selected()?.id ? 'option-selector-square__chip--selected' : ''
            }}"
            (click)="optionClicked(option)"
            [ngStyle]="{
                background:
                    option.id === selected()?.id
                        ? 'var(--ion-color-' + background + '-contrast)'
                        : 'var(--ion-color-' + background + '-tint)'
            }"
        >
            <p
                class="as-div s"
                [ngStyle]="{
                    color:
                        option.id === selected()?.id
                            ? 'var(--ion-color-' + background + ')'
                            : 'var(--ion-color-' + background + '-contrast)'
                }"
            >
                {{ option.label }}
            </p>
        </ion-chip>
    }
</ion-card>
