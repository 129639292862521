import { IsEmail, IsOptional, IsString } from 'class-validator';

export class InternalPostUserCommand {
    @IsString()
    id!: string;

    @IsString()
    accountId!: string;

    @IsEmail()
    mail!: string;

    // TODO: JL - remove isoptional 1st Sep - check what happens when user creates account with slow internet so ip isnt resolved
    @IsOptional()
    @IsString()
    countryCode?: string;
}
