import { Injectable, inject } from '@angular/core';
import {
    accountFeature,
    createAccountSuccess,
    createEmptyUserSuccess,
    getAccountSuccess,
    updateAccountSuccess,
} from '@frontend/data-access/user/account';
import { getConfigCatFeatureFlagsSuccess } from '@frontend/data-access/user/config-cat';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { filter, map, switchMap } from 'rxjs/operators';
import { setAnalyticsProfile, trackEvent } from '@frontend/data-access/analytics';
import { selectAnalyticsFeatureFlagProfile, selectAnalyticsProfile } from './analytics.selectors';

@Injectable()
export class AnalyticsEffects {
    private readonly actions$ = inject(Actions);
    private readonly store = inject(Store);

    setAnalyticsProfile$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(createAccountSuccess, createEmptyUserSuccess, updateAccountSuccess, getAccountSuccess),
            concatLatestFrom(() => [
                this.store.select(selectAnalyticsProfile),
                this.store.select(accountFeature.selectId),
            ]),
            filter(([, , id]) => !!id),
            map(([, profile, id]) => setAnalyticsProfile({ profile, id: id! })),
        );
    });

    updateAnalyticsFeatureFlags$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getConfigCatFeatureFlagsSuccess),
            concatLatestFrom(() => [
                this.store.select(selectAnalyticsFeatureFlagProfile),
                this.store.select(accountFeature.selectId),
            ]),
            filter(([, , id]) => !!id),
            switchMap(([flags, profile, id]) => [
                setAnalyticsProfile({ profile, id: id! }),
                trackEvent({
                    eventName: 'current-feature-flag-state',
                    eventProperties: { featureFlags: flags.configCatFlagState.configCatFlags },
                }),
            ]),
        );
    });
}
