<app-onboarding-question-template
    backButtonId="question-breed-back-btn"
    title="What breed is {{ vm().dogName }}?"
    subtitle="We tailor your lessons based on your pup's breed"
    [progress]="vm().progress"
    [isLoading]="vm().isLoading"
    (backButtonPressed)="routeToPrevious()"
>
    <ng-container body>
        <form id="breedForm" [formGroup]="form" (ngSubmit)="onSubmit()">
            <app-breed-selector
                class="breed-selector-margin-top"
                formControlName="breedId"
                data-test="onboarding-breed-selector"
                [breedOptions]="vm().breeds"
                [isDisabled]="vm().isLoading"
            />
        </form>
    </ng-container>
    <ng-container footer>
        <ion-button
            appTrackClick
            identifier="question-breed-next-btn"
            expand="block"
            type="submit"
            form="breedForm"
            data-test="question-breed-next-btn"
            [color]="Color.Max"
            [disabled]="form.invalid || vm().isLoading"
        >
            @if (vm().isLoading) {
            <ion-spinner name="crescent" />
            } @else { Next }
        </ion-button>
    </ng-container>
</app-onboarding-question-template>
