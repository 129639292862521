@if (vm()) {
    <ion-content [fullscreen]="true">
        <div class="payment-modal-parent-content ion-align-items-center">
            <ion-header class="page-header ion-no-border">
                <ion-toolbar>
                    <app-header
                        buttonBackground="white"
                        identifierRightButton="close-parent-payment-modal-btn"
                        identifierLeftButton="back-parent-payment-modal-btn"
                        [hasLeftButton]="false"
                        [hasRightButton]="true"
                        (rightButtonPressed)="onCloseClick()"
                    />
                </ion-toolbar>
            </ion-header>

            <img
                class="payment-modal-parent-logo"
                [src]="'assets/images/zigzag/zigzag-logo.png'"
                alt="The Zigzag Logo"
            />

            <section class="subscription-title">
                @if (showDiscountProducts) {
                    <h1>
                        {{ vm().ownerName }}, here is an additional
                        <span class="title-highlight">{{
                            vm().discountProducts[0]?.discount?.discountPercentage
                        }}</span>
                        off for you
                    </h1>
                    <p class="s">
                        {{ 'Limited time only! Continue [NAME]s journey to pup happiness.' | textReplacement }}
                    </p>
                } @else {
                    <div>
                        <h1 data-test="payment-modal-parent-title">
                            Choose a plan and
                            {{ vm().hasHistoricalPurchase ? 'continue your journey' : 'start your free trial now' }}
                        </h1>
                        <p class="s" data-test="payment-modal-parent-description">
                            Cancel anytime within your free trial period
                        </p>

                        <ul class="value-prop-section">
                            <li class="value-prop-item">
                                <p class="xl"><i class="fa-thin fa-circle-check circle-icon"></i></p>
                                <p class="s bullet-text">
                                    {{ "A training journey that reacts to [NAME]'s progress" | textReplacement }}
                                </p>
                            </li>

                            <li class="value-prop-item">
                                <p class="xl"><i class="fa-thin fa-circle-check circle-icon"></i></p>
                                <p class="s bullet-text">Unlimited access to 250+ lessons to overcome any issue</p>
                            </li>

                            <li class="value-prop-item">
                                <p class="xl"><i class="fa-thin fa-circle-check circle-icon"></i></p>
                                <p class="s bullet-text">24/7 live chat support with training coaches</p>
                            </li>
                        </ul>
                    </div>
                }
            </section>

            <section class="product-container">
                <div class="flex-container">
                    @if (showDiscountProducts) {
                        @for (product of vm().discountProducts; track product.identifier) {
                            <app-payment-modal-product
                                [isSelected]="product.identifier === selectedProduct().identifier"
                                [product]="product"
                                (productClicked)="onProductClick($event)"
                            />
                        }
                    } @else {
                        @for (product of vm().products; track product.identifier) {
                            <app-payment-modal-product
                                [isSelected]="product.identifier === selectedProduct().identifier"
                                [product]="product"
                                (productClicked)="onProductClick($event)"
                            />
                        }
                    }

                    <ion-footer class="product-footer">
                        <div class="product-footer__button-group">
                            <ion-button
                                appTrackClick
                                [identifier]="'payment-purchase-' + selectedProduct().identifier"
                                color="max"
                                expand="full"
                                data-test="purchase-product-button"
                                shape="round"
                                [disabled]="!selectedProduct()"
                                (click)="onPurchaseClick(selectedProduct())"
                            >
                                @if (showDiscountProducts) {
                                    Get offer
                                } @else {
                                    {{ vm().hasHistoricalPurchase ? 'Start my subscription' : 'Start my free trial' }}
                                }
                            </ion-button>
                            @if (!showDiscountProducts) {
                                <button
                                    class="zz-button"
                                    appTrackClick
                                    identifier="payment-restore-button"
                                    data-test="restore-btn"
                                    (click)="onRestoreClick()"
                                >
                                    Restore purchases
                                </button>
                            }
                        </div>
                        <p class="xxs">
                            Your subscription automatically renews for the same term unless cancelled at least 24 hours
                            prior to the end of the current term. Cancel any time in the App Store at no additional
                            costs; your subscription will then cease at the end of the current term.
                        </p>
                    </ion-footer>
                </div>
            </section>
        </div>
    </ion-content>
}
