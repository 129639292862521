import { AsyncPipe } from '@angular/common';
import { Component, computed, signal } from '@angular/core';
import { Color } from '@shared/utils/typescript';
import { AnalyticsTrackClickDirective, MultiSelectComponent } from '@frontend/ui';
import { IonButton } from '@ionic/angular/standalone';
import { OnboardingBaseComponent } from '../../onboarding-base/onboarding-base.component';
import { selectQuestionSpecialNeedsPageVm } from './question-special-needs.page.selectors';
import { OnboardingQuestionTemplateComponent } from '../../onboarding-question-template/onboarding-question-template.component';
import { onboardingQuestionSubmittedNeeds } from '../../data-access/onboarding.actions';

@Component({
    selector: 'app-question-special-needs',
    templateUrl: './question-special-needs.page.html',
    styleUrls: ['../../onboarding.scss', 'question-special-needs.page.scss'],
    standalone: true,
    imports: [
        AnalyticsTrackClickDirective,
        AsyncPipe,
        IonButton,
        MultiSelectComponent,
        OnboardingQuestionTemplateComponent,
    ],
})
export class OnboardingQuestionSpecialNeedsPage extends OnboardingBaseComponent {
    public vm = this.store.selectSignal(selectQuestionSpecialNeedsPageVm);

    public options = signal(this.vm().specialNeeds);

    public needs = computed(() => {
        const needs: Record<string, boolean> = {};

        this.options().forEach((need) => {
            needs[need.value] = need.selected;
        });

        return needs;
    });
    public nextButtonText = computed(() => {
        const noTilesSelected = !this.options().some((option) => option.selected);

        return noTilesSelected ? 'Skip' : 'Next';
    });

    protected readonly Color = Color;

    public continue(): void {
        this.store.dispatch(onboardingQuestionSubmittedNeeds({ needs: this.needs() }));
    }
}
