import { selectBreeds } from '@frontend/data-access/contentful';
import { accountFeature, selectCountryCode } from '@frontend/data-access/user/account';
import { createSelector } from '@ngrx/store';
import { selectIsInputOpenOnAndroid } from '@frontend/data-access/keyboard';
import { platformFeature } from '@frontend/data-access/platform';
import { editProfileFeature } from '../../../../store/forms/forms.reducer';

export const selectProfileEditVm = createSelector(
    editProfileFeature.selectEditProfile,
    platformFeature.selectPlatformIsIos,
    platformFeature.selectPlatformIsAndroid,
    platformFeature.selectPlatformIsCapacitor,
    accountFeature.selectProfile,
    selectBreeds,
    selectCountryCode,
    selectIsInputOpenOnAndroid,
    (
        form,
        platformIsIos,
        platformIsAndroid,
        platformIsCapacitor,
        profile,
        breeds,
        countryCode,
        isInputFocusedOnAndroid,
    ) => {
        let subscriptionLink = undefined;

        if (platformIsIos) {
            subscriptionLink = 'https://apps.apple.com/account/subscriptions';
        } else if (platformIsAndroid) {
            subscriptionLink = 'https://play.google.com/store/account/subscriptions';
        }

        return {
            platformIsAndroid,
            platformIsIos,
            platformIsCapacitor,
            form,
            subscriptionLink,
            profile,
            breeds,
            countryCode,
            isInputFocusedOnAndroid,
            currentDate: new Date(),
        };
    },
);
