import { NgIfContext, NgTemplateOutlet } from '@angular/common';
import {
    AfterContentInit,
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { IonSearchbar } from '@ionic/angular/standalone';

export interface ResultTemplateContext {
    searchValue: string;
}

@Component({
    selector: 'app-search-bar',
    templateUrl: './search-bar.component.html',
    styleUrls: ['./search-bar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgTemplateOutlet, IonSearchbar],
})
export class SearchBarComponent implements AfterContentInit {
    @Input({ required: true }) public hasResults!: boolean;
    @Input({ required: true }) public resultListTemplate!: TemplateRef<ResultTemplateContext>;
    @Input({ required: true }) public noResultTemplate!: TemplateRef<ResultTemplateContext>;
    @Input({ required: true }) public instructionTemplate!: TemplateRef<NgIfContext<string>>;

    @Input() public showCancelButton = 'never';
    @Input() public placeholder = 'Search';
    @Input() public initialFocus = false;

    @Output() public search = new EventEmitter<string>();
    @Output() public focusChange = new EventEmitter<boolean>();

    @ViewChild('searchBar', { static: false }) public searchBar: IonSearchbar | undefined;

    public isFocused = false;

    public ngAfterContentInit(): void {
        if (this.initialFocus) {
            setTimeout((): void => void this.searchBar?.setFocus(), 700);
        }
    }

    public searchItems(value: string | null | undefined): void {
        if (value !== null) {
            this.search.emit(value);
        }
    }

    public focus(): void {
        this.focusChange.emit((this.isFocused = true));
    }

    public blur(): void {
        this.focusChange.emit((this.isFocused = false));
    }
}
