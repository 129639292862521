import { NgClass } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IonCard, IonChip, IonCol, IonGrid, IonLabel, IonRow } from '@ionic/angular/standalone';
import { PurchaseProduct } from '@frontend/data-access/subscription';
import { PaymentProductCard } from './payment-modal-product.model';

@Component({
    standalone: true,
    selector: 'app-payment-modal-product',
    templateUrl: './payment-modal-product.component.html',
    styleUrls: ['./payment-modal-product.component.scss'],
    imports: [NgClass, IonChip, IonCard, IonLabel, IonGrid, IonRow, IonCol],
})
export class PaymentModalProductComponent {
    @Input({ required: true }) public product!: PaymentProductCard;
    @Input() public isSelected = false;
    @Input() public hasHistoricalPurchase = false;
    @Output() public productClicked = new EventEmitter<PurchaseProduct>();

    public clickProduct(product: PurchaseProduct): void {
        this.productClicked.emit(product);
    }
}
