import { NgClass, NgStyle } from '@angular/common';
import { Component, computed, inject, input } from '@angular/core';
import { AnalyticsTrackClickDirective, HeaderComponent, WeekdayPipe } from '@frontend/ui';
import { ModalService } from '@frontend/utility/modal';
import { IonButton, IonFooter, IonHeader, IonToolbar } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { Color, getTemplateThemeColor, isColorContrastLight } from '@shared/utils/typescript';
import { TextReplacementPipe } from '@frontend/data-access/text-replacement';
import { launchRatingQualifierModal } from '../../rating/app-rating-qualifier/store/rating-qualifier-modal.actions';
import { ProgressStreakModelInfo } from './progress-streak.model';

@Component({
    selector: 'app-progress-streak-modal',
    templateUrl: './progress-streak-modal.component.html',
    styleUrl: './progress-streak-modal.component.scss',
    standalone: true,
    imports: [
        NgStyle,
        NgClass,
        AnalyticsTrackClickDirective,
        TextReplacementPipe,
        IonFooter,
        IonButton,
        HeaderComponent,
        IonHeader,
        IonToolbar,
        WeekdayPipe,
    ],
})
export class ProgressStreakModalComponent {
    public courseStreak = input.required<Omit<ProgressStreakModelInfo, 'id'>>();

    private store = inject(Store);
    private modalService = inject(ModalService);

    public readonly DAYS_IN_WEEK = [1, 2, 3, 4, 5, 6, 7];
    public readonly squiggleDarkSvg = 'url(/assets/images/squiggles/progress-streak-modal-squiggle-dark.svg) no-repeat';
    public readonly squiggleLightSvg =
        'url(/assets/images/squiggles/progress-streak-modal-squiggle-light.svg) no-repeat';
    protected readonly Color = Color;

    public dynamicallyAppliedStyles = computed(() => {
        const courseColor = this.courseStreak().color;

        return {
            background: isColorContrastLight(courseColor) ? this.squiggleLightSvg : this.squiggleDarkSvg,
            'background-color': getTemplateThemeColor(courseColor),
            color: getTemplateThemeColor(courseColor, true),
            'background-size': 'contain',
        };
    });

    public headerButtonBackgroundColor = computed(() => {
        return isColorContrastLight(this.courseStreak().color) ? 'white' : 'black';
    });

    public handIcon = computed(() => {
        return isColorContrastLight(this.courseStreak().color) ? '✋' : '✋🏿';
    });

    public buttonColor = computed(() => {
        return isColorContrastLight(this.courseStreak().color) ? Color.Snowy : Color.Jack;
    });

    public getComplimentString = computed(() => {
        if (this.courseStreak().progressPercent === 0) {
            return 'Great job! You and [NAME] are smashing it!';
        } else {
            return `Great job! You and [NAME] are smashing it, you're ${this.courseStreak().progressPercent}% through ${this.courseStreak().title}.`;
        }
    });

    public closeModal(): void {
        void this.modalService.dismiss();
        this.store.dispatch(launchRatingQualifierModal({ trigger: 'progress-streak-modal' }));
    }
}
