import { AsyncPipe, NgClass } from '@angular/common';
import { Component, inject } from '@angular/core';
import { AnalyticsTrackClickDirective, SliderComponent } from '@frontend/ui';
import { IonCard } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { triggerAddNewCourse } from '../../store/course/course.actions';
import { CourseCardLoadingComponent } from './course-card/course-card-loading/course-card-loading.component';
import { CourseCardComponent } from './course-card/course-card.component';
import { CourseCard } from './course-card/course-card.model';
import { selectTodayCoursesVm } from './today-courses.selectors';

@Component({
    selector: 'app-today-courses',
    templateUrl: './today-courses.component.html',
    styleUrls: ['./today-courses.component.scss'],
    standalone: true,
    imports: [
        SliderComponent,
        CourseCardLoadingComponent,
        CourseCardComponent,
        IonCard,
        AnalyticsTrackClickDirective,
        NgClass,
        AsyncPipe,
    ],
})
export class TodayCoursesComponent {
    private readonly store = inject(Store);
    public vm$ = this.store.select(selectTodayCoursesVm);

    addNewCourse() {
        this.store.dispatch(triggerAddNewCourse());
    }

    trackByFn(_: number, course: CourseCard): string {
        return course.id;
    }
}
