export const SHOW_PAYMENT_MODAL_TRIGGERS = {
    COURSE_PREVIEW_PAGE: 'course-preview-page',
    PAYWALL_DEEPLINK: 'paywall-deeplink',
    PAYWALL_DEEPLINK_IOS_DISCOUNT: 'paywall-deeplink-ios-discount',
    STARTUP: 'startup',
};

export const REVENUE_CAT_OFFERINGS = {
    DEFAULT: 'Subscription-Q20-Y40',
    DISCOUNT_PAYWALL: 'Discount_Paywall_Offerings_July2024',
    DISCOUNT_PAYWALL_UK_PRICE_INCREASE: 'Increased_UK_Price_For_Discount_Paywall_AB_Test_July_2024',
};
