@if (vm()) {
<ion-header class="page-header ion-no-border">
    <ion-toolbar>
        <app-header
            title="Sign in"
            identifierLeftButton="login-back-btn"
            [isDisabled]="vm().isLoading"
            [hasLeftButton]="true"
            (leftButtonPressed)="routeToPrevious()"
        />
    </ion-toolbar>
</ion-header>

<ion-content class="page-ion-content ion-text-center login-page" [fullscreen]="true">
    <main class="page-content ion-justify-content-center">
        <section>
            <h1>Log in to your Zigzag account</h1>
            <p class="ion-padding-start ion-padding-end">Let’s start training again!</p>
        </section>

        <form class="login-page__form" [formGroup]="loginForm">
            <ion-item>
                <ion-input
                    class="zz-input"
                    formControlName="email"
                    type="email"
                    placeholder="Your email"
                    data-test="email-input"
                    [tabindex]="1"
                />
            </ion-item>
            @if (showEmailError()) {
            <app-invalid-input-error-message errorMessage="Please fill in a valid email" />
            }
            <ion-item class="login-page__password-item ion-margin-top">
                <ion-input
                    class="zz-input"
                    #passwordInput
                    formControlName="password"
                    type="password"
                    placeholder="Password"
                    data-test="password-input"
                    [tabindex]="2"
                />

                @if (isPasswordVisible) {
                <button class="zz-button l" type="button" (click)="togglePasswordVisibility()">
                    <i class="login-page__password-visibility fa-regular fa-eye-slash"></i>
                </button>
                } @else {
                <button class="zz-button l" type="button" (click)="togglePasswordVisibility()">
                    <i class="login-page__password-visibility fa-regular fa-eye"></i>
                </button>
                }
            </ion-item>

            @if (!!vm().error) {
            <app-invalid-input-error-message
                [errorMessage]="vm().error!.message"
                [linkText]="vm().error!.link?.text"
                (linkClicked)="routeToErrorLinkFromInvalidInput(vm().error!.link?.route ?? [])"
            />
            }
        </form>

        @if (vm().isSsoEnabled) { @if (vm().isIos) {
        <p class="login-page__or">or</p>

        <ion-button
            class="login-page__login-page-button"
            appTrackClick
            identifier="auth-apple-btn"
            expand="block"
            color="snowy"
            data-test="auth-apple-btn"
            (click)="signInWithApple()"
        >
            <h3 class="login-page__login-page-button__content">
                <i class="login-page__login-page-button__icon fa-brands fa-apple"></i>
                <span class="login-page__login-page-button__text">Continue with Apple</span>
            </h3>
        </ion-button>
        }

        <ion-button
            class="login-page__login-page-button"
            appTrackClick
            identifier="auth-google-btn"
            expand="block"
            color="snowy"
            data-test="auth-google-btn"
            (click)="signInWithGoogle()"
        >
            <h3 class="login-page__login-page-button__content">
                <img src="assets/images/third-party/google-logo-color.svg" alt="Continue with Google" />
                <span class="login-page__login-page-button__text">Continue with Google</span>
            </h3>
        </ion-button>
        }
    </main>
</ion-content>

@if (!vm().isInputFocusedOnAndroid) {
<ion-footer class="ion-text-center page-footer">
    <button
        class="zz-button login-page__forgot-password"
        appTrackClick
        identifier="forgot-password"
        data-test="forgot-password"
        (click)="forgotPassword()"
    >
        Forgot your password?
    </button>
    <ion-button
        appTrackClick
        identifier="sign-in-btn"
        color="max"
        expand="block"
        data-test="login-btn"
        [disabled]="!loginForm.valid"
        (click)="login()"
    >
        @if (vm().isLoading) {
        <ion-spinner name="crescent" />
        } @else {
        <div>Sign In</div>
        }
    </ion-button>
</ion-footer>
} }
