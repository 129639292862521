import { Routes } from '@angular/router';
import { LandingPage } from './feature-authentication/landing-page/landing.page';
import { LoginPage } from './feature-authentication/login/login.page';
import { ResetPasswordEnterEmailPage } from './feature-authentication/reset-password-enter-email/reset-password-enter-email.page';
import { ResetPasswordWaitingPage } from './feature-authentication/reset-password-waiting/reset-password-waiting.page';
import { CreateAccountPage } from './feature-authentication/create-account/create-account.page';

export const authenticationRoutes: Routes = [
    {
        path: '',
        component: LandingPage,
    },
    {
        path: 'login',
        component: LoginPage,
    },
    {
        path: 'request-password-reset',
        component: ResetPasswordEnterEmailPage,
    },
    {
        path: 'request-password-waiting',
        component: ResetPasswordWaitingPage,
    },
    {
        path: 'create-account',
        component: CreateAccountPage,
    },
];
