<ion-header class="page-header ion-no-border">
    <ion-toolbar>
        <app-header
            title="Search breed"
            identifierLeftButton="breed-selector-modal-back-btn"
            [hasLeftButton]="true"
            (leftButtonPressed)="back()"
        />
    </ion-toolbar>
</ion-header>

<app-search-bar
    [placeholder]="'Search breed'"
    [resultListTemplate]="resultListTemplate"
    [noResultTemplate]="noResults"
    [instructionTemplate]="resultListTemplate"
    [initialFocus]="true"
    [hasResults]="results.length > 0"
    (search)="searchItems($event)"
/>

<ng-template #resultListTemplate>
    <ion-chip class="ion-padding ion-margin"> If your pup is a mixed breed, select the most prominent breed. </ion-chip>
    <ion-content>
        <ion-list>
            @for (result of results; track result.id) {
                <ion-item (click)="selectBreed(result)" data-test="breed-search-result">
                    <ion-label class="ion-text-wrap">
                        <h3>{{ result.name }}</h3>
                    </ion-label>
                </ion-item>
            }
        </ion-list>
    </ion-content>
</ng-template>

<ng-template #noResults let-searchValue="searchValue">
    <div class="center">
        <div class="no-results-image">
            <img src="assets/images/oliver/oliver-looks-confused.png" />
        </div>
        <h3>No results for "{{ searchValue }}"</h3>
        <p>Try again using different spelling or keywords.</p>
    </div>
</ng-template>
