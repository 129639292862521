@if (product) {
    <div class="payment-product">
        <div class="chip-container">
            @if (product.discount) {
                <ion-chip class="most-popular" data-test="payment-modal-product-discount">
                    <ion-label>{{ product.discount.title }}</ion-label>
                </ion-chip>
            }
        </div>
        <ion-card
            class="available-product"
            [ngClass]="{ selected: isSelected }"
            (click)="clickProduct({ identifier: product.identifier, priceWithUnit: product.priceWithUnit })"
        >
            <ion-grid>
                <ion-row>
                    <ion-col>
                        <div class="left-product-container">
                            <div>
                                <h3 data-test="payment-modal-product-title">{{ product.title }}</h3>
                            </div>
                            <div [innerHTML]="product.description"></div>
                        </div>
                    </ion-col>
                    <ion-col size="5">
                        <div class="right-product-container">
                            <div class="align-right">
                                <h3 data-test="payment-modal-product-price">
                                    @if (product.discount) {
                                        <span class="strikethrough" data-test="payment-modal-product-price-original">{{
                                            product.discount.originalPrice
                                        }}</span>
                                    }
                                    {{ product.priceWithUnit }}
                                </h3>
                            </div>
                            @if (product.pricePerMonth) {
                                <div class="align-right" data-test="payment-modal-product-price-per-month">
                                    {{ product.pricePerMonth }}
                                </div>
                            }
                        </div>
                    </ion-col>
                </ion-row>
            </ion-grid>
        </ion-card>
    </div>
}
