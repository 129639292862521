import { AsyncPipe, NgClass } from '@angular/common';
import {
    AfterViewInit,
    Component,
    computed,
    effect,
    ElementRef,
    inject,
    OnDestroy,
    OnInit,
    Renderer2,
    TemplateRef,
    untracked,
    ViewChild,
    viewChild,
} from '@angular/core';
import { chatFeature, destroyChat, StreamChatHtmlMessagePipe } from '@frontend/data-access/chat';
import { AnalyticsTrackClickDirective, GenericChipComponent, LoadingScreenComponent } from '@frontend/ui';
import { ChatChannelType, ZIGGY_AI_ID } from '@shared/constants';
import { ConversationMessageRatingType } from '@shared/user-api-interface';
import { IonButton, IonContent, IonFooter, IonHeader, IonSpinner, IonToolbar } from '@ionic/angular/standalone';
import { Color } from '@ionic/cli/lib/utils/color';
import { Store } from '@ngrx/store';
import {
    CustomTemplatesService,
    MessageContext,
    MessageInputComponent,
    MessageListComponent,
    StreamChatModule,
    StreamMessage,
} from 'stream-chat-angular';
import { ChatMessageRatingFeedbackComponent } from '../chat-message-rating-feedback/chat-message-rating-feedback.component';
import { ChatMessageRatingFeedbackSubmittedEvent } from '../chat-message-rating-feedback/chat-message-rating-feedback.model';
import { ChatMessageRatingComponent } from '../chat-message-rating/chat-message-rating.component';
import { chatPageLoaded, userLeftMessageFeedback, userRatedMessage } from '../chat.actions';
import { selectAiChatPageVm } from './ai-chat-page.selectors';
import { ChatErrorMessageComponent } from './chat-error-message/chat-error-message.component';
import { AiChatIntroComponent } from './chat-intro/ai-chat-intro.component';
import { AiChatHeaderComponent } from './header/ai-chat-header.component';
import { sendMessage, userSentMessageInAiChat } from './store/ai-chat.actions';
import { aiChatFeature } from './store/ai-chat.reducer';
import { showPaymentModal } from '../../store/payment/store/payment.actions';
import { routeToPrevious } from '@frontend/data-access/router';

@Component({
    selector: 'app-ai-chat-page',
    templateUrl: './ai-chat.page.html',
    styleUrls: ['./ai-chat.page.scss'],
    standalone: true,
    imports: [
        AiChatHeaderComponent,
        NgClass,
        AiChatIntroComponent,
        StreamChatModule,
        GenericChipComponent,
        LoadingScreenComponent,
        ChatMessageRatingComponent,
        ChatMessageRatingFeedbackComponent,
        ChatErrorMessageComponent,
        AsyncPipe,
        AnalyticsTrackClickDirective,
        IonButton,
        IonFooter,
        IonSpinner,
        IonContent,
        StreamChatHtmlMessagePipe,
        IonHeader,
        IonToolbar,
    ],
})
export class AiChatPage implements OnInit, AfterViewInit, OnDestroy {
    private readonly customTemplatesService = inject(CustomTemplatesService);
    private readonly store = inject(Store);
    private readonly renderer2 = inject(Renderer2);
    private readonly elementRef = inject(ElementRef);

    @ViewChild('messageTemplate')
    private messageTemplate!: TemplateRef<MessageContext>;
    @ViewChild(MessageListComponent, { read: ElementRef })
    private messageListElementRef!: ElementRef;
    @ViewChild('streamMessageInput')
    private streamMessageInput!: MessageInputComponent;
    @ViewChild(ChatMessageRatingFeedbackComponent)
    private ratingFeedbackComponentRef!: ChatMessageRatingFeedbackComponent;
    @ViewChild(ChatMessageRatingFeedbackComponent, { read: ElementRef })
    private ratingFeedbackElementRef!: ElementRef;

    public shouldShowChatIntro = true;
    public lastNegativeRatedMessageId = '';

    protected readonly ConversationMessageRatingType = ConversationMessageRatingType;
    protected readonly Color = Color;
    protected readonly ZIGGY_AI_ID = ZIGGY_AI_ID;

    private wasPromptClicked = false;

    messageListRef = viewChild<MessageListComponent>(MessageListComponent);

    isChatInitialised = this.store.selectSignal(chatFeature.selectChannelInitialized);
    initialMessage = this.store.selectSignal(aiChatFeature.selectInitialMessage);
    vm = this.store.selectSignal(selectAiChatPageVm);

    shouldShowFreeQuestionChip = computed(() => {
        return !this.vm().contentUnlocked && this.vm().oneFreeQuestionInfo.questionsRemaining > 0;
    });
    messageInputLocked = computed(() => {
        return !this.vm().contentUnlocked && this.vm().oneFreeQuestionInfo.questionsRemaining < 1;
    });

    constructor() {
        // Scroll to the bottom of the container, this is required due to an issue with the chat not fully scrolling to the bottom when the chat is initialised
        effect(() => {
            if (this.messageListRef()) {
                setTimeout(() => {
                    this.messageListRef()?.scrollToBottom();
                }, 0);
            }
        });

        effect(() => {
            if (this.initialMessage()) {
                this.introTriggeredHide();
            }
        });

        /**
         * This effect is used to paste the initial message into the chat input and send it if the chat is already initialised.
         *
         * In the case where the user does NOT have free questions remaining, the message input will NOT render, and therefore,s
         * this.streamMessageInput will be undefined. This is why we need to check if this.streamMessageInput is defined before.
         */
        effect(() => {
            const safeToPaste = !!(this.initialMessage() && this.isChatInitialised());

            setTimeout(() => {
                if (safeToPaste && this.streamMessageInput !== undefined) {
                    this.streamMessageInput.textareaValue = this.initialMessage() ?? '';

                    /**
                     * Send the initial message if the message input is not locked, if the input becomes unlocked
                     * i.e. the user purchases a subscription, we don't want to send the message, just allow it to persist in the input.
                     */
                    untracked(() => {
                        if (!this.messageInputLocked()) {
                            this.sendMessage(this.initialMessage());
                        }
                    });
                }
            }, 0);
        });
    }

    ngOnInit() {
        this.store.dispatch(chatPageLoaded({ chatType: ChatChannelType.AI }));
    }

    ngAfterViewInit() {
        this.customTemplatesService.messageTemplate$.next(this.messageTemplate);
    }

    ngOnDestroy() {
        this.store.dispatch(destroyChat());
    }

    sendMessage(message: string | undefined) {
        if (message) {
            this.store.dispatch(sendMessage({ message }));

            this.clearMessage();
        }
    }

    clearMessage() {
        if (this.streamMessageInput) {
            this.streamMessageInput.textareaValue = '';
        }
    }

    routeToPrevious() {
        this.store.dispatch(routeToPrevious({ isAnimated: false }));
    }

    onFocusInStreamChatInput() {
        if (this.ratingFeedbackComponentRef) {
            this.ratingFeedbackComponentRef.onClickDone();
        }
    }

    onRatingChanged(rating: ConversationMessageRatingType, streamChatMessageId: string) {
        if (rating === ConversationMessageRatingType.NEGATIVE) {
            this.lastNegativeRatedMessageId = streamChatMessageId;

            this.checkIfShouldScrollToRatingFeedback();
        } else {
            this.lastNegativeRatedMessageId = '';
        }

        this.store.dispatch(
            userRatedMessage({
                rating,
                streamChatMessageId,
                streamChatUserId: ZIGGY_AI_ID,
            }),
        );
    }

    onFeedbackSubmitted(feedback: ChatMessageRatingFeedbackSubmittedEvent, streamChatMessageId: string) {
        this.lastNegativeRatedMessageId = '';

        this.store.dispatch(
            userLeftMessageFeedback({
                reason: feedback?.option.value,
                comment: feedback?.comment,
                streamChatMessageId,
            }),
        );
    }

    checkIfShouldScrollToRatingFeedback() {
        // Wait for the rating feedback element to be rendered, otherwise, ratingFeedbackElementRef will be undefined
        setTimeout(() => {
            if (!this.ratingFeedbackElementRef || !this.messageListElementRef) {
                return;
            }

            const chatMessageRatingBottomPosition =
                this.ratingFeedbackElementRef.nativeElement.getBoundingClientRect().bottom;
            const scrollContainerBottomPosition =
                this.messageListElementRef.nativeElement.getBoundingClientRect().bottom;

            const isChatMessageRatingBottomCutOff =
                chatMessageRatingBottomPosition + 20 >= scrollContainerBottomPosition;

            if (isChatMessageRatingBottomCutOff) {
                // Wait for the message list to adjust
                setTimeout(() => {
                    this.ratingFeedbackElementRef.nativeElement.scrollIntoView(false);
                }, 10);
            }
        }, 0);
    }

    placePromptMessageInInput(event: string | null) {
        this.wasPromptClicked = event !== null;
        this.streamMessageInput.textareaValue = event ?? '';

        const textArea = this.elementRef.nativeElement.querySelector('textarea');

        this.renderer2.setStyle(textArea, 'height', 'auto');

        setTimeout(() => {
            this.renderer2.setStyle(textArea, 'height', `${textArea.scrollHeight}px`);
        }, 0);
    }

    messageSentOrUpdated(event: { message: StreamMessage }) {
        this.store.dispatch(
            userSentMessageInAiChat({ message: { ...event.message }, wasPromptClicked: this.wasPromptClicked }),
        );

        this.shouldShowChatIntro = false;
    }

    introTriggeredHide() {
        this.shouldShowChatIntro = false;
    }

    openPaymentModal() {
        void this.store.dispatch(showPaymentModal({ trigger: 'ai-chat-page-show-paywall-button' }));
    }
}
