import { Component, inject } from '@angular/core';
import { trackEvent } from '@frontend/data-access/analytics';
import { setPushNotifications } from '@frontend/data-access/push-notification';
import { AnalyticsTrackClickDirective, HeaderComponent } from '@frontend/ui';
import {
    IonButton,
    IonContent,
    IonFooter,
    IonHeader,
    IonNav,
    IonToolbar,
    NavParams,
    ViewDidEnter,
} from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { addDays } from 'date-fns';
import { SHOW_PAYMENT_MODAL_TRIGGERS } from '../../store/payment/constants/payment.constants';
import { PaymentModalLimitedOfferComponent } from '../payment-modal-limited-offer/payment-modal-limited-offer.component';
import {
    selectIsEligibleForLimitedOfferPaymentModal,
    selectPaymentModalLimitedOfferProductCards,
} from '../payment-modal-limited-offer/payment-modal-limited-offer.selectors';
import { PaymentModalParentComponent } from '../payment-modal-parent/payment-modal-parent.component';

@Component({
    standalone: true,
    selector: 'app-payment-modal-trial-reminder',
    templateUrl: './payment-modal-trial-reminder.component.html',
    styleUrls: ['./payment-modal-trial-reminder.component.scss'],
    imports: [AnalyticsTrackClickDirective, IonContent, IonFooter, IonButton, HeaderComponent, IonHeader, IonToolbar],
})
export class PaymentModalTrialReminderComponent implements ViewDidEnter {
    private readonly store = inject(Store);
    private readonly nav = inject(IonNav);
    private readonly navParams = inject(NavParams);

    public isEligibleForLimitedOffer = this.store.selectSignal(selectIsEligibleForLimitedOfferPaymentModal);
    public limitedOfferProducts = this.store.selectSignal(selectPaymentModalLimitedOfferProductCards);

    public trialEndDate = addDays(new Date(), 5).toLocaleString('en-US', {
        day: 'numeric',
        month: 'long',
    });

    public ionViewDidEnter(): void {
        this.store.dispatch(trackEvent({ eventName: '[Paywall] Trial Reminder Screen Shown' }));
    }

    public seePlansClicked(): void {
        this.store.dispatch(setPushNotifications());

        const trigger = this.navParams.get('trigger');

        const isValidOrigin =
            trigger !== SHOW_PAYMENT_MODAL_TRIGGERS.COURSE_PREVIEW_PAGE &&
            trigger !== SHOW_PAYMENT_MODAL_TRIGGERS.PAYWALL_DEEPLINK;

        if (this.isEligibleForLimitedOffer() && isValidOrigin) {
            void this.nav.push(PaymentModalLimitedOfferComponent, {
                products: this.limitedOfferProducts(),
                selectedProductId: this.limitedOfferProducts()[0].identifier,
            });
        } else {
            void this.nav.push(PaymentModalParentComponent);
        }
    }
}
