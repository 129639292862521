<ion-header class="page-header ion-no-border">
    <ion-toolbar>
        <app-header
            buttonBackground="var(--ion-color-app-outline)"
            [identifierRightButton]="vm.modalId + '-close-btn'"
            [hasRightButton]="vm.closeable === undefined ? true : vm.closeable"
            (rightButtonPressed)="closeModal()"
        />
    </ion-toolbar>
</ion-header>

@if (vm.useModalTemplate) {
    <ion-content class="dismissible-modal" data-test="dismissible-modal-content-wrapper" [color]="vm.backgroundColor">
        <ng-container *ngTemplateOutlet="modalContent"></ng-container>
    </ion-content>

    <ion-footer>
        <ion-toolbar class="button-toolbar">
            <ion-button
                class="primary-btn"
                color="max"
                expand="block"
                appTrackClick
                [identifier]="vm.modalId + '-primary-btn'"
                [attr.data-test]="vm.modalId + '-primary-btn'"
                (click)="callToAction()"
            >
                {{ vm.mainButtonText ?? 'Got it!' }}
            </ion-button>
        </ion-toolbar>
    </ion-footer>
} @else {
    <ng-container *ngTemplateOutlet="modalContent"></ng-container>
}
