import { createSelector } from '@ngrx/store';
import { selectContentUnlocked } from '../../store/payment/store/payment.selectors';
import { selectSelectedPathCourseId } from '../course-path/store/course-path.selectors';

export const selectCourseInformationModalVm = createSelector(
    selectContentUnlocked,
    selectSelectedPathCourseId,
    (unlocked, selectedPathCourseId) => ({
        unlocked,
        selectedPathCourseId,
    }),
);
