import { LessonProgressType } from '@shared/user-domain';
import { StepProgress } from './step-progress.model';

export class StepProgressMock {
    private defaultValue: StepProgress = {
        id: 'id',
        stepId: 'step id',
        contentId: 111,
        progress: LessonProgressType.COMPLETED,
        isPractice: false,
        timestamp: 123,
    };

    constructor(overrides?: Partial<StepProgress>) {
        Object.assign(this.defaultValue, overrides);
    }

    public get value(): StepProgress {
        return this.defaultValue;
    }
}
