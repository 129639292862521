@if (vm()) {
<ion-header class="today-page-header">
    <ion-toolbar class="today-page-header__toolbar" color="app-outline" />
</ion-header>
<ion-content class="today-page page-ion-content" [fullscreen]="true">
    <app-today-developmental-window
        [@collapseAnimation]="vm().showAiChatView ? 'collapse' : 'expand'"
        class="today-page__developmental-window"
    />
    @if (isReversePaywallAb()) {
    <button (click)="openFreeAccessModal()">open free access modal</button>
    } @if (vm().isAIChatEnabled) {
    <app-ai-chat-header
        [@fadeInAnimation]="vm().showAiChatView ? 'in' : 'out'"
        appTrackClick
        identifier="today-ai-chat-input-back-btn"
        class="today-page__ai-chat-header"
        (backClicked)="aiChatBackButtonClicked()"
    />
    <app-today-ai-chat-input
        #aiChatInput
        appTrackClick
        identifier="today-ai-chat-input"
        class="today-page__ai-chat-input"
        [showSendButton]="!!vm().showAiChatView"
        (inputClicked)="aiChatInputClicked(!!vm().showAiChatView)"
        (submitted)="aiChatInputSubmitted($event)"
    />
    }
    <div class="text-block" [style.display]="vm().showAiChatView ? 'none' : 'block'">
        @if (vm().isHomeRecommendedContentEnabled) {
        <h2 class="title" data-test="today-page-recommended-courses-title">Picked for {{ vm().userInfo.dogName }}</h2>
        <app-slider [gap]="12">
            @for (course of filteredCourses(); track course.id) {
            <app-course-tile [course]="course" [attr.data-test]="'today-page-recommended-course-tile-' + course.id" />
            }
        </app-slider>
        } @else {
        <app-today-courses data-test="app-today-courses" />
        }
        <app-arrival-tile />
        <app-pinned-topic-overview />
        <h2 class="no-margin-bottom">
            {{ vm().isAIChatEnabled ? 'Common questions for pups like ' + vm().userInfo.dogName : 'Pup support hub' }}
        </h2>
        <app-slider [gap]="12">
            @if (vm().isAIChatEnabled) {
            <app-suggested-ai-chat-questions
                #suggestedQuestionsPrompt
                [suggestedQuestionsCards]="vm().suggestedQuestions"
                (promptClicked)="aiChatQuestionClicked($event)"
            />
            } @for (tile of vm().tiles; track tile.title) {
            <app-generic-card-square
                appTrackClick
                [identifier]="tile.contentUnlocked ? 'today-page-' + tile.cardName + '-tile': 'today-page-' + tile.cardName + '-tile-locked'"
                [title]="tile.title"
                [description]="tile.description"
                [imageUrl]="tile.imageUrl"
                [background]="tile.backgroundColor"
                [unlocked]="tile.contentUnlocked"
                [disabled]="!!vm().isStreamChatBusyCleaningUp"
                [attr.data-test]="tile.contentUnlocked ? 'today-page-' + tile.cardName + '-tile': 'today-page-' + tile.cardName + '-tile-locked'"
                (click)="cardClicked(tile.contentUnlocked, tile.cardName, !!(vm().isStreamChatBusyCleaningUp))"
            />
            }
        </app-slider>
        <app-today-daily-bites />
    </div>
</ion-content>
}
