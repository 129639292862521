import { Injectable, inject } from '@angular/core';
import { notEmpty } from '@shared/utils/typescript';
import { dismissibleInfoFeature, dismissInfoWithTimestamp } from '@frontend/data-access/dismissible-info';
import { timeFeature } from '@frontend/data-access/time';
import { createStepProgressSuccess } from '@frontend/data-access/user/progress';
import { ModalService } from '@frontend/utility/modal';
import { LessonProgressType } from '@shared/user-domain';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { filter, map, mergeMap, tap } from 'rxjs/operators';
import { trackEvent } from '@frontend/data-access/analytics';
import { selectCoursesWithStepsAndSplits } from '../../courses/course-steps-and-splits.selectors';
import { ProgressStreakModalComponent } from './progress-streak-modal.component';
import { selectProgressStreakInfo } from './progress-streak-modal.selectors';
import { didStepCompletionCauseStreakUserHasNotSeen } from './progress-streak.utils';

@Injectable()
export class ProgressStreakModalEffects {
    private readonly actions$ = inject(Actions);
    private readonly modalService = inject(ModalService);
    private readonly store = inject(Store);
    showProgressStreakModal$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(createStepProgressSuccess),
            map(({ progress }) => {
                return progress.filter((progressItem) => progressItem.progress === LessonProgressType.COMPLETED);
            }),
            filter((completedProgress) => completedProgress.length > 0),
            concatLatestFrom(() => [
                this.store.select(selectCoursesWithStepsAndSplits),
                this.store.select(selectProgressStreakInfo),
                this.store.select(dismissibleInfoFeature.selectDismissedInfoItems),
                this.store.select(timeFeature.selectToday),
            ]),
            map(([stepProgress, courses, allCourseStreaks, dismissedItems, today]) =>
                // TODO: GP: Make possible to handle arrays
                didStepCompletionCauseStreakUserHasNotSeen(
                    stepProgress[0].stepId,
                    courses,
                    allCourseStreaks,
                    dismissedItems,
                    today,
                ),
            ),
            filter(notEmpty),
            tap((progressStreakModalInfo) => {
                const { id, ...courseStreak } = progressStreakModalInfo;

                void this.modalService.showModal({
                    component: ProgressStreakModalComponent,
                    componentProps: {
                        courseStreak,
                    },
                    cssClass: ['modal', 'modal-fullscreen'],
                });
            }),
            mergeMap((progressStreakModalInfo) => {
                const { color, ...courseStreak } = progressStreakModalInfo;

                return [
                    trackEvent({
                        eventName: '[Progress Streak] Show Modal',
                        eventProperties: {
                            ...courseStreak,
                        },
                    }),
                    dismissInfoWithTimestamp({
                        id: `progress-streak-modal-${courseStreak.id}`,
                        timestamp: new Date().toString(),
                    }),
                ];
            }),
        );
    });
}
