import { AsyncPipe, JsonPipe } from '@angular/common';
import { Component, input, OnDestroy, OnInit } from '@angular/core';
import { IonCard } from '@ionic/angular/standalone';
import { formatCountdown } from '@shared/utils/typescript';
import { PaymentProductCard } from '../payment-modal-product/payment-modal-product.model';

@Component({
    selector: 'app-payment-limited-offer-modal-product',
    templateUrl: './payment-modal-limited-offer-product.component.html',
    styleUrls: ['./payment-modal-limited-offer-product.component.scss'],
    standalone: true,
    imports: [IonCard, JsonPipe, AsyncPipe],
})
export class PaymentModalLimitedOfferProductComponent implements OnInit, OnDestroy {
    public product = input<PaymentProductCard>();
    public isSelected = input<boolean>(false);

    public remainingTime = '';

    private intervalId!: NodeJS.Timeout;

    public ngOnInit(): void {
        this.updateCountdown();

        this.intervalId = setInterval(() => {
            this.updateCountdown();
        }, 1000);
    }

    public ngOnDestroy(): void {
        clearInterval(this.intervalId);
    }

    private updateCountdown(): void {
        if (this.product()?.offerEndDate) {
            const timeDifference = this.product()!.offerEndDate!.getTime() - new Date().getTime();

            if (timeDifference <= 0) {
                this.remainingTime = '';

                clearInterval(this.intervalId);

                return;
            }

            this.remainingTime = formatCountdown(timeDifference);
        }
    }
}
