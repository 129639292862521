import { createSelector } from '@ngrx/store';
import { selectSteps } from '../step/step.selectors';
import { TopicWithProgress } from './models/topic-with-progress.model';
import { selectTopics } from './topic.selectors';
import { getTopicProgress } from './topic.util';

export const selectTopicsWithProgress = createSelector(
    selectTopics,
    selectSteps,
    (topics, steps): TopicWithProgress[] => {
        return topics.map((topic) => {
            const progress = getTopicProgress(topic.title, steps);
            return { ...topic, progress };
        });
    },
);
export const selectPinnedTopicsWithProgress = createSelector(selectTopicsWithProgress, (topics) => {
    return topics.filter((topic) => topic.pinned);
});
