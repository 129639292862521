@if (vm$ | async; as vm) { @if (vm.step) {
<ion-content class="article-page page-ion-content" [ngClass]="{ 'no-scroll': !vm.unlocked }" [fullscreen]="true">
    @if (!vm.unlocked) {
    <div class="article-page__lock-overlay"></div>
    }

    <article class="article-page__container">
        <ion-header class="article-page__header page-header ion-no-border">
            <ion-toolbar>
                <app-header
                    buttonBackground="white"
                    identifierLeftButton="article-back-btn"
                    [hasLeftButton]="true"
                    (leftButtonPressed)="routeToPreviousPage()"
                />
            </ion-toolbar>
        </ion-header>

        <img class="article-page__header-image image-self" src="{{ vm.step.content.imageUrl }}" alt="header-image" />

        <div class="article-page__content-container text-block">
            <h1>{{ vm.step.content.title | textReplacement }}</h1>

            @if (vm.step.content.articleAuthor) {
            <app-author-chip
                [authorImageUrl]="vm.step.content.articleAuthor.authorImageUrl"
                [author]="vm.step.content.articleAuthor.authorName"
            ></app-author-chip>
            } @else if (vm.step.content.author) {
            <app-author-chip
                [authorImageUrl]="vm.step.content.authorImageUrl"
                [author]="vm.step.content.author"
            ></app-author-chip>

            } @if (vm.step.content.content) {
            <div
                class="article-page__content contentful-rich-text"
                data-test="legacy-content"
                [innerHTML]="vm.step.content.content | textReplacement"
            ></div>
            } @if (vm.step.content.richContent) {
            <div
                class="article-page__content contentful-rich-text"
                data-test="rich-content"
                [innerHTML]="vm.step.content.richContent | richContent | textReplacement"
            ></div>
            }
        </div>
    </article>

    <ion-footer class="ion-text-center gradient-footer">
        <ion-button
            appTrackClick
            identifier="article-read-btn"
            color="max"
            expand="block"
            data-test="article-read"
            (click)="vm.unlocked ? articleCompleted(vm.step) : openPaymentModal()"
        >
            @if (vm.unlocked) { Read it! } @else {
            <i class="fa-regular fa-lock-keyhole ion-margin-end icon-large article-page__padlock-icon"></i>
            @if (!vm.hasHistoricalPurchase) { Start my free trial } @else { See plans } }
        </ion-button>
    </ion-footer>
</ion-content>
} }
