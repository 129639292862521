import { AsyncPipe, NgClass } from '@angular/common';
import { Component, inject, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Color, isColorContrastLight } from '@shared/utils/typescript';
import { AnalyticsTrackClickDirective, ElementRerenderDirective, GenericCardLargeComponent } from '@frontend/ui';
import { CourseProgressType } from '@shared/user-domain';
import { ActionSheetButton, IonContent, ScrollDetail, ViewWillEnter } from '@ionic/angular/standalone';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { mergeMap, take } from 'rxjs/operators';
import { trackEvent } from '@frontend/data-access/analytics';
import { openActionSheet } from '../../store/action-sheet/action-sheet.actions';
import { triggerAddNewCourse } from '../../store/course/course.actions';
import { CourseStatus } from '../../store/course/course.model';
import { showEndCourseModal } from '../../store/modal/modal.actions';
import { showPaymentModal } from '../../store/payment/store/payment.actions';
import { triggerUpdateCourseProgress } from '../../store/progress/course-progress/course-progress.actions';
import { showCourseInformationModal } from '../course-information-modal/course-information-modal.actions';
import { CoursePathComponent } from '../course-path/course-path.component';
import { setSelectedPathCourseId } from '../course-slider/course-slider.actions';
import { CourseSliderComponent } from '../course-slider/course-slider.component';
import { CourseWithSteps } from '../models/courses.models';
import { selectCoursesPageVm } from './courses.page.selectors';

@Component({
    selector: 'app-courses',
    templateUrl: './courses.page.html',
    styleUrls: ['./courses.page.scss'],
    standalone: true,
    imports: [
        IonContent,
        AnalyticsTrackClickDirective,
        CourseSliderComponent,
        CoursePathComponent,
        NgClass,
        GenericCardLargeComponent,
        AsyncPipe,
        ElementRerenderDirective,
    ],
})
export class CoursesPage implements ViewWillEnter {
    private readonly store = inject(Store);
    private readonly actions$ = inject(Actions);
    public vm = this.store.selectSignal(selectCoursesPageVm);
    public ionViewWillEnter$ = new Subject<''>();
    public iconCount = 1;

    @ViewChild(IonContent, { static: false }) ionContent!: IonContent;

    courseStatus = CourseStatus;
    scrollHeight = 0;
    endCourseButtonPaymentModal: ActionSheetButton = {
        text: 'Remove from Today page',
        handler: () => {
            void this.store.dispatch(
                showPaymentModal({
                    trigger: 'courses-page-burger-menu',
                }),
            );
        },
    };

    startCourseButtonPaymentModal: ActionSheetButton = {
        text: 'Add course',
        handler: () => {
            void this.store.dispatch(
                showPaymentModal({
                    trigger: 'courses-page-burger-menu',
                }),
            );
        },
    };

    cancelButton: ActionSheetButton = {
        text: 'Cancel',
        role: 'cancel',
        handler: () => {
            this.store.dispatch(
                trackEvent({
                    eventName: 'click',
                    eventProperties: { identifier: 'course-menu-cancel-btn' },
                }),
            );
        },
    };

    constructor() {
        this.actions$
            .pipe(
                takeUntilDestroyed(),
                ofType(triggerAddNewCourse),
                mergeMap(() => this.ionViewWillEnter$.pipe(take(1))),
            )
            .subscribe(() => {
                void this.ionContent?.scrollToTop();
            });

        this.actions$.pipe(takeUntilDestroyed(), ofType(setSelectedPathCourseId)).subscribe(() => {
            void this.ionContent?.scrollToTop();
        });
    }

    ionViewWillEnter() {
        this.ionViewWillEnter$.next('');
    }

    openCourseInfoModal(course: CourseWithSteps): void {
        this.store.dispatch(showCourseInformationModal({ course }));
    }

    openMenu(currentCourse: CourseWithSteps, contentUnlocked: boolean) {
        let buttons: ActionSheetButton[];
        if (contentUnlocked) {
            buttons = [
                currentCourse.status === CourseStatus.IN_PROGRESS
                    ? this.removeCourseButton(currentCourse)
                    : this.addCourseButton(currentCourse),
                this.cancelButton,
            ];
        } else {
            buttons = [
                currentCourse.status === CourseStatus.IN_PROGRESS
                    ? this.endCourseButtonPaymentModal
                    : this.startCourseButtonPaymentModal,
                this.cancelButton,
            ];
        }

        this.store.dispatch(openActionSheet({ buttons: buttons }));
    }

    setScrollHeight(event: CustomEvent<ScrollDetail>): void {
        this.scrollHeight = event.detail.scrollTop;
    }

    scrollToFirstInactiveStep(firstInactiveStepHeight: number | undefined): void {
        void this.ionContent?.scrollToPoint(undefined, firstInactiveStepHeight, 500);
    }

    removeCourseButton(currentCourse: CourseWithSteps): ActionSheetButton {
        return {
            text: 'Remove from Today page',
            handler: () => {
                this.store.dispatch(
                    showEndCourseModal({
                        course: {
                            id: currentCourse.id,
                            title: currentCourse.title,
                            color: currentCourse.color,
                        },
                    }),
                );
                this.store.dispatch(
                    trackEvent({ eventName: 'click', eventProperties: { identifier: 'course-menu-end-course-btn' } }),
                );
            },
        };
    }

    addCourseButton(currentCourse: CourseWithSteps): ActionSheetButton {
        return {
            text: 'Add course',
            handler: () => {
                this.store.dispatch(
                    triggerUpdateCourseProgress({
                        courseProgress: {
                            id: currentCourse.id,
                            progressType: CourseProgressType.IN_PROGRESS,
                        },
                    }),
                );
                this.store.dispatch(
                    trackEvent({ eventName: 'click', eventProperties: { identifier: 'course-menu-start-course-btn' } }),
                );
            },
        };
    }

    getCourseCardDoodle(color: Color) {
        return isColorContrastLight(color)
            ? '/assets/images/squiggles/doodle.png'
            : '/assets/images/squiggles/doodle-dark.png';
    }

    slideChanged(): void {
        this.iconCount++;
    }

    protected readonly CourseStatus = CourseStatus;
}
