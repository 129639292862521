import { platformFeature } from '@frontend/data-access/platform';
import { selectIsSsoEnabled } from '@frontend/data-access/user/config-cat';
import { createSelector } from '@ngrx/store';

export const selectLandingPageVm = createSelector(
    selectIsSsoEnabled,
    platformFeature.selectPlatformIsIos,
    (isSsoEnabled, isIos) => {
        return {
            isSsoEnabled,
            isIos,
        };
    },
);
