import { Topic } from '@frontend/data-access/contentful';
import { createSelector } from '@ngrx/store';
import { StepCardSlimModel } from '../../step/step-card-slim/models/step-card-slim.model';
import { selectContentUnlocked } from '../payment/store/payment.selectors';
import { selectRouteParamTopic } from '../router/router.selectors';
import { Step } from '../step/step.model';
import { selectSteps } from '../step/step.selectors';
import { mapStepToStepCardSlim } from '../step/step.utils';
import { selectTopics } from '../topic/topic.selectors';
import { mapStepToSearchInput } from './library.utils';
import { selectCompletedStepIds } from '@frontend/data-access/user/progress';

export const selectSelectedTopic = createSelector(selectRouteParamTopic, selectTopics, (topicId, topics) =>
    topics.find((topic: Topic) => topic.entityId === topicId),
);

export const selectSelectedTopicSteps = createSelector(selectSelectedTopic, selectSteps, (topic, steps) => {
    let filteredSteps: Step[] = [];
    if (!topic) {
        return filteredSteps;
    }

    // TODO: JL - handle legacy topic -> step management in contentful whilst content team updates topics to include step references
    if (topic.stepIds.length === 0) {
        filteredSteps = steps.filter((step) => step.topic.title === topic.title);
        filteredSteps.sort((a, b) => a.rank - b.rank);
    } else {
        filteredSteps = steps.filter((step) => topic.stepIds.includes(step.id));
    }

    return filteredSteps;
});

export const selectSelectedTopicProgress = createSelector(
    selectSelectedTopicSteps,
    selectCompletedStepIds,
    (topicSteps, completedStepIds) => {
        return Math.trunc(
            (topicSteps.filter((step) => completedStepIds.includes(step.id)).length / topicSteps.length) * 100,
        );
    },
);

export const selectSelectedTopicStepDetails = createSelector(
    selectSelectedTopicSteps,
    selectContentUnlocked,
    (steps, contentUnlocked): StepCardSlimModel[] => {
        return steps.map((step: Step) => {
            const locked = !contentUnlocked;
            return mapStepToStepCardSlim(step, locked);
        });
    },
);

export const selectLibrarySearchBarVm = createSelector(selectSteps, selectContentUnlocked, (steps, unlocked) => {
    return {
        unlocked,
        cardItems: steps.map((step) => {
            return mapStepToStepCardSlim(step, !unlocked);
        }),
        searchItems: steps.map((step) => mapStepToSearchInput(step)),
    };
});
