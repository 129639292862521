import { Component, Input } from '@angular/core';
import { IonChip } from '@ionic/angular/standalone';

@Component({
    standalone: true,
    selector: 'app-generic-chip',
    templateUrl: './generic-chip.component.html',
    styleUrl: './generic-chip.component.scss',
    imports: [IonChip],
})
export class GenericChipComponent {
    @Input({ required: true }) text!: string;
}
