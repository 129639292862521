import { createSelector } from '@ngrx/store';
import { selectIsInputOpenOnAndroid } from '@frontend/data-access/keyboard';
import { onboardingFeature } from '../../data-access/onboarding.reducer';
import { selectOnboardingProgress } from '../../data-access/onboarding.selectors';
import { accountFeature } from '@frontend/data-access/user/account';

export const selectQuestionOwnerNamePageVm = createSelector(
    selectOnboardingProgress,
    accountFeature.selectDogName,
    selectIsInputOpenOnAndroid,
    onboardingFeature.selectIsLoading,
    (progress, dogName, isInputOpenOnAndroid, isLoading: boolean) => {
        return {
            progress,
            dogName,
            isInputOpenOnAndroid,
            isLoading,
        };
    },
);
