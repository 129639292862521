import { createAction, props } from '@ngrx/store';
import { Gender } from '@shared/user-domain';

export const onboardingNavigateBack = createAction('[Onboarding] Navigate Back');

export const onboardingQuestionSubmittedName = createAction(
    '[Onboarding] Question Submitted Name',
    props<{ name: string }>(),
);

export const onboardingQuestionSubmittedIsRescue = createAction(
    '[Onboarding] Question Submitted Is Rescue',
    props<{ isRescuePup: boolean }>(),
);

export const onboardingQuestionSubmittedDateOfBirth = createAction(
    '[Onboarding] Question Submitted Date Of Birth',
    props<{
        dateOfBirth: string; // format YYYY-MM-DD'T'HH:mm:ss:Z e.g. 2023-10-07T00:00:00.000Z
        isApproximateDateOfBirth: boolean;
    }>(),
);

export const onboardingQuestionSubmittedHasArrived = createAction(
    '[Onboarding] Question Submitted Has Arrived',
    props<{ hasArrived: boolean }>(),
);

export const onboardingQuestionSubmittedArrivalDate = createAction(
    '[Onboarding] Question Submitted Arrival Date',
    props<{ dateOfArrival: string }>(),
);

export const onboardingQuestionSubmittedFirstDayAtHome = createAction(
    '[Onboarding] Question Submitted First Day At Home',
    props<{ dateOfArrival: string }>(),
);

export const onboardingQuestionSubmittedGender = createAction(
    '[Onboarding] Question Submitted Gender',
    props<{ gender: Gender }>(),
);

export const onboardingQuestionSubmittedBreed = createAction(
    '[Onboarding] Question Submitted Breed',
    props<{ breedId: string }>(),
);

export const onboardingQuestionSubmittedOwnerName = createAction(
    '[Onboarding] Question Submitted Owner Name',
    props<{ ownerName: string }>(),
);

export const onboardingQuestionSubmittedTopic = createAction('[Onboarding] Question Submitted Topic');

export const onboardingQuestionSubmittedTopicExtra = createAction('[Onboarding] Question Submitted Topic Extra');

export const onboardingAddSelectedTopic = createAction(
    '[Onboarding] Add Selected Topic',
    props<{ topicEntityId: string }>(),
);

export const onboardingRemoveSelectedTopic = createAction(
    '[Onboarding] Remove Selected Topic',
    props<{ topicEntityId: string }>(),
);

export const onboardingQuestionSubmittedNeeds = createAction(
    '[Onboarding] Question Submitted Needs',
    props<{ needs: Record<string, boolean> }>(),
);

export const onboardingQuestionSubmittedPurinaOptIn = createAction(
    '[Onboarding] Question Submitted Purina Opt In',
    props<{ purinaMarketingAccepted: boolean }>(),
);

export const onboardingQuestionSubmittedNotifications = createAction(
    '[Onboarding] Question Submitted Notifications Opt In',
    props<{ notifications: boolean }>(),
);

export const onboardingComplete = createAction('[Onboarding] Onboarding Complete');

export const finalisePageFinishedWaiting = createAction('[Onboarding] Finalise Page Finished Waiting');
