import { Component, inject } from '@angular/core';
import { Platform, ViewWillEnter, ViewWillLeave } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { onboardingFeature } from '../data-access/onboarding.reducer';
import { onboardingNavigateBack } from '../data-access/onboarding.actions';

@Component({
    selector: 'app-onboarding-base',
    template: '',
    standalone: true,
})
export class OnboardingBaseComponent implements ViewWillEnter, ViewWillLeave {
    protected readonly store = inject(Store);

    private readonly platform = inject(Platform);

    // TODO: NBSon - can we make this public/use a VM so we don't have to add isLoading to each individual page vm?
    private isLoading = this.store.selectSignal(onboardingFeature.selectIsLoading);

    private backButtonSub: Subscription = new Subscription();

    public ionViewWillEnter(): void {
        this.backButtonSub = this.platform.backButton.subscribeWithPriority(20, () => {
            this.routeToPrevious();
        });
    }

    public ionViewWillLeave(): void {
        this.backButtonSub.unsubscribe();
    }

    public routeToPrevious(): void {
        if (this.isLoading()) {
            return;
        }

        void this.store.dispatch(onboardingNavigateBack());
    }
}
