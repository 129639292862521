import { createSelector } from '@ngrx/store';
import { selectOnboardingProgress } from '../../data-access/onboarding.selectors';
import { selectContentfulTopics } from '@frontend/data-access/contentful';
import { ONBOARDING_PREVIEW_TOPIC_IDS } from '../../constants/onboarding.constants';
import { onboardingFeature } from '../../data-access/onboarding.reducer';

export const selectQuestionTopicPageVm = createSelector(
    selectOnboardingProgress,
    selectContentfulTopics,
    onboardingFeature.selectSelectedTopicIds,
    (progress, topics, selectedTopicIds) => {
        return {
            progress,
            onboardingPreviewTopics: topics
                .filter((topic) => ONBOARDING_PREVIEW_TOPIC_IDS.some((item) => item === topic.entityId))
                .map((topic) => ({
                    title: topic.title,
                    value: topic.entityId,
                    image: topic.imageUrl,
                    backgroundColor: topic.color,
                    selected: selectedTopicIds.includes(topic.entityId),
                })),
            hasSelectedTopics: selectedTopicIds.length > 0,
        };
    },
);
