import { AsyncPipe, NgStyle } from '@angular/common';
import { Component, inject, Input } from '@angular/core';
import { getSVGColour, isColorContrastLight, setCourseBackgroundColor } from '@shared/utils/typescript';
import { AnalyticsTrackClickDirective } from '@frontend/ui';
import { CourseProgressType } from '@shared/user-domain';
import { IonButton, IonCard } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { CircleProgressOptions } from 'ng-circle-progress/lib/ng-circle-progress.component';
import { CourseStatus } from '../../store/course/course.model';
import { showPaymentModal } from '../../store/payment/store/payment.actions';
import { triggerUpdateCourseProgress } from '../../store/progress/course-progress/course-progress.actions';
import { showCourseInformationModal } from '../course-information-modal/course-information-modal.actions';
import { CourseWithSteps } from '../models/courses.models';
import { selectCourseSliderTileComponentVm } from './course-slider-tile.selectors';

@Component({
    selector: 'app-course-slider-tile',
    templateUrl: './course-slider-tile.component.html',
    styleUrls: ['./course-slider-tile.component.scss'],
    standalone: true,
    imports: [IonCard, IonButton, NgStyle, AnalyticsTrackClickDirective, NgCircleProgressModule, AsyncPipe],
})
export class CourseSliderTileComponent {
    private readonly store = inject(Store);
    @Input() backgroundColor = 'harley';
    @Input({ required: true }) course!: CourseWithSteps;

    getSVGColour = getSVGColour;
    CourseStatus = CourseStatus;

    public vm$ = this.store.select(selectCourseSliderTileComponentVm);

    setCourseBackgroundColor = setCourseBackgroundColor;

    getCircleProgressOptions(course: CourseWithSteps): CircleProgressOptions {
        const title =
            course.progress < 100 && course.status !== CourseStatus.COMPLETED ? course.progress.toString() : '✓';
        const titleColor =
            course.status === CourseStatus.COMPLETED ? `var(--ion-color-${course.color})` : 'var(--ion-color-black)';
        const titleFontSize = course.progress < 100 ? '15' : '25';
        const showUnits = course.progress < 100;
        const percent = course.status !== CourseStatus.COMPLETED ? course.progress : 0;

        const outerStrokeColor =
            course.status === CourseStatus.COMPLETED ? `var(--ion-color-${course.color})` : 'var(--ion-color-max)';
        const innerStrokeColor = `var(--ion-color-${course.color})`;

        return {
            showTitle: true,
            title,
            titleColor,
            titleFontSize,

            showSubtitle: false,

            clockwise: true,
            startFromZero: false,
            radius: 30,

            showUnits,
            units: '%',
            percent,
            toFixed: 0,
            unitsFontSize: 'medium',

            showBackground: true,
            backgroundColor: 'white',
            backgroundPadding: 0,
            space: 0,

            showZeroOuterStroke: false,
            outerStrokeWidth: 5,
            outerStrokeColor,

            innerStrokeWidth: 3,
            innerStrokeColor,

            animateTitle: false,
            animation: false,
            renderOnClick: false,
        } as CircleProgressOptions;
    }

    openCourseInformationModal(course: CourseWithSteps) {
        this.store.dispatch(showCourseInformationModal({ course }));
    }

    openPaymentModal() {
        this.store.dispatch(showPaymentModal({ trigger: 'my-journey-page' }));
    }

    startCourse(courseId: string) {
        this.store.dispatch(
            triggerUpdateCourseProgress({
                courseProgress: {
                    id: courseId,
                    progressType: CourseProgressType.IN_PROGRESS,
                },
            }),
        );
    }

    setBackground() {
        return {
            ...setCourseBackgroundColor(this.course.color, this.course.status !== CourseStatus.INACTIVE),
            'background-image': isColorContrastLight(this.course.color)
                ? 'url(/assets/images/squiggles/doodle.png)'
                : 'url(/assets/images/squiggles/doodle-dark.png)',
        };
    }
}
