import { AsyncPipe, NgClass } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    computed,
    effect,
    inject,
    viewChild,
    ViewChild,
} from '@angular/core';
import { coursesFeature } from '@frontend/data-access/contentful';
import { accountFeature, selectAgeInWeeks, selectHasArrived } from '@frontend/data-access/user/account';
import { selectReversePaywallAb } from '@frontend/data-access/user/config-cat';
import {
    AnalyticsTrackClickDirective,
    collapseAnimation,
    fadeInAnimation,
    GenericCardSquareComponent,
    SliderComponent,
} from '@frontend/ui';
import { IonContent, IonHeader, IonToolbar, ViewWillEnter } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { ArrivalTileComponent } from '../arrival/arrival-tile/arrival-tile.component';
import { AiChatHeaderComponent } from '../chat/ai-chat/header/ai-chat-header.component';
import {
    SuggestedAiChatQuestionsComponent,
    SuggestedQuestionsPromptClicked,
} from '../chat/ai-chat/suggested-questions-prompt/suggested-ai-chat-questions.component';
import { routeToAiChat, routeToCoachChat } from '../chat/chat.actions';
import { launchFreeAccessModal } from '../reverse-paywall/free-access-modal/store/free-access-modal.actions';
import { showPaymentModal } from '../store/payment/store/payment.actions';
import { TodayAiChatInputComponent } from './today-ai-chat-input/today-ai-chat-input.component';
import { CourseTileComponent } from './today-courses/course-tile/course-tile.component';
import { TodayCoursesComponent } from './today-courses/today-courses.component';
import { TodayDailyBitesComponent } from './today-daily-bites/today-daily-bites.component';
import { TodayDevelopmentalWindowComponent } from './today-developmental-window/today-developmental-window.component';
import { TodayPinnedTopicsComponent } from './today-pinned-topics/today-pinned-topics.component';
import {
    aiChatBackButtonClicked,
    aiChatInputClicked,
    aiChatInputSubmitted,
    suggestedAiChatQuestionClicked,
} from './today.page.actions';
import { todayPageFeature } from './today.page.reducer';
import { selectTodayPageVm } from './today.page.selectors';
import { filterCoursesForTodayPage } from './today.page.utils';

@Component({
    selector: 'app-today',
    templateUrl: './today.page.html',
    styleUrls: ['./today.page.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        AsyncPipe,
        NgClass,
        IonContent,
        TodayDevelopmentalWindowComponent,
        TodayCoursesComponent,
        ArrivalTileComponent,
        TodayPinnedTopicsComponent,
        SliderComponent,
        GenericCardSquareComponent,
        AnalyticsTrackClickDirective,
        TodayDailyBitesComponent,
        TodayAiChatInputComponent,
        AiChatHeaderComponent,
        SuggestedAiChatQuestionsComponent,
        IonHeader,
        IonToolbar,
        CourseTileComponent,
    ],
    animations: [fadeInAnimation(), collapseAnimation()],
})
export class TodayPage implements ViewWillEnter {
    @ViewChild('suggestedQuestionsPrompt')
    public suggestedQuestions!: SuggestedAiChatQuestionsComponent;

    public aiChatInputRef = viewChild<TodayAiChatInputComponent>('aiChatInput');

    private readonly store = inject(Store);
    private readonly cdr = inject(ChangeDetectorRef);

    public vm = this.store.selectSignal(selectTodayPageVm);
    public showAiChatView = this.store.selectSignal(todayPageFeature.selectShowAiChatView);
    public isReversePaywallAb = this.store.selectSignal(selectReversePaywallAb);

    private courses = this.store.selectSignal(coursesFeature.selectCourses);
    private hasArrived = this.store.selectSignal(selectHasArrived);
    private ageInWeeks = this.store.selectSignal(selectAgeInWeeks);
    private isRescue = this.store.selectSignal(accountFeature.selectIsRescuePup);

    public filteredCourses = computed(() => {
        return filterCoursesForTodayPage(this.courses(), this.hasArrived(), this.ageInWeeks(), this.isRescue());
    });

    constructor() {
        // TODO: NBSon - look into testing effects
        // Clear the input when the view is hidden
        effect(() => {
            if (this.aiChatInputRef() && this.showAiChatView() === false) {
                this.aiChatInputRef()!.clear();
            }
        });
    }

    public openFreeAccessModal() {
        return this.store.dispatch(launchFreeAccessModal());
    }

    public ionViewWillEnter(): void {
        this.cdr.markForCheck();
    }

    public aiChatInputClicked(showAiChatView: boolean): void {
        if (!showAiChatView) {
            this.store.dispatch(aiChatInputClicked());
        }
    }

    public aiChatBackButtonClicked(): void {
        this.store.dispatch(aiChatBackButtonClicked());
    }

    public aiChatInputSubmitted(message: string): void {
        this.store.dispatch(
            aiChatInputSubmitted({
                message,
            }),
        );
    }

    public aiChatQuestionClicked({ optionId, option, card }: SuggestedQuestionsPromptClicked): void {
        if (optionId && option && card) {
            void this.store.dispatch(suggestedAiChatQuestionClicked({ optionId, option, card }));
            this.suggestedQuestions.clearSelection();
        }
    }

    public async cardClicked(unlocked: boolean, cardName: string, shouldAllowClick: boolean): Promise<void> {
        if (shouldAllowClick) {
            return;
        }

        if (unlocked) {
            switch (cardName) {
                case 'puppy-coach':
                    void this.store.dispatch(routeToCoachChat());
                    break;
                case 'ziggy-ai':
                    void this.store.dispatch(routeToAiChat({}));
                    break;
                default:
                    break;
            }
        } else {
            void this.store.dispatch(showPaymentModal({ trigger: cardName }));
        }
    }
}
