<ion-card [disabled]="disabled" class="{{ background }} square-card">
    @if (!unlocked) {
        <ion-icon class="square-card__locked-icon" name="lock-closed" data-test="square-card__locked-icon"></ion-icon>
    }
    <div class="square-card__container {{ !unlocked ? 'square-card__container--locked' : '' }}">
        <img class="square-card__img" [src]="imageUrl" />
        <h2 class="square-card__title" [ngStyle]="{ color: 'var(--ion-color-' + background + '-contrast)' }">
            {{ title }}
        </h2>
        <p class="square-card__description" [ngStyle]="{ color: 'var(--ion-color-' + background + '-contrast)' }">
            {{ description }}
        </p>
    </div>
</ion-card>
