import { NgStyle } from '@angular/common';
import { Component, inject, Input } from '@angular/core';
import { Color, isColorContrastLight } from '@shared/utils/typescript';
import { HeaderComponent, RichContentPipe } from '@frontend/ui';
import { ModalService } from '@frontend/utility/modal';
import {
    IonAccordion,
    IonAccordionGroup,
    IonContent,
    IonHeader,
    IonItem,
    IonLabel,
    IonToolbar,
} from '@ionic/angular/standalone';
import { TextReplacementPipe } from '@frontend/data-access/text-replacement';
import { DevelopmentalWindowTimelineModel } from '../developmental-window-timeline/developmental-window-timeline.model';
import { getDevelopmentalWindowModalColors } from '../developmental-window-timeline/developmental-window-timeline.utils';

@Component({
    selector: 'app-developmental-window-modal',
    templateUrl: './developmental-window-modal.component.html',
    styleUrls: ['./developmental-window-modal.component.scss'],
    standalone: true,
    imports: [
        NgStyle,
        RichContentPipe,
        TextReplacementPipe,
        IonContent,
        IonAccordionGroup,
        IonAccordion,
        IonItem,
        IonLabel,
        HeaderComponent,
        IonHeader,
        IonToolbar,
    ],
})
export class DevelopmentalWindowModalComponent {
    public readonly modalService = inject(ModalService);
    @Input({ required: true }) developmentalWindow!: DevelopmentalWindowTimelineModel;
    protected readonly Color = Color;
    protected readonly getDevelopmentalWindowModalColors = getDevelopmentalWindowModalColors;
    public readonly squiggleDarkSvg =
        'url(/assets/images/squiggles/course-information-squiggle-dark.svg) no-repeat top -50px right 0px';
    public readonly squiggleLightSvg =
        'url(/assets/images/squiggles/course-information-squiggle-light.svg) no-repeat top -50px right 0px';

    closeModal() {
        void this.modalService.dismiss();
    }

    public getDynamicallyAppliedStyles() {
        const color = getDevelopmentalWindowModalColors(this.developmentalWindow.color).modalColor;
        return {
            background: isColorContrastLight(color) ? this.squiggleLightSvg : this.squiggleDarkSvg,
        };
    }
}
