import { Article, Lesson, Topic } from '@frontend/data-access/contentful';

export enum StepStatus {
    INACTIVE = 'inactive',
    COMPLETED = 'completed',
    SKIPPED = 'skipped',
    READ = 'read',
}

export interface StepProgressInfo {
    state: StepStatus;
    isPractice: boolean;
    rating?: number;
    date?: Date;
}

export interface Step<TContent = Lesson | Article> {
    id: string;
    rank: number;
    topic: Topic;
    progress: StepProgressInfo;
    imageUrl: string;
    content: TContent;
}
