import { Component, inject } from '@angular/core';
import { CONTENT, grantFreeAccess } from '@frontend/data-access/subscription';
import { accountFeature } from '@frontend/data-access/user/account';
import { AnalyticsTrackClickDirective } from '@frontend/ui';
import { ModalService } from '@frontend/utility/modal';
import { IonicModule } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { Color } from '@shared/utils/typescript';

@Component({
    standalone: true,
    selector: 'app-free-access-modal',
    templateUrl: './free-access-modal.component.html',
    styleUrls: ['./free-access-modal.component.scss'],
    imports: [AnalyticsTrackClickDirective, IonicModule],
})
export class FreeAccessModalComponent {
    protected readonly Color = Color;
    public readonly modalService = inject(ModalService);
    private store = inject(Store);
    public userId = this.store.selectSignal(accountFeature.selectId);

    //todo test this
    public getFreeAccess() {
        this.store.dispatch(
            grantFreeAccess({ data: { userId: this.userId(), entitlementId: CONTENT, freeAccessLengthInDays: 3 } }),
        );

        this.closeModal();
    }

    closeModal(): void {
        void this.modalService.dismiss({
            dismissed: true,
        });
    }
}
