@if (vm$ | async; as vm) {
<ion-content class="page-safe-margin" [ngClass]="{ 'is-searching' : isSearching }" [fullscreen]="true">
    <main class="library-page page-padding-top">
        <header class="text-block">
            <h1 class="page-title" data-test="library-page-title">Library</h1>
        </header>

        <app-library-search-bar
            [onLibraryPage]="arriveOnPage$"
            (searching)="isSearching=$event"
        ></app-library-search-bar>

        @if (!isSearching) { @if (vm.aiChatEnabled) {
        <app-generic-card-slim
            class="library-page__ai-chat-card"
            appTrackClick
            [identifier]="'library-page-ziggy-ai-tile'"
            [attr.data-test]="'library-page-ziggy-ai-tile'"
            [title]="'Ask Ziggy'"
            [imageUrl]="'assets/images/ziggy/ziggy-close-up.svg'"
            [background]="Color.Sophie"
            [disabled]="vm.isStreamChatBusyCleaningUp"
            [unlocked]="vm.aiChatEnabled"
            (click)="openZiggyChat(!vm.isStreamChatBusyCleaningUp)"
        ></app-generic-card-slim>
        }

        <app-library-topic-overview></app-library-topic-overview>

        <app-generic-card-slim
            class="library-page__faq-card"
            appTrackClick
            identifier="library-faq-btn"
            title="Frequently Asked Questions"
            imageUrl="/assets/images/oliver/oliver-looks-confused.png"
            [background]="Color.Bailey"
            [unlocked]="true"
            (click)="cardClicked()"
        ></app-generic-card-slim>

        <app-library-daily-bite-tile></app-library-daily-bite-tile>
        }
    </main>
</ion-content>
}
