import { InternalStepDto } from '../../dtos/internal-api/internal-step.dto';
import { InternalArticleDtoMock } from './internal-article-dto.mock';
import { InternalTopicDtoMock } from './internal-topic-dto.mock';

export class InternalStepDtoMock {
    private defaultValue: InternalStepDto = {
        id: 'step-1',
        title: 'Step Title',
        rank: 1,
        topic: new InternalTopicDtoMock().value,
        content: new InternalArticleDtoMock().value,
    };

    constructor(overrides?: Partial<InternalStepDto>) {
        Object.assign(this.defaultValue, overrides);
    }

    public get value(): InternalStepDto {
        return this.defaultValue;
    }
}
