import { AsyncPipe } from '@angular/common';
import { Component, inject, Input } from '@angular/core';
import { AnalyticsTrackClickDirective } from '@frontend/ui';
import { CourseProgressType } from '@shared/user-domain';
import { IonButton } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { trackEvent } from '@frontend/data-access/analytics';
import { TextReplacementPipe } from '@frontend/data-access/text-replacement';
import { StepCardSlimModel } from '../../../../step/step-card-slim/models/step-card-slim.model';
import { StepCardSlimSkeletonComponent } from '../../../../step/step-card-slim/skeleton/step-card-slim-skeleton.component';
import { StepCardSlimComponent } from '../../../../step/step-card-slim/step-card-slim.component';
import { CourseStatus } from '../../../../store/course/course.model';
import { showPaymentModal } from '../../../../store/payment/store/payment.actions';
import { triggerUpdateCourseProgress } from '../../../../store/progress/course-progress/course-progress.actions';
import { routeToStep } from '../../../../store/router/router.actions';
import { CourseCard } from '../course-card.model';
import { selectCourseCardStepsVm } from './store/course-card-steps.selectors';

@Component({
    selector: 'app-course-card-today-steps-component',
    templateUrl: 'course-card-steps.component.html',
    styleUrls: ['course-card-steps.component.scss'],
    standalone: true,
    imports: [
        StepCardSlimSkeletonComponent,
        StepCardSlimComponent,
        AnalyticsTrackClickDirective,
        AsyncPipe,
        TextReplacementPipe,
        IonButton,
    ],
})
export class CourseCardStepsComponent {
    private readonly store = inject(Store);
    public vm$ = this.store.select(selectCourseCardStepsVm);
    @Input() public course: Omit<CourseCard, 'completedStepsToday'> | undefined;

    public CourseStatus = CourseStatus;

    goToStep(step: StepCardSlimModel): void {
        this.store.dispatch(
            routeToStep({
                baseUrl: ['main', 'today'],
                stepId: step.id,
                from: 'today',
                fromPracticeStep: step.isPractice,
            }),
        );
        this.store.dispatch(
            trackEvent({
                eventName: 'click',
                eventProperties: {
                    identifier: '[Step] To Step From Today Page',
                    stepId: step.contentId, // TODO - check with Jack to see if he is okay with this being changed to stepId
                    stepType: step.contentType,
                    topic: step.topicTitle,
                    unlocked: !step.locked,
                    title: step.title,
                    progressStatus: step.status,
                    progressRating: step.rating,
                },
            }),
        );
    }

    public openPaymentModal(): void {
        this.store.dispatch(showPaymentModal({ trigger: 'today-course-card' }));
    }

    clickStartCourse(courseId: string) {
        this.store.dispatch(
            triggerUpdateCourseProgress({
                courseProgress: {
                    id: courseId,
                    progressType: CourseProgressType.IN_PROGRESS,
                },
            }),
        );
    }
}
