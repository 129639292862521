import { Component, Input, inject } from '@angular/core';
import {
    AnalyticsTrackClickDirective,
    HeaderComponent,
    RadioWithExplanationComponent,
    RadioWithExplanationResult,
} from '@frontend/ui';
import { Color } from '@shared/utils/typescript';
import { ModalService } from '@frontend/utility/modal';
import { IonButton, IonFooter, IonHeader, IonToolbar } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { CourseSplit } from '../../models/courses.models';
import { startNextPartClicked } from './jump-ahead-modal.actions';

@Component({
    standalone: true,
    imports: [
        RadioWithExplanationComponent,
        AnalyticsTrackClickDirective,
        IonButton,
        IonFooter,
        HeaderComponent,
        IonHeader,
        IonToolbar,
    ],
    selector: 'app-jump-ahead-modal',
    templateUrl: 'jump-ahead-modal.component.html',
    styleUrls: ['jump-ahead-modal.component.scss'],
})
export class JumpAheadModalComponent {
    public readonly modalService = inject(ModalService);
    private readonly store = inject(Store);
    @Input() split: CourseSplit | undefined;
    radioResult: RadioWithExplanationResult | undefined;
    Color = Color;

    buttons = [
        {
            title: 'Completed lessons elsewhere',
            value: 'completed-elsewhere',
            explainMore: {
                placeholder: 'Please explain more',
            },
        },
        {
            title: 'Lessons are not suitable',
            value: 'not-suitable',
            explainMore: {
                placeholder: 'Please explain more',
            },
        },
        {
            title: 'Something else',
            value: 'something-else',
            explainMore: {
                placeholder: 'Please add your reason',
            },
        },
    ];

    setAnswer(event: RadioWithExplanationResult) {
        this.radioResult = event;
    }

    startNextPart() {
        if (this.radioResult && this.split) {
            this.store.dispatch(startNextPartClicked({ radioResult: this.radioResult, split: this.split }));
        }
        this.closeModal();
    }

    closeModal(): void {
        void this.modalService.dismiss({
            dismissed: true,
        });
    }
}
