<app-onboarding-question-template
    class="purina-opt-in"
    backButtonId="question-purina-opt-in-back-btn"
    title="Treat yourself and {{ vm().dogName }}!"
    subtitle='Click "YES" to hear from our trusted partner Purina and receive:'
    [progress]="vm().progress"
    [isLoading]="vm().isLoading"
    (backButtonPressed)="routeToPrevious()"
>
    <ng-container body>
        <ul class="purina-opt-in__list">
            <li class="purina-opt-in__list-item">
                <i class="fa-xl fa-regular fa-check"></i>
                <h3>Reward points</h3>
            </li>
            <li class="purina-opt-in__list-item">
                <i class="fa-xl fa-regular fa-check"></i>
                <h3>Personalized pup nutrition</h3>
            </li>
            <li class="purina-opt-in__list-item">
                <i class="fa-xl fa-regular fa-check"></i>
                <h3>Exclusive free gifts</h3>
            </li>
        </ul>
    </ng-container>
    <ng-container footer>
        <p class="xs">
            I would like to get marketing communications from Zigzag's trusted partner Purina, by having the information
            I provide to Zigzag shared with Purina. I agree Purina will use my data according to its
            <strong
                data-test="purina-opt-in-show-privacy"
                (click)="openUrlWindow('https://www.purina.com/privacy-policy')"
                >Privacy Policy &
            </strong>
            <strong
                data-test="purina-opt-in-show-terms"
                (click)="openUrlWindow('https://www.purina.com/terms-and-conditions')"
                >Terms.</strong
            >
        </p>
        <div class="purina-opt-in__button-container">
            <ion-button
                [color]="Color.Jack"
                fill="outline"
                expand="block"
                appTrackClick
                data-test="question-purina-opt-in-yes-btn"
                identifier="question-purina-opt-in-yes-btn"
                [disabled]="vm().isLoading"
                (click)="answeredPurinaOptIn(true)"
            >
                @if (vm().isLoading) {
                <ion-spinner name="crescent" />
                } @else { Yes }
            </ion-button>
            <button
                class="zz-button purina-opt-in__button"
                appTrackClick
                data-test="question-purina-opt-in-no-btn"
                identifier="question-purina-opt-in-no-btn"
                [disabled]="vm().isLoading"
                (click)="answeredPurinaOptIn(false)"
            >
                No thanks
            </button>
        </div>
    </ng-container>
</app-onboarding-question-template>
