@if (course) {
    <div class="end-course-modal">
        <div class="end-course-background" [ngStyle]="{ background: 'var(--ion-color-' + course.color + ')' }"></div>
        <!--div necessary for flexbox spread-->
        <div>
            <ion-header class="page-header ion-no-border">
                <ion-toolbar>
                    <app-header
                        buttonBackground="white"
                        identifierRightButton="end-course-modal-close-btn"
                        [hasRightButton]="true"
                        (rightButtonPressed)="closeModal()"
                    />
                </ion-toolbar>
            </ion-header>

            <div class="page-content-padding-t dop page-padding-sides">
                <div class="end-course-image-container">
                    <img [src]="'/assets/images/charly/charly-looks-at-poo-large.svg'" alt="pup with poo" />
                </div>
                <h3 class="end-course-title ion-text-center">
                    Why do you want to end “<span [class]="getTitleTextColorClass(course.color)">{{
                        course.title
                    }}</span
                    >” course
                </h3>
                <div class="end-course-radio">
                    <app-radio-with-explanation
                        [buttons]="buttons"
                        [color]="course.color"
                        (changeEvent)="setAnswer($event)"
                    ></app-radio-with-explanation>
                </div>
            </div>
        </div>

        <div class="end-course-modal__button-container page-padding-sides page-padding-bottom">
            @if (radioResult && radioResult.answer.value !== '') {
                <div class="text-warning ion-text-center end-course-warning">
                    Ending this course will make it inactive and remove it from your Today screen. Your progress will be
                    saved.
                </div>
            }

            <ion-button
                (click)="endCourse(course.id, course.title)"
                color="max"
                expand="block"
                appTrackClick
                identifier="end-course-confirm-btn"
                data-test="end-course-confirm-btn"
                class="disabled-max-no-opacity"
                [disabled]="!radioResult?.complete"
                >End course
            </ion-button>
            <button
                (click)="closeModal()"
                appTrackClick
                identifier="end-course-cancel-btn"
                data-test="end-course-cancel-btn"
                class="zz-button end-course-cancel-button ion-text-center"
            >
                Cancel
            </button>
        </div>
    </div>
}
