import { inject, Injectable } from '@angular/core';
import {
    loginWithAppleSuccess,
    loginWithGoogleSuccess,
    signUpWithEmailAndPasswordSuccess,
} from '@frontend/data-access/user/authentication';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, concatMap, filter, map, of } from 'rxjs';
import { InternalApiError } from '../../models/internal-api-error.model';
import { AccountService } from '../../services/account.service';
import { mapUserDtoToProfile } from '../../utils/account.map';
import { accountFeature } from '../account.reducer';
import { createAccount, createAccountFailure, createAccountSuccess } from './create-account.actions';
import { selectCountryCode } from '../account.selectors';

@Injectable()
export class CreateAccountEffects {
    private readonly actions$ = inject(Actions);
    private readonly store = inject(Store);
    private readonly accountService = inject(AccountService);

    createAccountWhenFirebaseAccountCreatedWithEmail$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(signUpWithEmailAndPasswordSuccess),
            concatLatestFrom(() => {
                return [this.store.select(accountFeature.selectId), this.store.select(selectCountryCode)];
            }),
            filter(([_, userId]) => !!userId),
            map(([{ user }, userId, countryCode]) => {
                return createAccount({
                    id: userId!,
                    accountId: user.id,
                    email: user.email,
                    countryCode: countryCode!,
                });
            }),
        );
    });

    createAccountWhenFirebaseAccountCreatedWithSSO$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(loginWithGoogleSuccess, loginWithAppleSuccess),
            filter(({ additionalUserInfo }) => {
                return !!additionalUserInfo && additionalUserInfo.isNewUser;
            }),
            concatLatestFrom(() => {
                return [this.store.select(accountFeature.selectId), this.store.select(selectCountryCode)];
            }),
            filter(([_, userId]) => !!userId),
            map(([{ user }, userId, countryCode]) => {
                return createAccount({
                    id: userId!,
                    accountId: user.id,
                    email: user.email,
                    countryCode: countryCode!,
                });
            }),
        );
    });

    createAccountWithExistingAccountId$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(createAccount),
            concatMap(({ id, accountId, email, countryCode }) =>
                this.accountService.createAccount({ id, accountId, mail: email, countryCode }).pipe(
                    map((userDto) => {
                        return createAccountSuccess({
                            profile: mapUserDtoToProfile(userDto),
                            profileId: userDto.id,
                        });
                    }),
                    catchError(({ error }: { error: InternalApiError }) => of(createAccountFailure({ error }))),
                ),
            ),
        );
    });
}
