import { CourseProgressType } from '@shared/user-domain';
import { IsNumber, IsString } from 'class-validator';

export class DomainPostCourseProgressCommand {
    @IsString()
    userId!: string;

    @IsString()
    courseId!: string;

    progressType!: CourseProgressType;

    @IsNumber()
    timestamp!: number;
}
