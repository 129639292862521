import { chatFeature } from '@frontend/data-access/chat';
import { selectIsAIChatEnabled } from '@frontend/data-access/user/config-cat';
import { createSelector } from '@ngrx/store';
import { selectContentUnlocked } from '../store/payment/store/payment.selectors';

export const selectLibraryPageVm = createSelector(
    selectContentUnlocked,
    selectIsAIChatEnabled,
    chatFeature.selectIsDestroying,
    (unlocked, aiChatEnabled, isStreamChatBusyCleaningUp) => ({
        unlocked,
        aiChatEnabled,
        isStreamChatBusyCleaningUp,
    }),
);
