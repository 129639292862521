@if (content) {
    <ion-card class="image-card">
        <div class="card-image" [style.background-image]="'url(' + content.imageUrl + ')'"></div>
        <ion-card-header class="card-header">
            <h3 class="ignore-screen-based-font-scaling card-title" data-test="image-card-title">
                {{ content.title }}
            </h3>
        </ion-card-header>
        <ion-card-content class="card-content">
            <img src="assets/images/trainers/{{ content.author.split(' ').join('_') }}.jpg" class="author-image" />
            <p class="author-text xs" data-test="image-card-author">{{ content.author }}, Zigzag training coach</p>
        </ion-card-content>
    </ion-card>
}
