<app-onboarding-question-template
    backButtonId="question-gender-back-btn"
    title="Is {{ vm().dogName }} a boy or a girl?"
    subtitle="We all know it feels weird calling puppies an 'it'."
    [progress]="vm().progress"
    [isLoading]="vm().isLoading"
    (backButtonPressed)="routeToPrevious()"
>
    <ng-container body>
        <div class="image-container">
            <ion-img
                class="zigzag-boy"
                [src]="'assets/images/charly/charly-sits-with-bow-tie-on.svg'"
                alt="male puppy"
            />
            <ion-img
                class="zigzag-girl"
                [src]="'assets/images/buddy/buddy-sits-with-bow-tie-on-head.svg'"
                alt="female puppy"
            />
        </div>
    </ng-container>
    <ng-container footer>
        <div class="onboarding-radio-button-container">
            <ion-button
                class="light-button-activated-jack"
                appTrackClick
                identifier="question-gender-male-btn"
                fill="outline"
                expand="block"
                data-test="question-gender-male-btn"
                [disabled]="vm().isLoading"
                (click)="genderAnswered(Gender.MALE)"
            >
                @if (vm().isLoading) {
                <ion-spinner name="crescent" />
                } @else { Boy }
            </ion-button>
            <ion-button
                class="light-button-activated-jack"
                appTrackClick
                identifier="question-gender-female-btn"
                fill="outline"
                expand="block"
                data-test="question-gender-female-btn"
                [disabled]="vm().isLoading"
                (click)="genderAnswered(Gender.FEMALE)"
            >
                @if (vm().isLoading) {
                <ion-spinner name="crescent" />
                } @else { Girl }
            </ion-button>
        </div>
    </ng-container>
</app-onboarding-question-template>
