<div class="invalid-input-error">
    <p class="invalid-input-error__text xs">
        <span data-test="error-text-area">{{ errorMessage }}</span>
        @if (linkText) {
            <span (click)="linkClicked.emit()" class="invalid-input-error__link" data-test="error-link-text">{{
                linkText
            }}</span>
        }
    </p>
    <span class="invalid-input-error__error-icon"
        ><ion-icon class="invalid-input-error__sad-smiley" name="sad-outline"></ion-icon
    ></span>
</div>
