import { inject, Injectable } from '@angular/core';
import { trackEvent } from '@frontend/data-access/analytics';
import { setPushNotifications } from '@frontend/data-access/push-notification';
import { routeTo } from '@frontend/data-access/router';
import {
    selectAgeInWeeks,
    selectHasArrived,
    selectIsUsUser,
    updateAccount,
    updateAccountSuccess,
} from '@frontend/data-access/user/account';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { toUTC } from '@shared/utils/typescript';
import { concatMap, filter } from 'rxjs/operators';
import { OnboardingRoutes } from '../onboarding-routing.model';
import {
    onboardingComplete,
    onboardingNavigateBack,
    onboardingQuestionSubmittedArrivalDate,
    onboardingQuestionSubmittedBreed,
    onboardingQuestionSubmittedDateOfBirth,
    onboardingQuestionSubmittedFirstDayAtHome,
    onboardingQuestionSubmittedGender,
    onboardingQuestionSubmittedHasArrived,
    onboardingQuestionSubmittedIsRescue,
    onboardingQuestionSubmittedName,
    onboardingQuestionSubmittedNeeds,
    onboardingQuestionSubmittedNotifications,
    onboardingQuestionSubmittedOwnerName,
    onboardingQuestionSubmittedPurinaOptIn,
    onboardingQuestionSubmittedTopic,
    onboardingQuestionSubmittedTopicExtra,
} from './onboarding.actions';
import { onboardingFeature } from './onboarding.reducer';
import { selectOnboardingPreviousPage } from './onboarding.selectors';

@Injectable()
export class OnboardingEffects {
    private readonly store = inject(Store);
    private readonly actions$ = inject(Actions);

    handleNavigateBack$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(onboardingNavigateBack),
            concatLatestFrom(() => [this.store.select(selectOnboardingPreviousPage)]),
            filter(([_, onboardingRoute]) => !!onboardingRoute),
            concatMap(([_, onboardingRoute]) => {
                return [routeTo({ commands: ['onboarding', onboardingRoute], isAnimated: true })];
            }),
        );
    });

    handleQuestionNameSubmitted$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(onboardingQuestionSubmittedName),
            concatMap(({ name }) => {
                return [updateAccount({ command: { name }, correlationId: onboardingQuestionSubmittedName.type })];
            }),
        );
    });

    handleQuestionNameSubmittedSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(updateAccountSuccess),
            filter(({ correlationId }) => correlationId === onboardingQuestionSubmittedName.type),
            concatMap(() => {
                return [routeTo({ commands: ['onboarding', OnboardingRoutes.RESCUE] })];
            }),
        );
    });

    handleQuestionIsRescueSubmitted$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(onboardingQuestionSubmittedIsRescue),
            concatMap(({ isRescuePup }) => {
                return [
                    updateAccount({
                        command: { isRescuePup },
                        correlationId: onboardingQuestionSubmittedIsRescue.type,
                    }),
                ];
            }),
        );
    });

    handleQuestionIsRescueSubmittedSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(updateAccountSuccess),
            filter(({ correlationId }) => correlationId === onboardingQuestionSubmittedIsRescue.type),
            concatMap(() => {
                return [routeTo({ commands: ['onboarding', OnboardingRoutes.AGE] })];
            }),
        );
    });

    handleQuestionDateOfBirthSubmitted$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(onboardingQuestionSubmittedDateOfBirth),
            concatMap(({ dateOfBirth, isApproximateDateOfBirth }) => {
                return [
                    updateAccount({
                        command: { dateOfBirth, isApproximateDateOfBirth },
                        correlationId: onboardingQuestionSubmittedDateOfBirth.type,
                    }),
                ];
            }),
        );
    });

    handleQuestionDateOfBirthSubmittedSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(updateAccountSuccess),
            filter(({ correlationId }) => correlationId === onboardingQuestionSubmittedDateOfBirth.type),
            concatMap(() => {
                return [routeTo({ commands: ['onboarding', OnboardingRoutes.HAS_ARRIVED] })];
            }),
        );
    });

    handleQuestionHasArrivedSubmitted$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(onboardingQuestionSubmittedHasArrived),
            concatMap(({ hasArrived }) => {
                return [
                    updateAccount({
                        command: { hasArrived },
                        correlationId: onboardingQuestionSubmittedHasArrived.type,
                    }),
                ];
            }),
        );
    });

    handleQuestionHasArrivedSubmittedSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(updateAccountSuccess),
            filter(({ correlationId }) => correlationId === onboardingQuestionSubmittedHasArrived.type),
            concatLatestFrom(() => [this.store.select(selectHasArrived), this.store.select(selectAgeInWeeks)]),
            concatMap(([_, hasArrived, ageInWeeks]) => {
                let nextRoute: OnboardingRoutes;

                if (!hasArrived) {
                    nextRoute = OnboardingRoutes.ARRIVAL;
                } else if (ageInWeeks !== undefined && ageInWeeks <= 9) {
                    nextRoute = OnboardingRoutes.FIRST_DAY_AT_HOME;
                } else {
                    nextRoute = OnboardingRoutes.GENDER;
                }

                return [routeTo({ commands: ['onboarding', nextRoute] })];
            }),
        );
    });

    handleQuestionDateOfArrivalSubmitted$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(onboardingQuestionSubmittedArrivalDate),
            concatMap(({ dateOfArrival }) => {
                return [
                    updateAccount({
                        command: { dateOfArrival },
                        correlationId: onboardingQuestionSubmittedArrivalDate.type,
                    }),
                ];
            }),
        );
    });

    handleQuestionDateOfArrivalSubmittedSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(updateAccountSuccess),
            filter(({ correlationId }) => correlationId === onboardingQuestionSubmittedArrivalDate.type),
            concatMap(() => {
                return [routeTo({ commands: ['onboarding', OnboardingRoutes.GENDER] })];
            }),
        );
    });

    handleQuestionFirstDayAtHomeSubmitted$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(onboardingQuestionSubmittedFirstDayAtHome),
            concatMap(({ dateOfArrival }) => {
                return [
                    updateAccount({
                        command: { dateOfArrival },
                        correlationId: onboardingQuestionSubmittedFirstDayAtHome.type,
                    }),
                ];
            }),
        );
    });

    handleQuestionFirstDayAtHomeSubmittedSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(updateAccountSuccess),
            filter(({ correlationId }) => correlationId === onboardingQuestionSubmittedFirstDayAtHome.type),
            concatMap(() => {
                return [routeTo({ commands: ['onboarding', OnboardingRoutes.GENDER] })];
            }),
        );
    });

    handleQuestionGenderSubmitted$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(onboardingQuestionSubmittedGender),
            concatMap(({ gender }) => {
                return [
                    updateAccount({
                        command: { gender },
                        correlationId: onboardingQuestionSubmittedGender.type,
                    }),
                ];
            }),
        );
    });

    handleQuestionGenderSubmittedSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(updateAccountSuccess),
            filter(({ correlationId }) => correlationId === onboardingQuestionSubmittedGender.type),
            concatMap(() => {
                return [routeTo({ commands: ['onboarding', OnboardingRoutes.BREED] })];
            }),
        );
    });

    handleQuestionBreedIdSubmitted$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(onboardingQuestionSubmittedBreed),
            concatMap(({ breedId }) => {
                return [
                    updateAccount({
                        command: { breedId },
                        correlationId: onboardingQuestionSubmittedBreed.type,
                    }),
                ];
            }),
        );
    });

    handleQuestionBreedIdSubmittedSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(updateAccountSuccess),
            filter(({ correlationId }) => correlationId === onboardingQuestionSubmittedBreed.type),
            concatMap(() => {
                return [routeTo({ commands: ['onboarding', OnboardingRoutes.OWNER_NAME] })];
            }),
        );
    });

    handleQuestionOwnerNameSubmitted$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(onboardingQuestionSubmittedOwnerName),
            concatMap(({ ownerName }) => {
                return [
                    updateAccount({
                        command: { ownerName },
                        correlationId: onboardingQuestionSubmittedOwnerName.type,
                    }),
                ];
            }),
        );
    });

    handleQuestionOwnerNameSubmittedSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(updateAccountSuccess),
            filter(({ correlationId }) => correlationId === onboardingQuestionSubmittedOwnerName.type),
            concatLatestFrom(() => this.store.select(selectHasArrived)),
            concatMap(([_, hasArrived]) => {
                return [
                    routeTo({
                        commands: ['onboarding', hasArrived ? OnboardingRoutes.TOPIC : OnboardingRoutes.NEEDS],
                    }),
                ];
            }),
        );
    });

    handleQuestionTopicsSubmitted$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(onboardingQuestionSubmittedTopic),
            concatLatestFrom(() => this.store.select(onboardingFeature.selectSelectedTopicIds)),
            concatMap(([_, selectedTopicEntityIds]) => {
                return [
                    routeTo({ commands: ['onboarding', OnboardingRoutes.NOTIFICATIONS] }),
                    trackEvent({
                        eventName: 'onboarding-topic',
                        eventProperties: { topics: selectedTopicEntityIds },
                    }),
                ];
            }),
        );
    });

    handleQuestionTopicsExtraSubmitted$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(onboardingQuestionSubmittedTopicExtra),
            concatLatestFrom(() => this.store.select(onboardingFeature.selectSelectedTopicIds)),
            concatMap(([_, selectedTopicEntityIds]) => {
                return [
                    routeTo({ commands: ['onboarding', OnboardingRoutes.NOTIFICATIONS] }),
                    trackEvent({
                        eventName: 'onboarding-topic-focus',
                        eventProperties: { topics: selectedTopicEntityIds },
                    }),
                ];
            }),
        );
    });

    handleQuestionNeedsSubmitted$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(onboardingQuestionSubmittedNeeds),
            concatMap(({ needs }) => {
                return [
                    routeTo({ commands: ['onboarding', OnboardingRoutes.NOTIFICATIONS] }),
                    trackEvent({
                        eventName: 'onboarding-special-needs',
                        eventProperties: needs,
                    }),
                ];
            }),
        );
    });

    // TODO: NBSon - check if we should be doing ifs inside or just more effects with filters/concats
    handleQuestionNotificationsSubmitted$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(onboardingQuestionSubmittedNotifications),
            concatLatestFrom(() => this.store.select(selectIsUsUser)),
            concatMap(([{ notifications }, isUsUser]) => {
                const actions: Action[] = [];

                if (notifications) {
                    actions.push(setPushNotifications());
                }

                if (isUsUser) {
                    actions.push(routeTo({ commands: ['onboarding', OnboardingRoutes.PURINA_OPT_IN] }));
                } else {
                    actions.push(onboardingComplete());
                }

                return actions;
            }),
        );
    });

    handleQuestionPurinaOptInSubmitted$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(onboardingQuestionSubmittedPurinaOptIn),
            concatMap(({ purinaMarketingAccepted }) => {
                return [
                    updateAccount({
                        command: {
                            purinaMarketingAccepted,
                        },
                        correlationId: onboardingQuestionSubmittedPurinaOptIn.type,
                    }),
                    trackEvent({
                        eventName: '[Onboarding] Purina Opt-in Answered',
                        eventProperties: { optedIn: purinaMarketingAccepted },
                    }),
                ];
            }),
        );
    });

    handleQuestionPurinaOptInSubmittedSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(updateAccountSuccess),
            filter(({ correlationId }) => correlationId === onboardingQuestionSubmittedPurinaOptIn.type),
            concatMap(() => {
                return [onboardingComplete()];
            }),
        );
    });

    handleOnboardingComplete$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(onboardingComplete),
            concatMap(() => {
                return [
                    updateAccount({
                        command: {
                            dateOfOnboarding: toUTC(new Date()).toISOString(),
                        },
                        correlationId: onboardingComplete.type,
                    }),
                ];
            }),
        );
    });

    handleOnboardingCompleteSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(updateAccountSuccess),
            filter(({ correlationId }) => correlationId === onboardingComplete.type),
            concatMap(() => {
                return [routeTo({ commands: ['onboarding', OnboardingRoutes.FINALIZE] })];
            }),
        );
    });
}
