import { AsyncPipe } from '@angular/common';
import { Component, effect } from '@angular/core';
import { accountFeature } from '@frontend/data-access/user/account';
import { Color } from '@shared/utils/typescript';
import { AnalyticsTrackClickDirective, BreedSelectorComponent } from '@frontend/ui';
import { IonButton, IonSpinner } from '@ionic/angular/standalone';
import { OnboardingBaseComponent } from '../../onboarding-base/onboarding-base.component';
import { selectQuestionBreedPageVm } from './question-breed.page.selectors';
import { OnboardingQuestionTemplateComponent } from '../../onboarding-question-template/onboarding-question-template.component';
import { onboardingQuestionSubmittedBreed } from '../../data-access/onboarding.actions';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';

@Component({
    selector: 'app-onboarding-question-breed',
    templateUrl: 'question-breed.page.html',
    styleUrls: ['../../onboarding.scss'],
    standalone: true,
    imports: [
        AnalyticsTrackClickDirective,
        AsyncPipe,
        BreedSelectorComponent,
        IonButton,
        OnboardingQuestionTemplateComponent,
        ReactiveFormsModule,
        IonSpinner,
    ],
})
export class OnboardingQuestionBreedPage extends OnboardingBaseComponent {
    public vm = this.store.selectSignal(selectQuestionBreedPageVm);

    private breedId = this.store.selectSignal(accountFeature.selectBreedId);

    public form = new FormGroup({
        breedId: new FormControl('', { validators: Validators.required, nonNullable: true }),
    });

    protected readonly Color = Color;

    constructor() {
        super();

        effect(() => {
            if (this.breedId()) {
                this.form.setValue({
                    breedId: this.breedId()!,
                });
            }
        });
    }

    public onSubmit(): void {
        if (this.form.invalid || this.vm().isLoading) {
            return;
        }

        this.store.dispatch(onboardingQuestionSubmittedBreed({ breedId: this.form.controls.breedId.value }));
    }
}
