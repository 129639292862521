import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { exhaustMap, filter, of, tap } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AuthenticationService } from '../services/authentication.service';
import {
    foundValidUserSession,
    initializeAuthenticationService,
    initializeAuthenticationServiceFailure,
    loginWithApple,
    loginWithAppleFailure,
    loginWithAppleSuccess,
    loginWithEmailAndPassword,
    loginWithEmailAndPasswordFailure,
    loginWithEmailAndPasswordSuccess,
    loginWithGoogle,
    loginWithGoogleFailure,
    loginWithGoogleSuccess,
    logout,
    logoutFailure,
    logoutSuccess,
    noValidUserSession,
    requestPasswordReset,
    requestPasswordResetFailure,
    requestPasswordResetSuccess,
    signUpWithEmailAndPassword,
    signUpWithEmailAndPasswordFailure,
    signUpWithEmailAndPasswordSuccess,
    watchForLoginStateChange,
} from './authentication.actions';
import { trackEvent } from '@frontend/data-access/analytics';

@Injectable()
export class AuthenticationEffects {
    private readonly actions$ = inject(Actions);
    private readonly authenticationService = inject(AuthenticationService);

    initialize$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(initializeAuthenticationService),
                tap(() => {
                    this.authenticationService.initialize();
                }),
                filter(() => false),
                catchError((error) => of(initializeAuthenticationServiceFailure({ error }))),
            );
        },
        { dispatch: false },
    );

    logout$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(logout),
            exhaustMap(() =>
                this.authenticationService.logout().pipe(
                    map(() => logoutSuccess()),
                    catchError((error) => of(logoutFailure({ error }))),
                ),
            ),
        );
    });

    signUpWithEmailAndPassword$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(signUpWithEmailAndPassword),
            exhaustMap(({ email, password }) =>
                this.authenticationService.signUpWithEmailAndPassword(email, password).pipe(
                    map(({ user }) => signUpWithEmailAndPasswordSuccess({ user })),
                    catchError((error) => of(signUpWithEmailAndPasswordFailure({ error }))),
                ),
            ),
        );
    });

    loginWithEmailAndPassword$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(loginWithEmailAndPassword),
            exhaustMap(({ email, password }) =>
                this.authenticationService.loginWithEmailAndPassword(email, password).pipe(
                    map(({ user }) => loginWithEmailAndPasswordSuccess({ user })),
                    catchError((error) => of(loginWithEmailAndPasswordFailure({ error }))),
                ),
            ),
        );
    });

    loginWithGoogle$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(loginWithGoogle),
            exhaustMap(() => {
                return this.authenticationService.loginWithGoogle().pipe(
                    switchMap(({ user, additionalUserInfo }) => {
                        return [
                            trackEvent({ eventName: '[SSO] Sign In With Google Success' }),
                            loginWithGoogleSuccess({ user, additionalUserInfo }),
                        ];
                    }),
                    catchError((error) => {
                        return of(loginWithGoogleFailure({ error }));
                    }),
                );
            }),
        );
    });

    loginWithApple$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(loginWithApple),
            exhaustMap(() => {
                return this.authenticationService.loginWithApple().pipe(
                    switchMap(({ user, additionalUserInfo }) => {
                        return [
                            trackEvent({ eventName: '[SSO] Sign In With Apple Success' }),
                            loginWithAppleSuccess({ user, additionalUserInfo }),
                        ];
                    }),
                    catchError((error) => {
                        return of(loginWithAppleFailure({ error }));
                    }),
                );
            }),
        );
    });

    requestPasswordReset$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(requestPasswordReset),
            exhaustMap(({ email }) =>
                this.authenticationService.requestPasswordReset(email).pipe(
                    map(() => requestPasswordResetSuccess({ email })),
                    catchError((error) => of(requestPasswordResetFailure({ error }))),
                ),
            ),
        );
    });

    watchForLoginStateChange$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(watchForLoginStateChange),
            exhaustMap(() =>
                this.authenticationService
                    .isLoggedIn()
                    .pipe(map((loggedIn) => (loggedIn ? foundValidUserSession() : noValidUserSession()))),
            ),
        );
    });
}
