import { AsyncPipe, NgClass, NgStyle } from '@angular/common';
import { Component } from '@angular/core';
import { CheckboxChangeEventDetail } from '@ionic/angular';
import { Color } from '@shared/utils/typescript';
import { AnalyticsTrackClickDirective, HeaderComponent } from '@frontend/ui';
import {
    IonButton,
    IonCheckbox,
    IonContent,
    IonFooter,
    IonHeader,
    IonItem,
    IonList,
    IonToolbar,
} from '@ionic/angular/standalone';
import { OnboardingBaseComponent } from '../../onboarding-base/onboarding-base.component';
import { selectQuestionExtraTopicPageVm } from './question-topic-extra.page.selectors';
import {
    onboardingAddSelectedTopic,
    onboardingQuestionSubmittedTopicExtra,
    onboardingRemoveSelectedTopic,
} from '../../data-access/onboarding.actions';

@Component({
    selector: 'app-question-topic-extra',
    templateUrl: './question-topic-extra.page.html',
    styleUrls: ['../../onboarding.scss', 'question-topic-extra.page.scss'],
    standalone: true,
    imports: [
        AnalyticsTrackClickDirective,
        AsyncPipe,
        HeaderComponent,
        IonButton,
        IonCheckbox,
        IonContent,
        IonFooter,
        IonHeader,
        IonItem,
        IonList,
        IonToolbar,
        NgClass,
        NgStyle,
    ],
})
export class OnboardingQuestionTopicExtraPage extends OnboardingBaseComponent {
    public vm = this.store.selectSignal(selectQuestionExtraTopicPageVm);

    protected readonly Color = Color;

    public continue(): void {
        this.store.dispatch(onboardingQuestionSubmittedTopicExtra());
    }

    public onCheckboxChange(event: CustomEvent<CheckboxChangeEventDetail>): void {
        if (event.detail.checked) {
            this.store.dispatch(
                onboardingAddSelectedTopic({
                    topicEntityId: event.detail.value,
                }),
            );
        } else {
            this.store.dispatch(
                onboardingRemoveSelectedTopic({
                    topicEntityId: event.detail.value,
                }),
            );
        }
    }
}
