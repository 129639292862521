import { AsyncPipe } from '@angular/common';
import { Component, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Color, emptyString, isStringNumber } from '@shared/utils/typescript';
import {
    AnalyticsTrackClickDirective,
    AudioPlayerComponent,
    FilterPipe,
    GenericCardSlimComponent,
    HeaderComponent,
} from '@frontend/ui';
import { ModalService } from '@frontend/utility/modal';
import {
    IonButton,
    IonContent,
    IonFooter,
    IonHeader,
    IonIcon,
    IonToolbar,
    NavController,
} from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { addIcons } from 'ionicons';
import { checkmarkSharp } from 'ionicons/icons';
import { tap } from 'rxjs/operators';
import { TextReplacementPipe } from '@frontend/data-access/text-replacement';
import { DynamicContentBlockComponent } from '../../../step/dynamic-content-block/dynamic-content-block.component';
import { Step, StepStatus } from '../../../store/step/step.model';
import { launchTrainingReminderModal } from '../../../training-reminder/store/training-reminder-modal.actions';
import { LessonCloseNotificationModalComponent } from '../../lesson-close-notification-modal/lesson-close-notification-modal.component';
import { selectSelectedLessonPagesVm } from '../lesson.page.selectors';
import { ChatTileComponent } from '../../../chat/chat-tile/chat-tile.component';

@Component({
    templateUrl: './lesson-instruction-page.component.html',
    styleUrls: ['./lesson-instruction-page.component.scss', '../lesson.scss'],
    standalone: true,
    imports: [
        AnalyticsTrackClickDirective,
        AsyncPipe,
        AudioPlayerComponent,
        ChatTileComponent,
        DynamicContentBlockComponent,
        FilterPipe,
        GenericCardSlimComponent,
        HeaderComponent,
        IonButton,
        IonContent,
        IonFooter,
        IonHeader,
        IonIcon,
        IonToolbar,
        TextReplacementPipe,
    ],
})
export class LessonInstructionPage {
    private readonly store = inject(Store);
    private readonly activatedRoute = inject(ActivatedRoute);
    private readonly modalService = inject(ModalService);
    private readonly navController = inject(NavController);
    private readonly sanitizer = inject(DomSanitizer);

    public vm$ = this.store.select(selectSelectedLessonPagesVm);

    public videoUrl: SafeResourceUrl | undefined;

    protected readonly Color = Color;
    protected readonly emptyString = emptyString;

    constructor() {
        this.vm$
            .pipe(
                takeUntilDestroyed(),
                tap((vm) => {
                    if (vm.step && vm.step.content) {
                        this.setVideoUrl(vm.step.content.videoId);
                    }
                }),
            )
            .subscribe();

        addIcons({ checkmarkSharp });
    }

    public nextPage(): void {
        void this.navController.navigateForward(['../rating'], {
            animated: true,
            relativeTo: this.activatedRoute,
            queryParams: this.activatedRoute.snapshot.queryParams,
        });
    }

    public previousPage(): void {
        this.navController.back();
    }

    public navigateOrShowNotificationModal(step: Step): void {
        if (
            step.progress.state === StepStatus.COMPLETED ||
            step.progress.state === StepStatus.SKIPPED ||
            step.progress.state === StepStatus.READ
        ) {
            void this.navController.navigateBack(['../../../../'], {
                relativeTo: this.activatedRoute,
                animated: false,
            });
        } else {
            void this.modalService.showModal({
                component: LessonCloseNotificationModalComponent,
                componentProps: { step, relativeRoute: this.activatedRoute },
                cssClass: ['modal', 'modal-info-notification'],
            });
        }
    }

    public setTrainingReminder(dogName: string): void {
        this.store.dispatch(launchTrainingReminderModal({ dogName, trigger: 'lesson-instruction-page' }));
    }

    private setVideoUrl(id: string): void {
        this.videoUrl = isStringNumber(id)
            ? this.sanitizer.bypassSecurityTrustResourceUrl(
                  `https://player.vimeo.com/video/${id}?title=0&byline=0&portrait=0&texttrack=en`,
              )
            : undefined;
    }
}
