import { Component, EventEmitter, input, Input, Output } from '@angular/core';
import { HeaderComponent } from '@frontend/ui';
import { IonContent, IonFooter, IonHeader, IonToolbar } from '@ionic/angular/standalone';

// TODO: NBSon - not sure why there are 2 components, this one and then OnboardingBaseComponent, we should probably merge them if we keep inheritance
@Component({
    selector: 'app-onboarding-question-template',
    templateUrl: './onboarding-question-template.component.html',
    styleUrls: ['../onboarding.scss', 'onboarding-question-template.component.scss'],
    standalone: true,
    imports: [IonContent, IonFooter, HeaderComponent, IonHeader, IonToolbar],
})
export class OnboardingQuestionTemplateComponent {
    @Input()
    public title: string | undefined;
    @Input()
    public subtitle: string | undefined;
    @Input()
    public progress = 0;
    @Input()
    public backButtonId = 'onboarding-back-btn';

    public isLoading = input(false);

    @Output() public backButtonPressed = new EventEmitter();

    public backButtonAction(): void {
        this.backButtonPressed.emit();
    }
}
