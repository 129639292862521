import { Component, inject, Input } from '@angular/core';
import { AnalyticsTrackClickDirective, CardItemComponent } from '@frontend/ui';
import { ModalService } from '@frontend/utility/modal';
import { IonCard } from '@ionic/angular/standalone';
import { TrainerInfoModalOptions } from '../trainer-info-modal/trainer-info-modal-options.model';
import { TrainerInfoModalComponent } from '../trainer-info-modal/trainer-info-modal.component';
import { expertCards, usExpertCards } from './trainer-info.constant';

@Component({
    selector: 'app-trainer-info',
    templateUrl: './trainer-info.component.html',
    styleUrls: ['./trainer-info.component.scss'],
    standalone: true,
    imports: [IonCard, AnalyticsTrackClickDirective, CardItemComponent],
})
export class TrainerInfoComponent {
    private readonly modalService = inject(ModalService);

    @Input()
    public isUsUser = false;

    public expertCards = this.isUsUser ? usExpertCards : expertCards;

    public async showExpert(supportExpert: TrainerInfoModalOptions): Promise<void> {
        await this.modalService.showModal({
            component: TrainerInfoModalComponent,
            componentProps: { supportExpert },
        });
    }
}
