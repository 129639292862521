import { inject, Injectable } from '@angular/core';
import { capacitorAppResume } from '@frontend/data-access/capacitor';
import { subscriptionFeature } from '@frontend/data-access/subscription';
import { accountFeature, getAccountSuccess, updateAccount } from '@frontend/data-access/user/account';
import { authenticationFeature } from '@frontend/data-access/user/authentication';
import { getConfigCatFeatureFlagsSuccess, selectReversePaywallAb } from '@frontend/data-access/user/config-cat';
import { ModalService } from '@frontend/utility/modal';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { differenceInHours } from 'date-fns';
import { combineLatest, skipWhile } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { ArrivalModalComponent } from '../../arrival/arrival-modal/arrival-modal.component';
import { EndCourseModalComponent } from '../../courses/end-course-modal/end-course-modal.component';
import { launchPupHappinessModal } from '../../pup-happiness-modal/store/pup-happiness-modal.actions';
import { launchFreeAccessModal } from '../../reverse-paywall/free-access-modal/store/free-access-modal.actions';
import { changeAppReadyStatus } from '../app-component/app-component.actions';
import { AppReadyStatus } from '../app-component/app-ready-status.model';
import { UPDATE_ACCOUNT_FROM_ZIGZAG_MAIN } from '../profile/account/account.constants';
import { showArrivalModal, showEndCourseModal } from './modal.actions';
import { selectShouldLaunchPupHappinessModal } from './modal.selectors';

@Injectable()
export class ModalEffects {
    private actions$ = inject(Actions);
    private store = inject(Store);
    private modalService = inject(ModalService);

    showArrivalModal$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(showArrivalModal),
            switchMap(() => {
                return this.modalService.showModalWithCallback<{ arrived: boolean }>({
                    component: ArrivalModalComponent,
                    cssClass: ['modal', 'modal-small'],
                });
            }),
            filter((data) => !!data?.arrived),
            map(() =>
                updateAccount({
                    command: {
                        hasArrived: true,
                        dateOfArrival: new Date().toISOString(),
                    },
                    correlationId: UPDATE_ACCOUNT_FROM_ZIGZAG_MAIN,
                }),
            ),
        );
    });

    showEndCourseModal$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(showEndCourseModal),
                tap(({ course }) => {
                    return this.modalService.showModal({
                        component: EndCourseModalComponent,
                        componentProps: { course },
                        cssClass: 'modal',
                    });
                }),
                filter(() => false),
            );
        },
        { dispatch: false },
    );

    showPupHappinessModalOnResume$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(capacitorAppResume),
            concatLatestFrom(() => [
                this.store.select(authenticationFeature.selectLoggedIn),
                this.store.select(selectShouldLaunchPupHappinessModal),
                this.store.select(accountFeature.selectDogName),
            ]),
            filter(([_, isLoggedIn, shouldLaunchPupHappinessModal]) => isLoggedIn && shouldLaunchPupHappinessModal),
            map(([, , , dogName]) => {
                return launchPupHappinessModal({ dogName });
            }),
        );
    });

    showPupHappinessModalOnceUserLoaded$ = createEffect(() => {
        return combineLatest([
            this.actions$.pipe(ofType(getAccountSuccess), take(1)),
            this.actions$.pipe(ofType(getConfigCatFeatureFlagsSuccess), take(1)),
            this.actions$.pipe(ofType(changeAppReadyStatus)),
        ]).pipe(
            skipWhile(([, , { status }]) => status !== AppReadyStatus.ready),
            concatLatestFrom(() => [
                this.store.select(selectShouldLaunchPupHappinessModal),
                this.store.select(accountFeature.selectDogName),
            ]),
            filter(([_, shouldLaunchPupHappinessModal]) => shouldLaunchPupHappinessModal),
            map(([, , dogName]) => {
                return launchPupHappinessModal({ dogName });
            }),
        );
    });

    showFreeAccessModalOnceUserLoaded$ = createEffect(() => {
        return combineLatest([
            this.actions$.pipe(ofType(getAccountSuccess), take(1)),
            this.actions$.pipe(ofType(getConfigCatFeatureFlagsSuccess), take(1)),
            this.actions$.pipe(ofType(changeAppReadyStatus)),
        ]).pipe(
            skipWhile(([, , { status }]) => status !== AppReadyStatus.ready),
            concatLatestFrom(() => [
                this.store.select(selectReversePaywallAb),
                this.store.select(subscriptionFeature.selectHasHistoricalPurchase),
            ]),
            filter(([[{ profile }], reversePaywallAb, hasHistoricalPurchase]) => {
                return (
                    reversePaywallAb &&
                    !hasHistoricalPurchase &&
                    differenceInHours(new Date(), profile.dateOfOnboarding!) >= 48
                );
            }),
            map(() => {
                return launchFreeAccessModal();
            }),
        );
    });
}
