import { Component, Input, inject } from '@angular/core';
import { ModalService } from '@frontend/utility/modal';
import { IonButton, IonContent, IonFooter, IonHeader, IonToolbar } from '@ionic/angular/standalone';
import { AnalyticsTrackClickDirective } from '../directives/tracking/analytics-track-click.directive';
import { HeaderComponent } from '../header/header.component';

@Component({
    standalone: true,
    selector: 'app-error-screen',
    templateUrl: './error-screen.component.html',
    styleUrls: ['./error-screen.component.scss'],
    imports: [AnalyticsTrackClickDirective, IonContent, IonFooter, IonButton, HeaderComponent, IonHeader, IonToolbar],
})
export class ErrorScreenComponent {
    private readonly modalService = inject(ModalService);
    @Input({ required: true }) errorMessage!: string;
    @Input({ required: true }) buttonText!: string;

    closeModal(): void {
        void this.modalService.dismiss({
            dismissed: true,
        });
    }

    buttonClick(): void {
        if (this.buttonText === 'Contact us') {
            window.location.href = 'mailto:contact@zigzag.dog';
        } else {
            this.closeModal();
        }
    }
}
