import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { forceAppRefresh, openPrivacy, openTerms, openUrlWindow, routeTo, routeToPrevious } from './router.actions';
import { filter, tap } from 'rxjs/operators';
import { NavController } from '@ionic/angular/standalone';
import { WINDOW } from '@frontend/configuration';
import { isCountryCodeNorthAmerica } from '@frontend/data-access/geo-location';

const US_PRIVACY = 'https://zigzag.dog/en-us/privacy/';
const US_TERMS = 'https://zigzag.dog/en-us/terms/';
const REST_OF_WORLD_PRIVACY = 'https://zigzag.dog/privacy/';
const REST_OF_WORLD_TERMS = 'https://zigzag.dog/terms-and-conditions/';

@Injectable()
export class RouterEffects {
    private readonly actions$ = inject(Actions);
    private readonly navController = inject(NavController);
    private readonly window = inject<Window>(WINDOW);
    routeTo$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(routeTo),
                tap(({ commands, extras, isAnimated }) => {
                    const animated = isAnimated ?? false;
                    void this.navController.navigateForward(commands, { ...extras, animated });
                }),
                filter(() => false),
            );
        },
        { dispatch: false },
    );

    routeToPrevious$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(routeToPrevious),
                tap(({ isAnimated }) => {
                    void this.navController.back({ animated: isAnimated ?? true });
                }),
                filter(() => false),
            );
        },
        { dispatch: false },
    );

    openUrlWindow$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(openUrlWindow),
                tap(({ url, openInNewWindow }) => {
                    openInNewWindow ? this.window.open(url, '_blank') : this.window.open(url);
                }),
            );
        },
        { dispatch: false },
    );

    forceAppRefresh$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(forceAppRefresh),
                tap(() => {
                    this.window.location.href = new URL(this.window.location.href).origin;
                }),
                filter(() => false),
            );
        },
        { dispatch: false },
    );

    openPrivacy$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(openPrivacy),
                tap(({ countryCode }) => {
                    if (isCountryCodeNorthAmerica(countryCode)) {
                        this.window.open(US_PRIVACY);
                    } else {
                        this.window.open(REST_OF_WORLD_PRIVACY);
                    }
                }),
                filter(() => false),
            );
        },
        { dispatch: false },
    );

    openTerms$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(openTerms),
                tap(({ countryCode }) => {
                    if (isCountryCodeNorthAmerica(countryCode)) {
                        this.window.open(US_TERMS);
                    } else {
                        this.window.open(REST_OF_WORLD_TERMS);
                    }
                }),
                filter(() => false),
            );
        },
        { dispatch: false },
    );
}
