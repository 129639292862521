import { AsyncPipe, Location } from '@angular/common';
import { Component, inject, ViewChild } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { routeTo } from '@frontend/data-access/router';
import { loginWithApple, loginWithEmailAndPassword, loginWithGoogle } from '@frontend/data-access/user/authentication';
import { AnalyticsTrackClickDirective, HeaderComponent, InvalidInputErrorMessageComponent } from '@frontend/ui';
import {
    IonButton,
    IonContent,
    IonFooter,
    IonHeader,
    IonInput,
    IonItem,
    IonSpinner,
    IonToolbar,
} from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { selectLoginPageVm } from '../../data-access/store/authentication.selectors';

@Component({
    selector: 'app-login',
    templateUrl: './login.page.html',
    styleUrl: './login.page.scss',
    standalone: true,
    imports: [
        FormsModule,
        ReactiveFormsModule,
        InvalidInputErrorMessageComponent,
        AnalyticsTrackClickDirective,
        AsyncPipe,
        IonContent,
        IonItem,
        IonInput,
        IonFooter,
        IonButton,
        IonSpinner,
        HeaderComponent,
        IonHeader,
        IonToolbar,
    ],
})
export class LoginPage {
    private readonly store = inject(Store);
    public readonly location = inject(Location);

    @ViewChild('passwordInput') input!: IonInput;

    public vm = this.store.selectSignal(selectLoginPageVm);

    loginForm = new FormGroup({
        email: new FormControl('', [Validators.required, Validators.email]),
        password: new FormControl('', [Validators.required]),
    });
    isPasswordVisible = false;

    login() {
        this.store.dispatch(
            loginWithEmailAndPassword({
                email: this.loginForm.value.email as string,
                password: this.loginForm.value.password as string,
            }),
        );
    }

    public signInWithApple(): void {
        this.store.dispatch(loginWithApple());
    }

    public signInWithGoogle(): void {
        this.store.dispatch(loginWithGoogle());
    }

    routeToErrorLinkFromInvalidInput(route: string[]) {
        void this.store.dispatch(routeTo({ commands: route }));
    }

    routeToPrevious() {
        this.location.back();
    }

    forgotPassword() {
        this.store.dispatch(routeTo({ commands: ['auth', 'request-password-reset'] }));
    }

    showEmailError(): boolean {
        return this.loginForm.controls.email.dirty && this.loginForm.controls.email.invalid;
    }

    togglePasswordVisibility() {
        this.isPasswordVisible = !this.isPasswordVisible;
        this.input.type = this.isPasswordVisible ? 'text' : 'password';
    }
}
