import { Component, inject, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AnalyticsTrackClickDirective } from '@frontend/ui';
import { ModalService } from '@frontend/utility/modal';
import { IonButton, IonIcon, NavController } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { addIcons } from 'ionicons';
import { glassesOutline, homeOutline } from 'ionicons/icons';
import { setStepRead } from '../../store/progress/step-progress/step-progress.actions';
import { Step } from '../../store/step/step.model';

@Component({
    selector: 'app-lesson-close-notification-modal',
    templateUrl: './lesson-close-notification-modal.component.html',
    styleUrls: ['./lesson-close-notification-modal.component.scss'],
    standalone: true,
    imports: [AnalyticsTrackClickDirective, IonIcon, IonButton],
})
export class LessonCloseNotificationModalComponent {
    private readonly modalService = inject(ModalService);
    private readonly store = inject(Store);
    private readonly navController = inject(NavController);
    @Input() step: Step | undefined;
    @Input() relativeRoute: ActivatedRoute | undefined;

    constructor() {
        addIcons({ homeOutline, glassesOutline });
    }

    closeModal(): void {
        void this.modalService.dismiss({
            dismissed: true,
        });
    }

    setLessonAsRead(): void {
        this.closeModal();

        void this.navController.navigateBack(['../../../../'], { relativeTo: this.relativeRoute, animated: false });

        if (this.step) {
            this.store.dispatch(
                setStepRead({
                    properties: {
                        title: this.step.content.title,
                        stepId: this.step.id,
                        contentType: this.step.content.contentType,
                        contentId: this.step.content.id,
                        topicId: this.step.content.topic,
                    },
                }),
            );
        }
    }
}
