import { selectIsAIChatEnabled } from '@frontend/data-access/user/config-cat';
import { createSelector } from '@ngrx/store';
import {
    selectSelectedTopic,
    selectSelectedTopicProgress,
    selectSelectedTopicStepDetails,
} from '../../../store/library/library.selector';
import { selectContentUnlocked } from '../../../store/payment/store/payment.selectors';

export const selectTopicVm = createSelector(
    selectSelectedTopic,
    selectSelectedTopicStepDetails,
    selectSelectedTopicProgress,
    selectContentUnlocked,
    selectIsAIChatEnabled,
    (topic, steps, progress, contentUnlocked, aiChatEnabled) => ({
        topic,
        steps,
        progress,
        contentUnlocked,
        aiChatEnabled,
    }),
);
