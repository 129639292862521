import { NgStyle } from '@angular/common';
import { Component, Input, inject } from '@angular/core';
import { Color } from '@shared/utils/typescript';
import {
    AnalyticsTrackClickDirective,
    HeaderComponent,
    RadioWithExplanationComponent,
    RadioWithExplanationResult,
} from '@frontend/ui';
import { ModalService } from '@frontend/utility/modal';
import { IonButton, IonHeader, IonToolbar } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { setCourseProgressEnd } from '../../store/course/course.actions';
import { EndCourseModel } from './end-course.model';

@Component({
    standalone: true,
    imports: [
        RadioWithExplanationComponent,
        AnalyticsTrackClickDirective,
        NgStyle,
        IonButton,
        HeaderComponent,
        IonHeader,
        IonToolbar,
    ],
    selector: 'app-end-course-modal',
    templateUrl: 'end-course-modal.component.html',
    styleUrls: ['end-course-modal.component.scss'],
})
export class EndCourseModalComponent {
    public readonly modalService = inject(ModalService);
    private readonly store = inject(Store);
    @Input({ required: true }) course!: EndCourseModel;

    radioResult: RadioWithExplanationResult | undefined;

    buttons = [
        {
            title: 'Just wanted to take a look',
            value: 'just-browsing',
        },
        {
            title: 'My pup is not ready for this yet',
            value: 'not-ready',
        },
        {
            title: 'I have too many courses already',
            value: 'too-many-courses',
        },
        {
            title: 'Something else',
            value: 'something-else',
            explainMore: {
                placeholder: 'Please add your reason',
            },
        },
    ];

    getTitleTextColorClass(color: Color): string {
        const useBlackText = [Color.Oliver, Color.Lola, Color.Bella, Color.Luna, Color.Bailey].some(
            (lightColor) => lightColor === color,
        );

        return useBlackText ? '' : `font-${color}`;
    }

    setAnswer(event: RadioWithExplanationResult) {
        this.radioResult = event;
    }

    endCourse(id: string, title: string) {
        if (this.radioResult) {
            this.store.dispatch(
                setCourseProgressEnd({
                    courseEnd: {
                        id,
                        title,
                        reason: this.radioResult.answer.value,
                        explanation: this.radioResult.answer.explanation,
                    },
                }),
            );
        }
        this.closeModal();
    }

    closeModal(): void {
        void this.modalService.dismiss({
            dismissed: true,
        });
    }
}
