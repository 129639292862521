import { inject, Injectable } from '@angular/core';
import { chatFeature, getLatestMessageDate } from '@frontend/data-access/chat';
import { createAccountSuccess, getAccountSuccess } from '@frontend/data-access/user/account';
import { AI_CHAT_RESPONSE_TIMEOUT, ChatChannelType, ZIGGY_AI_ID } from '@shared/constants';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { from, mergeMap, of, takeUntil, timer } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';
import { ChannelService } from 'stream-chat-angular';
import { trackEvent } from '@frontend/data-access/analytics';
import {
    answerTimeOut,
    sendMessage,
    sendMessageFailure,
    sendMessageSuccess,
    userSentMessageInAiChat,
} from './ai-chat.actions';
import { routeToAiChat } from '../../chat.actions';
import { selectCurrentTab } from '../../../store/router/router.selectors';
import { aiChatInputSubmitted } from '../../../today/today.page.actions';
import {
    dismissibleInfoFeature,
    dismissInfo,
    shouldDisplayModal,
    ShowModalFrequency,
} from '@frontend/data-access/dismissible-info';
import { AiChatIntroModalComponent } from '../ai-chat-intro-modal/ai-chat-intro-modal.component';
import { ModalService } from '@frontend/utility/modal';
import { aiChatFeature } from './ai-chat.reducer';
import { routeTo } from '@frontend/data-access/router';

@Injectable()
export class AiChatEffects {
    private readonly actions$ = inject(Actions);
    private readonly store = inject(Store);
    private readonly channelService = inject(ChannelService);
    private readonly modalService = inject(ModalService);

    routeToAiChatPage$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(routeToAiChat),
            concatLatestFrom(() => {
                return this.store.select(selectCurrentTab);
            }),
            map(([{ message }, currentTab]) => {
                return routeTo({
                    commands: ['main', currentTab ?? 'today', 'chat', 'ai'],
                    extras: { queryParams: { optionId: message } },
                });
            }),
        );
    });

    navigateToAiChatWhenTodayQuestionAsked$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(aiChatInputSubmitted),
            map(({ message }) => {
                return routeToAiChat({ message });
            }),
        );
    });

    showAiChatIntroModal$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(routeToAiChat),
            concatLatestFrom(() => this.store.select(dismissibleInfoFeature.selectDismissedInfoItems)),
            filter(([, dismissibleInfoItems]) => {
                return shouldDisplayModal(
                    ShowModalFrequency.ONCE,
                    'ai-chat-intro-modal',
                    dismissibleInfoItems,
                    new Date(),
                );
            }),
            tap(() => {
                void this.modalService.showModal({
                    component: AiChatIntroModalComponent,
                    cssClass: 'modal',
                });
            }),
            mergeMap(() => {
                return [
                    dismissInfo({ id: 'ai-chat-intro-modal' }),
                    trackEvent({ eventName: '[AI Chat] Show AI chat introduction modal' }),
                ];
            }),
        );
    });

    trackUserSentMessageInAiChat$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(userSentMessageInAiChat),
            map(({ message, wasPromptClicked }) => {
                return trackEvent({
                    eventName: '[Chat] User Sent Message',
                    eventProperties: {
                        channelId: message.cid,
                        channelType: ChatChannelType.AI,
                        messageId: message.id,
                        wasAPromptClickedBeforeSent: wasPromptClicked,
                    },
                });
            }),
        );
    });

    trackAnswerTimeOutInAiChat$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(answerTimeOut),
            map(({ message }) => {
                return trackEvent({
                    eventName: '[Chat] Error',
                    eventProperties: {
                        channelId: message.cid,
                        channelType: ChatChannelType.AI,
                        messageId: message.id,
                        errorMessage: 'Ziggy timed out sending response',
                    },
                });
            }),
        );
    });

    setTimeOutForAiChatAnswer$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(userSentMessageInAiChat, sendMessageSuccess),
            switchMap(({ message }) => {
                return timer(1000 * AI_CHAT_RESPONSE_TIMEOUT).pipe(
                    takeUntil(
                        this.store
                            .select(aiChatFeature.selectIsAnswerLoading)
                            .pipe(filter((isAnswerLoading) => !isAnswerLoading)),
                    ),
                    map(() => {
                        return answerTimeOut({ message });
                    }),
                );
            }),
        );
    });

    getLastSentMessageDate$ = createEffect(() => {
        return this.actions$.pipe(ofType(getAccountSuccess, createAccountSuccess)).pipe(
            map(() => {
                return getLatestMessageDate({ chatType: ChatChannelType.AI });
            }),
        );
    });

    getLastSentMessageDateWhenUserSendsMessageInChat$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(userSentMessageInAiChat),
            concatLatestFrom(() => this.store.select(chatFeature.selectLastMessageSender)),
            filter(([, lastMessageSender]) => lastMessageSender !== ZIGGY_AI_ID),
            map(() => {
                return getLatestMessageDate({ chatType: ChatChannelType.AI });
            }),
        );
    });

    getLastSentMessageDateWhenUserSendsStickyMessage$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(sendMessageSuccess),
            map(() => {
                return getLatestMessageDate({ chatType: ChatChannelType.AI });
            }),
        );
    });

    sendMessage$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(sendMessage),
            mergeMap(({ message }) => {
                return from(this.channelService.sendMessage(message)).pipe(
                    map((streamMessage) => {
                        return sendMessageSuccess({ message: streamMessage });
                    }),
                    catchError(() => {
                        return of(sendMessageFailure());
                    }),
                );
            }),
        );
    });
}
