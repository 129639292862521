import { selectCourses } from '@frontend/data-access/contentful';
import {
    accountFeature,
    selectAgeInWeeksAtOnboarding,
    selectCountryCode,
    selectHasArrived,
    selectHasArrivedAtOnboarding,
    selectHasArrivedOnDateOfOnboarding,
} from '@frontend/data-access/user/account';
import { createSelector } from '@ngrx/store';
import { getContentfulLocaleFromAlpha2CountryCode } from '@shared/constants';
import { getCourseToSetInProgressAfterOnboardingForNonHomeRecommendedContentUsers } from './content.utils';

export const selectGetContentInfo = createSelector(
    accountFeature.selectBreedId,
    selectCountryCode,
    selectAgeInWeeksAtOnboarding,
    selectHasArrivedAtOnboarding,
    selectHasArrivedOnDateOfOnboarding,
    accountFeature.selectIsRescuePup,
    (breedId, countryCode, ageInWeeks, hasArrived, hasArrivedOnDateOfOnboarding, isRescue) => {
        return {
            breedId,
            locale: getContentfulLocaleFromAlpha2CountryCode(countryCode),
            ageInWeeks,
            hasArrived,
            hasArrivedOnDateOfOnboarding,
            isRescue,
        };
    },
);

export const selectCourseToSetInProgressAfterOnboardingForNonHomeRecommendedContentUsers = createSelector(
    selectCourses,
    selectHasArrived,
    (courses, hasArrived) => {
        return getCourseToSetInProgressAfterOnboardingForNonHomeRecommendedContentUsers(courses, hasArrived);
    },
);
