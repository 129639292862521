import { inject, Injectable } from '@angular/core';
import { trackEvent } from '@frontend/data-access/analytics';
import { routeTo } from '@frontend/data-access/router';
import {
    getAccountSuccess,
    selectConfigCatUserProperties,
    updateAccountSuccess,
} from '@frontend/data-access/user/account';
import {
    loginWithAppleSuccess,
    loginWithEmailAndPasswordSuccess,
    loginWithGoogleSuccess,
} from '@frontend/data-access/user/authentication';
import { getConfigCatFeatureFlags } from '@frontend/data-access/user/config-cat';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { concatMap } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable()
export class ProfileEffects {
    private readonly actions$ = inject(Actions);
    private readonly store = inject(Store);

    routeToTodayOnceUserLoggedInWithEmail$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(loginWithEmailAndPasswordSuccess),
            map(() => {
                return routeTo({ commands: ['main', 'today'] });
            }),
        );
    });

    /**
     * The {@link OnboardingCompleteGuard} should handle redirecting to onboarding for new SSO users.
     */
    routeToTodayOnceUserLoggedInWithSSO$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(loginWithGoogleSuccess, loginWithAppleSuccess),
            filter(({ additionalUserInfo }) => {
                return !!additionalUserInfo && !additionalUserInfo.isNewUser;
            }),
            map(() => {
                return routeTo({ commands: ['main', 'today'] });
            }),
        );
    });

    getConfigCatFeatureFlags$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getAccountSuccess, updateAccountSuccess),
            concatLatestFrom(() => this.store.select(selectConfigCatUserProperties)),
            filter(([, { identifier }]) => {
                return !!identifier;
            }),
            concatMap(([, userProfileProperties]) => {
                return [
                    trackEvent({
                        eventName: '[ConfigCat] Get Feature Flags',
                        eventProperties: userProfileProperties,
                    }),
                    getConfigCatFeatureFlags({
                        userObject: { ...userProfileProperties, identifier: userProfileProperties.identifier! },
                    }),
                ];
            }),
        );
    });
}
