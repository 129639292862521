import { AsyncPipe } from '@angular/common';
import { Component } from '@angular/core';
import { Color } from '@shared/utils/typescript';
import { AnalyticsTrackClickDirective } from '@frontend/ui';
import { IonButton, IonSpinner } from '@ionic/angular/standalone';
import { OnboardingBaseComponent } from '../../onboarding-base/onboarding-base.component';
import { selectQuestionNotificationsPageVm } from './question-notifications.page.selectors';
import { OnboardingQuestionTemplateComponent } from '../../onboarding-question-template/onboarding-question-template.component';
import { onboardingQuestionSubmittedNotifications } from '../../data-access/onboarding.actions';

@Component({
    selector: 'app-onboarding-question-notifications',
    templateUrl: 'question-notifications.page.html',
    styleUrls: ['../../onboarding.scss'],
    standalone: true,
    imports: [OnboardingQuestionTemplateComponent, AnalyticsTrackClickDirective, AsyncPipe, IonButton, IonSpinner],
})
export class OnboardingQuestionNotificationsPage extends OnboardingBaseComponent {
    public vm = this.store.selectSignal(selectQuestionNotificationsPageVm);

    protected readonly Color = Color;

    public continue(notifications: boolean): void {
        if (this.vm().isLoading) {
            return;
        }

        this.store.dispatch(onboardingQuestionSubmittedNotifications({ notifications }));
    }
}
