<app-onboarding-question-template
    backButtonId="question-topic-back-btn"
    title="Do you want to work on a specific topic?"
    subtitle="Find lessons on your selected topics in the library"
    [progress]="vm().progress"
    (backButtonPressed)="routeToPrevious()"
>
    <ng-container body>
        <div class="multi-select-container">
            <app-multi-select
                id="multiSelect"
                [multiSelectOptions]="vm().onboardingPreviewTopics"
                (optionChanged)="updateTopics($event)"
            />
            <button
                class="zz-button center multi-select-container__extra-options-button"
                appTrackClick
                identifier="question-topic-others-btn"
                data-test="extra-topic-btn"
                (click)="openExtraTopics()"
            >
                Other topics
            </button>
        </div>
        <ion-footer class="onboarding-radio-button-container">
            <ion-button
                class="light-button-activated-jack"
                appTrackClick
                identifier="question-topic-next-btn"
                fill="outline"
                data-test="question-topic-next-btn"
                [class.light-button]="!vm().hasSelectedTopics"
                [color]="!vm().hasSelectedTopics ? '' : Color.Max"
                (click)="continue()"
                >{{ !vm().hasSelectedTopics ? 'Skip' : 'Next' }}
            </ion-button>
        </ion-footer>
    </ng-container>
</app-onboarding-question-template>
