import { mixpanelConfigProd } from '@shared/config/mixpanel-config';
import { firebaseConfigProd } from '../firebase.config';
import { Environment } from './environment.model';
import { ProjectEnvironment } from '@frontend/configuration';

export const environment: Environment = {
    baseEnvironmentConfig: {
        production: true,
        environment: ProjectEnvironment.PRODUCTION,
        internalApiUrl: 'https://zigzag-internal-traffic-manager-prod.trafficmanager.net/',
        internalAuthSecret: 'ZAvw67ZWlx',
        applicationToInternalHashedApiKey:
            '$argon2id$v=19$m=4096,t=3,p=1$aWdmamRzaWZqZHNkZmRzZg$VYGU6nvTQptoy5ZCFSlKjDyd+exuy9SVrOQpglpp7uA',
    },
    firebaseConfig: firebaseConfigProd,
    useSubscriptionMock: false,
    revenueCatKey: 'FkcuhfqFHCUvosCPapJXilfzyXCjzOnd',
    adjustAppToken: 'zlhl6pv8y70g',
    streamChatConfig: {
        apiKey: 'm4qu9fdm5ssy',
        useServiceMock: false,
    },
    configCatConfig: {
        apiKey: 'configcat-sdk-1/w6LaCCdY1kiHQRICXSOQjA/2NGnYV9DAkm7WydcfVnf1w',
        useServiceMock: false,
    },
    applicationInsights: {
        active: true,
        instrumentationKey: 'cc0640b9-5e08-41a6-bdef-09a32ba66eb1',
    },
    mixpanelConfig: {
        platform: 'app',
        projectToken: mixpanelConfigProd.projectToken,
    },
    attachStoreToWindow: false,
};
