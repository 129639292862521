import { AsyncPipe } from '@angular/common';
import { Component } from '@angular/core';
import { Color } from '@shared/utils/typescript';
import { AnalyticsTrackClickDirective } from '@frontend/ui';
import { IonButton, IonFooter, IonInput, IonItem, IonSpinner } from '@ionic/angular/standalone';
import { OnboardingBaseComponent } from '../../onboarding-base/onboarding-base.component';
import { selectQuestionRescuePageVm } from './question-rescue.page.selectors';
import { OnboardingQuestionTemplateComponent } from '../../onboarding-question-template/onboarding-question-template.component';
import { ReactiveFormsModule } from '@angular/forms';
import { onboardingQuestionSubmittedIsRescue } from '../../data-access/onboarding.actions';

@Component({
    selector: 'app-onboarding-question-rescue',
    templateUrl: 'question-rescue.page.html',
    styleUrls: ['../../onboarding.scss', 'question-rescue.page.scss'],
    standalone: true,
    imports: [
        AnalyticsTrackClickDirective,
        AsyncPipe,
        IonButton,
        IonFooter,
        IonInput,
        IonItem,
        OnboardingQuestionTemplateComponent,
        ReactiveFormsModule,
        IonSpinner,
    ],
})
export class OnboardingQuestionRescuePage extends OnboardingBaseComponent {
    public vm = this.store.selectSignal(selectQuestionRescuePageVm);

    protected readonly Color = Color;

    public continue(isRescuePup: boolean): void {
        if (this.vm().isLoading) {
            return;
        }

        this.store.dispatch(onboardingQuestionSubmittedIsRescue({ isRescuePup }));
    }
}
