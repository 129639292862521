import { createAction, props } from '@ngrx/store';

export const showPaymentModal = createAction(
    '[Payment] Show Payment Modal',
    props<{ trigger: string; showDiscountProducts?: boolean }>(),
);

export const showIosDiscountPaymentModal = createAction(
    '[Payment] Show iOS Discount Payment Modal',
    props<{ trigger: string }>(),
);

export const showPaymentModalLimitedOffer = createAction('[Payment] Show Payment Modal Limited Offer');

export const closePaymentModalClicked = createAction('[Payment] Close Payment Modal Clicked');

export const closePaymentModalLimitedOfferClicked = createAction('[Payment] Close Payment Modal Limited Offer Clicked');

export const openIosDiscountProducts = createAction('[Payment] Open Ios Discount Products');
