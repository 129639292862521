import { IsNotEmpty, IsNumber, IsOptional, IsPositive, IsString, Max, Min } from 'class-validator';

export class DomainPatchStepProgressCommand {
    @IsString()
    @IsNotEmpty()
    @IsOptional()
    userId?: string;

    @IsString()
    @IsNotEmpty()
    @IsOptional()
    stepId?: string;

    @IsNumber()
    @IsPositive()
    @IsOptional()
    contentId?: number;

    @IsString()
    @IsNotEmpty()
    progressTypeId?: string;

    @IsNumber()
    @IsOptional()
    timestamp?: number;

    @IsNumber()
    @Min(0)
    @Max(5)
    @IsOptional()
    rating?: number;
}
