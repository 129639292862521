import { Component, inject, Input } from '@angular/core';
import { AnalyticsTrackClickDirective } from '@frontend/ui';
import { ModalService } from '@frontend/utility/modal';
import { IonButton, IonText, NavController } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { TextReplacementPipe } from '@frontend/data-access/text-replacement';
import { setStepSkipped } from '../../store/progress/step-progress/step-progress.actions';
import { SkipReason, SkipStepOptions } from '../../store/progress/step-progress/step-progress.model';
import { Step } from '../../store/step/step.model';
import { selectCurrentTab } from '../../store/router/router.selectors';

@Component({
    selector: 'app-lesson-skip-notification-modal',
    templateUrl: './lesson-skip-notification-modal.component.html',
    styleUrls: ['./lesson-skip-notification-modal.component.scss'],
    standalone: true,
    imports: [AnalyticsTrackClickDirective, TextReplacementPipe, IonButton, IonText],
})
export class LessonSkipNotificationModalComponent {
    private readonly store = inject(Store);
    private readonly navController = inject(NavController);
    private readonly modalService = inject(ModalService);
    @Input() step: Step | undefined;

    private currentTab = this.store.selectSignal(selectCurrentTab);
    public skipStepOptions: SkipStepOptions = {
        reasons: [
            { id: 'can-already-do-this', text: '[NAME] can already do this' },
            { id: 'too-difficult', text: "It's too difficult" },
            { id: 'no-time', text: 'I want to do this lesson later' },
            { id: 'not-relevant', text: 'This lesson is not relevant' },
        ],
    };

    closeModal(): void {
        void this.modalService.dismiss({
            dismissed: true,
        });
    }

    cancelLessonStep(): void {
        this.closeModal();
    }

    skipLesson(skipReason: SkipReason): void {
        this.closeModal();

        if (this.step) {
            this.store.dispatch(
                setStepSkipped({
                    properties: {
                        title: this.step.content.title,
                        stepId: this.step.id,
                        contentType: this.step.content.contentType,
                        contentId: this.step.content.id,
                        topicId: this.step.content.topic,
                        skipReason: skipReason.id,
                    },
                }),
            );
        }

        if (this.currentTab() === 'today') {
            void this.navController.navigateBack(['../../'], { animated: false });
        } else if (this.currentTab() === 'courses') {
            void this.navController.navigateBack(['main/courses'], { animated: false });
        } else if (this.currentTab() === 'library') {
            void this.navController.navigateBack(['main/library'], { animated: false });
        }
    }
}
