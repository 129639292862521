<ion-content [color]="Color.Alvin" [fullscreen]="true">
    <ion-header class="page-header ion-no-border">
        <ion-toolbar>
            <app-header
                buttonBackground="white"
                identifierRightButton="payment-modal-limited-offer-close-btn"
                [hasRightButton]="true"
                (rightButtonPressed)="onCloseModalClick()"
            />
        </ion-toolbar>
    </ion-header>

    <section class="payment-modal-limited-offer">
        <img
            class="payment-modal-limited-offer__logo"
            [src]="'assets/images/zigzag/zigzag-logo.svg'"
            alt="zigzag logo"
        />

        <h3 class="payment-modal-limited-offer__limited-text">Limited offer</h3>

        <h1 class="payment-modal-limited-offer__title l">
            Save&nbsp;<span class="payment-modal-limited-offer__percentage">{{ discount() }}</span
            >&nbsp;today!
        </h1>

        <img
            class="payment-modal-limited-offer__dog"
            [src]="'assets/images/bailey/bailey-runs-after-tennis-ball.svg'"
            alt="Bailey running after a tennis ball with excitement"
        />

        <ul class="value-prop-section">
            <li class="value-prop-item">
                <p class="xxl bullet-icon"><i class="fa-thin fa-circle-check circle-icon"></i></p>
                <p class="s bullet-text">
                    {{ "A training journey that reacts to [NAME]'s progress" | textReplacement }}
                </p>
            </li>

            <li class="value-prop-item">
                <p class="xxl bullet-icon"><i class="fa-thin fa-circle-check circle-icon"></i></p>
                <p class="s bullet-text">Unlimited access to 250+ lessons to overcome any issue</p>
            </li>

            <li class="value-prop-item">
                <p class="xxl bullet-icon"><i class="fa-thin fa-circle-check circle-icon"></i></p>
                <p class="s bullet-text">24/7 live chat support with training coaches</p>
            </li>
        </ul>

        <div class="payment-modal-limited-offer__products">
            @for (product of products(); track product.identifier) {
                <app-payment-limited-offer-modal-product
                    [isSelected]="product.identifier === selectedProductId()"
                    [product]="product"
                    (click)="onProductClick(product.identifier)"
                />
            }
        </div>

        <p class="payment-modal-limited-offer__cancel-anytime s">Cancel anytime in the App Store</p>

        <p class="payment-modal-limited-offer__disclaimer xs">
            Your chosen subscription automatically renews for the same term.
        </p>
    </section>
</ion-content>

<ion-footer class="payment-modal-limited-offer-footer page-padding-sides">
    <ion-button
        appTrackClick
        identifier="payment-modal-limited-offer-start-trial-btn"
        expand="full"
        shape="round"
        data-test="payment-modal-limited-offer-start-trial-btn"
        [color]="Color.Max"
        (click)="onStartTrialClick()"
    >
        Start my free 7 days
    </ion-button>
</ion-footer>
