<ion-header class="page-header ion-no-border">
    <ion-toolbar>
        <app-header
            identifierLeftButton="onboarding-back-btn"
            [progressRatio]="progress"
            [hasLeftButton]="true"
            [isDisabled]="isLoading()"
            (leftButtonPressed)="backButtonAction()"
        />
    </ion-toolbar>
</ion-header>

<ion-content [fullscreen]="true" class="page-ion-content ion-text-center doodle">
    <div class="page-content ion-justify-content-start">
        <div class="onboarding-header">
            @if (title) {
                <h1>{{ title }}</h1>
            }
            @if (subtitle) {
                <p class="ion-padding-horizontal" data-test="onboarding-question-template-subtitle">{{ subtitle }}</p>
            }
        </div>

        <ng-content select="[body]"></ng-content>
    </div>
</ion-content>

<ion-footer class="ion-text-center page-footer">
    <ng-content select="[footer]"></ng-content>
</ion-footer>
