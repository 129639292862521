import { AsyncPipe } from '@angular/common';
import { Component, EventEmitter, Output, ViewChild, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AbstractControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InvalidInputErrorMessageComponent } from '@frontend/ui';
import { IonInput, IonItem, Platform } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { startWith } from 'rxjs/operators';
import { routeTo } from '@frontend/data-access/router';
import { selectCreateAccountPageVm } from '../create-account.page.selectors';
import { signUpForm } from '../create-account.model';

@Component({
    selector: 'app-create-account-form',
    templateUrl: './create-account-form.component.html',
    styleUrls: ['./create-account-form.component.scss'],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, InvalidInputErrorMessageComponent, AsyncPipe, IonItem, IonInput],
})
export class CreateAccountFormComponent {
    private readonly store = inject(Store);
    public readonly platform = inject(Platform);
    @Output() signUpFormChange = new EventEmitter<FormGroup>();
    @Output() inputFocus = new EventEmitter<boolean>();

    @ViewChild('passwordInput') input!: IonInput;

    public vm$ = this.store.select(selectCreateAccountPageVm);

    public signUpForm = signUpForm;

    public passwordVisible = false;

    constructor() {
        this.signUpForm.valueChanges.pipe(startWith(1), takeUntilDestroyed()).subscribe(() => {
            const emailValue = this.signUpForm.value.email;
            // if statement required to prevent infinite loop
            if (emailValue !== emailValue?.trim()) {
                this.signUpForm.patchValue({ email: emailValue?.trim() });
            }
            void this.signUpFormChange.emit(this.signUpForm);
        });
    }

    onInputFocus() {
        void this.inputFocus.emit(true);
    }

    onInputBlur() {
        void this.inputFocus.emit(false);
    }

    routeToErrorLinkFromInvalidInput(route: string[] | undefined) {
        if (route) {
            void this.store.dispatch(routeTo({ commands: route }));
        }
    }

    showError(control: AbstractControl): boolean {
        return control.dirty && control.invalid;
    }

    togglePasswordVisibility(): void {
        this.passwordVisible = !this.passwordVisible;
        this.input.type = this.passwordVisible ? 'text' : 'password';
    }
}
