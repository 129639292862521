import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { User } from '../models/user.model';

@Injectable()
export abstract class AuthenticationService {
    abstract readonly token$: Observable<string | null>;

    abstract initialize(): void;

    abstract signUpWithEmailAndPassword(email: string, password: string): Observable<{ user: User }>;

    abstract loginWithEmailAndPassword(email: string, password: string): Observable<{ user: User }>;

    abstract loginWithGoogle(): any;

    abstract loginWithApple(): any;

    abstract logout(): Observable<void>;

    abstract isLoggedIn(): Observable<boolean>;

    abstract requestPasswordReset(email: string): Observable<void>;
}
