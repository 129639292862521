import { AsyncPipe, Location } from '@angular/common';
import { Component, inject } from '@angular/core';
import { selectFaqs } from '@frontend/data-access/contentful';
import { AccordionComponent, HeaderComponent, RichContentPipe } from '@frontend/ui';
import { IonContent, IonHeader, IonToolbar, Platform, ViewWillEnter, ViewWillLeave } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { TextReplacementPipe } from '@frontend/data-access/text-replacement';

@Component({
    selector: 'app-faq',
    templateUrl: './faq.page.html',
    styleUrls: ['./faq.page.scss'],
    standalone: true,
    imports: [
        AccordionComponent,
        AsyncPipe,
        RichContentPipe,
        TextReplacementPipe,
        IonContent,
        HeaderComponent,
        IonHeader,
        IonToolbar,
    ],
})
export class FaqPage implements ViewWillEnter, ViewWillLeave {
    private readonly store = inject(Store);
    public readonly location = inject(Location);
    private readonly platform = inject(Platform);
    public vm$ = this.store.select(selectFaqs);

    private backButtonSub: Subscription = new Subscription();

    ionViewWillEnter(): void {
        this.backButtonSub = this.platform.backButton.subscribeWithPriority(20, () => this.routeToPrevious());
    }

    ionViewWillLeave(): void {
        this.backButtonSub.unsubscribe();
    }

    routeToPrevious(): void {
        this.location.back();
    }
}
