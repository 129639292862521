<ion-header class="page-header ion-no-border">
    <ion-toolbar>
        <app-header
            title="FAQ"
            toolbarBackground="var(--ion-color-app-background)"
            identifierRightButton="faq-back-to-support-btn"
            [hasLeftButton]="true"
            (leftButtonPressed)="routeToPrevious()"
        />
    </ion-toolbar>
</ion-header>

@if (vm$| async ; as faqs) {
<ion-content class="page-ion-content">
    <div class="column">
        <div class="image-box lola">
            <img src="assets/images/oliver/oliver-looks-confused.png" class="image-self" />
        </div>
        <div class="text-block">
            <h3 class="l">How can we help you?</h3>
        </div>
        @for (faq of faqs; track faq.id) {
        <app-accordion
            [title]="faq.title | textReplacement"
            [content]="faq.content | richContent | textReplacement"
            [analytics]="{identifier: '[FAQ] Opened' , properties: {faqTitle: faq.mixpanelId}}"
        ></app-accordion>
        }
    </div>
</ion-content>
}
