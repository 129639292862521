import { NgStyle } from '@angular/common';
import { Component, Input } from '@angular/core';
import { Color } from '@shared/utils/typescript';
import { IonCard, IonIcon } from '@ionic/angular/standalone';
import { addIcons } from 'ionicons';
import { lockClosed } from 'ionicons/icons';

@Component({
    standalone: true,
    selector: 'app-generic-card-square',
    templateUrl: './generic-card-square.component.html',
    styleUrls: ['./generic-card-square.component.scss'],
    imports: [NgStyle, IonCard, IonIcon],
})
export class GenericCardSquareComponent {
    @Input({ required: true }) public title!: string;
    @Input({ required: true }) public imageUrl!: string;
    @Input({ required: true }) public background!: Color;
    @Input({ required: true }) public description!: string;
    @Input({ required: true }) public unlocked!: boolean;
    @Input() public disabled = false;

    constructor() {
        addIcons({ lockClosed });
    }
}
