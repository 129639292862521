<app-onboarding-question-template
    backButtonId="question-age-back-btn"
    title="How old is {{ vm().dogName }}?"
    [progress]="vm().progress"
    [isLoading]="vm().isLoading"
    (backButtonPressed)="routeToPrevious()"
>
    <ng-container body>
        <form id="ageForm" [formGroup]="form" (ngSubmit)="onSubmit()">
            <app-radio-with-logic
                [buttons]="AGE_RADIO_OPTIONS"
                [isDisabled]="vm().isLoading"
                (changeEvent)="setAnswer($event)"
            />

            @if (radioResult?.complete && radioResult?.selected !== OVER_TWO_YEARS_RADIO_OPTION) {
            <section class="question-age-page__date-input-container">
                <p class="question-age-page__date-text">
                    Please specify {{ vm().dogName }}'s {{ radioResult?.selected === ZERO_TO_FOUR_MONTHS_RADIO_OPTION ?
                    'birthday' : 'birth month' }}, or your closest estimate to help us tailor the program
                </p>

                <ion-datetime-button datetime="datetime" class="zz-datetime-button" [disabled]="vm().isLoading" />
                <ion-modal [keepContentsMounted]="true">
                    <ng-template>
                        <ion-datetime
                            id="datetime"
                            formControlName="dateOfBirth"
                            [presentation]="radioResult?.selected?.domElement?.type"
                            [max]="getMaxValue(radioResult)"
                            [min]="getMinValue(radioResult)"
                            [showDefaultButtons]="true"
                        />
                    </ng-template>
                </ion-modal>
            </section>
            } @if(form.dirty && form.valid && radioResult?.complete && radioResult?.selected !==
            OVER_TWO_YEARS_RADIO_OPTION) {
            <p class="question-age-page__age-text" data-test="age-in-weeks-text">
                {{ vm().dogName }} is {{ form.controls.dateOfBirth.value | age : vm().currentDate }} old
            </p>
            }
        </form>
    </ng-container>
    <ng-container footer>
        <ion-button
            appTrackClick
            identifier="question-age-next-btn"
            type="submit"
            form="ageForm"
            expand="block"
            data-test="question-age-next-btn"
            [color]="Color.Max"
            [disabled]="form.invalid || vm().isLoading"
        >
            @if (vm().isLoading) {
            <ion-spinner name="crescent" />
            } @else { Next }
        </ion-button>
    </ng-container>
</app-onboarding-question-template>
