<app-onboarding-question-template
    backButtonId="question-has-arrived-back-btn"
    title="Is it {{ vm().dogName }}'s first day at home?"
    subtitle="We'll adjust the program to best fit you"
    [progress]="vm().progress"
    [isLoading]="vm().isLoading"
    (backButtonPressed)="routeToPrevious()"
>
    <ng-container body></ng-container>
    <ng-container footer>
        <div class="onboarding-radio-button-container">
            <ion-button
                class="light-button-activated-jack"
                appTrackClick
                identifier="question-first-day-at-home-true-btn"
                fill="outline"
                expand="block"
                data-test="question-first-day-at-home-true-btn"
                [disabled]="vm().isLoading"
                (click)="hasArrivedTodayAnswered(true)"
            >
                @if (vm().isLoading) {
                <ion-spinner name="crescent" />
                } @else { Yes }
            </ion-button>
            <ion-button
                class="light-button-activated-jack"
                appTrackClick
                identifier="question-first-day-at-home-false-btn"
                fill="outline"
                expand="block"
                data-test="question-first-day-at-home-false-btn"
                [disabled]="vm().isLoading"
                (click)="hasArrivedTodayAnswered(false)"
            >
                @if (vm().isLoading) {
                <ion-spinner name="crescent" />
                } @else { No }
            </ion-button>
        </div>
    </ng-container>
</app-onboarding-question-template>
