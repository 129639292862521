import { InternalIterablePostSendEmailCommand } from '../../commands/internal-api/third-party/iterable/internal-iterable-post-send-email.command';

export class InternalIterablePostSendEmailCommandMock {
    private defaultValue: InternalIterablePostSendEmailCommand = {
        recipientUserId: 'user-id',
        campaignId: 1,
        dataFields: {
            locale: 'en-GB',
        },
    };

    constructor(overrides?: Partial<InternalIterablePostSendEmailCommand>) {
        Object.assign(this.defaultValue, overrides);
    }

    public get value(): InternalIterablePostSendEmailCommand {
        return this.defaultValue;
    }
}
