export const expertCards = [
    {
        card: {
            imageUrl: 'assets/images/trainers/coach-carolyn-blue-circle.png',
            title: 'Carolyn Menteith',
            subtitle: 'Professional dog trainer',
            rounded: true,
        },
        imageUrl: 'assets/images/trainers/Carolyn_Menteith.jpg',
        firstName: 'Carolyn',
        lastName: 'Menteith',
        aboutMe: [
            'Carolyn is a dog trainer and behaviour consultant with over 20 years experience working with animals, the\n' +
                'last 15 of which she has spent specialising solely in dogs - puppies in particular. Carolyn is the author of\n' +
                'the Zigzag programme.',
            'Carolyn won the inaugural Kennel Club Accredited Instructor of the Year award presented at Crufts 2015.\n' +
                'Additionally, she has written five published books and over 800 articles in all aspects of dogs training,\n' +
                'behaviour, welfare and care since 1999. She has taught and presented on training, socialisation and\n' +
                'habituation for puppies to owners and other professionals around the world.\n' +
                'In addition, Carolyn has a media background, and can be heard regularly on radio programmes as a dog expert,\n' +
                'and has appeared as a presenter and expert on TV programmes and series’ such as Top Dog (Animal Planet),\n' +
                'What’s Up Dog? (Carlton TV), Celebrity Dog School (BBC1 for Children in Need), Barking Babes (Granada), The\n' +
                'Money Programme (BBC) and many others.',
        ],
        certifications: [
            'Kennel Club Accredited Instructor (CDA)',
            'Centre of Applied Pet Ethology (COAPE) Diploma in Animal Behaviour (DipCAPT)',
            'Accredited Animal Trainer (ABTC)',
            'Accredited member of COAPE Association of Pet Behaviourists and Trainers',
            'Accredited member of the Association of Pet Dog Trainers',
            'Accredited member of the Pet Professional Guild',
            'Accredited member of INTODogs',
        ],
    },
    {
        card: {
            imageUrl: 'assets/images/trainers/coach-petrina-red-circle.png',
            title: 'Petrina Firth',
            subtitle: 'Professional dog trainer',
            rounded: true,
        },
        imageUrl: 'assets/images/trainers/Petrina_Firth.jpg',
        firstName: 'Petrina',
        lastName: 'Firth',
        aboutMe: [
            'Petrina has been training animals professionally for over ten years. She uses the most up to date, science\n' +
                'backed, kind fair and effective methods, and works with animals holistically, looking at nutritional health,\n' +
                'breed specific needs, mental stimulation and physical needs, in her sessions. Based in Central London, she\n' +
                'sees dog and cat owners on a one to one basis, for group training classes, private training sessions and\n' +
                'behaviour consults.',
            'Petrina previously assisted Chirag Patel at The Company of Animals and Karen England at The Best From Your\n' +
                'Dog. She has appeared in the Daily Mail, Edition Dog magazine and been on BBC radio five live.\n' +
                'She sits on the board of The Association of Pet Dog Trainers UK, and the committee of COAPE Association of\n' +
                'Pet Behaviourist and Trainers.\n' +
                'She sees clients remotely throughout the UK and worldwide to support them through the separation anxiety\n' +
                'training process and helping their dog cope with being alone.',
        ],
        certifications: [
            'Certified Separation Anxiety Trainer (CSAT), one of only two in the UK',
            'Full member of The Association of Pet Dog Trainers (APDT UK 01238)',
            'Full member of the COAPE Association of Pet Behaviourists and Trainers (CAPBT)',
            'Centre of Applied Pet Ethology (COAPE) Diploma in Animal Behaviour (DipCAPT)',
            'Accredited Animal Behaviourist and Trainer (ABTC)',
            'Certified Animal Behaviourist (ICAN)',
            'Fully qualified Dog Yoga Instructor',
        ],
    },
    {
        card: {
            imageUrl: 'assets/images/trainers/coach-sue-yellow-circle.png',
            title: 'Sue Tasker',
            subtitle: 'Professional dog trainer',
            rounded: true,
        },
        imageUrl: 'assets/images/trainers/Sue_Tasker.jpg',
        firstName: 'Sue',
        lastName: 'Tasker',
        aboutMe: [
            'Sue is highly experienced and knowledgeable in all aspects of animal welfare and pet behaviour. She\n' +
                'qualified as a Veterinary Nurse in 1977. Sue offers a wealth of experience with a wide variety of companion\n' +
                'animals. She currently owns Molly, a terrier cross and Tess, a setter x spaniel.',
            'Sue also runs puppy classes based on the “life skills for puppies” programme developed by the team at\n' +
                'Lincoln University. Sue has a particular interest in working with families with young children and their\n' +
                'pets, but everyone has animals for very personal reasons, and sometimes some professional help and guidance\n' +
                'can make that relationship so much stronger and more positive.',
        ],
        certifications: [
            'Full member of COAPE Association of Pet Behaviourists and Trainers (level 4)',
            'Full member of APDT UK',
            'Pro Dog Trainer - Absolute Dogs',
            'Dog Aid Trainer',
        ],
    },
];

export const usExpertCards = [
    {
        card: {
            imageUrl: 'assets/images/trainers/coach-carolyn-blue-circle.png',
            title: 'Carolyn Menteith',
            subtitle: 'Professional dog trainer',
            rounded: true,
        },
        imageUrl: 'assets/images/trainers/Carolyn_Menteith.jpg',
        firstName: 'Carolyn',
        lastName: 'Menteith',
        aboutMe: [
            'Carolyn is a dog trainer and behavior consultant with over 20 years experience working with animals, the\n' +
                'last 15 of which she has spent specializing solely in dogs - puppies in particular. Carolyn is the author of\n' +
                'the Zigzag program.',
            'Carolyn won the inaugural Kennel Club Accredited Instructor of the Year award presented at Crufts 2015.\n' +
                'Additionally, she has written five published books and over 800 articles in all aspects of dogs training,\n' +
                'behavior, welfare and care since 1999. She has taught and presented on training, socialization and\n' +
                'habituation for puppies to owners and other professionals around the world.\n' +
                'In addition, Carolyn has a media background, and can be heard regularly on radio programs as a dog expert,\n' +
                'and has appeared as a presenter and expert on TV programs and series’ such as Top Dog (Animal Planet),\n' +
                'What’s Up Dog? (Carlton TV), Celebrity Dog School (BBC1 for Children in Need), Barking Babes (Granada), The\n' +
                'Money Programme (BBC) and many others.',
        ],
        certifications: [
            'Kennel Club Accredited Instructor (CDA)',
            'Centre of Applied Pet Ethology (COAPE) Diploma in Animal Behaviour (DipCAPT)',
            'Accredited Animal Trainer (ABTC)',
            'Accredited member of COAPE Association of Pet Behaviourists and Trainers',
            'Accredited member of the Association of Pet Dog Trainers',
            'Accredited member of the Pet Professional Guild',
            'Accredited member of INTODogs',
        ],
    },
    {
        card: {
            imageUrl: 'assets/images/trainers/coach-petrina-red-circle.png',
            title: 'Petrina Firth',
            subtitle: 'Professional dog trainer',
            rounded: true,
        },
        imageUrl: 'assets/images/trainers/Petrina_Firth.jpg',
        firstName: 'Petrina',
        lastName: 'Firth',
        aboutMe: [
            'Petrina has been training animals professionally for over ten years. She uses the most up to date, science\n' +
                'backed, kind fair and effective methods, and works with animals holistically, looking at nutritional health,\n' +
                'breed specific needs, mental stimulation and physical needs, in her sessions. Based in Central London, she\n' +
                'sees dog and cat owners on a one to one basis, for group training classes, private training sessions and\n' +
                'behavior consults.',
            'Petrina previously assisted Chirag Patel at The Company of Animals and Karen England at The Best From Your\n' +
                'Dog. She has appeared in the Daily Mail, Edition Dog magazine and been on BBC radio five live.\n' +
                'She sits on the board of The Association of Pet Dog Trainers UK, and the committee of COAPE Association of\n' +
                'Pet Behaviourist and Trainers.\n' +
                'She sees clients remotely throughout the UK and worldwide to support them through the separation anxiety\n' +
                'training process and helping their dog cope with being alone.',
        ],
        certifications: [
            'Certified Separation Anxiety Trainer (CSAT), one of only two in the UK',
            'Full member of The Association of Pet Dog Trainers (APDT UK 01238)',
            'Full member of the COAPE Association of Pet Behaviourists and Trainers (CAPBT)',
            'Centre of Applied Pet Ethology (COAPE) Diploma in Animal Behaviour (DipCAPT)',
            'Accredited Animal Behaviourist and Trainer (ABTC)',
            'Certified Animal Behaviourist (ICAN)',
            'Fully qualified Dog Yoga Instructor',
        ],
    },
    {
        card: {
            imageUrl: 'assets/images/trainers/coach-sue-yellow-circle.png',
            title: 'Sue Tasker',
            subtitle: 'Professional dog trainer',
            rounded: true,
        },
        imageUrl: 'assets/images/trainers/Sue_Tasker.jpg',
        firstName: 'Sue',
        lastName: 'Tasker',
        aboutMe: [
            'Sue is highly experienced and knowledgeable in all aspects of animal welfare and pet behavior. She\n' +
                'qualified as a Veterinary Nurse in 1977. Sue offers a wealth of experience with a wide variety of companion\n' +
                'animals. She currently owns Molly, a terrier cross and Tess, a setter x spaniel.',
            'Sue also runs puppy classes based on the “life skills for puppies” program developed by the team at\n' +
                'Lincoln University. Sue has a particular interest in working with families with young children and their\n' +
                'pets, but everyone has animals for very personal reasons, and sometimes some professional help and guidance\n' +
                'can make that relationship so much stronger and more positive.',
        ],
        certifications: [
            'Full member of COAPE Association of Pet Behaviourists and Trainers (level 4)',
            'Full member of APDT UK',
            'Pro Dog Trainer - Absolute Dogs',
            'Dog Aid Trainer',
        ],
    },
];
