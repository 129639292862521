import { inject, Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { filter, map } from 'rxjs/operators';
import { routeToStep, routeToStepUsingContentId } from './router.actions';
import { routeTo } from '@frontend/data-access/router';
import { Store } from '@ngrx/store';
import { stepsFeature } from '@frontend/data-access/contentful';

@Injectable()
export class RouterEffects {
    private readonly actions$ = inject(Actions);
    private readonly store = inject(Store);

    routeToStep$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(routeToStep),
            map(({ baseUrl, stepId, from, fromPracticeStep }) => {
                return routeTo({
                    commands: [...baseUrl, 'step', stepId],
                    extras: {
                        queryParams: { from: from, fromPracticeStep: fromPracticeStep ?? false },
                    },
                });
            }),
        );
    });

    routeToStepUsingContentId$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(routeToStepUsingContentId),
            concatLatestFrom(() => {
                return this.store.select(stepsFeature.selectSteps);
            }),
            filter(([{ contentId }, steps]) => {
                return !!steps.find((step) => step.content.id === contentId)?.id;
            }),
            map(([{ baseUrl, contentId, from, fromPracticeStep }, steps]) => {
                const stepId = steps.find((step) => step.content.id === contentId)!.id;
                return routeToStep({
                    baseUrl,
                    stepId,
                    from,
                    fromPracticeStep,
                });
            }),
        );
    });
}
