import { selectUrlLastChild } from '@frontend/data-access/router';
import { accountFeature, selectIsUsUser } from '@frontend/data-access/user/account';
import { createSelector } from '@ngrx/store';
import { differenceInWeeks } from 'date-fns';
import {
    NUMBER_OF_ONBOARDING_PAGES,
    NUMBER_OF_ONBOARDING_PAGES_US,
    ONBOARDING_ROUTES_CHECKPOINTS,
    ONBOARDING_ROUTES_CHECKPOINTS_US,
    ONBOARDING_ROUTES_PROGRESS,
    ONBOARDING_ROUTES_PROGRESS_US,
} from '../constants/onboarding.constants';
import { OnboardingRoutes } from '../onboarding-routing.model';

export const selectOnboardingProgress = createSelector(selectUrlLastChild, selectIsUsUser, (lastChild, isUSUser) => {
    if (!lastChild) {
        return 0;
    }

    const progressUS = ONBOARDING_ROUTES_PROGRESS_US[lastChild] / NUMBER_OF_ONBOARDING_PAGES_US;
    const progressROW = ONBOARDING_ROUTES_PROGRESS[lastChild] / NUMBER_OF_ONBOARDING_PAGES;

    return isUSUser ? progressUS : progressROW;
});

export const selectOnboardingPreviousPage = createSelector(
    selectUrlLastChild,
    selectIsUsUser,
    (currentRoute, isUsUser) => {
        if (!currentRoute) {
            return undefined;
        }

        // Special case for topic extra
        if (currentRoute === OnboardingRoutes.TOPIC_EXTRA) {
            return OnboardingRoutes.TOPIC;
        }

        const routes = isUsUser ? ONBOARDING_ROUTES_CHECKPOINTS_US : ONBOARDING_ROUTES_CHECKPOINTS;

        const indexOfCurrentRoute = Object.keys(routes).indexOf(currentRoute);

        for (let i = indexOfCurrentRoute - 1; i >= 0; i--) {
            const route = Object.keys(routes)[i];

            if (routes[route]) {
                return route;
            }
        }

        return undefined; // if we can't find a previous route, we're at the beginning
    },
);

export const selectOnboardingGuardPage = createSelector(accountFeature.selectAccountState, ({ profile }) => {
    // assumes:
    // we have already waited for a profile call to complete
    // date of onboarding has already been checked and user let through if it was defined

    // determine progress through onboarding otherwise
    if (!profile?.name) {
        return OnboardingRoutes.NAME;
    }

    if (profile.isRescuePup === undefined) {
        return OnboardingRoutes.RESCUE;
    }

    if (profile.dateOfBirth === undefined) {
        return OnboardingRoutes.AGE;
    }

    if (profile.hasArrived === undefined) {
        return OnboardingRoutes.HAS_ARRIVED;
    }

    if (!profile.hasArrived && profile.dateOfArrival === undefined) {
        return OnboardingRoutes.ARRIVAL;
    }

    if (
        profile.hasArrived &&
        differenceInWeeks(new Date(), profile.dateOfBirth) <= 9 &&
        profile.dateOfArrival === undefined
    ) {
        return OnboardingRoutes.FIRST_DAY_AT_HOME;
    }

    if (profile.hasArrived && differenceInWeeks(new Date(), profile.dateOfBirth) >= 9 && profile.gender === undefined) {
        return OnboardingRoutes.GENDER;
    }

    if (profile.gender === undefined) {
        return OnboardingRoutes.GENDER;
    }

    if (profile.breedId === undefined) {
        return OnboardingRoutes.BREED;
    }

    // We don't store anything beyond this point, so we'll just go here
    return OnboardingRoutes.OWNER_NAME;
});
