import { InternalIterablePostEventCommand } from '../../commands/internal-api/third-party/iterable/internal-iterable-post-event.command';

export class InternalIterablePostEventCommandMock {
    private defaultValue: InternalIterablePostEventCommand = {
        userId: 'user-id',
        eventName: '[Event Source] Event Name',
        createdAt: 1,
        dataFields: {
            important: 'value',
        },
    };

    constructor(overrides?: Partial<InternalIterablePostEventCommand>) {
        Object.assign(this.defaultValue, overrides);
    }

    public get value(): InternalIterablePostEventCommand {
        return this.defaultValue;
    }
}
