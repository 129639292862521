import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';
import { signInClicked } from './landing.page.actions';
import { routeTo } from '@frontend/data-access/router';

@Injectable()
export class LandingPageEffects {
    private readonly actions$ = inject(Actions);
    routeToLoginAfterSignInClicked$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(signInClicked),
            map(() => routeTo({ commands: ['auth', 'login'] })),
        );
    });
}
