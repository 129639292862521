<app-slider [gap]="12">
    @for (card of suggestedQuestionsCards; track card.identifier) {
        <app-option-selector-square
            #questionCards
            [title]="card.title"
            [background]="card.background"
            [imageUrl]="card.imageUrl"
            [options]="card.options"
            [selected]="selectedOption()"
            (selectedChange)="selectedOptionChanged(card, $event)"
        />
    }
</app-slider>
