import { createSelector } from '@ngrx/store';
import { onboardingFeature } from '../../data-access/onboarding.reducer';
import { selectOnboardingProgress } from '../../data-access/onboarding.selectors';
import { accountFeature } from '@frontend/data-access/user/account';

export const selectQuestionFirstDayAtHomePageVm = createSelector(
    selectOnboardingProgress,
    accountFeature.selectDogName,
    onboardingFeature.selectIsLoading,
    (progress, dogName, isLoading) => {
        return {
            progress,
            dogName,
            isLoading,
        };
    },
);
