import { Component, computed, inject, signal, Signal } from '@angular/core';
import { PurchaseProduct, purchaseProduct, restoreSubscription } from '@frontend/data-access/subscription';
import { TextReplacementPipe } from '@frontend/data-access/text-replacement';
import { AnalyticsTrackClickDirective, HeaderComponent } from '@frontend/ui';
import { IonButton, IonContent, IonFooter, IonHeader, IonToolbar, NavParams } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { closePaymentModalClicked, openIosDiscountProducts } from '../../store/payment/store/payment.actions';
import { PaymentModalProductComponent } from '../payment-modal-product/payment-modal-product.component';
import { selectPaymentModalVm } from '../payment-modal.selectors';

@Component({
    standalone: true,
    selector: 'app-payment-modal-parent',
    templateUrl: './payment-modal-parent.component.html',
    styleUrls: ['./payment-modal-parent.component.scss'],
    imports: [
        AnalyticsTrackClickDirective,
        HeaderComponent,
        IonButton,
        IonContent,
        IonFooter,
        IonHeader,
        IonToolbar,
        PaymentModalProductComponent,
        TextReplacementPipe,
    ],
})
export class PaymentModalParentComponent {
    private readonly store = inject(Store);
    private readonly navParams = inject(NavParams);

    public showDiscountProducts = this.navParams.data['showDiscountProducts'] ?? false;

    public vm = this.store.selectSignal(selectPaymentModalVm);

    public initialSelectedProduct: Signal<PurchaseProduct> = computed(() => {
        if (this.showDiscountProducts) {
            return {
                identifier: this.vm().discountProducts?.[0]?.identifier,
                priceWithUnit: this.vm().discountProducts?.[0]?.priceWithUnit,
            };
        }

        return {
            identifier: this.vm().products?.[0]?.identifier,
            priceWithUnit: this.vm().products?.[0]?.priceWithUnit,
        };
    });

    public selectedProduct = signal<PurchaseProduct>(this.initialSelectedProduct());

    public onCloseClick(): void {
        this.store.dispatch(closePaymentModalClicked());
    }

    public onProductClick(product: PurchaseProduct): void {
        this.selectedProduct.set(product);
    }

    public onPurchaseClick(product: PurchaseProduct): void {
        if (this.showDiscountProducts) {
            this.store.dispatch(openIosDiscountProducts());
        } else {
            this.store.dispatch(purchaseProduct({ data: product }));
        }
    }

    public onRestoreClick(): void {
        this.store.dispatch(restoreSubscription());
    }
}
