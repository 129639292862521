import { Component, computed, inject, input, model } from '@angular/core';
import { purchaseProduct } from '@frontend/data-access/subscription';
import { TextReplacementPipe } from '@frontend/data-access/text-replacement';
import { AnalyticsTrackClickDirective, HeaderComponent } from '@frontend/ui';
import { IonButton, IonContent, IonFooter, IonHeader, IonToolbar } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { Color } from '@shared/utils/typescript';
import { closePaymentModalLimitedOfferClicked } from '../../store/payment/store/payment.actions';
import { PaymentModalLimitedOfferProductComponent } from '../payment-modal-limited-offer-product/payment-modal-limited-offer-product.component';
import { PaymentModalProductComponent } from '../payment-modal-product/payment-modal-product.component';
import { PaymentProductCard } from '../payment-modal-product/payment-modal-product.model';

@Component({
    selector: 'app-payment-modal-limited-offer',
    templateUrl: './payment-modal-limited-offer.component.html',
    styleUrls: ['./payment-modal-limited-offer.component.scss'],
    standalone: true,
    imports: [
        IonContent,
        IonToolbar,
        IonHeader,
        HeaderComponent,
        TextReplacementPipe,
        AnalyticsTrackClickDirective,
        IonButton,
        IonFooter,
        PaymentModalLimitedOfferProductComponent,
        PaymentModalProductComponent,
    ],
})
export class PaymentModalLimitedOfferComponent {
    private readonly store = inject(Store);

    public products = input<PaymentProductCard[]>([]);

    public selectedProductId = model<string>('');

    public discount = computed(() => {
        const discountedProduct = this.products().find((product) => !!product.discount);

        if (!discountedProduct) {
            return undefined;
        }

        return discountedProduct.discount?.discountPercentage;
    });
    public selectedPurchaseProduct = computed(() => {
        const selectedProduct = this.products().find((product) => product.identifier === this.selectedProductId());

        if (!selectedProduct) {
            return undefined;
        }

        return {
            identifier: selectedProduct.identifier,
            priceWithUnit: selectedProduct.priceWithUnit,
        };
    });

    protected readonly Color = Color;

    public onCloseModalClick(): void {
        this.store.dispatch(closePaymentModalLimitedOfferClicked());
    }

    public onProductClick(id: string): void {
        this.selectedProductId.set(id);
    }

    public onStartTrialClick(): void {
        if (!this.selectedPurchaseProduct()) {
            return;
        }

        this.store.dispatch(
            purchaseProduct({
                data: this.selectedPurchaseProduct()!,
            }),
        );
    }
}
