import { differenceInDays, differenceInMonths, differenceInWeeks } from 'date-fns';
import { ValidationErrors } from 'ngrx-forms';
import { StringUnion } from './string-union.utils';

const unitsOfTime = StringUnion('Year', 'Month', 'Date', 'Hours', 'Minutes', 'Seconds', 'Milliseconds');
export type UnitOfTime = typeof unitsOfTime.type;

export function toUTC(date: Date, precision: UnitOfTime = 'Milliseconds'): Date {
    const utc = {
        Year: date.getUTCFullYear(),
        Month: date.getUTCMonth(),
        Date: date.getUTCDate(),
        Hours: date.getUTCHours(),
        Minutes: date.getUTCMinutes(),
        Seconds: date.getUTCSeconds(),
        Milliseconds: date.getUTCMilliseconds(),
    };

    let precisionReached = false;

    const utcWithPrecision = unitsOfTime.values
        .filter((unit) => {
            if (precision === unit) {
                precisionReached = true;

                return true;
            }
            return !precisionReached;
        })
        .reduce(
            (dateWithPrecision, unit) => ({ ...dateWithPrecision, [unit]: utc[unit] }),
            {} as Record<string, number>,
        );

    // @ts-expect-error bit unsafe, but it does work fine
    return new Date(Date.UTC(...Object.values(utcWithPrecision)));
}

export const mapDateOfBirthToAgeInDays = (
    dateOfBirth: Date | undefined | string,
    dateOfReference: Date = new Date(),
): number => {
    if (dateOfBirth === undefined) {
        return 0;
    }

    let dateOfBirthAsDate: Date;

    if (typeof dateOfBirth === 'string') {
        dateOfBirthAsDate = new Date(dateOfBirth);
    } else {
        dateOfBirthAsDate = dateOfBirth;
    }

    return differenceInDays(dateOfReference, dateOfBirthAsDate);
};

export const mapDateOfBirthToAgeInWeeks = (
    dateOfBirth: Date | undefined | string,
    dateOfReference: Date = new Date(),
): number => {
    if (dateOfBirth === undefined) {
        return 0;
    }

    let dateOfBirthAsDate: Date;

    if (typeof dateOfBirth === 'string') {
        dateOfBirthAsDate = new Date(dateOfBirth);
    } else {
        dateOfBirthAsDate = dateOfBirth;
    }

    return differenceInWeeks(dateOfReference, dateOfBirthAsDate);
};

export const mapDateOfBirthToAgeInMonths = (
    dateOfBirth: Date | undefined | string,
    dateOfReference: Date = new Date(),
): number => {
    if (dateOfBirth === undefined) {
        return 0;
    }

    let dateOfBirthAsDate: Date;

    if (typeof dateOfBirth === 'string') {
        dateOfBirthAsDate = new Date(dateOfBirth);
    } else {
        dateOfBirthAsDate = dateOfBirth;
    }

    return differenceInMonths(dateOfReference, dateOfBirthAsDate);
};

export const getDayName = (date: Date, locale: string): string => {
    return date.toLocaleDateString(locale, { weekday: 'short' });
};

export const getDateInStreamChatFormat = (date: Date): string => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
    const seconds = String(date.getUTCSeconds()).padStart(2, '0');

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}+00:00`;
};

export const formatCountdown = (timestamp: number) => {
    const hours = Math.floor(timestamp / (1000 * 60 * 60));
    const minutes = Math.floor((timestamp % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timestamp % (1000 * 60)) / 1000);

    const paddedHours = String(hours).padStart(2, '0');
    const paddedMinutes = String(minutes).padStart(2, '0');
    const paddedSeconds = String(seconds).padStart(2, '0');

    return `${paddedHours}:${paddedMinutes}:${paddedSeconds}`;
};

export const addDaysAndConvertToEpochMs = (numberOfDays: number, dateToAdd: Date): number => {
    return new Date(dateToAdd.getTime() + 1000 * 60 * 60 * 24 * numberOfDays).getTime();
};

export const maxDate =
    (date: Date) =>
    (value: string | null | undefined): ValidationErrors => {
        if (!value || stripTime(value) > stripTime(date)) {
            return { invalidMaxDate: true } as ValidationErrors;
        }

        return {};
    };

export const minDate =
    (date: Date) =>
    (value: string | null | undefined): ValidationErrors => {
        if (!value || stripTime(value) < stripTime(date)) {
            return { invalidMinDate: true } as ValidationErrors;
        }

        return {};
    };

const stripTime = (date: Date | string): Date => {
    const newDate = new Date(date).setHours(12, 0, 0, 0);
    return new Date(newDate);
};
