import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { ContentService } from '../../content.service';
import { getTopics, getTopicsFailure, getTopicsSuccess } from './topic.actions';

@Injectable()
export class TopicEffects {
    private readonly actions$ = inject(Actions);
    private readonly contentService = inject(ContentService);
    getTopics$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getTopics),
            switchMap(({ params }) => {
                return this.contentService.getTopics(params).pipe(
                    map((topics) => {
                        return getTopicsSuccess({ topics });
                    }),
                    catchError((error: Error) => {
                        console.error(error);
                        return of(getTopicsFailure({ error }));
                    }),
                );
            }),
        );
    });
}
