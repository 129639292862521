import { createAction, props } from '@ngrx/store';
import { Profile } from '../../models/profile.model';

export const getAccount = createAction('[Account] Get Account');

export const getAccountSuccess = createAction(
    '[Account] Get Account Success',
    props<{ profile: Profile; profileId: string }>(),
);

export const getAccountFailure = createAction('[Account] Get Account Failure', props<{ error: Error }>());

export const createEmptyUser = createAction('[Account] Get New User Id');

export const createEmptyUserSuccess = createAction(
    '[Account] Create Empty User Success',
    props<{ profileId: string }>(),
);

export const createEmptyUserFailure = createAction('[Account] Create Empty User Failure', props<{ error: Error }>());
