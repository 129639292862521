import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BASE_ENVIRONMENT_CONFIG, BaseEnvironmentConfig } from '@frontend/configuration';
import { httpOptions } from '@frontend/utility/constants';
import { internalEndpoints } from '@shared/user-api-interface';
import { Observable } from 'rxjs';
import { PromotionalEntitlement } from '../store/models/subscription.model';

@Injectable({
    providedIn: 'root',
})
export class RevenueCatSubscriptionApiService {
    constructor(
        private http: HttpClient,
        @Inject(BASE_ENVIRONMENT_CONFIG) private config: BaseEnvironmentConfig,
    ) {}

    public grantPromotionalEntitlement(command: PromotionalEntitlement): Observable<boolean> {
        return this.http.post<boolean>(
            `${this.config.internalApiUrl}${internalEndpoints.subscriptionsCollection}/grant-promotional-access`,
            command,
            httpOptions,
        );
    }
}
