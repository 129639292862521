<section class="radio-with-logic__radio-container">
    @for (button of buttons; track button.value) {
        <div
            class="radio-with-logic__button-container"
            [ngStyle]="{ top: button.value === selected?.value ? 0 : $index * 75 + 'px' }"
        >
            @if (selected === undefined || button.value === selected.value) {
                <ion-button
                    class="radio-with-logic__button"
                    [class.unselected]="button.value !== selected?.value"
                    [ngStyle]="{
                        '--background':
                            button.value === selected?.value
                                ? 'var(--ion-color-max)'
                                : 'var(--ion-color-app-background)',
                        '--border-color':
                            button.value === selected?.value
                                ? 'var(--ion-color-max)'
                                : 'var(--ion-color-disabled-text)',
                        '--color':
                            button.value === selected?.value ? 'var(--ion-color-max-contrast)' : 'var(--ion-color-jack)'
                    }"
                    expand="block"
                    fill="solid"
                    [disabled]="isDisabled()"
                    [attr.data-test]="'radio-button-' + button.value"
                    (click)="clickButton(button)"
                >
                    {{ button.value }}
                </ion-button>
            }
        </div>
    }
</section>
