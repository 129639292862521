import { AsyncPipe, NgClass } from '@angular/common';
import { Component, inject } from '@angular/core';
import { openPrivacy, openTerms, routeToPrevious } from '@frontend/data-access/router';
import { resetAccountErrorState } from '@frontend/data-access/user/account';
import { signUpWithEmailAndPassword } from '@frontend/data-access/user/authentication';
import { AnalyticsTrackClickDirective, HeaderComponent } from '@frontend/ui';
import { IonButton, IonContent, IonFooter, IonHeader, IonSpinner, IonToolbar } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { Color } from '@shared/utils/typescript';
import { CreateAccountFormComponent } from './create-account-form/create-account-form.component';
import { CreateAccountForm, signUpForm } from './create-account.model';
import { selectCreateAccountPageVm } from './create-account.page.selectors';

@Component({
    selector: 'app-onboarding-create-account',
    templateUrl: 'create-account.page.html',
    styleUrls: ['create-account.page.scss'],
    standalone: true,
    imports: [
        CreateAccountFormComponent,
        NgClass,
        AnalyticsTrackClickDirective,
        AsyncPipe,
        IonContent,
        IonFooter,
        IonButton,
        IonSpinner,
        HeaderComponent,
        IonHeader,
        IonToolbar,
    ],
})
export class CreateAccountPage {
    private readonly store = inject(Store);

    public vm$ = this.store.select(selectCreateAccountPageVm);
    public form = signUpForm;

    protected readonly Color = Color;

    public signUpClicked(email: string | undefined | null, password: string | undefined | null): void {
        if (!email || !password) {
            return;
        }

        this.store.dispatch(signUpWithEmailAndPassword({ email, password }));
    }

    public openPrivacy(countryCode: string): void {
        this.store.dispatch(openPrivacy({ countryCode }));
    }

    public openTerms(countryCode: string): void {
        this.store.dispatch(openTerms({ countryCode }));
    }

    public handleSignUpFormChange(event: CreateAccountForm): void {
        this.form = event;
    }

    public routeToPrevious(): void {
        this.form.reset();
        this.store.dispatch(resetAccountErrorState());
        void this.store.dispatch(routeToPrevious({}));
    }
}
