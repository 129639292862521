<app-header class="app-header--modal-small" [hasRightButton]="true" (rightButtonPressed)="onClickCloseModal()" />

<ion-content class="training-reminder-modal" [fullscreen]="true">
    <h2 class="training-reminder-modal__title">
        With training reminders, you are <span class="highlight">75%</span> more likely to achieve your goals
    </h2>

    <form [formGroup]="form" class="training-reminder-modal-datetime">
        <p class="training-reminder-modal-datetime__title">Select your best time to train</p>
        <ion-datetime
            class="training-reminder-modal-datetime__ion-datetime"
            formControlName="time"
            presentation="time"
            hourCycle="h12"
            size="cover"
        />
    </form>

    <ion-footer class="training-reminder-modal-footer page-footer">
        <ion-button
            color="max"
            expand="block"
            data-test="training-reminder-modal-add-to-calendar-btn"
            (click)="onClickAddToCalendar()"
        >
            Add to calendar
        </ion-button>
    </ion-footer>
</ion-content>
