import { createAction, props } from '@ngrx/store';
import { NavigationExtras } from '@angular/router';

export const routeToPrevious = createAction('[Router] Route to Previous', props<{ isAnimated?: boolean }>());
export const routeTo = createAction(
    '[Router] Route to',
    props<{ commands: any[]; extras?: NavigationExtras; isAnimated?: boolean }>(),
);

export const forceAppRefresh = createAction('[Router] Force App Refresh');

export const openPrivacy = createAction('[Router] Open Privacy', props<{ countryCode: string }>());

export const openTerms = createAction('[Router] Open Terms', props<{ countryCode: string }>());

export const openUrlWindow = createAction(
    '[Router] Open Url Window',
    props<{ url: string; openInNewWindow: boolean }>(),
);
