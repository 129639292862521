import { platformFeature } from '@frontend/data-access/platform';
import {
    selectIsNonNativeSubscriptionActive,
    subscriptionFeature,
    SubscriptionOffering,
    SubscriptionPeriodType,
} from '@frontend/data-access/subscription';
import { timeFeature } from '@frontend/data-access/time';
import { selectIsDevWithFreeContent } from '@frontend/data-access/user/config-cat';
import { createSelector } from '@ngrx/store';
import { differenceInHours } from 'date-fns';
import { REVENUE_CAT_OFFERINGS } from '../constants/payment.constants';

export const selectContentUnlockedEntitlement = createSelector(
    subscriptionFeature.selectEntitlements,
    (entitlements): boolean => {
        return entitlements.some((entitlement) => entitlement.identifier === 'content' && entitlement.isActive);
    },
);

export const selectContentUnlocked = createSelector(
    selectIsDevWithFreeContent,
    selectContentUnlockedEntitlement,
    selectIsNonNativeSubscriptionActive,
    (isDevWithFreeContent, contentUnlockedEntitlement, isSubscriptionActive) =>
        isDevWithFreeContent || contentUnlockedEntitlement || isSubscriptionActive,
);

export const selectDefaultOfferingAvailablePackages = createSelector(
    subscriptionFeature.selectDefaultOffering,
    (offering: SubscriptionOffering | undefined) => {
        return offering?.availablePackages;
    },
);

export const selectIncreasedUkPriceForDiscountPaywallAbTestJuly2024OfferingAvailablePackages = createSelector(
    subscriptionFeature.selectOfferings,
    (offerings) => {
        return offerings.find(
            (offering) => offering.identifier === REVENUE_CAT_OFFERINGS.DISCOUNT_PAYWALL_UK_PRICE_INCREASE,
        )?.availablePackages;
    },
);

export const selectDefaultOfferingAvailablePackagesSortedByPrice = createSelector(
    selectDefaultOfferingAvailablePackages,
    (availablePackages) =>
        availablePackages ? [...availablePackages].sort((a, b) => b.product.price - a.product.price) : [],
);

export const selectIncreasedUkPriceForDiscountPaywallAbTestJuly2024OfferingAvailablePackagesSortedByPrice =
    createSelector(
        selectIncreasedUkPriceForDiscountPaywallAbTestJuly2024OfferingAvailablePackages,
        (availablePackages) =>
            availablePackages ? [...availablePackages].sort((a, b) => b.product.price - a.product.price) : [],
    );

export const selectIsEligibleForDiscountPaymentModal = createSelector(
    subscriptionFeature.selectEntitlements,
    selectDefaultOfferingAvailablePackagesSortedByPrice,
    platformFeature.selectPlatformIsIos,
    timeFeature.selectToday,
    (entitlements, offeringPackages, isIos, today): boolean => {
        const hasActiveSubscription = entitlements.some(
            (entitlement) => entitlement.identifier === 'content' && entitlement.isActive,
        );
        const isMoreThan24HoursExpiredTrial = entitlements.some(
            (entitlement) =>
                entitlement.identifier === 'content' &&
                entitlement.periodType === SubscriptionPeriodType.TRIAL &&
                entitlement.expirationDate &&
                differenceInHours(today, entitlement.expirationDate) >= 24,
        );
        const hasDiscountProduct = offeringPackages.filter((aPackage) => aPackage.product.discount).length > 0;

        return isIos && !hasActiveSubscription && isMoreThan24HoursExpiredTrial && hasDiscountProduct;
    },
);
