import { Injectable, inject } from '@angular/core';
import { getGeoLocation } from '@frontend/data-access/geo-location';
import { accountFeature, getAccountFailure, getAccountSuccess } from '@frontend/data-access/user/account';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { filter, map } from 'rxjs/operators';
import { setUserMobileAppSentry } from '@frontend/data-access/sentry';
import { routeTo } from '@frontend/data-access/router';
import { getActiveSubscription, logInToPurchases } from '@frontend/data-access/subscription';

@Injectable()
export class GetAccountEffects {
    private readonly actions$ = inject(Actions);
    private readonly store = inject(Store);
    routeToAuth$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getAccountFailure),
            map(() => {
                return routeTo({ commands: ['auth'] });
            }),
        );
    });

    getSubscriptions$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getAccountSuccess),
            map(() => {
                return getActiveSubscription();
            }),
        );
    });

    loginToPurchases$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getAccountSuccess),
            map(({ profile, profileId }) => {
                return logInToPurchases({
                    profileId,
                    email: profile.mail,
                });
            }),
        );
    });

    addMissingCountryCode$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getAccountSuccess),
            concatLatestFrom(() => this.store.select(accountFeature.selectCountryCode)),
            filter(([, countryCode]) => !countryCode),
            map(() => getGeoLocation()),
        );
    });

    setSentryUser$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getAccountSuccess),
            map(({ profile, profileId }) => setUserMobileAppSentry({ userId: profileId, email: profile.mail })),
        );
    });
}
