import { AsyncPipe, Location, NgClass } from '@angular/common';
import { AfterViewInit, Component, OnDestroy, OnInit, TemplateRef, ViewChild, inject } from '@angular/core';
import { destroyChat, StreamChatHtmlMessagePipe } from '@frontend/data-access/chat';
import { HeaderComponent, LoadingScreenComponent, RightButtonHeaderType } from '@frontend/ui';
import { ChatChannelType } from '@shared/constants';
import {
    IonContent,
    IonHeader,
    IonSpinner,
    IonToolbar,
    Platform,
    ViewWillEnter,
    ViewWillLeave,
} from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { CustomTemplatesService, MessageContext, StreamChatModule, StreamMessage } from 'stream-chat-angular';
import { ChatErrorMessageComponent } from '../ai-chat/chat-error-message/chat-error-message.component';
import { ChatMessageRatingFeedbackComponent } from '../chat-message-rating-feedback/chat-message-rating-feedback.component';
import { ChatMessageRatingComponent } from '../chat-message-rating/chat-message-rating.component';
import { chatPageLoaded, userSentPuppyCoachMessage } from '../chat.actions';
import { TrainingCoachChatIntroComponent } from './chat-intro/training-coach-chat-intro.component';
import { selectTrainingCoachChatPageVm } from './training-coach-chat-page.selectors';

@Component({
    selector: 'app-training-coach-chat-page',
    templateUrl: './training-coach-chat-page.component.html',
    styleUrls: ['./training-coach-chat-page.component.scss'],
    standalone: true,
    imports: [
        TrainingCoachChatIntroComponent,
        StreamChatModule,
        LoadingScreenComponent,
        AsyncPipe,
        IonContent,
        ChatErrorMessageComponent,
        ChatMessageRatingComponent,
        ChatMessageRatingFeedbackComponent,
        IonSpinner,
        StreamChatHtmlMessagePipe,
        NgClass,
        HeaderComponent,
        IonHeader,
        IonToolbar,
    ],
})
export class TrainingCoachChatPage implements OnInit, AfterViewInit, OnDestroy, ViewWillEnter, ViewWillLeave {
    public readonly store = inject(Store);
    private readonly platform = inject(Platform);
    public readonly location = inject(Location);
    private readonly customTemplatesService = inject(CustomTemplatesService);
    @ViewChild('messageTemplate')
    private messageTemplate!: TemplateRef<MessageContext>;

    public vm$ = this.store.select(selectTrainingCoachChatPageVm);

    public RightButtonHeaderType = RightButtonHeaderType;

    private backButtonSub: Subscription = new Subscription();

    ngOnInit() {
        this.store.dispatch(chatPageLoaded({ chatType: ChatChannelType.AGENT }));
    }

    ngAfterViewInit() {
        this.customTemplatesService.messageTemplate$.next(this.messageTemplate);
    }

    ngOnDestroy() {
        this.store.dispatch(destroyChat());
    }

    ionViewWillEnter(): void {
        this.backButtonSub = this.platform.backButton.subscribeWithPriority(20, () => this.routeToPrevious());
    }

    ionViewWillLeave(): void {
        this.backButtonSub.unsubscribe();
    }

    routeToPrevious(): void {
        this.location.back();
    }

    messageSent(event: { message: StreamMessage }) {
        const attachments = event.message?.attachments?.map((attachment) => attachment.type) ?? [];
        this.store.dispatch(
            userSentPuppyCoachMessage({
                imagesAttached: attachments.filter((type) => type === 'image').length,
                videosAttached: attachments.filter((type) => type === 'video').length,
                audioAttached: attachments.filter((type) => type === 'audio').length,
            }),
        );
    }
}
