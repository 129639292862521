import { Routes } from '@angular/router';
import { AuthGuard } from '@frontend/data-access/user/authentication';
import { OnboardingCoursePreviewPage, tabRoutes } from '@frontend/zigzag-main';
import { OnboardingCompleteGuard, onboardingRoutes } from '@frontend/feature/onboarding';
import { authenticationRoutes, LogoutPage } from '@frontend/feature/authentication';

const appRoutes: Routes = [
    {
        path: '',
        redirectTo: 'main',
        pathMatch: 'full',
    },
    {
        path: 'auth',
        children: authenticationRoutes,
    },
    {
        path: 'onboarding',
        canActivate: [AuthGuard],
        children: onboardingRoutes,
    },
    {
        path: 'main',
        canActivate: [AuthGuard, OnboardingCompleteGuard],
        children: tabRoutes,
    },
    {
        path: 'course-preview',
        canActivate: [AuthGuard, OnboardingCompleteGuard],
        component: OnboardingCoursePreviewPage,
    },
    {
        path: 'logout',
        canActivate: [AuthGuard],
        component: LogoutPage,
        title: 'Logout',
    },
];

export default appRoutes;
