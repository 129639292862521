<app-onboarding-question-template
    backButtonId="question-special-needs-back-btn"
    title="Tell us about your situation"
    subtitle="We will adjust the program to your personal situation"
    [progress]="vm().progress"
    (backButtonPressed)="routeToPrevious()"
>
    <ng-container body>
        <section class="multi-select-container">
            <app-multi-select id="multiSelect" [multiSelectOptions]="options()" (valueChanged)="options.set($event)" />
        </section>
    </ng-container>
    <ng-container footer>
        <ion-button
            appTrackClick
            identifier="question-needs-next-btn"
            expand="block"
            data-test="question-needs-next-btn"
            [color]="Color.Max"
            (click)="continue()"
            >{{ nextButtonText() }}
        </ion-button>
    </ng-container>
</app-onboarding-question-template>
