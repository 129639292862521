import { Color } from '@shared/utils/typescript';
import { chatFeature } from '@frontend/data-access/chat';
import { selectIsAIChatEnabled } from '@frontend/data-access/user/config-cat';
import { createSelector } from '@ngrx/store';
import { selectContentUnlocked } from '../store/payment/store/payment.selectors';
import { selectIsUsUser } from '@frontend/data-access/user/account';

export const selectSupportPageVm = createSelector(
    selectContentUnlocked,
    selectIsUsUser,
    selectIsAIChatEnabled,
    chatFeature.selectIsDestroying,
    (isContentUnlocked, isUsUser, isAIChatEnabled, isStreamChatBusyCleaningUp) => {
        const tiles = [];

        const aiChat = {
            imageUrl: '/assets/images/ziggy/ziggy-close-up.svg',
            title: 'Ask Ziggy',
            backgroundColor: Color.Sophie,
            cardName: 'ai-chat',
            isContentUnlocked: true,
        };

        const expertSupport = {
            imageUrl: '/assets/images/trainers/coach-petrina-red-circle.png',
            title: 'Chat with a training coach',
            backgroundColor: Color.Harley,
            cardName: 'expertsupport',
            isContentUnlocked,
        };

        if (isAIChatEnabled) {
            tiles.push(aiChat);
        }

        tiles.push(expertSupport);

        return {
            tiles,
            isUsUser,
            isAIChatEnabled,
            isStreamChatBusyCleaningUp,
        };
    },
);
