import { Component, EventEmitter, HostBinding, input, Input, Output } from '@angular/core';
import { IonButton, IonButtons, IonIcon, IonProgressBar, IonTitle, IonToolbar } from '@ionic/angular/standalone';
import { addIcons } from 'ionicons';
import { arrowBackOutline, close } from 'ionicons/icons';
import { AnalyticsTrackClickDirective } from '../directives/tracking/analytics-track-click.directive';
import { RightButtonHeaderType } from './header.model';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    standalone: true,
    imports: [AnalyticsTrackClickDirective, IonButton, IonButtons, IonIcon, IonProgressBar, IonTitle, IonToolbar],
})
export class HeaderComponent {
    @Input()
    public title = '';
    @Input()
    public progressRatio: number | undefined = undefined;

    @HostBinding('style.--button-background')
    @Input()
    public buttonBackground = 'none';
    @HostBinding('style.--toolbar-background')
    @Input()
    public toolbarBackground = 'none';

    public isDisabled = input(false);

    @Input()
    public hasLeftButton = false;
    @Input()
    public identifierLeftButton = '';

    @Input()
    public hasRightButton = false;
    @Input()
    public identifierRightButton = '';
    @Input()
    public rightButton: {
        type: RightButtonHeaderType;
        content: string;
        color?: string;
        pinned?: boolean;
    } = {
        type: RightButtonHeaderType.ION_ICON,
        content: 'close',
    };

    @Input()
    public shouldTrackEvents = true;

    @Output()
    public leftButtonPressed = new EventEmitter();
    @Output()
    public rightButtonPressed = new EventEmitter();

    public RightButtonHeaderType = RightButtonHeaderType;

    constructor() {
        addIcons({ close, arrowBackOutline });
    }

    public rightButtonAction(): void {
        if (this.isDisabled()) {
            return;
        }

        this.rightButtonPressed.emit();
    }

    public leftButtonAction(): void {
        if (this.isDisabled()) {
            return;
        }

        this.leftButtonPressed.emit();
    }
}
