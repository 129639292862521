import { AsyncPipe, NgStyle } from '@angular/common';
import { Component, inject, Input } from '@angular/core';
import { TextReplacementPipe } from '@frontend/data-access/text-replacement';
import { AnalyticsTrackClickDirective, HeaderComponent, RichContentPipe } from '@frontend/ui';
import { ModalService } from '@frontend/utility/modal';
import { IonButton, IonContent, IonFooter, IonHeader, IonToolbar } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { isColorContrastLight } from '@shared/utils/typescript';
import { CourseStatus } from '../../store/course/course.model';
import { showPaymentModal } from '../../store/payment/store/payment.actions';
import { setCourseSlider } from '../course-slider/course-slider.actions';
import { CourseWithSteps } from '../models/courses.models';
import { startCourseClicked } from './course-information-modal.actions';
import { selectCourseInformationModalVm } from './course-information-modal.selectors';

@Component({
    selector: 'app-course-information-modal',
    templateUrl: './course-information-modal.component.html',
    styleUrls: ['./course-information-modal.component.scss'],
    standalone: true,
    imports: [
        IonContent,
        NgStyle,
        AnalyticsTrackClickDirective,
        AsyncPipe,
        RichContentPipe,
        TextReplacementPipe,
        IonFooter,
        IonButton,
        HeaderComponent,
        IonHeader,
        IonToolbar,
    ],
})
export class CourseInformationModalComponent {
    private readonly modalService = inject(ModalService);
    private readonly store = inject(Store);
    @Input() course!: CourseWithSteps;
    public courseStatus = CourseStatus;
    public vm$ = this.store.select(selectCourseInformationModalVm);

    public readonly squiggleDarkSvg =
        'url(/assets/images/squiggles/course-information-squiggle-dark.svg) no-repeat top -50px right 0px';
    public readonly squiggleLightSvg =
        'url(/assets/images/squiggles/course-information-squiggle-light.svg) no-repeat top -50px right 0px';

    goToCourse(startCourse: boolean): void {
        if (startCourse) {
            this.store.dispatch(startCourseClicked({ courseId: this.course.id }));
        } else {
            this.store.dispatch(setCourseSlider({ courseId: this.course.id }));
        }
        this.closeModal();
    }

    closeModal(): void {
        void this.modalService.dismiss({
            dismissed: true,
        });
    }

    openPaymentModal() {
        this.store.dispatch(showPaymentModal({ trigger: 'course-information-modal' }));
    }

    public getDynamicallyAppliedStyles() {
        return {
            background: isColorContrastLight(this.course.color) ? this.squiggleLightSvg : this.squiggleDarkSvg,
        };
    }
}
