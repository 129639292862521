import { AsyncPipe, NgStyle } from '@angular/common';
import { Component, inject } from '@angular/core';
import { AnalyticsTrackClickDirective } from '@frontend/ui';
import { IonButton, IonCard, IonSkeletonText } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { showJumpAheadModal } from '../jump-ahead-modal/jump-ahead-modal.actions';
import { CourseSplitWithCoordinates } from '../store/models/course-path.model';
import { selectCoursePathSplitComponentVm } from './course-path-split.component.selectors';

@Component({
    selector: 'app-course-path-split',
    templateUrl: './course-path-split.component.html',
    styleUrls: ['course-path-split.component.scss'],
    standalone: true,
    imports: [IonCard, NgStyle, AnalyticsTrackClickDirective, AsyncPipe, IonSkeletonText, IonButton],
})
export class CoursePathSplitComponent {
    private readonly store = inject(Store);
    public vm$ = this.store.select(selectCoursePathSplitComponentVm);

    jumpAheadClicked(splitWithCoordinates: CourseSplitWithCoordinates) {
        const { coordinates, ...split } = splitWithCoordinates;
        this.store.dispatch(showJumpAheadModal({ split }));
    }

    trackByFn(_: number, split: CourseSplitWithCoordinates): number {
        return split.splitIndex;
    }
}
