<ion-card [disabled]="disabled()" class="{{ background() }} slim-card">
    <div
        class="slim-card__container"
        [ngClass]="{
            'slim-card__container--locked': !unlocked()
        }"
    >
        <img class="slim-card__img" [src]="imageUrl()" />
        <h2 [ngStyle]="{ color: 'var(--ion-color-' + background() + '-contrast)' }">
            {{ title() }}
        </h2>
        @if (!unlocked()) {
            <ion-icon class="slim-card__locked-icon" name="lock-closed" data-test="slim-card-locked-icon" />
        }
    </div>
</ion-card>
