export enum AgeAlternativeValues {
    NOT_BORN = 'Not born yet',
    OLDER = 'Older than 15 weeks',
}

export enum DogColor {
    BLACK = 'Black',
    YELLOW = 'Yellow',
    CHOCOLATE = 'Chocolate',
}

export enum BreedType {
    RETRIEVERS = 'Retrievers',
    GUNDOGS = 'GunDogs',
    PASTORALDOGS = 'PastoralDogs',
    WORKINGDOGS = 'WorkingDogs',
    TERRIER = 'Terrier',
    SIGHTHOUNDS = 'SightHounds',
    SCENTHOUNDS = 'ScentHounds',
    TOYDOGS = 'ToyDogs',
    GUARDIANDOGS = 'GuardianDogs',
    BULLDOGS = 'BullDogs',
}

export enum Breed {
    AFFENPINSCHER = 'Affenpinscher',
    AFGHAN_HOUND = 'Afghan Hound',
    AFRICANIS = 'Africanis',
    AIDI = 'Aidi',
    AIREDALE_TERRIER = 'Airedale Terrier',
    AKITA = 'Akita',
    AMERICAN_AKITA = 'American Akita',
    JAPANESE_AKITA = 'Japanese Akita',
    ALASKAN_KLEE_KAI = 'Alaskan Klee Kai',
    ALASKAN_MALAMUTE = 'Alaskan Malamute',
    ALPINE_DACHSBRACKE = 'Alpine Dachsbracke',
    AMERICAN_BULLDOG = 'American Bulldog',
    AMERICAN_COCKER_SPANIEL = 'American Cocker Spaniel',
    AMERICAN_ENGLISH_COONHOUND = 'American English Coonhound',
    AMERICAN_ESKIMO_DOG = 'American Eskimo Dog',
    AMERICAN_FOXHOUND = 'American Foxhound',
    AMERICAN_HAIRLESS_TERRIER = 'American Hairless Terrier',
    AMERICAN_LEOPARD_HOUND = 'American Leopard Hound',
    AMERICAN_PIT_BULL_TERRIER = 'American Pit Bull Terrier',
    AMERICAN_STAFFORDSHIRE_TERRIER = 'American Staffordshire Terrier',
    AMERICAN_WATER_SPANIEL = 'American Water Spaniel',
    ANATOLIAN_SHEPHERD_DOG = 'Anatolian Shepherd Dog',
    ANGLO_FRANCAIS_DE_PETITE_VENERIE = 'Francais de Petite Venerie',
    APPENZELLER_MOUNTAIN_DOG = 'Appenzeller Mountain Dog',
    ARIEGE_POINTING_DOG = 'Ariege Pointing Dog',
    ARIEGEOIS = 'Ariegeois',
    ARTOIS_HOUND = 'Artois Hound',
    AUSTRALIAN_CATTLE_DOG = 'Australian Cattle Dog',
    AUSTRALIAN_KELPIE = 'Australian Kelpie',
    AUSTRALIAN_SHEPHERD = 'Australian Shepherd',
    AUSTRALIAN_SILKY_TERRIER = 'Australian Silky Terrier',
    AUSTRALIAN_STUMPY_TAIL_CATTLE_DOG = 'Australian Stumpy Tail Cattle Dog',
    AUSTRALIAN_TERRIER = 'Australian Terrier',
    AUSTRIAN_BLACK_AND_TAN_HOUND = 'Austrian Black and Tan Hound',
    AUSTRIAN_PINSCHER = 'Austrian Pinscher',
    AUVERGNE_POINTER = 'Auvergne Pointer',
    AZAWAKH = 'Azawakh',
    BARAK_HOUND = 'Barak Hound',
    BARBADO_DA_TERCEIRA = 'Barbado da Terceira',
    BARBET = 'Barbet',
    BASENJI = 'Basenji',
    BASSET_ARTESIEN_NORMAND = 'Basset Artesien Normand',
    BASSET_BLEU_DE_GASCOGNE = 'Basset Bleu De Gascogne',
    BASSET_FAUVE_DE_BRETAGNE = 'Basset Fauve De Bretagne',
    BASSET_GRIFFON_VENDEEN_GRAND = 'Grand Basset Griffon Vendeen',
    BASSET_GRIFFON_VENDEEN_PETIT = 'Petit Basset Griffon Vendeen',
    BASSET_HOUND = 'Basset Hound',
    BAVARIAN_MOUNTAIN_HOUND = 'Bavarian Mountain Hound',
    BEAGLE = 'Beagle',
    BEAGLE_HARRIER = 'Beagle Harrier',
    BEAGLIER = 'Beaglier',
    BEARDED_COLLIE = 'Bearded Collie',
    BEAUCERON = 'Beauceron',
    BEDLINGTON_TERRIER = 'Bedlington Terrier',
    BELGIAN_SHEPHERD_DOG_GROENENDAEL = 'Groenendael Belgian Shepherd',
    BELGIAN_SHEPHERD_DOG_LAEKENOIS = 'Laekenois Belgian Shepherd',
    BELGIAN_SHEPHERD_DOG_MALINOIS = 'Malinois Belgian Shepherd',
    BELGIAN_SHEPHERD_DOG_TERVUEREN = 'Tervueren Belgian Shepherd',
    BERGAMASCO = 'Bergamasco',
    BERGER_PICARD = 'Berger Picard',
    BERNADOODLE = 'Bernadoodle',
    BERNESE_MOUNTAIN_DOG = 'Bernese Mountain Dog',
    BICHON_FRISE = 'Bichon Frise',
    BIEWER_TERRIER = 'Biewer Terrier',
    BILLY = 'Billy',
    BLACK_TAN_COONHOUND = 'Black and Tan Coonhound',
    BLACK_NORWEGIAN_ELKHOUND = 'Black Norwegian Elkhound',
    BLOODHOUND = 'Bloodhound',
    BLUE_PICARDY_SPANIEL = 'Blue Picardy Spaniel',
    BLUETICK_COONHOUND = 'Bluetick Coonhound',
    BOERBOEL = 'Boerboel',
    BOHEMIAN_SHEPHERD = 'Bohemian Shepherd',
    BOLOGNESE = 'Bolognese',
    BOLONKA = 'Bolonka',
    BORADOR = 'Borador',
    BORDER_COLLIE = 'Border Collie',
    BORDER_TERRIER = 'Border Terrier',
    BORZOI = 'Borzoi',
    BOSTON_TERRIER = 'Boston Terrier',
    BOURBONNAIS_POINTING_DOG = 'Bourbonnais Pointing Dog',
    BOUVIER_DES_ARDENNES = 'Bouvier des Ardennes',
    BOUVIER_DES_FLANDRES = 'Bouvier Des Flandres',
    BOXER = 'Boxer',
    BOYKIN_SPANIEL = 'Boykin Spaniel',
    BRACCO_ITALIANO = 'Bracco Italiano',
    BRAQUE_DAUVERGNE = 'Braque D’Auvergne',
    BRAQUE_DU_BOURBONNAIS = 'Braque du Bourbonnais',
    BRAQUE_FRANCAIS_PYRENEAN = 'Braque Francais Pyrenean',
    BRAZILIAN_MASTIFF = 'Brazilian Mastiff',
    BRAZILIAN_TERRIER = 'Brazilian Terrier',
    BRIARD = 'Briard',
    BRIQUET_GRIFFON_VENDEEN = 'Briquet Griffon Vendeen',
    BRITTANY = 'Brittany',
    BROHOLMER = 'Broholmer',
    BRUSSELS_GRIFFON = 'Brussels Griffon',
    BUCOVINA_SHEPHERD = 'Bucovina shepherd',
    BULL_ARAB = 'Bull Arab',
    ENGLISH_BULL_TERRIER = 'Bull Terrier',
    BULLDOG = 'Bulldog',
    BULLMASTIFF = 'Bullmastiff',
    BURGOS_POINTER = 'Burgos Pointer',
    CA_DE_BOU = 'Ca de Bou',
    CAIRN_TERRIER = 'Cairn Terrier',
    CANAAN_DOG = 'Canaan Dog',
    CANADIAN_ESKIMO_DOG = 'Canadian Eskimo Dog',
    ITALIAN_CANE_CORSO = 'Italian Cane Corso',
    CAROLINA_DOG = 'Carolina Dog',
    CARPATHIAN_SHEPHERD_DOG = 'Carpathian Shepherd Dog',
    CATAHOULA_LEOPARD_DOG = 'Catahoula Leopard Dog',
    CATALAN_SHEEPDOG = 'Catalan Sheepdog',
    CAUCASIAN_SHEPHERD_DOG = 'Caucasian Shepherd Dog',
    CAVACHON = 'Cavachon',
    CAVALIER_KING_CHARLES_SPANIEL = 'Cavalier King Charles Spaniel',
    CAVAPOO = 'Cavapoo',
    CAVAPOOCHON = 'Cavapoochon',
    CENTRAL_ASIAN_SHEPHERD_DOG = 'Central Asian Shepherd Dog',
    CESKY_FOUSEK = 'Cesky Fousek',
    CESKY_TERRIER = 'Cesky Terrier',
    CHESAPEAKE_BAY_RETRIEVER = 'Chesapeake Bay Retriever',
    CHIHUAHUA = 'Chihuahua',
    CHIHUAHUA_LONG_COAT = 'Long Coat Chihuahua',
    CHIHUAHUA_SMOOTH_COAT = 'Smooth Coat Chihuahua',
    CHINESE_CRESTED = 'Chinese Crested',
    CHINOOK = 'Chinook',
    CHIWENIE = 'Chiwenie',
    CHORKIE = 'Chorkie',
    CHOW_CHOW = 'Chow Chow',
    CHUG = 'Chug',
    CIMARRON_URUGUAYO = 'Cimarrón Uruguayo',
    CIRNECO_DELLETNA = "Cirneco Dell'Etna",
    CLUMBER_SPANIEL = 'Clumber Spaniel',
    COCKALIER = 'Cockalier',
    COCKAPOO = 'Cockapoo',
    COCKER_SPANIEL = 'Cocker Spaniel',
    COLLIE_ROUGH = 'Rough Collie',
    COLLIE_SMOOTH = 'Smooth Collie',
    CORGIDOR = 'Corgidor',
    COTON_DE_TULEAR = 'Coton De Tulear',
    CROATIAN_SHEEPDOG = 'Croatian Sheepdog',
    CURLY_COATED_RETRIEVER = 'Curly Coated Retriever',
    CZECHOSLOVAKIAN_WOLFDOG = 'Czechoslovakian Wolfdog',
    DACHSHUND = 'Dachshund',
    DACHSHUND_LONG_HAIRED = 'Long Haired Dachshund',
    DACHSHUND_MINIATURE_LONG_HAIRED = 'Miniature Long Haired Dachshund',
    DACHSHUND_MINIATURE_SMOOTH_HAIRED = 'Miniature Smooth Haired Dachshund',
    DACHSHUND_MINIATURE_WIRE_HAIRED = 'Miniature Wire Haired Dachshund',
    DACHSHUND_SMOOTH_HAIRED = 'Smooth Haired Dachshund',
    DACHSHUND_WIRE_HAIRED = 'Wire Haired Dachshund',
    DALMATIAN = 'Dalmatian',
    DANDIE_DINMONT_TERRIER = 'Dandie Dinmont Terrier',
    DANISH_SWEDISH_FARMDOG = 'Danish–Swedish Farmdog',
    DEERHOUND = 'Deerhound',
    DEUTSCHER_WACHTELHUND = 'Deutscher Wachtelhund',
    DOBERMANN = 'Dobermann',
    DOGO_ARGENTINO = 'Dogo Argentino',
    DOGUE_DE_BORDEAUX = 'Dogue de Bordeaux',
    DOXIEPOO = 'Doxiepoo',
    DRENTSCHE_PATRIJSHOND = 'Drentsche Patrijshond',
    DREVER = 'Drever',
    NORWEGIAN_HOUND = 'Norwegian Hound',
    DUTCH_PARTRIDGE_DOG = 'Dutch Partridge Dog',
    DUTCH_SHEPHERD = 'Dutch Shepherd',
    DUTCH_SMOUSHOND = 'Dutch Smoushond',
    EAST_SIBERIAN_LAIKA = 'East Siberian Laika',
    ENGLISH_COCKER_SPANIEL = 'English Cocker Spaniel',
    ENGLISH_SETTER = 'English Setter',
    ENGLISH_SPRINGER_SPANIEL = 'English Springer Spaniel',
    ENGLISH_TOY_SPANIEL = 'English Toy Spaniel',
    ENGLISH_TOY_TERRIER_BLACK_TAN = 'English Toy Terrier',
    ENTLEBUCHER_MOUNTAIN_DOG = 'Entlebucher Mountain Dog',
    ESTONIAN_HOUND = 'Estonian Hound',
    ESTRELA_MOUNTAIN_DOG = 'Estrela Mountain Dog',
    EURASIER = 'Eurasier',
    FIELD_SPANIEL = 'Field Spaniel',
    FINNISH_HOUND = 'Finnish Hound',
    FINNISH_LAPPHUND = 'Finnish Lapphund',
    FINNISH_SPITZ = 'Finnish Spitz',
    FLAT_COATED_RETRIEVER = 'Flat Coated Retriever',
    FORMOSAN_MOUNTAIN_DOG = 'Formosan Mountain Dog',
    FOX_TERRIER_SMOOTH = 'Smooth Fox Terrier',
    FOX_TERRIER_WIRE = 'Wire Fox Terrier',
    FOX_TERRIER = 'Fox Terrier',
    ENGLISH_FOXHOUND = 'English Foxhound',
    FOXHOUND = 'Foxhound',
    FRENCH_BULLDOG = 'French Bulldog',
    FRENCH_POINTING_DOG_GASCOGNE_TYPE = 'Gascogne French Pointing Dog',
    FRENCH_POINTING_DOG_PYRENEAN_TYPE = 'Pyrenean French Pointing Dog ',
    FRENCH_SPANIEL = 'French Spaniel',
    FRENCH_WHITE_AND_BLACK_HOUND = 'French white and black hound',
    FRENCH_WHITE_AND_ORANGE_HOUND = 'French white and orange hound',
    FRISIAN_WATER_DOG = 'Frisian Water Dog',
    GALGO = 'Galgo',
    GRAND_GASCON_SAINTONGEOIS = 'Grand Gascon Saintongeois',
    PETIT_GASCON_SAINTONGEOIS = 'Petit Gascon Saintongeois',
    GASCON_SAINTONGEOIS = 'Gascon Saintongeois',
    GERBERIAN_SHEPSKY = 'Gerberian Shepsky',
    GERMAN_HOUND = 'German Hound',
    GERMAN_LONGHAIRED_POINTER = 'German Longhaired Pointer',
    GERMAN_PINSCHER = 'German Pinscher',
    GERMAN_ROUGHHAIRED_POINTER = 'German Roughhaired Pointer',
    GERMAN_SHEPHERD_DOG = 'German Shepherd Dog',
    GERMAN_SHORTHAIRED_POINTER = 'German Shorthaired Pointer',
    GERMAN_SPANIEL = 'German Spaniel',
    GERMAN_SPITZ_KLEIN = 'Klein German Spitz',
    GERMAN_SPITZ = 'German Spitz',
    GERMAN_SPITZ_MITTEL = 'Mittel German Spitz',
    GERMAN_WIREHAIRED_POINTER = 'German Wirehaired Pointer',
    GIANT_SCHNAUZER = 'Giant Schnauzer',
    GLEN_OF_IMAAL_TERRIER = 'Glen Of Imaal Terrier',
    GOBERIAN = 'Goberian',
    GOLDADOR = 'Goldador',
    GOLDEN_RETRIEVER = 'Golden Retriever',
    GOLDENDOODLE = 'Goldendoodle',
    GORDON_SETTER = 'Gordon Setter',
    GRAND_BLEU_DE_GASCOGNE = 'Grand Bleu De Gascogne',
    GRAND_GRIFFON_VENDEEN = 'Grand Griffon Vendeen',
    GREAT_ANGLO_FRENCH_TRI_COLOUR_HOUND = 'Great Anglo French Tri Colour Hound',
    GREAT_ANGLO_FRENCH_WHITE_AND_BLACK_HOUND = 'Great Anglo French White and Black Hound',
    GREAT_ANGLO_FRENCH_WHITE_AND_ORANGE_HOUND = 'Great Anglo French White and Orange Hound',
    GREAT_DANE = 'Great Dane',
    GREAT_GASCONY_BLUE = 'Great Gascony Blue',
    GREAT_PYRENEES = 'Great Pyrenees',
    GREAT_SWISS_MOUNTAIN_DOG = 'Great Swiss Mountain Dog',
    GREEK_HAREHOUND = 'Greek Harehound',
    GREENLAND_DOG = 'Greenland Dog',
    GREYHOUND = 'Greyhound',
    BLEU_DE_GASCOGNE_GRIFFON = 'Bleu de Gascogne Griffon',
    GRIFFON_BRUXELLOIS = 'Bruxellois Griffon',
    GRIFFON_FAUVE_DE_BRETAGNE = 'Fauve De Bretagne Griffon',
    NIVERNAIS_GRIFFON = 'Nivernais Griffon',
    WIREHAIRED_POINTING_GRIFFON = 'Wirehaired Pointing Griffon',
    HALDEN_HOUND = 'Halden Hound',
    HAMILTONSTOVARE = 'Hamiltonstovare',
    HANOVER_HOUND = 'Hanover Hound',
    HANOVERIAN_SCENTHOUND = 'Hanoverian Scenthound',
    HARRIER = 'Harrier',
    HAVANESE = 'Havanese',
    HOKKAIDO = 'Hokkaido',
    HOVAWART = 'Hovawart',
    HUNGARIAN_KUVASZ = 'Hungarian Kuvasz',
    HUNGARIAN_PULI = 'Hungarian Puli',
    HUNGARIAN_PUMI = 'Hungarian Pumi',
    HUNGARIAN_VIZSLA = 'Hungarian Vizsla',
    HUNGARIAN_WIRE_HAIRED_VIZSLA = 'Hungarian Wire Haired Vizsla',
    HYGEN_HOUND = 'Hygen Hound',
    IBIZAN_HOUND = 'Ibizan Hound',
    ICELANDIC_SHEEPDOG = 'Icelandic Sheepdog',
    ILLYRIAN_SHEPHERD_DOG = 'Illyrian shepherd dog',
    IRISH_RED_WHITE_SETTER = 'Irish Red & White Setter',
    IRISH_SETTER = 'Irish Setter',
    IRISH_TERRIER = 'Irish Terrier',
    IRISH_WATER_SPANIEL = 'Irish Water Spaniel',
    IRISH_WOLFHOUND = 'Irish Wolfhound',
    ISTRIAN_COARSE_HAIRED_HOUND = 'Istrian Coarse haired Hound',
    ISTRIAN_SHORT_HAIRED_HOUND = 'Istrian Short haired Hound',
    ITALIAN_GREYHOUND = 'Italian Greyhound',
    ITALIAN_SPINONE = 'Italian Spinone',
    JACKAPOO = 'Jackapoo',
    JACK_RUSSELL_TERRIER = 'Jack Russell Terrier',
    JAGDTERRIER = 'Jagdterrier',
    JAPANESE_AKITA_INU = 'Japanese Akita Inu',
    JAPANESE_CHIN = 'Japanese Chin',
    JAPANESE_SHIBA_INU = 'Japanese Shiba Inu',
    JAPANESE_SPITZ = 'Japanese Spitz',
    JAPANESE_TERRIER = 'Japanese Terrier',
    JUG = 'Jug',
    KAI_KEN = 'Kai Ken',
    KARELIAN_BEAR_DOG = 'Karelian Bear Dog',
    KARST_SHEPHERD = 'Karst Shepherd',
    KEESHOND = 'Keeshond',
    KERRY_BLUE_TERRIER = 'Kerry Blue Terrier',
    KING_CHARLES_SPANIEL = 'King Charles Spaniel',
    KINTAMANI_BALI_DOG = 'Kintamani Bali Dog',
    KISHU_KEN = 'Kishu Ken',
    KOMONDOR = 'Komondor',
    KOOIKERHONDJE = 'Kooikerhondje',
    KOREAN_JINDO = 'Korean Jindo',
    KORTHALS_GRIFFON = 'Korthals Griffon',
    KROMFOHRLANDER = 'Kromfohrländer',
    LABRADOODLE = 'Labradoodle',
    LABRADOR_RETRIEVER = 'Labrador Retriever',
    LAGOTTO_ROMAGNOLO = 'Lagotto Romagnolo',
    LAKELAND_TERRIER = 'Lakeland Terrier',
    LANCASHIRE_HEELER = 'Lancashire Heeler',
    LANDSEER = 'Landseer',
    LAPPONIAN_HERDER = 'Lapponian Herder',
    LARGE_MUNSTERLANDER = 'Large Munsterlander',
    LEONBERGER = 'Leonberger',
    LHASA_APSO = 'Lhasa Apso',
    LOWCHEN_LITTLE_LION_DOG = 'Lowchen',
    LUCAS_TERRIER = 'Lucas Terrier',
    LURCHER = 'Lurcher',
    MAGYAR_AGAR = 'Magyar agar',
    MAJORCA_SHEPHERD_DOG = 'Majorca Shepherd Dog',
    MALSHI = 'Malshi',
    MALTESE = 'Maltese',
    MALTIPOO = 'Maltipoo',
    MANCHESTER_TERRIER = 'Manchester Terrier',
    MAREMMA_SHEEPDOG = 'Maremma Sheepdog',
    MASTIFF = 'Mastiff',
    MINIATURE_AMERICAN_SHEPHERD = 'Miniature American Shepherd',
    BULL_TERRIER_MINIATURE = 'Miniature Bull Terrier',
    MINIATURE_PINSCHER = 'Miniature Pinscher',
    MINIATURE_SCHNAUZER = 'Miniature Schnauzer',
    MONTENEGRIN_MOUNTAIN_HOUND = 'Montenegrin Mountain Hound',
    MORKIE = 'Morkie',
    MOUNTAIN_CUR = 'Mountain Cur',
    MUDI = 'Mudi',
    NEAPOLITAN_MASTIFF = 'Neapolitan Mastiff',
    NEWFOUNDLAND = 'Newfoundland',
    NORFOLK_TERRIER = 'Norfolk Terrier',
    NORRBOTTENSPETS = 'Norrbottenspets',
    NORTHERN_INUIT = 'Northern Inuit',
    NORWEGIAN_BUHUND = 'Norwegian Buhund',
    NORWEGIAN_ELKHOUND = 'Norwegian Elkhound',
    NORWEGIAN_LUNDEHUND = 'Norwegian Lundehund',
    NORWICH_TERRIER = 'Norwich Terrier',
    NOVA_SCOTIA_DUCK_TOLLING_RETRIEVER = 'Nova Scotia Duck Tolling Retriever',
    OLD_DANISH_POINTER = 'Old Danish Pointer',
    OLD_ENGLISH_SHEEPDOG = 'Old English Sheepdog',
    OTTERHOUND = 'Otterhound',
    PAPILLON = 'Papillon',
    PARSON_RUSSELL_TERRIER = 'Parson Russell Terrier',
    PATTERDALE_TERRIER = 'Patterdale Terrier',
    PEEKAPOO = 'Peekapoo',
    PEKINGESE = 'Pekingese',
    PERRO_DE_PRESA_CANARIO = 'Perro de Presa Canario',
    PERUVIAN_INCA_ORCHID_DOG = 'Peruvian Inca Orchid Dog',
    PETIT_BLEU_DE_GASCOGNE = 'Petit Bleu de Gascogne',
    PHARAOH_HOUND = 'Pharaoh Hound',
    PICARDY_SHEEPDOG = 'Picardy',
    PICARDY_SPANIEL = 'Picardy Spaniel',
    PLOTT_HOUND = 'Plott Hound',
    PODENCO_CANARIO = 'Podenco Canario',
    POINTER = 'Pointer',
    POITEVIN = 'Poitevin',
    POLISH_GREYHOUND = 'Polish Greyhound',
    POLISH_HOUND = 'Polish Hound',
    POLISH_HUNTING_DOG = 'Polish Hunting Dog',
    POLISH_LOWLAND_SHEEPDOG = 'Polish Lowland Sheepdog',
    POMAPOO = 'Pomapoo',
    POMERANIAN = 'Pomeranian',
    POMSKY = 'Pomsky',
    PONT_AUDEMER_SPANIEL = 'Pont Audemer Spaniel',
    POOCHON = 'Poochon',
    POODLE = 'Poodle',
    POODLE_MINIATURE = 'Miniature Poodle',
    POODLE_TOY = 'Toy Poodle',
    PORCELAINE = 'Porcelaine',
    PORTUGUESE_CATTLE_DOG = 'Portuguese cattle dog',
    PORTUGUESE_PODENGO = 'Portuguese Podengo',
    PORTUGUESE_PODENGO_PEQUENO = 'Portuguese Podengo Pequeno',
    PORTUGUESE_POINTER = 'Portuguese Pointer',
    PORTUGUESE_SHEEPDOG = 'Portuguese Sheepdog',
    PORTUGUESE_WATER_DOG = 'Portuguese Water Dog',
    POSAVAC_HOUND = 'Posavac Hound',
    PRAGUE_RATTER = 'Prague Ratter',
    PUDELPOINTER = 'Pudelpointer',
    PUG = 'Pug',
    PUGGLE = 'Puggle',
    PYRENEAN_MASTIFF = 'Pyrenean Mastiff',
    PYRENEAN_MOUNTAIN_DOG = 'Pyrenean Mountain Dog',
    PYRENEAN_SHEEPDOG_LONG_HAIRED = 'Long Haired Pyrenean Sheepdog',
    SMOOTH_FACED_PYRENEAN_SHEEPDOG = 'Smooth Faced Pyrenean Sheepdog',
    RAFEIRO_DO_ALENTEJO = 'Rafeiro do Alentejo',
    RASTREADOR_BRASILEIRO = 'Rastreador Brasileiro',
    RAT_TERRIER = 'Rat Terrier',
    REDBONE_COONHOUND = 'Redbone Coonhound',
    RHODESIAN_RIDGEBACK = 'Rhodesian Ridgeback',
    ROMANIAN_MIORITIC_SHEPHERD_DOG = 'Romanian Mioritic Shepherd Dog',
    ROTTWEILER = 'Rottweiler',
    RUSSIAN_BLACK_TERRIER = 'Russian Black Terrier',
    RUSSIAN_TOY = 'Russian Toy',
    RUSSO_EUROPEAN_LAIKA = 'Russo European Laika',
    SABUESO_ESPANOL = 'Sabueso Espanol',
    ST_BERNARD = 'Saint Bernard',
    SAINT_GERMAIN_POINTING_DOG = 'Saint Germain Pointing Dog',
    SAINT_MIGUEL_CATTLE_DOG = 'Saint Miguel Cattle Dog',
    SALUKI = 'Saluki',
    SAMOYED = 'Samoyed',
    SARLOOS_WOLFDOG = 'Sarloos Wolfdog',
    SCHAPENDOES = 'Schapendoes',
    SCHILLERSTOVARE = 'Schillerstovare',
    SCHIPPERKE = 'Schipperke',
    SCHNAUZER = 'Schnauzer',
    SCHNOODE = 'Schnoodle',
    SCHWEIZER_LAUFHUND = 'Schweizer Laufhund',
    SMALL_SWISS_HOUND = 'Small Swiss Hound',
    SCOTTISH_TERRIER = 'Scottish Terrier',
    SEALYHAM_TERRIER = 'Sealyham Terrier',
    SEGUGIO_ITALIANO = 'Segugio Italiano',
    SEGUGIO_MAREMMANO = 'Segugio Maremmano',
    SERBIAN_HOUND = 'Serbian Hound',
    SERBIAN_TRICOLOUR_HOUND = 'Serbian Tricolour Hound',
    SHAR_PEI = 'Shar Pei',
    SHEEPADOODLE = 'Sheepadoodle',
    BELGIAN_SHEPHERD_DOG = 'Belgian Shepherd',
    SHETLAND_SHEEPDOG = 'Shetland Sheepdog',
    SHI_POO = 'Shi poo',
    SHIH_TZU = 'Shih Tzu',
    SHIKOKU = 'Shikoku',
    SHORKIE = 'Shorkie',
    SIBERIAN_HUSKY = 'Siberian Husky',
    SKYE_TERRIER = 'Skye Terrier',
    SLOUGHI = 'Sloughi',
    SLOVAKIAN_ROUGH_HAIRED_POINTER = 'Slovakian Rough Haired Pointer',
    CUVAC = 'Cuvac',
    KOPOV = 'Kopov',
    SMALAND_HOUND = 'Smaland Hound',
    SMALL_MUNSTERLANDER = 'Small Munsterlander',
    SOFT_COATED_WHEATEN_TERRIER = 'Soft Coated Wheaten Terrier',
    SOUTH_RUSSIAN_SHEPHERD_DOG = 'South Russian Shepherd Dog',
    SPANISH_MASTIFF = 'Spanish Mastiff',
    SPANISH_WATER_DOG = 'Spanish Water Dog',
    SPROCKER = 'Sprocker',
    STABYHOUN = 'Stabyhoun',
    STAFFORDSHIRE_BULL_TERRIER = 'Staffordshire Bull Terrier',
    STYRIAN_COARSE_HAIRED_HOUND = 'Styrian Coarse haired Hound',
    SUSSEX_SPANIEL = 'Sussex Spaniel',
    SWEDISH_ELKHOUND = 'Swedish Elkhound',
    SWEDISH_LAPPHUND = 'Swedish Lapphund',
    SWEDISH_VALLHUND = 'Swedish Vallhund',
    TAIWAN_DOG = 'Taiwan Dog',
    TATRA_SHEPHERD_DOG = 'Tatra Shepherd Dog',
    TEDDY_ROOSEVELT_TERRIER = 'Teddy Roosevelt Terrier',
    THAI_BANGKAEW_DOG = 'Thai Bangkaew Dog',
    THAI_RIDGEBACK = 'Thai Ridgeback',
    TIBETAN_MASTIFF = 'Tibetan Mastiff',
    TIBETAN_SPANIEL = 'Tibetan Spaniel',
    TIBETAN_TERRIER = 'Tibetan Terrier',
    TORNJAK = 'Tornjak',
    TOSA = 'Tosa',
    TOY_FOX_TERRIER = 'Toy Fox Terrier',
    TRANSMONTANO_MASTIFF = 'Transmontano Mastiff',
    TRANSYLVANIAN_HOUND = 'Transylvanian Hound',
    TREEING_TENNESSEE_BRINDLE = 'Treeing Tennessee Brindle',
    TREEING_WALKER_COONHOUND = 'Treeing Walker Coonhound',
    TURKISH_KANGAL_DOG = 'Turkish Kangal Dog',
    TYROLEAN_HOUND = 'Tyrolean Hound',
    VOLPINO_ITALIANO = 'Volpino Italiano',
    WEIMADOR = 'Weimador',
    WEIMARANER = 'Weimaraner',
    WELSH_CORGI_CARDIGAN = 'Cardigan Welsh Corgi',
    WELSH_CORGI_PEMBROKE = 'Pembroke Welsh Corgi',
    WELSH_SPRINGER_SPANIEL = 'Welsh Springer Spaniel',
    WELSH_TERRIER = 'Welsh Terrier',
    WEST_HIGHLAND_WHITE_TERRIER = 'West Highland White Terrier',
    WEST_SIBERIAN_LAIKA = 'West Siberian Laika',
    WESTPHALIAN_HOUND = 'Westphalian Hound',
    WETTERHOUN = 'Wetterhoun',
    WHIPPET = 'Whippet',
    WHITE_SWISS_SHEPHERD_DOG = 'White Swiss Shepherd Dog',
    WHOODLE = 'Whoodle',
    WORKING_KELPIE = 'Working Kelpie',
    MEXICAN_HAIRLESS = 'Mexican Hairless',
    XOLOITZCUINTLE_MEXICAN_HAIRLESS_INT = 'Mexican Hairless Int',
    XOLOITZCUINTLE_MEXICAN_HAIRLESS_MIN = 'Mexican Hairless Min',
    XOLOITZCUINTLE_MEXICAN_HAIRLESS_STD = 'Mexican Hairless Std',
    YAKUTIAN_LAIKA = 'Yakutian Laika',
    YORKIEPOO = 'Yorkiepoo',
    YORKSHIRE_TERRIER = 'Yorkshire Terrier',
    ZUCHON = 'Zuchon',
    LARGE_CROSSBREED = 'Large Crossbreed',
    LARGE_MIXED_BREED = 'Large Mixed Breed',
    SMALL_CROSSBREED = 'Small Crossbreed',
    SMALL_MIXED_BREED = 'Small Mixed Breed',
    CROSSBREED = 'Medium Crossbreed',
    MIXED_BREED = 'Medium Mixed Breed',
}

export type BreedKey = keyof typeof Breed;

export enum Gender {
    MALE = 'Male',
    FEMALE = 'Female',
    TBD = 'To be determined',
}
