import { selectContentfulTopics, Topic } from '@frontend/data-access/contentful';
import { createSelector } from '@ngrx/store';
import { onboardingFeature } from '../../data-access/onboarding.reducer';

export const selectQuestionExtraTopicPageVm = createSelector(
    onboardingFeature.selectSelectedTopicIds,
    selectContentfulTopics,
    (selectedIds, topics) => {
        return {
            topics: topics.map((topic): Topic & { selected: boolean } => ({
                ...topic,
                selected: selectedIds.some((selectedTopicId) => selectedTopicId === topic.entityId),
            })),
            hasSelectedTopics: selectedIds.length > 0,
        };
    },
);
