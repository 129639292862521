import { accountFeature, selectAgeInWeeks, selectHasArrived } from '@frontend/data-access/user/account';
import { selectIsHomeRecommendedContentEnabled } from '@frontend/data-access/user/config-cat';
import { createSelector } from '@ngrx/store';
import {
    AiChatSuggestedQuestionsCardConfig,
    fiftyTwoPlusWeeks,
    pupNotArrivedOptions,
    pupNotArrivedOptionsHomeRecommended,
    twelveToTwentySixWeekOptions,
    twentySixPlusWeeks,
    twentySixPlusWeeksAndRescue,
    zeroToTwelveWeeksOptions,
} from '../../suggested-questions-prompt/suggested-questions-card-options.constants';

export const selectAiChatSuggestedQuestionsCardData = createSelector(
    selectHasArrived,
    selectAgeInWeeks,
    accountFeature.selectIsRescuePup,
    selectIsHomeRecommendedContentEnabled,
    (hasArrived, weeksOld, isRescue, isHomeRecommendedContentEnabled): AiChatSuggestedQuestionsCardConfig[] => {
        if (!isHomeRecommendedContentEnabled) {
            if (!hasArrived) {
                return pupNotArrivedOptions;
            }

            if (weeksOld && 12 <= weeksOld && weeksOld < 26) {
                return twelveToTwentySixWeekOptions;
            }

            if (weeksOld && weeksOld >= 26 && isRescue) {
                return twentySixPlusWeeksAndRescue;
            }

            if (weeksOld && weeksOld >= 26 && weeksOld < 52) {
                return twentySixPlusWeeks;
            }

            if (weeksOld && weeksOld >= 52) {
                return fiftyTwoPlusWeeks;
            }

            return zeroToTwelveWeeksOptions;
        }

        // Home recommended content enabled
        if (!hasArrived) {
            return pupNotArrivedOptionsHomeRecommended;
        }

        if (weeksOld && 12 <= weeksOld && weeksOld < 26) {
            return twelveToTwentySixWeekOptions;
        }

        if (weeksOld && weeksOld >= 26 && isRescue) {
            return twentySixPlusWeeksAndRescue;
        }

        if (weeksOld && weeksOld >= 26 && weeksOld < 52) {
            return pupNotArrivedOptionsHomeRecommended;
        }

        if (weeksOld && weeksOld >= 52) {
            return fiftyTwoPlusWeeks;
        }

        return zeroToTwelveWeeksOptions;
    },
);
