import { AsyncPipe } from '@angular/common';
import { Component } from '@angular/core';
import { Color } from '@shared/utils/typescript';
import { AnalyticsTrackClickDirective } from '@frontend/ui';
import { Gender } from '@shared/user-domain';
import { IonButton, IonImg, IonSpinner } from '@ionic/angular/standalone';
import { OnboardingBaseComponent } from '../../onboarding-base/onboarding-base.component';
import { selectGenderPageVm } from './question-gender.page.selectors';
import { OnboardingQuestionTemplateComponent } from '../../onboarding-question-template/onboarding-question-template.component';
import { onboardingQuestionSubmittedGender } from '../../data-access/onboarding.actions';

@Component({
    selector: 'app-onboarding-question-gender',
    templateUrl: 'question-gender.page.html',
    styleUrls: ['../../onboarding.scss', 'question-gender.page.scss'],
    standalone: true,
    imports: [
        OnboardingQuestionTemplateComponent,
        AnalyticsTrackClickDirective,
        AsyncPipe,
        IonImg,
        IonButton,
        IonSpinner,
    ],
})
export class OnboardingQuestionGenderPage extends OnboardingBaseComponent {
    public vm = this.store.selectSignal(selectGenderPageVm);

    protected readonly Gender = Gender;
    protected readonly Color = Color;

    public genderAnswered(gender: Gender): void {
        if (this.vm().isLoading) {
            return;
        }

        this.store.dispatch(onboardingQuestionSubmittedGender({ gender }));
    }
}
