@if (vm$ | async; as vm) {
<ion-header class="page-header ion-no-border">
    <ion-toolbar>
        <app-header
            identifierLeftButton="question-create-account-back-btn"
            [isDisabled]="vm.isLoading"
            [progressRatio]="0"
            [hasLeftButton]="true"
            (leftButtonPressed)="routeToPrevious()"
        />
    </ion-toolbar>
</ion-header>

<ion-content class="page-ion-content ion-text-center doodle" [fullscreen]="true">
    <div class="page-content ion-justify-content-start">
        <div class="onboarding-header">
            <h1>Let's sign up!</h1>
            <p class="ion-padding-horizontal" data-test="create-account-page-subtitle">
                We'll save your progress and send you updates to help with your training journey!
            </p>
        </div>
        <app-create-account-form (signUpFormChange)="handleSignUpFormChange($event)" />
    </div>
</ion-content>

@if (!vm.isInputFocusedOnAndroid) {
<ion-footer class="ion-text-center page-footer">
    <div class="sign-up-footer">
        <div class="hide-on-keyboard-small-screen">
            @if (!vm.isInputFocusedOnAndroid) {
            <p data-test="create-account-disclaimer">
                By signing up to Zigzag, you agree to our <br /><strong
                    data-test="show-terms"
                    (click)="openTerms(vm.countryCode)"
                    >Terms</strong
                >
                &
                <strong data-test="show-privacy" (click)="openPrivacy(vm.countryCode)">Privacy statement</strong>
            </p>
            }
            <ion-button
                appTrackClick
                identifier="question-create-account-sign-up-btn"
                expand="block"
                data-test="question-create-account-sign-up-btn"
                [color]="Color.Max"
                [disabled]="form && (!form.controls.email.valid || !form.controls.password.valid || vm.isLoading)"
                (click)="signUpClicked(form.value.email, form.value.password)"
            >
                @if (vm.isLoading) {
                <ion-spinner name="crescent" />
                } @else { Sign up }
            </ion-button>
        </div>
    </div>
</ion-footer>
} }
