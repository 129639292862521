import { AiChatSuggestedQuestionsCardConfig } from './suggested-questions-card-options.constants';

export const mapSuggestedQuestionCard = (
    suggestedQuestionCard: AiChatSuggestedQuestionsCardConfig,
    isUsUser: boolean,
) => {
    return {
        title: isUsUser && suggestedQuestionCard.titleUs ? suggestedQuestionCard.titleUs : suggestedQuestionCard.title,
        identifier: suggestedQuestionCard.identifier,
        imageUrl: suggestedQuestionCard.imageUrl,
        background: suggestedQuestionCard.background,
        options: suggestedQuestionCard.options.map((option) => ({
            id: option.id,
            label: isUsUser && option.labelUs ? option.labelUs : option.label,
            value: isUsUser && option.valueUs ? option.valueUs : option.value,
        })),
    };
};
