import { IsDate, IsNumber, IsString } from 'class-validator';

export class DomainPostTodayProgressCommand {
    @IsString()
    courseId!: string;

    @IsNumber()
    numberOfStepsCompleted!: number;

    @IsDate()
    date!: Date;
}
