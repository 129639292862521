import { NgClass } from '@angular/common';
import { Component, inject, input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { PluginPermissionsMap } from '@ebarooni/capacitor-calendar';
import { trackEvent } from '@frontend/data-access/analytics';
import { openSettingsAndroid, openSettingsIos } from '@frontend/data-access/capacitor';
import { dismissInfo } from '@frontend/data-access/dismissible-info';
import { HeaderComponent } from '@frontend/ui';
import { ModalService } from '@frontend/utility/modal';
import {
    IonButton,
    IonContent,
    IonDatetime,
    IonFooter,
    IonHeader,
    IonLabel,
    IonToolbar,
    Platform,
} from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { Color } from '@shared/utils/typescript';
import { AndroidSettings, IOSSettings } from 'capacitor-native-settings';
import { addMinutes, format } from 'date-fns';
import { openToast } from '../../../store/toast/toast.actions';
import { CalendarService } from '../../service/calendar.service';
import {
    TRAINING_REMINDER_MODAL_CALENDAR_EVENT_URL,
    TRAINING_REMINDER_TILE_ID,
} from '../../training-reminder.constant';

@Component({
    selector: 'app-training-reminder-modal',
    templateUrl: './training-reminder-modal.component.html',
    styleUrl: './training-reminder-modal.component.scss',
    standalone: true,
    imports: [
        NgClass,
        IonButton,
        IonContent,
        IonDatetime,
        IonFooter,
        IonHeader,
        IonLabel,
        IonToolbar,
        HeaderComponent,
        ReactiveFormsModule,
    ],
})
export class TrainingReminderModalComponent implements OnInit {
    private store = inject(Store);
    private formBuilder = inject(FormBuilder);
    private platform = inject(Platform);
    private calendarService = inject(CalendarService);
    private modalService = inject(ModalService);

    public dogName = input.required<string>();

    public form!: FormGroup;

    public ngOnInit(): void {
        const formattedDateTime = format(new Date(), "yyyy-MM-dd'T'HH:mm:ss");

        this.form = this.formBuilder.group({
            time: [formattedDateTime, Validators.required],
        });
    }

    public onClickCloseModal(): void {
        this.store.dispatch(
            trackEvent({
                eventName: `[Training Reminder] Close Button Clicked`,
            }),
        );

        void this.modalService.dismiss();
    }

    public async createCalendarEvent() {
        const title = `${this.dogName()}'s training reminder`;
        const startDate = new Date(this.form.value.time).getTime();
        const endDate = addMinutes(startDate, 15).getTime(); // 15 minutes later

        const notes = this.platform.is('ios')
            ? `It's time to train. ${TRAINING_REMINDER_MODAL_CALENDAR_EVENT_URL}`
            : `It's time to train. <a href="${TRAINING_REMINDER_MODAL_CALENDAR_EVENT_URL}">click here</a>`; // Google Calendar, clickable link

        const { result } = await this.calendarService.createEventWithPrompt(title, startDate, endDate, notes);

        if (!result.length) {
            return;
        }

        this.store.dispatch(
            dismissInfo({
                id: TRAINING_REMINDER_TILE_ID,
            }),
        );
        this.store.dispatch(
            openToast({
                message: 'Training reminder has been set',
                color: Color.Luna,
            }),
        );
        this.store.dispatch(
            trackEvent({
                eventName: `[Training Reminder] Training Reminder Added to Calendar`,
            }),
        );

        void this.modalService.dismiss();
    }

    openApplicationDetails() {
        if (this.platform.is('android')) {
            this.store.dispatch(openSettingsAndroid({ option: AndroidSettings.ApplicationDetails }));
        }

        if (this.platform.is('ios')) {
            this.store.dispatch(openSettingsIos({ option: IOSSettings.App }));
        }
    }

    public async onClickAddToCalendar() {
        try {
            this.store.dispatch(
                trackEvent({
                    eventName: `[Training Reminder] Add To Calendar Button Clicked`,
                }),
            );

            const permissions: PluginPermissionsMap = await this.calendarService.checkAllPermissions();

            //first time user clicks add to calendar
            if (permissions['readCalendar'] === 'prompt' || permissions['writeCalendar'] === 'prompt') {
                const { result } = await this.calendarService.requestFullCalendarAccess();
                if (result === 'denied') {
                    return;
                }
                if (result === 'granted') {
                    await this.createCalendarEvent();
                    return;
                }
            }

            //second time user clicks add to calendar
            if (permissions['readCalendar'] === 'denied' || permissions['writeCalendar'] === 'denied') {
                this.openApplicationDetails();
                return;
            }

            await this.createCalendarEvent();
        } catch (error) {
            this.store.dispatch(
                trackEvent({
                    eventName: `[Training Reminder] Add To Calendar Failed`,
                    eventProperties: {
                        error: error,
                    },
                }),
            );
        }
    }
}
