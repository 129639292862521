import { createSelector } from '@ngrx/store';
import { configCatFeature } from './config-cat.reducer';
import { filterConfigCatStateFlag } from './config-cat.utils';

export const selectFreeContent = createSelector(configCatFeature.selectConfigCatFlags, (state): boolean =>
    filterConfigCatStateFlag('freeContent', state),
);

export const selectIsTestUser = createSelector(configCatFeature.selectConfigCatFlags, (state): boolean =>
    filterConfigCatStateFlag('isTestUser', state),
);

export const selectIsDevWithFreeContent = createSelector(
    selectFreeContent,
    selectIsTestUser,
    (freeContent, devUser): boolean => freeContent && devUser,
);

export const selectShowFoodCalculator = createSelector(configCatFeature.selectConfigCatFlags, (state): boolean => {
    return filterConfigCatStateFlag('foodCalculator', state);
});

export const selectIsAIChatEnabled = createSelector(configCatFeature.selectConfigCatFlags, (state): boolean => {
    return filterConfigCatStateFlag('aiChat', state);
});

export const selectIsRescuePupValuePropAb = createSelector(configCatFeature.selectConfigCatFlags, (state): boolean => {
    return filterConfigCatStateFlag('rescuePupValueProp', state);
});

export const selectIsSelfSetReminders = createSelector(configCatFeature.selectConfigCatFlags, (state): boolean => {
    return filterConfigCatStateFlag('selfSetReminders', state);
});

export const selectIsSsoEnabled = createSelector(configCatFeature.selectConfigCatFlags, (state): boolean => {
    return filterConfigCatStateFlag('ssoEnabled', state);
});

export const selectIsHomeRecommendedContentEnabled = createSelector(
    configCatFeature.selectConfigCatFlags,
    (state): boolean => {
        return filterConfigCatStateFlag('homeRecommendedContentEnabled', state);
    },
);

export const selectIsFollowUpDiscountOfferAb = createSelector(
    configCatFeature.selectConfigCatFlags,
    (state): boolean => {
        return filterConfigCatStateFlag('followUpDiscountOffer', state);
    },
);

export const selectReversePaywallAb = createSelector(configCatFeature.selectConfigCatFlags, (state) => {
    return filterConfigCatStateFlag('reversePaywallAb', state);
});
