import { AsyncPipe } from '@angular/common';
import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { loginWithApple, loginWithGoogle } from '@frontend/data-access/user/authentication';
import { AnalyticsTrackClickDirective, ErrorScreenComponent } from '@frontend/ui';
import { ModalService } from '@frontend/utility/modal';
import { Network } from '@capacitor/network';
import { IonButton, IonContent, IonFooter, IonSpinner, IonText } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { Color } from '@shared/utils/typescript';
import { getUserIdForFeatureFlags, signInClicked } from './landing.page.actions';
import { routeTo } from '@frontend/data-access/router';
import { selectLandingPageVm } from './landing.page.selectors';

// TODO: NBSon - some members/methods are public (for the sake of testing), but not used in the template, consider making them private?

@Component({
    selector: 'app-authentication',
    templateUrl: './landing.page.html',
    styleUrls: ['./landing.page.scss'],
    standalone: true,
    imports: [AnalyticsTrackClickDirective, AsyncPipe, IonButton, IonContent, IonFooter, IonSpinner, IonText],
})
export class LandingPage implements OnInit, OnDestroy {
    private readonly store = inject(Store);
    private readonly modalService = inject(ModalService);

    public vm = this.store.selectSignal(selectLandingPageVm);

    public isConnectedToInternet = true;

    protected readonly Color = Color;

    public async ngOnInit(): Promise<void> {
        await Network.addListener('networkStatusChange', (networkStatus) => {
            this.isConnectedToInternet = networkStatus.connected;
        });

        this.store.dispatch(getUserIdForFeatureFlags());
    }

    public async ngOnDestroy(): Promise<void> {
        await Network.removeAllListeners();
    }

    public getStarted(): void {
        this.isConnectedToInternet
            ? this.store.dispatch(
                  routeTo({
                      commands: ['auth', 'create-account'],
                  }),
              )
            : this.openErrorModal();
    }

    public signInWithApple(): void {
        this.store.dispatch(loginWithApple());
    }

    public signInWithGoogle(): void {
        this.store.dispatch(loginWithGoogle());
    }

    public signIn(): void {
        this.isConnectedToInternet ? this.store.dispatch(signInClicked()) : this.openErrorModal();
    }

    public openErrorModal(): void {
        void this.modalService.showModal({
            component: ErrorScreenComponent,
            cssClass: ['modal', 'modal-fullscreen'],
            componentProps: {
                errorMessage:
                    'It looks like you aren’t connected to the internet. Please check your connection and try again!',
                buttonText: 'Try again',
            },
        });
    }
}
