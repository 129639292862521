<ion-header class="page-header ion-no-border">
    <ion-toolbar>
        <app-header
            title="Something else"
            identifierLeftButton="topic-extra-back-button"
            [hasLeftButton]="true"
            (leftButtonPressed)="routeToPrevious()"
        />
    </ion-toolbar>
</ion-header>

<ion-content>
    <ion-list class="topic-item-list">
        @for (topic of vm().topics; track topic.entityId; let i = $index) {
        <ion-item class="topic-item" detail="false" lines="none">
            <img
                slot="start"
                class="topic-image"
                [ngStyle]="{ 'background-color': 'var(--ion-color-' + topic.color + ')' }"
                [src]="topic.imageUrl"
                [alt]="topic.title"
            />
            <ion-checkbox
                [value]="topic.entityId"
                [checked]="topic.selected"
                [attr.data-test]="topic.title"
                (ionChange)="onCheckboxChange($event)"
            >
                {{ topic.title }}
            </ion-checkbox>
        </ion-item>
        }
    </ion-list>
</ion-content>

<ion-footer
    class="ion-text-center gradient-footer"
    [ngClass]="vm().hasSelectedTopics ? 'onboarding-radio-button-container' : ''"
>
    <ion-button
        class="light-button-activated-jack"
        expand="block"
        appTrackClick
        data-test="question-topic-extra-next-btn"
        identifier="question-topic-extra-next-btn"
        (click)="continue()"
        [color]="vm().hasSelectedTopics ? Color.Max : ''"
        >{{ vm().hasSelectedTopics ? 'Next' : 'Skip' }}
    </ion-button>
</ion-footer>
