import { InternalIterableSendPushNotificationCommand } from '../../commands/internal-api/third-party/iterable/internal-iterable-send-push-notification.command';

export class InternalIterableSendPushNotificationCommandMock {
    private defaultValue: InternalIterableSendPushNotificationCommand = {
        recipientUserId: 'user-id',
        campaignId: 1,
    };

    constructor(overrides?: Partial<InternalIterableSendPushNotificationCommand>) {
        Object.assign(this.defaultValue, overrides);
    }

    public get value(): InternalIterableSendPushNotificationCommand {
        return this.defaultValue;
    }
}
